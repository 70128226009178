import { Injectable } from '@angular/core';
import { OrderType, OrderViewModel } from '@traas/boldor/all-models';
import {
    RefundDepartureTicketGQL,
    RefundDepartureTicketMutation,
    RefundDepartureTicketMutationVariables,
    RefundItineraryTicketGQL,
    RefundItineraryTicketMutation,
    RefundItineraryTicketMutationVariables,
    RefundQuickTicketGQL,
    RefundQuickTicketMutation,
    RefundQuickTicketMutationVariables,
} from '@traas/boldor/graphql-generated/graphql';
import { BoldorLocalizationService } from '@traas/boldor/localization';
import { firstValueFrom } from 'rxjs';

type RefundVariablesTypes =
    | RefundDepartureTicketMutationVariables
    | RefundItineraryTicketMutationVariables
    | RefundQuickTicketMutationVariables;

type RefundResultsTypes =
    | RefundQuickTicketMutation['purchasePublic']['refundQuickTicketOrder']
    | RefundDepartureTicketMutation['purchasePublic']['refundDepartureTicketOrder']
    | RefundItineraryTicketMutation['purchasePublic']['refundItineraryTicketOrder'];

@Injectable({
    providedIn: 'root',
})
export class RefundOrderService {
    constructor(
        private refundQuickTicketGQL: RefundQuickTicketGQL,
        private refundDepartureTicketGQL: RefundDepartureTicketGQL,
        private refundItineraryTicketGQL: RefundItineraryTicketGQL,
        private boldorLocalizationService: BoldorLocalizationService,
    ) {}

    #createRefundVariables(orderId: string): RefundVariablesTypes {
        const langId = this.boldorLocalizationService.languageCode;
        return {
            order: {
                orderId,
            },
            language: {
                id: langId,
            },
        };
    }

    async refundOrder(ticketOrder: OrderViewModel): Promise<RefundResultsTypes> {
        const variables = this.#createRefundVariables(ticketOrder.id);
        switch (ticketOrder.orderType) {
            case OrderType.BuyDepartureTickets:
                return this.#refundDepartureTicketOrder(variables);
            case OrderType.BuyItineraryTickets:
                return this.#refundItineraryTicketOrder(variables);
            case OrderType.BuyQuickTicket:
            default:
                return this.#refundQuickTicketOrder(variables);
        }
    }

    async #refundQuickTicketOrder(
        variables: RefundVariablesTypes,
    ): Promise<RefundQuickTicketMutation['purchasePublic']['refundQuickTicketOrder']> {
        const result = await firstValueFrom(this.refundQuickTicketGQL.mutate(variables));
        const refundQuickTicketOrder = result.data?.purchasePublic.refundQuickTicketOrder;
        if (!refundQuickTicketOrder) {
            throw new Error('Error: refundQuickTicket response must not be empty.');
        }
        return refundQuickTicketOrder;
    }

    async #refundDepartureTicketOrder(
        variables: RefundVariablesTypes,
    ): Promise<RefundDepartureTicketMutation['purchasePublic']['refundDepartureTicketOrder']> {
        const result = await firstValueFrom(this.refundDepartureTicketGQL.mutate(variables));
        const refundDepartureTicketOrder = result.data?.purchasePublic.refundDepartureTicketOrder;
        if (!refundDepartureTicketOrder) {
            throw new Error('Error: refundDepartureTicket response must not be empty.');
        }
        return refundDepartureTicketOrder;
    }

    async #refundItineraryTicketOrder(
        variables: RefundVariablesTypes,
    ): Promise<RefundItineraryTicketMutation['purchasePublic']['refundItineraryTicketOrder']> {
        const result = await firstValueFrom(this.refundItineraryTicketGQL.mutate(variables));
        const refundItineraryTicketOrder = result.data?.purchasePublic.refundItineraryTicketOrder;
        if (!refundItineraryTicketOrder) {
            throw new Error('Error: refundItineraryTicket response must not be empty.');
        }
        return refundItineraryTicketOrder;
    }
}
