import { Area, BookmarkArea, Metadata, StopArea } from '../area.model';
import { Bookmark } from '../bookmark.model';
import { ChangePlaceEventSourceEnum } from './change-place-event-source.enum';
import { LatLngBounds } from 'leaflet';
import { LatLngRect, toBounds, toLatLngRect } from '../lat-lng-rect';
import { YOUR_LOCATION_I18N_KEY } from './your-location-i18n-key';
import { PhysicalStop } from '@traas/common/models';

export function createStopArea(
    stops: PhysicalStop[],
    source: ChangePlaceEventSourceEnum,
    bounds: LatLngBounds | null,
    locationName = '',
    latitude = '',
    longitude = '',
): StopArea {
    return {
        metadata: {
            source,
            locationName,
            longitude,
            latitude,
        },
        physicalStops: stops,
        boundsRect: bounds ? toLatLngRect(bounds) ?? null : null,
    };
}

export function createEmptyArea(source: ChangePlaceEventSourceEnum): Area {
    return {
        metadata: {
            source,
            locationName: '',
            latitude: '',
            longitude: '',
        },
        boundsRect: null,
    };
}

export function createMyGpsPositionArea(stops: PhysicalStop[], bounds: LatLngRect | null): StopArea {
    const gpsBounds = toBounds(bounds);
    if (!gpsBounds) {
        throw new Error(`createMyGpsPositionArea: Bounds is null or undefined.`);
    }
    const centerOfGpsBounds = gpsBounds.getCenter();
    return {
        metadata: {
            source: ChangePlaceEventSourceEnum.MyGpsPositionSelection,
            locationName: YOUR_LOCATION_I18N_KEY,
            latitude: `${centerOfGpsBounds.lat}`,
            longitude: `${centerOfGpsBounds.lng}`,
        },
        physicalStops: stops,
        boundsRect: bounds,
    };
}

export function createEmptyGPSLocationArea(): StopArea {
    return {
        metadata: {
            source: ChangePlaceEventSourceEnum.ClickFollowGps,
            locationName: YOUR_LOCATION_I18N_KEY,
            latitude: '',
            longitude: '',
        },
        physicalStops: [],
        boundsRect: null,
    };
}

export function createBookmarkArea(bookmark: Bookmark, metadata: Metadata, boundsRect: LatLngRect | null): BookmarkArea {
    return {
        metadata,
        boundsRect,
        originalBookmark: bookmark,
    };
}
