import { inject, Injectable } from '@angular/core';
import { DepartureService } from '../../../features/departure/services/departure.service';
import { ItineraryArticleService } from '../../../features/itinerary/services/itinerary-article.service';
import {
    ArticlesBundle,
    Cart,
    CheckoutStep,
    Departure,
    DepartureArticleViewModel,
    GuestCustomer,
    Itinerary,
    ItineraryCart,
    JourneyData,
    NearestStop,
    OrderViewModel,
    QuickArticleViewModel,
    TicketDuration,
    Zone,
} from '@traas/boldor/all-models';
import { QuickArticleService } from '../../../features/ticket/services/quick-article.service';
import { DepartureAdapter, isDepartureJourney } from '../../../models/departure/departure';
import { isItineraryJourney, ItineraryAdapter } from '../../../models/itinerary/itinerary';
import { GqlArticleConverter } from '../../../models/ticket/gql-article-converter';
import { isItineraryCart, isQuickTicketByZoneCart, isQuickTicketCart, isQuickTicketCartByCategory } from '../../../models/cart/cart.utils';
import { CartFactory } from '../../../models/cart/cart.factory';
import { BoldorLocalizationService } from '@traas/boldor/localization';
import { CompanyService } from '@traas/boldor/company';
import { CartActions } from '../../../features/cart/store';
import { Store } from '@ngrx/store';
import { GUEST_CUSTOMER_PARAM_NAME, RoutingService } from '@traas/common/routing';
import { ErrorCodes, isSuccess, TechnicalError } from '@traas/common/models';
import { convertToError, LoggingService } from '@traas/common/logging';
import { LoadingController, ModalController } from '@ionic/angular';
import { PaymentService } from '../purchase/payment.service';
import { endOfMinute } from 'date-fns';
import { firstValueFrom } from 'rxjs';
import { CreditCardsSelectors } from '../../../features/credit-cards/store';
import { PaymentPage } from '../../../pages/payment/payment.page';
import { OrderService } from '../order/order.service';
import { GqlToFrontOrderConverter } from '../../../models/order';
import { BookingStoreActions } from '../../../features/booking/store';
import { StopRequestService } from '../stop-request/stop-request.service';
import { AlertService } from '../alert.service';
import { ToasterService } from '../toaster/toaster.service';
import { ItineraryService } from '../../../features/itinerary/services/itinerary.service';
import { ArticleCategoryService } from '../../../features/ticket/services/article-category.service';
import { PreferencesService } from '../preferences/preferences.service';

@Injectable({
    providedIn: 'root',
})
export class CartService {
    #itineraryArticleService = inject(ItineraryArticleService);
    #departureService = inject(DepartureService);
    #quickArticleService = inject(QuickArticleService);
    #localizationService = inject(BoldorLocalizationService);
    #store = inject(Store);
    #routingService = inject(RoutingService);
    #preferencesService = inject(PreferencesService);
    #modalCtrl = inject(ModalController);
    #logger = inject(LoggingService);
    #orderService = inject(OrderService);
    #paymentService = inject(PaymentService);
    #articleCategoryService = inject(ArticleCategoryService);
    #stopRequestService = inject(StopRequestService);
    #toasterService = inject(ToasterService);
    #loadingController = inject(LoadingController);
    #boldorLocalizationService = inject(BoldorLocalizationService);
    #alertService = inject(AlertService);
    #itineraryService = inject(ItineraryService);

    async createAndNavigateToNewCartFromDeparture(departure: Departure): Promise<void> {
        const cart = await CartFactory.createCartFromDeparture(new DepartureAdapter(departure), this.#localizationService);

        this.#store.dispatch(
            new CartActions.InitCart({
                cart,
                travelType: this.#preferencesService.getTravelType(),
                chooseTicketManually: false,
            }),
        );
        await this.#routingService.navigateToCartJourneyActions();
    }

    async createAndNavigateToNewCartFromItinerary(itinerary: Itinerary, guestCustomer?: GuestCustomer): Promise<void> {
        const cart = await CartFactory.createCartFromItinerary(new ItineraryAdapter(itinerary), this.#localizationService);
        const travelType = this.#preferencesService.getTravelType();
        const isTpg = CompanyService.isTPG();
        const chooseTicketManually = isTpg;

        this.#store.dispatch(
            new CartActions.InitCart({
                cart,
                travelType,
                chooseTicketManually,
            }),
        );
        if (isTpg) {
            await this.#routingService.navigateToCartTicketConfiguration(guestCustomer);
        } else {
            await this.#routingService.navigateToCartJourneyActions();
        }
    }

    async fetchArticles(
        cart: Cart | null,
        articlesBundle: ArticlesBundle,
        durationsFilter: TicketDuration[],
        nearestStop: NearestStop | null,
    ): Promise<ArticlesBundle> {
        if (isItineraryJourney(cart?.journeyViewModel)) {
            return this.#prepareItineraryArticleBundle(cart.journeyViewModel, articlesBundle, durationsFilter);
        }
        if (isDepartureJourney(cart?.journeyViewModel)) {
            return this.#prepareDepartureArticleBundle(cart.journeyViewModel, articlesBundle, durationsFilter);
        }
        if (isQuickTicketCartByCategory(cart)) {
            if (!cart.origin?.categoryId) {
                throw new Error('[fetchArticles] categoryId is required');
            }
            return this.#prepareQuickArticleBundleByCategory(
                articlesBundle as ArticlesBundle<QuickArticleViewModel>,
                cart.origin.categoryId,
            );
        }
        if (isQuickTicketByZoneCart(cart) && !!cart.article) {
            return this.#prepareQuickArticleByZoneBundle(
                articlesBundle as ArticlesBundle<QuickArticleViewModel>,
                +cart.article.id,
                cart.article.zones,
            );
        }

        // This must be the last test case
        if (isQuickTicketCart(cart)) {
            return this.#prepareQuickArticleBundle(articlesBundle as ArticlesBundle<QuickArticleViewModel>, nearestStop, durationsFilter);
        }
        throw Error('A Cart must be one of the following types: departure, itinerary, quickTicket.');
    }

    hasValidArticlesSelections(cart: Cart): boolean {
        const articlesSelections = cart.articleSelections?.filter(({ article, passenger }) => {
            return !!article && !!passenger;
        });
        return !!articlesSelections && articlesSelections.length > 0;
    }

    async buyTicket(cart: Cart, guestCustomer?: GuestCustomer): Promise<void> {
        if (!guestCustomer) {
            // update 3d secure preferences
            const creditCardState = await firstValueFrom(this.#store.select(CreditCardsSelectors.getState));
            if (creditCardState.skip3DSecure !== null) {
                await this.#preferencesService.setSkip3DSecure(creditCardState.skip3DSecure);
            }
        }
        const BUY_ERROR_MESSAGE = 'Fail to buy ticket';
        try {
            const paymentModal = await this.#modalCtrl.create({
                component: PaymentPage,
                componentProps: { [GUEST_CUSTOMER_PARAM_NAME]: guestCustomer },
            });
            await paymentModal.present();
            const order = await this.purchase(cart, guestCustomer);
            await this.#routingService.navigateToOrder(order);
            await paymentModal.dismiss();
            this.resetState();
            // todo Bien de le faire ici ? plutôt que dans la page de détails de l'order ?
            await this.#orderService.setTicketsToSeen([order.id]);
        } catch (error) {
            const shouldLog = this.shouldLogError(error);
            if (shouldLog) {
                this.#logger.logError(new TechnicalError(BUY_ERROR_MESSAGE, ErrorCodes.Purchase.BuyArticles, convertToError(error)));
            }
        }
    }

    private shouldLogError(error: any): boolean {
        if (error instanceof TechnicalError) {
            switch (error.errorCode) {
                case ErrorCodes.Purchase.SaferpayPaymentFailed:
                case ErrorCodes.Purchase.SaferpayPaymentAborted:
                    return false;
                default:
                    return this.shouldLogError(error.innerError);
            }
        }
        return true;
    }

    async #prepareItineraryArticleBundle(
        itinerary: Itinerary,
        articlesBundle: ArticlesBundle,
        durationsFilter: TicketDuration[],
    ): Promise<ArticlesBundle> {
        try {
            const itineraryArticles = await this.#itineraryArticleService.generateItineraryTicketArticles(
                itinerary,
                articlesBundle.passenger,
                durationsFilter,
            );

            const availableArticles = itineraryArticles.map((article) => GqlArticleConverter.itineraryArticleToViewModel(article));
            return {
                ...articlesBundle,
                availableArticles,
            };
        } catch (error) {
            const itineraryAdapter = new ItineraryAdapter(itinerary);
            const currency = await this.#preferencesService.getCurrency();
            throw new TechnicalError(
                'Error while fetching itinerary articles',
                ErrorCodes.Purchase.PrepareItineraryArticle,
                convertToError(error),
                {
                    currency,
                    'passenger-id': articlesBundle.passenger.id,
                    'language-id': this.#localizationService.languageCode,
                    itinerary: {
                        id: itineraryAdapter.getId(),
                        departure: itineraryAdapter.getDepartureStop().getName(),
                        arrival: itineraryAdapter.getArrivalStop().getName(),
                        scheduledAt: itineraryAdapter.getScheduledDepartureDate(),
                        line: itineraryAdapter.getTransportLegs()[0]?.getLine().number ?? '',
                    },
                },
            );
        }
    }

    async #prepareDepartureArticleBundle(
        journey: Departure,
        articlesBundle: ArticlesBundle,
        durationsFilter: TicketDuration[],
    ): Promise<ArticlesBundle<DepartureArticleViewModel>> {
        const departuresArticles = await this.#departureService.generateDepartureTicketArticles(
            journey,
            articlesBundle.passenger,
            durationsFilter,
        );

        const availableArticles = departuresArticles.map((article) => GqlArticleConverter.departureArticleToViewModel(article));
        return {
            ...articlesBundle,
            availableArticles,
        };
    }

    async #prepareQuickArticleBundle(
        articlesBundle: ArticlesBundle<QuickArticleViewModel>,
        nearestStop: NearestStop | null,
        durationsFilter?: TicketDuration[],
    ): Promise<ArticlesBundle<QuickArticleViewModel>> {
        // used by TPC to get articles by gps position
        const articles = await this.#quickArticleService.getQuickArticles(nearestStop, articlesBundle.passenger, durationsFilter);

        const availableArticles = articles.map((article) => GqlArticleConverter.quickArticleToViewModel(article));

        return {
            ...articlesBundle,
            availableArticles,
        };
    }

    async #prepareQuickArticleByZoneBundle(
        articlesBundle: ArticlesBundle<QuickArticleViewModel>,
        articleId: number,
        zones: Zone[],
    ): Promise<ArticlesBundle<QuickArticleViewModel>> {
        const articleZones: Zone[] = zones.map(({ id }) => ({ id }));
        const articles = await this.#quickArticleService.generateQuickArticleByZone(articleId, articleZones, articlesBundle.passenger);
        const availableArticles = articles.map((article) => GqlArticleConverter.quickArticleToViewModel(article, zones));

        return {
            ...articlesBundle,
            availableArticles,
        };
    }

    async #prepareQuickArticleBundleByCategory(
        articlesBundle: ArticlesBundle<QuickArticleViewModel>,
        categoryId: string,
    ): Promise<ArticlesBundle<QuickArticleViewModel>> {
        const articles = await this.#articleCategoryService.getTicketsUsingCategory(articlesBundle, categoryId);
        const availableArticles = articles.map((article) => GqlArticleConverter.quickArticleToViewModel(article, article.zones));
        return {
            ...articlesBundle,
            availableArticles,
        };
    }

    purchase(cart: Cart, guestCustomer: GuestCustomer | undefined): Promise<OrderViewModel> {
        return this.#paymentService.processPurchase(cart, guestCustomer);
    }

    resetState(): void {
        this.#store.dispatch(new CartActions.ResetCart());
        this.#paymentService.resetState();
    }

    /**
     * Une offre est considérée comme expirée si elle est liée à un itinéraire dont la date de départ est dans le passé.
     * Le billet étant généré à partir de la date de début en temps réél de l'itinéraire, c'est cette date qui est utilisée pour la validation
     * @param cart - Cart
     * @param itinerary - Itinerary
     * @param now - Date
     */
    offerExpired(cart: Cart, itinerary: Itinerary | null, now = new Date()): boolean {
        if (!isItineraryCart(cart) || !itinerary) {
            return false;
        }

        const rtDepartureDate = new ItineraryAdapter(itinerary).getRealTimeDepartureDate();
        return endOfMinute(rtDepartureDate) < now;
    }

    async executeNonPurchaseActions(cart: Cart): Promise<void> {
        if (cart.operations?.stopRequest?.isChecked && !!cart.journeyViewModel) {
            this.#store.dispatch(new CartActions.SetCheckoutStep(CheckoutStep.Processing));
            await this.#requestStop(cart.journeyViewModel);
            return;
        }

        if (cart.operations?.save?.available && isItineraryCart(cart)) {
            this.#store.dispatch(new CartActions.SetCheckoutStep(CheckoutStep.Processing));
            await this.#saveItinerary(cart);
            return;
        }
    }

    hasSelectedArticles(cart: Cart): boolean {
        const articleSelections = cart.articleSelections;

        if (!articleSelections || articleSelections.length === 0) {
            return false;
        }

        return articleSelections.some(({ article }) => !!article);
    }

    async #requestStop(journey: JourneyData): Promise<void> {
        const loader = await this.#presentRequestStopLoader();
        this.#store.dispatch(new CartActions.AskToStop());
        try {
            const result = await this.#stopRequestService.requestStop(journey);
            if (isSuccess(result)) {
                this.#store.dispatch(new CartActions.AskToStopSuccess());
                const orderViewModel = GqlToFrontOrderConverter.fromStopRequestToOrderViewModel(result.value);
                this.#store.dispatch(new BookingStoreActions.AddOrder(orderViewModel));
                void this.#alertService.presentSuccessItineraryBooking();
                await this.#routingService.navigateToOrder(orderViewModel);
                return;
            }

            this.#store.dispatch(new CartActions.AskToStopError());
            if (result.error.isOutdated) {
                void this.#toasterService.presentFailureBookingOutdated();
            } else {
                void this.#alertService.presentFailureItineraryBooking();
            }
        } catch (error) {
            void this.#alertService.presentFailureItineraryBooking();
            this.#store.dispatch(new CartActions.AskToStopError());
            this.#logger.logError(
                new TechnicalError('Fail to request stop', error?.errorCode ?? ErrorCodes.Purchase.StopRequest, convertToError(error)),
            );
        } finally {
            await loader.dismiss();
        }
    }

    async #presentRequestStopLoader(): Promise<HTMLIonLoadingElement> {
        const requestStopProgress = await this.#boldorLocalizationService.get('tpc.request-stop.progress');
        const loader = await this.#loadingController.create({
            message: requestStopProgress,
            cssClass: 'custom-loading',
        });
        await loader.present();
        return loader;
    }

    async #saveItinerary(cart: ItineraryCart): Promise<void> {
        const loader = await this.#presentSavingLoader();
        this.#store.dispatch(new CartActions.Save());
        try {
            const itineraryOrder = await this.#itineraryService.saveItinerary(cart.itinerary);
            this.#store.dispatch(new CartActions.SaveSuccess());
            const itineraryOrderViewModel = GqlToFrontOrderConverter.fromItineraryOrderToViewModel(itineraryOrder);
            this.#store.dispatch(new BookingStoreActions.AddOrder(itineraryOrderViewModel));
            await this.#toasterService.presentSuccessItinerarySaving();
            this.resetState();
            await this.#routingService.navigateToOrder(itineraryOrderViewModel);
        } catch (error) {
            const unknown = await this.#boldorLocalizationService.get('error-message.unknown');
            void this.#toasterService.presentGenericWarning(unknown);
            this.#store.dispatch(new CartActions.SaveError());
            this.#logger.logError(
                new TechnicalError('Fail to save itinerary', error?.errorCode ?? ErrorCodes.Purchase.SaveItinerary, convertToError(error), {
                    itinerary: cart.itinerary,
                }),
            );
        } finally {
            await loader.dismiss();
        }
    }

    async #presentSavingLoader(): Promise<HTMLIonLoadingElement> {
        const saveItinerary = await this.#boldorLocalizationService.get('cart.save-itinerary-progress');
        const loader = await this.#loadingController.create({
            message: saveItinerary,
            cssClass: 'custom-loading',
        });
        await loader.present();
        return loader;
    }
}
