import { Component, inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ColorModeOptions } from '@traas/boldor/all-models';
import { ActivatedRoute, Router } from '@angular/router';
import { AnalyticsService } from '@traas/common/analytics';
import { PreferencesService } from '@traas/boldor/common/services/common/preferences/preferences.service';
import { AuthenticationApi } from '@traas/common/feature-account';
import { BookmarkService } from '@traas/boldor/common/services/common/bookmark/bookmark.service';
import { ConfigurationService } from '@traas/boldor/common/services/common/configuration/configuration.service';
import { DataVersionService } from '@traas/boldor/common/services/common/data-version/data-version.service';
import { GeolocationService } from '@traas/boldor/common/services/common/geolocation/geolocation.service';
import { ItineraryFiltersService } from '@traas/boldor/common/features/itinerary/services/itinerary-filters.service';
import { LineStylesService } from '@traas/boldor/common/services/common/line-styles/line-styles.service';
import { PlatformUtilsService } from '@traas/common/utils';
import { GenderTitlesService } from '@traas/boldor/common/services/common/gender-titles/gender-titles.service';
import { RouteUrl, RoutingService } from '@traas/common/routing';
import { StatusBarService } from '@traas/boldor/common/services/common/status-bar/status-bar.service';
import { backButtonPushed$ } from '@traas/boldor/common/listeners/app-listener-utils';
import { filter, withLatestFrom } from 'rxjs/operators';
import { CompanyService } from '@traas/boldor/company';
import { observeRouterUrlEvents } from '@traas/boldor/all-helpers';
import * as _ from 'lodash';
import { AndroidBackButtonLockService } from '@traas/boldor/common/services/common/home/android-back-button-lock.service';
import { Platform } from '@ionic/angular';
import { CurrentUserSession } from '@traas/common/logging';
import { CustomerProviderService } from '@traas/boldor/common/services/common/customer/customer-provider.service';
import { ForceUpdateService } from '@traas/boldor/common/services/common/force-update-service';

@Component({
    selector: 'traas-root',
    templateUrl: 'app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
    #onDestroy$: Subject<void>;
    colorMode$: Observable<ColorModeOptions> = new Subject<ColorModeOptions>();

    #activatedRoute = inject(ActivatedRoute);
    #analyticsService = inject(AnalyticsService);
    #androidBackButtonLockService = inject(AndroidBackButtonLockService);
    #bookmarkService = inject(BookmarkService);
    #configurationService = inject(ConfigurationService);
    #dataVersionService = inject(DataVersionService);
    #geolocationService = inject(GeolocationService);
    #itineraryFiltersService = inject(ItineraryFiltersService);
    #lineStylesService = inject(LineStylesService);
    #platform = inject(Platform);
    #platformUtilsService = inject(PlatformUtilsService);
    #preferencesService = inject(PreferencesService);
    #genderTitlesService = inject(GenderTitlesService);
    #renderer = inject(Renderer2);
    #router = inject(Router);
    #routingService = inject(RoutingService);
    #statusBarService = inject(StatusBarService);
    #authenticationApi = inject(AuthenticationApi);
    #customerProviderService = inject(CustomerProviderService);
    #forceUpdateService = inject(ForceUpdateService);

    constructor() {
        void this.#forceUpdateService.redirectToUpdatePageIfOutdatedApp();
        this.colorMode$ = this.#preferencesService.getColorModeOption$();
    }

    ngOnInit(): void {
        this.#onDestroy$ = new Subject<void>();
        void this.#initUserSession();
        this.#analyticsService.initializeWebTrackingIfEnabled();
        this.#subscribeToRouterChanges();
        this.#initApp().then(() => console.debug('App initialized!'));
    }

    ngOnDestroy(): void {
        this.#onDestroy$.next();
        this.#onDestroy$.complete();
    }

    async #initApp(): Promise<void> {
        await this.#platform.ready();

        this.#enableBackButton();
        if (this.#platformUtilsService.isApp()) {
            this.#geolocationService.checkLocationPermissions();
        }

        void this.#statusBarService.initializeStatusBar();
        this.#addThemeCssTag();
        void this.#lineStylesService.initLineStyles();

        await this.#dataVersionService.update();

        const initializationsPromises = this.#initializationsPromises();
        await Promise.all(initializationsPromises);
    }

    #enableBackButton(): void {
        try {
            backButtonPushed$()
                .pipe(
                    withLatestFrom(this.#activatedRoute.queryParams, (url, queryParams) => ({
                        url: this.#routingService.getRouteUrl(),
                        queryParams,
                    })),
                    filter(({ url }) => this.#isUrlAllowedToUseBackbutton(url)),
                    filter(({ queryParams }) => !_.isEmpty(queryParams)),
                )
                .subscribe(() => {
                    if (this.#androidBackButtonLockService.lock('back button pressed')) {
                        this.#routingService.back();
                    }
                });
        } catch (error) {
            console.warn('Enable Back Button Error ', error);
        }
    }

    #isUrlAllowedToUseBackbutton(url: string): boolean {
        return url.includes(RouteUrl.departureResultUrl) || url.includes(RouteUrl.departureDetailUrl);
    }

    #initializationsPromises(): Promise<void>[] {
        return [
            // Be careful, initializeBookmarks must be called after dataVersionService.update
            this.#bookmarkService.initializeBookmarks(),
            this.#preferencesService.initializeDefaultPreferences(),
            this.#configurationService.assertConfiguration(),
            this.#genderTitlesService.initTitles(),
            this.#itineraryFiltersService.fetchAndStoreTransportModes(),
        ];
    }

    #addThemeCssTag(): void {
        const theme = CompanyService.getClassNameForCurrentCompany();
        this.#renderer.addClass(document.body, theme);
        if (this.#platformUtilsService.isAndroid()) {
            this.#renderer.addClass(document.body, 'android');
        } else if (this.#platformUtilsService.isIos()) {
            this.#renderer.addClass(document.body, 'ios');
        }
    }

    #subscribeToRouterChanges(): void {
        observeRouterUrlEvents(this.#router, this.#onDestroy$).subscribe((url) => this.#analyticsService.reportPageView(url));
    }

    async #initUserSession(): Promise<void> {
        try {
            if (await this.#authenticationApi.isAuthenticated()) {
                CurrentUserSession.activeUser = { id: (await this.#customerProviderService.getCustomer())?.id };
            }
        } catch (error) {
            console.error('Error during initialization of current user session:', error);
        }
    }
}
