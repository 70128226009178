import { Directive, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { throttleTime } from 'rxjs/operators';

// https://stackoverflow.com/a/51390698/4583018  changed to throttle

@Directive({
    selector: '[appThrottleClick]',
})
export class ThrottleClickDirective implements OnInit, OnDestroy {
    @Input() throttleTime = 500;

    @Output() throttleClick = new EventEmitter();

    readonly #clicks = new Subject();
    #subscription: Subscription;

    ngOnInit(): void {
        this.#subscription = this.#clicks.pipe(throttleTime(this.throttleTime)).subscribe((e) => this.throttleClick.emit(e));
    }

    ngOnDestroy(): void {
        this.#subscription.unsubscribe();
    }

    @HostListener('click', ['$event'])
    clickEvent(event: any): void {
        event.preventDefault();
        event.stopPropagation();
        this.#clicks.next(event);
    }
}
