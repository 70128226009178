import { Injectable } from '@angular/core';
import { LoggingService } from '@traas/common/logging';
import { from, merge, Observable, Subject } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { UnsecureStorage } from './unsecure-storage.service';
import { PlatformUtilsService } from '../platform-utils.service';
import { UnsecureTypedStorage } from './unsecure-typed-storage.service';

/**
 * @deprecated NE PLUS UTILISER, utiliser uniquement observable-typed-storage
 */
@Injectable()
export class UnsecureObservableTypedStorage<T> extends UnsecureTypedStorage<T> {
    readonly #$didSetItem = new Subject<keyof T>();

    /**
     * @deprecated NE PLUS UTILISER, utiliser uniquement observable-typed-storage
     */
    constructor(unsecureStorage: UnsecureStorage, logger: LoggingService, platformUtilsService: PlatformUtilsService) {
        super(unsecureStorage, logger, platformUtilsService);
    }

    /**
     * @deprecated NE PLUS UTILISER, utiliser uniquement observable-typed-storage
     */
    override async setItem<K extends keyof T>(key: K, value: T[K]): Promise<void> {
        await super.setItem(key, value);
        this.#$didSetItem.next(key);
    }

    /**
     * @deprecated NE PLUS UTILISER, utiliser uniquement observable-typed-storage
     */
    $getItem<K extends keyof T>(key: K, defaultValue: T[K]): Observable<T[K]> {
        const $changedItem: Observable<T[K]> = this.#$didSetItem.pipe(
            filter((updatedKey) => updatedKey === key),
            switchMap(async () => this.getItem(key)),
            map((item) => item ?? defaultValue),
        );

        const $currentItem: Observable<T[K]> = from(this.getItem(key, defaultValue)).pipe(map((item) => item ?? defaultValue));
        return merge($currentItem, $changedItem);
    }
}
