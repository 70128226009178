/* eslint-disable */
import {
    ArticlesBundle,
    ArticleSelection,
    ArticleViewModel,
    Cart,
    ConfigureTicket,
    PriceType,
    TicketDuration,
    TravelClass,
} from '@traas/boldor/all-models';
import { uniqBy } from 'lodash';
import { TravelType } from '@traas/boldor/graphql-generated/graphql';
import { isQuickTicketCart } from '../../../models/cart/cart.utils';

export const NOT_AVAILABLE = 'N/A';

export function hasZeroPassengerSelected(articlesBundles: ArticlesBundle[]): boolean {
    return articlesBundles?.length === 0;
}

export function hasZeroTypeAvailable(configureTicket: ConfigureTicket): boolean {
    return configureTicket?.ticketTypes?.length === 0;
}

export function isSupersaverPriceTypeSelected(priceType: PriceType): boolean {
    return priceType === PriceType.Supersaver;
}

export function hasNoTicketTypeSection(
    hideTicketType: boolean,
    articleSelections: ArticleSelection<ArticleViewModel>[] | undefined,
    configureTicket: ConfigureTicket,
): boolean {
    return (
        hideTicketType &&
        !hasNoTypeAvailableForSelectedClass(articleSelections, configureTicket) &&
        !hasNotAllTravellersAvailable(articleSelections, configureTicket)
    );
}

export function hasDurationSelection(
    hideTicketType: boolean,
    articleSelections: ArticleSelection<ArticleViewModel>[] | undefined,
    configureTicket: ConfigureTicket,
    priceType: PriceType,
    durations: TicketDuration[],
): boolean {
    return (
        !hasNoTicketTypeSection(hideTicketType, articleSelections, configureTicket) &&
        durations.length > 1 &&
        !isSupersaverPriceTypeSelected(priceType)
    );
}

export function hideTicketTypeFromCart(cart: Cart | null): boolean {
    if (isQuickTicketCart(cart)) {
        return !!cart?.origin?.productId;
    }
    return false;
}

export function hasNoTicketAvailableForSelectedType(
    articleSelections: ArticleSelection<ArticleViewModel>[] | undefined,
    configureTicket: ConfigureTicket,
): boolean {
    return (
        !!articleSelections &&
        articleSelections.length > 0 &&
        configureTicket.ticketTypes.some(({ id, priceInCents }) => id === configureTicket?.selectedProductId && priceInCents === 0)
    );
}

export function hasNoTypeAvailableForSelectedClass(
    articleSelections: ArticleSelection<ArticleViewModel>[] | undefined,
    configureTicket: ConfigureTicket,
): boolean {
    return (
        !!articleSelections &&
        articleSelections.length > 0 &&
        configureTicket?.ticketTypes?.every(({ priceInCents }) => priceInCents === 0) &&
        !hasNoTicketAvailableForSelectedType(articleSelections, configureTicket) &&
        !hasZeroTypeAvailable(configureTicket)
    );
}

export function hasNotAllTravellersAvailable(
    articleSelections: ArticleSelection<ArticleViewModel>[] | undefined,
    configureTicket: ConfigureTicket,
): boolean {
    return (
        !hasNoTypeAvailableForSelectedClass(articleSelections, configureTicket) &&
        !hasNoTicketAvailableForSelectedType(articleSelections, configureTicket) &&
        !!articleSelections &&
        articleSelections?.length > 1 &&
        articleSelections?.some((a) => !a.article)
    );
}

export function getUniqDurationsOf(articlesBundles: ArticlesBundle[] | null): TicketDuration[] {
    if (!articlesBundles) {
        return [];
    }

    let results: TicketDuration[] = [];
    for (const articlesBundle of articlesBundles) {
        const travelTypes = getUniqDurationsOfArticles(articlesBundle.availableArticles);
        results = [...results, ...travelTypes];
    }
    return uniqBy(results, 'value');
}

export function getUniqDurationsOfArticles(articles: ArticleViewModel[]): TicketDuration[] {
    let results: TicketDuration[] = [];
    if (!articles) {
        return results;
    }

    const travelTypes = articles
        .filter((article) => !!article.travelType)
        .map(({ travelType, validityDurationLabel }) => ({
            value: travelType!,
            label: validityDurationLabel,
        }))
        .filter((travelType) => !!travelType);
    results = [...travelTypes];

    return uniqBy(results, 'value');
}

export function getUniqTravelClassOf(articlesBundles: ArticlesBundle[]): TravelClass[] {
    let results: TravelClass[] = [];
    if (!articlesBundles) {
        return results;
    }
    for (const articlesBundle of articlesBundles) {
        const travelsClass = getUniqTravelClassOfArticles(articlesBundle.availableArticles);
        results = [...results, ...travelsClass];
    }
    return uniqBy(results, 'id');
}

export function getUniqTravelClassOfArticles(articles: ArticleViewModel[]): TravelClass[] {
    if (!articles) {
        return [];
    }
    const travelsClass = articles.map(({ travelClass }) => travelClass).filter((travelClass): travelClass is TravelClass => !!travelClass);
    const results = [...travelsClass];
    return uniqBy(results, 'id');
}
