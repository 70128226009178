import { MessageViewModel } from '../models/message-view-model';

export interface StartupNotificationState {
    isLoading: boolean;
    pendingMessages: MessageViewModel[];
    currentMessage: MessageViewModel | null;
    isAllClear: boolean;
}

export const initialState: StartupNotificationState = {
    isLoading: false,
    currentMessage: null,
    pendingMessages: [],
    isAllClear: false,
};
