@if (showPageContent()) {
  <ion-list>
    <!-- MOBILE PAYMENT METHODS -->
    <div>
      <hr />
      <ion-item lines="none">
        <ion-label class="ion-no-margin bold-text">{{ 'credit-cards.selector.use-mobile-payment' | translate }}</ion-label>
      </ion-item>
      <div class="flex-center payment-icons wrap-icons">
        @for (mobilePaymentMethod of paymentMethodsConfiguration.mobiles; track mobilePaymentMethod) {
          <div (click)="selectMobilePaymentMethod(mobilePaymentMethod)">
            <ion-img
              class="selectable-item"
              [class.selected-option]="selectedMobilePaymentMethod === mobilePaymentMethod"
              src="{{ getMobilePaymentMethodIconPath(mobilePaymentMethod) }}"
            ></ion-img>
            <ion-radio class="e2e-use-mobile-payment-{{ mobilePaymentMethod }} ion-hide" value="{{ mobilePaymentMethod }}">
            </ion-radio>
          </div>
        }
      </div>
    </div>

    <!-- SAVED CARDS -->
    @if (!guestCustomer && creditCards.length > 0) {
      <div>
        <hr />
        <ion-item lines="none">
          <ion-label class="ion-no-margin bold-text">
            {{ 'credit-cards.selector.use-saved-card' | translate }}
          </ion-label>
        </ion-item>
        @for (creditCard of creditCards; track creditCard.id) {
          <ion-item
            lines="none"
            (click)="selectSavedCreditCard(creditCard)"
            class="e2e-credit-card selectable-saved-card-item selectable-item"
            [class.selected-option]="selectedSavedCard === creditCard"
            >
            <ion-icon
              class="payment-method-icon"
              src="{{ getCreditCardIconPath(creditCard) }}"
              [class.selected-saved-card-item]="selectedSavedCard === creditCard"
            ></ion-icon>
            <ion-text class="credit-card-number" [class.selected-saved-card-item]="selectedSavedCard === creditCard">
              <p class="ion-no-margin">
                {{ creditCard.maskedNumber }}
              </p>
            </ion-text>
          </ion-item>
        }
        @if (selectedSavedCard !== null) {
          <app-use-3d-secure
            [showSkip3DSecure]="showSkip3DSecure"
            [skip3DSecure]="skip3DSecure"
            (skip3DSecureChange)="onSkip3DSecureChange($event)"
            >
          </app-use-3d-secure>
        }
        <hr />
      </div>
    }

    <!-- CARDS PAYMENT METHODS -->
    <div>
      <ion-item lines="none">
        <ion-label class="ion-no-margin bold-text">
          {{ 'credit-cards.selector.use-new' | translate }}
        </ion-label>
      </ion-item>
      <div class="flex-center payment-icons wrap-icons">
        @for (cardPaymentMethod of paymentMethodsConfiguration.cards; let i = $index; track cardPaymentMethod) {
          <div (click)="selectCardPaymentMethod(cardPaymentMethod)" class="e2e-use-card-payment-{{ cardPaymentMethod }}">
            <ion-img
              class="selectable-item"
              [class.selected-option]="selectedCardPaymentMethod === cardPaymentMethod"
              src="{{ getCardPaymentMethodIconPath(cardPaymentMethod) }}"
            ></ion-img>
            <ion-radio class="ion-hide" value="{{ cardPaymentMethod }}"></ion-radio>
          </div>
        }
      </div>
      @if (showSaveNewCard()) {
        <ion-item lines="none">
          <ion-checkbox
            slot="start"
            [checked]="createCreditCard"
            (ionChange)="onCreateCreditCardChange($event)"
            value="save-new"
            class="e2e-save-new-card"
          ></ion-checkbox>
          <ion-label class="ion-text-wrap">
            {{ 'credit-cards.selector.save-new-card' | translate }}
          </ion-label>
        </ion-item>
        <app-use-3d-secure
          [showSkip3DSecure]="showSkip3DSecure"
          [skip3DSecure]="skip3DSecure"
          (skip3DSecureChange)="onSkip3DSecureChange($event)"
          >
        </app-use-3d-secure>
      }
    </div>
  </ion-list>
} @else {
  <div class="loader-container">
    <ion-spinner></ion-spinner>
  </div>
}
