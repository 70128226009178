import { Injectable } from '@angular/core';
import { createDefaultGeoposition } from '@traas/boldor/all-helpers';
import { AssociatedCommercialStopAdapter, DataNearPoint, NearestStop, PositionType } from '@traas/boldor/all-models';
import { MapService } from './map.service';
import { CompanyService } from '@traas/boldor/company';
import { GeolocationService } from '../../../services/common/geolocation/geolocation.service';
import { StopNamePipe } from '../../../pipes/stop-name.pipe';
import { convertToError, LoggingService } from '@traas/common/logging';
import { LatLng } from 'leaflet';
import { firstValueFrom } from 'rxjs';
import { ErrorCodes, isSuccess, TechnicalError } from '@traas/common/models';

interface StopInfo {
    stopDidok: number;
    stopName: string;
}

@Injectable({ providedIn: 'root' })
export class NearestStopService {
    constructor(
        private loggingService: LoggingService,
        private mapService: MapService,
        private geolocationService: GeolocationService,
    ) {}

    async getNearestStop(): Promise<NearestStop | null> {
        try {
            const gpsPosition = await this.#getCurrentGpsPosition();
            const defaultNearestStopPromise = this.#fetchNearestStop(this.#getDefaultPosition(), PositionType.Default);

            if (gpsPosition) {
                const nearestStop = await this.#fetchNearestStop(gpsPosition, PositionType.Gps);
                return nearestStop || (await defaultNearestStopPromise);
            }

            return await defaultNearestStopPromise;
        } catch (error) {
            this.#logError('Error finding nearest stop', error);
            return null;
        }
    }

    async #getCurrentGpsPosition(): Promise<LatLng | null> {
        const $position = this.geolocationService.$getPositionUpdates();
        const position = await firstValueFrom($position);
        return position ? position.getLatLng() : null;
    }

    #getDefaultPosition(): LatLng {
        return createDefaultGeoposition(CompanyService.getDefaultPlaceByCompany()).getLatLng();
    }

    async #fetchNearestStop(position: LatLng, positionType: PositionType): Promise<NearestStop | null> {
        const stopInfo = await this.#fetchStopInfoNear(position);
        if (!stopInfo) {
            this.#logInfo(`No stop found near ${position}`);
            return null;
        }
        return { ...stopInfo, positionType };
    }

    async #fetchStopInfoNear(latLng: LatLng): Promise<StopInfo | null> {
        const dataNearPointResult = await this.mapService.fetchDataNear(latLng);
        if (!isSuccess(dataNearPointResult)) {
            this.loggingService.logError(dataNearPointResult.error);
            return null;
        }
        if (!dataNearPointResult.value.stops?.length) {
            return null;
        }
        return this.#extractFirstCommercialStopDidok(dataNearPointResult.value);
    }

    #extractFirstCommercialStopDidok(data: DataNearPoint): StopInfo {
        const [firstStop] = data.stops;
        const firstCommercialStop = new AssociatedCommercialStopAdapter(firstStop.associatedCommercialStop);
        const stopDidok = firstCommercialStop.getDidok();
        const stopName = StopNamePipe.transformValue(firstCommercialStop);

        if (!stopDidok) {
            throw new Error(`No DIDOK found for ${stopName}`);
        }

        return {
            stopDidok,
            stopName,
        };
    }

    #logError(message: string, error: unknown): void {
        this.loggingService.logError(new TechnicalError(message, ErrorCodes.NearestStop.Fetch, convertToError(error)));
    }

    #logInfo(message: string): void {
        this.loggingService.logLocalError(new Error(message), { level: 'info' });
    }
}
