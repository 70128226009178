import { GqlToFrontDepartureConverter } from '../../features/departure/converters/gql-to-front';
import { GqlToFrontItineraryConverter } from '../../features/itinerary/converters/gql-to-front';
import * as Gql from '@traas/boldor/graphql-generated/graphql';
import { GetItineraryStopRequestsQuery } from '@traas/boldor/graphql-generated/graphql';
import { DepartureStopRequest, ItineraryStopRequest, OrderType, StopRequest } from '@traas/boldor/all-models';

export class GqlToFrontStopRequestConverter {
    static toDepartureStopRequest(departureStopRequest: Gql.DepartureStopRequest): DepartureStopRequest {
        return {
            id: departureStopRequest.id,
            orderType: OrderType.DepartureStopRequest,
            customerId: departureStopRequest.customerId,
            departure: GqlToFrontDepartureConverter.toDeparture(departureStopRequest.departure),
        };
    }

    static isDepartureStopRequest(data: StopRequest): data is DepartureStopRequest {
        return data?.orderType === OrderType.DepartureStopRequest;
    }

    static toItineraryStopRequest(
        // @ts-ignore ignore type checking
        itineraryStopRequest: GetItineraryStopRequestsQuery['order']['getItineraryStopRequests'][number],
    ): ItineraryStopRequest {
        return {
            id: itineraryStopRequest.id,
            orderType: OrderType.ItineraryStopRequest,
            customerId: itineraryStopRequest.customerId,
            itinerary: GqlToFrontItineraryConverter.toItinerary(itineraryStopRequest.itinerary),
        };
    }

    static isItineraryStopRequest(data: StopRequest): data is ItineraryStopRequest {
        return data?.orderType === OrderType.ItineraryStopRequest;
    }
}
