import { Cart } from './cart.model';
import { Article } from '../ticket/article.model';
import { ArticleSelection } from './article-selection.model';
import { NearestStop } from '../nearest-stop.model';
import { QuickArticleViewModel } from '../ticket/quick-article-view-model.model';

export enum QuickTicketOriginFrom {
    // collection (category)
    COLLECTION = 'COLLECTION',
}

export interface QuickTicketOrigin {
    // used by Quick Ticket TPG
    from: QuickTicketOriginFrom;
    categoryId?: string; // when user select a ticket by category
    productId?: string; // when user select a ticket by suggestion
}
export interface QuickTicketCart extends Cart {
    nearestStop: NearestStop | null;
    article?: Article;
    origin?: QuickTicketOrigin;
    articleSelections: ArticleSelection<QuickArticleViewModel>[];
}
