import { JourneyAdapter } from '../../features/booking/models/journey';
import { CommercialStop, createHumanReadableStop, Departure, HumanReadableStop, JourneyData, JourneyEnum } from '@traas/boldor/all-models';
import { Transport } from '../../../../../../common/components/src/lib/vehicle-icon/vehicle-icon.component';
import { ONE_SECOND_IN_MS } from '@traas/common/utils';
import * as moment from 'moment';
import { AssociatedCommercialStop, Line, PhysicalStop } from '@traas/common/models';

export class DepartureAdapter extends JourneyAdapter<Departure> {
    constructor(data: Departure) {
        super(data);
        this.setType(JourneyEnum.Departure);
    }

    getAdditionalInformation(): string[] {
        return this.getData().additionalInformation ?? [];
    }

    getLine(): Line {
        return this.data.line;
    }

    getDirection(): string {
        return this.data.direction;
    }

    getRealTimeDepartureDate(): Date {
        return new Date(+this.data.departureTimestamp * ONE_SECOND_IN_MS);
    }

    getScheduledDepartureDate(): Date {
        return moment(this.data.plannedDepartureTime).toDate();
    }

    getScheduledDepartureTimestamp(): number {
        return this.getScheduledDepartureDate().getTime();
    }

    getDepartureDelay(): number {
        const x = moment(this.getRealTimeDepartureDate());
        const y = moment(this.getScheduledDepartureDate());
        return x.diff(y, 'seconds');
    }

    getDepartureStop(): HumanReadableStop {
        return createHumanReadableStop(this.getCommercialStop());
    }

    getTrack(): string {
        return this.data.track;
    }

    getTransport(): Transport {
        return this.data.transport;
    }

    getPhysicalStop(): PhysicalStop {
        return this.getStop().physicalStop;
    }

    getAssociatedCommercialStop(): AssociatedCommercialStop | undefined | null {
        return this.getPhysicalStop().associatedCommercialStop;
    }

    getStop(): CommercialStop {
        return this.data.stop;
    }

    getServiceId(): string {
        return this.data.serviceId;
    }

    getCommercialStop(): CommercialStop {
        return this.data.stop;
    }

    getLines(): Line[] {
        return [this.getLine()];
    }

    getId(): string {
        return this.data.id;
    }

    isOutdated(): boolean {
        if (this.getData().outdatedDate) {
            return moment(this.getData().outdatedDate).isBefore(new Date());
        }
        return true;
    }
}

export function isDepartureJourney(journey: JourneyData | undefined): journey is Departure {
    return !!journey && journey.__type__ === JourneyEnum.Departure;
}
