import { ChangeDetectionStrategy, Component } from '@angular/core';
import { environment } from '@traas/boldor/environments';
import { ViewDidEnter } from '@ionic/angular';
import { NB_CLICKS_TO_DISPLAY_DEBUG_INFOS } from '@traas/boldor/business-rules';

@Component({
    selector: 'traas-page-menu',
    templateUrl: 'menu.html',
    styleUrls: ['menu.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuPageComponent implements ViewDidEnter {
    timesClickedMenu = 0;

    ionViewDidEnter(): void {
        this.timesClickedMenu = 0;
    }

    clickMenuToShowDebugInfo(): void {
        this.timesClickedMenu += 1;
    }

    showDebugInfo(): boolean {
        return environment.showEnvironmentInformations || this.timesClickedMenu >= NB_CLICKS_TO_DISPLAY_DEBUG_INFOS;
    }
}
