import { CreditCard, TicketViewModel } from '@traas/boldor/all-models';

export enum ArticleType {
    ITINERARY,
    QUICK,
    DEPARTURE,
    UNKNOWN,
}

export interface PaymentState {
    paymentStatus: PaymentStatus;
    ticketStatus: TicketStatus;
    orderId: string | null;
    articleType: ArticleType;
    selectedCreditCard: CreditCard | null;
    createCreditCard: boolean | null;
    ticket: TicketViewModel | null;
    isCanceledByUser: boolean;
    cardPaymentMethodName: string | null;
    mobilePaymentMethodName: string | null;
    paymentMethodName: string | null;
}

export enum PaymentStatus {
    ABORT = 'ABORT',
    INITIAL = 'INITIAL',
    FAIL = 'FAIL',
    PREPARATION = 'PREPARATION',
    VERIFICATION = 'VERIFICATION',
    VERIFIED = 'VERIFIED',
    VALIDATION = 'VALIDATION',
    CAPTURED = 'CAPTURED',
    TIMED_OUT = 'TIMED_OUT',
    BUY_FAIL = 'BUY_FAIL',
}

export enum TicketStatus {
    INITIAL,
    CREATION,
    CREATED,
    RECOVERY_FAILED,
}

export const initialState: PaymentState = {
    paymentStatus: PaymentStatus.INITIAL,
    ticketStatus: TicketStatus.INITIAL,
    orderId: null,
    articleType: ArticleType.UNKNOWN,
    selectedCreditCard: null,
    createCreditCard: null,
    ticket: null,
    isCanceledByUser: false,
    paymentMethodName: null,
    cardPaymentMethodName: null,
    mobilePaymentMethodName: null,
};
