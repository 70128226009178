<ion-item [detail]="detail" class="ion-no-padding" lines="none">
  <div class="ticket">
    <ion-row class="title">
      <ion-col> {{ 'my-journeys.ticket-summary.itinerary' | translate }}</ion-col>
      <ion-col>
        <app-lines-icon-horizontal-list [lines]="lines"></app-lines-icon-horizontal-list>
      </ion-col>
    </ion-row>
    <div class="ticket-container">
      <div class="ticket-infos">
        <app-itinerary-ticket-from-to
          [defaultPaddingOnCol]="false"
          [class.show-operations-icons]="showOperationsIcons"
          [departureStop]="departureStop"
          [customColPadding]="paddingCol"
          [arrivalStop]="arrivalStop"
          >
        </app-itinerary-ticket-from-to>

        <ion-row class="departure-datetime" [class.show-operations-icons]="showOperationsIcons">
          <ion-col size="10">
            <app-human-readable-date [departureDatetime]="departureDate"></app-human-readable-date>
          </ion-col>
        </ion-row>

        <div>
          @if (showOperationsIcons) {
            <div class="ticket-icons-price">
              <ion-icon class="operation-icon separation-right" src="assets/icons/tabs/monochrome/journey.svg"></ion-icon>
              @if (itineraryOrder.tickets.length > 0) {
                <ion-icon
                  class="operation-icon separation-right"
                  src="assets/icons/tabs/monochrome/tickets.svg"
                ></ion-icon>
              }
              @if (itineraryOrder.itinerary.hasStopRequest) {
                <ion-icon
                  class="operation-icon"
                  src="assets/icons/stop-on-request.svg"
                ></ion-icon>
              }
              @if (isRefundable) {
                <ion-icon
                  (click)="confirmRefundOrder($event)"
                  class="ticket-delete"
                  src="assets/icons/grid/delete.svg"
                ></ion-icon>
              }
              <div class="ticket-price" [class.strikethrough]="areThirdPartyPaidTickets(itineraryOrder)">
                {{ totalPrice }}
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  </div>
</ion-item>
