<!--Le svg est ici car cela permet de lui appliquer du style depuis le CSS-->
@if (isBus) {
  <svg
    class="canceled-cross-icon"
    width="77"
    height="77"
    viewBox="0 0 77 77"
    xmlns="http://www.w3.org/2000/svg"
    >
    <path
      d="M2.4 21.5H4.8V35.8H2.4C1.1 35.8 0 34.7 0 33.4V23.9C0 22.5 1.1 21.5 2.4 21.5ZM73.9 21.5H71.5V35.8H73.9C75.2 35.8 76.3 34.7 76.3 33.4V23.9C76.3 22.5 75.3 21.5 73.9 21.5ZM66.8 16.7V59.6C66.8 62.2 64.7 64.4 62 64.4V69.2C62 71.8 59.9 74 57.2 74H52.4C49.8 74 47.6 71.9 47.6 69.2V64.4H28.6V69.2C28.6 71.8 26.5 74 23.8 74H19C16.4 74 14.2 71.9 14.2 69.2V64.4C11.6 64.4 9.4 62.3 9.4 59.6V16.7C9.5 8.79999 16 2.39999 23.9 2.39999H52.5C60.4 2.39999 66.8 8.79999 66.8 16.7ZM28.6 11.9H47.7V7.19999H28.6V11.9ZM23.9 54.9C23.9 52.3 21.8 50.1 19.1 50.1C16.5 50.1 14.3 52.2 14.3 54.9C14.3 57.5 16.4 59.7 19.1 59.7C21.7 59.6 23.9 57.5 23.9 54.9ZM62 54.9C62 52.3 59.9 50.1 57.2 50.1C54.5 50.1 52.4 52.2 52.4 54.9C52.4 57.5 54.5 59.7 57.2 59.7C59.9 59.6 62 57.5 62 54.9ZM62 16.7H14.3V45.3H62V16.7Z"
      />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M74 63.989L63.989 74L38.5 48.511L13.011 74L3 63.989L28.489 38.5L3 13.011L13.011 3L38.5 28.489L63.989 3L74 13.011L48.511 38.5L74 63.989Z"
      />
  </svg>
} @else {
  <ion-icon [src]="ICON_BASE_PATH + '/canceled_travel.svg'" class="canceled-cross-icon"></ion-icon>
}
