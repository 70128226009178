import { HttpStatusCode } from '@angular/common/http';
import { ApolloError } from '@apollo/client/core';
import { GraphQLError } from 'graphql';
import { I18nMessage, PromiseError, SyntheseInvalidResponseError, TraasGraphQlExtension } from '@traas/boldor/all-models';
import { GraphQLErrors } from '@apollo/client/errors';
import { ErrorCodes, TechnicalError } from '@traas/common/models';
import { EmptyError } from 'rxjs';

interface BoldorErrorWrapper {
    originalCode: number;
    errorCode: string;
    i18nMessages: I18nMessage;
}

export function isApolloNetworkError(error: Error): boolean {
    const hasNetworkError = error instanceof ApolloError && !!error.networkError;
    return hasNetworkError && !isGraphqlSchemaValidationFailed(error);
}

export function isGraphqlSchemaValidationFailed(error: ApolloError): boolean {
    return (error.networkError as any)?.error?.errors?.some((httpError: any) => httpError.extensions?.code === 'GRAPHQL_VALIDATION_FAILED');
}

export function isApolloAuthenticationError(error: Error): boolean {
    return error instanceof ApolloError && containsAuthenticationError(error.graphQLErrors);
}

function containsAuthenticationError(graphQLErrors: GraphQLErrors): boolean {
    return graphQLErrors.some((gqlError) => gqlError.extensions['statusCode'] === HttpStatusCode.Unauthorized);
}

export function getErrorMessageToDisplay(retryMessage: string, errorParam: Error | unknown, message = ''): string {
    try {
        if (errorParam instanceof ApolloError && errorParam.graphQLErrors.length > 0) {
            const [graphQLError]: readonly GraphQLError[] = errorParam.graphQLErrors;
            return extractMessageI18N(graphQLError, retryMessage);
        }
        if (isSyntheseInvalidResponseError(errorParam)) {
            return extractMessageI18NFromSyntheseError(errorParam, retryMessage);
        }
        return message;
    } catch (error) {
        return retryMessage;
    }
}

export function isSyntheseInvalidResponseError(error: any): error is SyntheseInvalidResponseError {
    return !!error && error instanceof SyntheseInvalidResponseError;
}

export function getRealErrorData(error: unknown): any {
    if (isPromiseError(error)) {
        return error.rejection;
    }
    return error;
}

function extractMessageI18N(error: GraphQLError, retryMessage: string): string {
    const boldorErrorWrapper = error.extensions['exception'] as BoldorErrorWrapper;
    if (boldorErrorWrapper.i18nMessages) {
        const locale = 'fr';
        return boldorErrorWrapper.i18nMessages[locale]; // TODO return message depending on the selected locale
    }
    return retryMessage;
}

function extractMessageI18NFromSyntheseError(error: SyntheseInvalidResponseError, retryMessage: string): string {
    if (error?.messages) {
        const locale = 'fr';
        return error.messages[locale]; // TODO return message depending on the selected locale
    }
    return retryMessage;
}

function isPromiseError(error: unknown): error is PromiseError {
    return error && (error as any).rejection;
}

export function getCorrelationIdFromGraphQLError(apolloError: ApolloError): string | undefined {
    const error = apolloError.graphQLErrors.find((graphQLError) => getCorrelationIdFromError(graphQLError));
    return error ? getCorrelationIdFromError(error) : undefined;
}

export function getCorrelationIdFromError(error: GraphQLError): string {
    return error.extensions && (error.extensions['correlationId'] as string);
}

export function convertToError(error: unknown): Error | undefined {
    if (error instanceof EmptyError) {
        // Reformat stack trace for error of type EmptyErrorImpl, caused by rxjs
        error.stack = error.stack?.replace('Error\n', `EmptyError: ${error.message}\n`);
        return error;
    }
    if (error instanceof Error) {
        return error;
    }
    if (typeof error === 'string') {
        return new Error(error);
    }
    if (error === undefined || error === null) {
        return undefined;
    }
    try {
        const parsedError = JSON.stringify(error);
        return new Error(parsedError);
    } catch (parsingError) {
        if (parsingError instanceof Error) {
            return new TechnicalError(`Could not parse error`, ErrorCodes.Technical.ErrorParsing, parsingError);
        } else {
            return new TechnicalError(`Could not parse error`, ErrorCodes.Technical.ErrorParsing);
        }
    }
}

export function getGqlOperationName(error: ApolloError): string | undefined {
    for (const gqlError of error.graphQLErrors) {
        const extension: TraasGraphQlExtension = gqlError.extensions;
        if (extension.operation) {
            return extension.operation.operationName;
        }
    }
    return undefined;
}
