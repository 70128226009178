import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CreditCard, GuestCustomer, PaymentMethodsConfiguration } from '@traas/boldor/all-models';
import { CreditCardsService } from '../../services/credit-cards.service';

// TODO cart step 2 : il faudrait virer ce composant et/ou le fusionner avec son parent, ça n'a pas de sens un composant utilisé à
// un seul endroit dans un composant qui ne fait que charger ce composant. Soit le composant est dumb et simple, soit il est
// smart mais complet et indépendant. On ne split pas des composants en dehors de ces 2 situations
@Component({
    selector: 'app-credit-cards',
    templateUrl: './credit-cards.component.html',
    styleUrls: ['./credit-cards.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreditCardsComponent {
    @Input({ required: true }) createCreditCard = false;
    @Input({ required: true }) skip3DSecure = false;
    @Input({ required: true }) creditCards: CreditCard[] = [];
    @Input({ required: true }) showSkip3DSecure = false;
    @Input({ required: true }) paymentMethodsConfiguration!: PaymentMethodsConfiguration;
    @Input({ required: true }) guestCustomer!: GuestCustomer | undefined;
    @Input({ required: true }) set selectedCreditCard(value: CreditCard | null) {
        if (value) {
            this.selectSavedCreditCard(value);
        }
    }

    @Input({ required: true }) set selectedCardPaymentMethodName(value: string | null) {
        if (value) {
            this.selectCardPaymentMethod(value);
        }
    }

    @Input({ required: true }) set selectedMobilePaymentMethodName(value: string | null) {
        if (value) {
            this.selectMobilePaymentMethod(value);
        }
    }

    @Output() createCreditCardChange = new EventEmitter<boolean>();
    @Output() savedCreditCardSelectedChange = new EventEmitter<CreditCard | null>();
    @Output() skip3DSecureChange = new EventEmitter<boolean>();
    @Output() hasPaymentMethodSelected = new EventEmitter<boolean>();
    @Output() hasPaymentMethodNameSelected = new EventEmitter<string | null>();
    @Output() hasCardPaymentMethodNameSelected = new EventEmitter<string | null>();
    @Output() hasMobilePaymentMethodNameSelected = new EventEmitter<string | null>();

    selectedMobilePaymentMethod: string | null = null;
    selectedCardPaymentMethod: string | null = null;
    selectedSavedCard: CreditCard | null = null;

    constructor(private creditCardsService: CreditCardsService) {}

    getCreditCardIconPath(creditCard: CreditCard): string {
        return this.creditCardsService.getCreditCardIconPath(creditCard.brand?.toLowerCase());
    }

    onCreateCreditCardChange(event: Event): void {
        event.preventDefault();
        event.stopPropagation();
        this.createCreditCardChange.emit((event as CustomEvent).detail.checked);
    }

    onSkip3DSecureChange(checked: boolean): void {
        this.skip3DSecureChange.emit(checked);
        this.skip3DSecure = checked;
    }

    getMobilePaymentMethodIconPath(mobileMethod: string): string {
        return this.creditCardsService.getMobilePaymentMethodIconPath(mobileMethod);
    }

    getCardPaymentMethodIconPath(creditCard: string): string {
        return this.creditCardsService.getCardPaymentMethodIconPath(creditCard);
    }

    showSaveNewCard(): boolean {
        return !this.guestCustomer && this.selectedCardPaymentMethod !== null;
    }

    selectMobilePaymentMethod(mobilePaymentMethod: string): void {
        this.selectedMobilePaymentMethod = mobilePaymentMethod;
        this.hasPaymentMethodNameSelected.emit(mobilePaymentMethod);
        this.hasMobilePaymentMethodNameSelected.emit(mobilePaymentMethod);
        this.hasPaymentMethodSelected.emit(true);

        // reset other states
        this.savedCreditCardSelectedChange.emit(null);
        this.selectedSavedCard = null;
        this.selectedCardPaymentMethod = null;
    }

    selectCardPaymentMethod(cardPaymentMethod: string): void {
        this.hasPaymentMethodNameSelected.emit(cardPaymentMethod);
        this.selectedCardPaymentMethod = cardPaymentMethod;
        this.hasCardPaymentMethodNameSelected.emit(cardPaymentMethod);
        this.hasPaymentMethodSelected.emit(true);

        // reset other states
        this.savedCreditCardSelectedChange.emit(null);
        this.selectedMobilePaymentMethod = null;
        this.hasMobilePaymentMethodNameSelected.emit(null);
        this.selectedSavedCard = null;
    }

    selectSavedCreditCard(card: CreditCard): void {
        this.selectedSavedCard = card;
        this.savedCreditCardSelectedChange.emit(card);
        this.hasPaymentMethodSelected.emit(true);

        // reset other states
        this.hasPaymentMethodNameSelected.emit(null);
        this.selectedMobilePaymentMethod = null;
        this.hasCardPaymentMethodNameSelected.emit(null);
        this.hasMobilePaymentMethodNameSelected.emit(null);
        this.selectedCardPaymentMethod = null;
    }

    // to refacto to remove that, compute it before is possible
    showPageContent(): boolean {
        const hasPaymentMethods =
            !!this.paymentMethodsConfiguration &&
            this.paymentMethodsConfiguration?.cards?.length > 0 &&
            this.paymentMethodsConfiguration?.mobiles?.length > 0;
        return hasPaymentMethods && (!!this.guestCustomer || !!this.creditCards);
    }
}
