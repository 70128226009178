import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { HumanReadableStop } from '@traas/boldor/all-models';

@Component({
    selector: 'app-itinerary-ticket-from-to',
    templateUrl: './itinerary-ticket-from-to.component.html',
    styleUrls: ['./itinerary-ticket-from-to.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItineraryTicketFromToComponent implements OnInit {
    @Input() arrivalStop: HumanReadableStop | null;
    @Input() customColPadding: {
        top: string;
        bottom: string;
        left: string;
        right: string;
    };
    @Input() defaultPaddingOnCol = true;
    @Input() departureDatetime: string | null;
    @Input({ required: true }) departureStop!: HumanReadableStop;

    paddingBottom: string | null;
    paddingTop: string | null;

    ngOnInit(): void {
        this.paddingTop = this.customColPadding ? this.customColPadding.top : null;
        this.paddingBottom = this.customColPadding ? this.customColPadding.bottom : null;
    }
}
