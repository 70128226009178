import { inject, Injectable } from '@angular/core';
import {
    GetByBoundingBoxGQL,
    GetByBoundingBoxQueryVariables,
    GetByCoordinatesGQL,
    GetByCoordinatesQueryVariables,
} from '@traas/boldor/graphql-generated/graphql';
import { LatLng, LatLngBounds } from 'leaflet';
import { firstValueFrom, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ItineraryAdapter } from '../../../models/itinerary/itinerary';
import { convertToError, LoggingService } from '@traas/common/logging';
import { ErrorCodes, Failure, Result, Success, TechnicalError } from '@traas/common/models';
import { PhysicalStopAdapter } from '@traas/common/utils';

@Injectable()
export class PhysicalStopService {
    readonly #logger = inject(LoggingService);

    static getPhysicalStopsFromItineraries(itineraries: ItineraryAdapter[]): PhysicalStopAdapter[] {
        const departureStops = itineraries.map((itinerary) => itinerary.getDepartureStop());
        return departureStops.map((departureStop) => departureStop.getPhysicalStop()).filter((stop): stop is PhysicalStopAdapter => !!stop);
    }

    constructor(
        private getByCoordinatesGQL: GetByCoordinatesGQL,
        private getByBoundingBoxGQL: GetByBoundingBoxGQL,
    ) {}

    async fetchPhysicalStopsInBBox(bounds: LatLngBounds): Promise<PhysicalStopAdapter[]> {
        if (!bounds) {
            return [];
        }
        const getByBoundingBoxQueryVariables = this.#createGetByBoundingBoxQueryVariables(bounds);
        const $getByBoundingBoxGQL = this.getByBoundingBoxGQL.fetch(getByBoundingBoxQueryVariables);
        const $physicalStopAdapters = $getByBoundingBoxGQL.pipe(
            map((response) => {
                return response.data?.physicalStops?.byBoundingBox
                    .filter(({ geometry }) => !!geometry)
                    .map((physicalStop) => new PhysicalStopAdapter(physicalStop));
            }),
            catchError((error) => {
                const technicalError = new TechnicalError(
                    "Can't fetch physical stops by bounding box",
                    ErrorCodes.PhysicalStops.FetchByBBox,
                    convertToError(error),
                    { bounds },
                );
                this.#logger.logError(technicalError);
                return of([]);
            }),
        );
        return firstValueFrom($physicalStopAdapters);
    }

    $getPhysicalStopsByLatLng(latLng: LatLng): Observable<Result<PhysicalStopAdapter[], TechnicalError>> {
        if (!latLng) {
            return of({ success: true, value: [] });
        }
        const getByCoordinatesQueryVariables = this.#createGetByCoordinatesQueryVariables(latLng);
        const $getByCoordinatesGQL = this.getByCoordinatesGQL.fetch(getByCoordinatesQueryVariables);
        return $getByCoordinatesGQL.pipe(
            map((response) => {
                const stops = response.data.physicalStops.byCoordinates
                    .filter(({ geometry }) => !!geometry)
                    .map((physicalStop) => new PhysicalStopAdapter(physicalStop));
                return { success: true, value: stops } as Success<PhysicalStopAdapter[]>;
            }),
            catchError((error) => {
                const technicalError = new TechnicalError(
                    "Can't fetch physical stops by lat/lng",
                    ErrorCodes.PhysicalStops.FetchByLatLng,
                    convertToError(error),
                    { latLng },
                );
                return of({ success: false, error: technicalError } as Failure<TechnicalError>);
            }),
        );
    }

    #createGetByBoundingBoxQueryVariables(bounds: LatLngBounds): GetByBoundingBoxQueryVariables {
        const southWest = bounds.getSouthWest();
        const northEast = bounds.getNorthEast();
        return {
            boundingBox: {
                northEast: {
                    latitude: northEast.lat,
                    longitude: northEast.lng,
                },
                southWest: {
                    latitude: southWest.lat,
                    longitude: southWest.lng,
                },
            },
        };
    }

    #createGetByCoordinatesQueryVariables(latLng: LatLng): GetByCoordinatesQueryVariables {
        return {
            coordinates: {
                longitude: latLng.lng,
                latitude: latLng.lat,
            },
        };
    }
}

export function distinctStopsById(stops: PhysicalStopAdapter[]): PhysicalStopAdapter[] {
    return stops.filter((currentStop, index, self) => {
        return (
            self.findIndex((subCurrentStop) => {
                return subCurrentStop.getId() === currentStop.getId();
            }) === index
        );
    });
}
