/* tslint:disable:object-literal-key-quotes */
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { getCompanyFromNetworkId, getLineCode, UNKNOWN_LINE_BACKGROUND_COLOR, UNKNOWN_LINE_FONT_COLOR } from '@traas/boldor/business-rules';
import { Line } from '@traas/common/models';

// not okay at all but let's pretends it is. Sometimes the server provides a number like |SL3
// sometimes the official name 85_000011_SL3. Here we try to cover both case. This should definitely
// be solved passing all the requests through (and providing the icons from) the middleware
const lineFilePath = 'assets/imgs/lines.min.svg';
function getSymbolUrl(symbolName: string): string {
    return `${lineFilePath}#${symbolName}`;
}

const LEGACY_LINE_ICONS_DICTIONARY = {
    '85_000011_SL1': getSymbolUrl('85_000011_SL1'),
    '85_000011_SL2': getSymbolUrl('85_000011_SL2'),
    '85_000011_SL3': getSymbolUrl('85_000011_SL3'),
    '85_000011_SL4': getSymbolUrl('85_000011_SL4'),
    '85_000011_SL5': getSymbolUrl('85_000011_SL5'),
    '85_000011_SL6': getSymbolUrl('85_000011_SL6'),
    '85_000011_TER': getSymbolUrl('85_000011_TER'),
    '85_776_TACTANG': getSymbolUrl('85_776_TACTANGO'),
    '85_818_TPCBUS': getSymbolUrl('85_818_TPCBUS'),
    '85_818_TPCNOC': getSymbolUrl('85_818_TPCNOC'),
    '85_818_TPCSKI': getSymbolUrl('85_818_TPCSKI'),
    '85_818_TPCTAD': getSymbolUrl('85_818_TPCTAD'),
    '85_87_ARA_LT71': getSymbolUrl('85_87_ARA_LT71'),
    '85_87_ARA_LT72': getSymbolUrl('85_87_ARA_LT72'),
    '85_87_ARA_LT73': getSymbolUrl('85_87_ARA_LT73'),
    '85_87_ARA_LT74': getSymbolUrl('85_87_ARA_LT74'),
    '87_87_LEX_TER': getSymbolUrl('85_000011_TER'),
    BLT71: getSymbolUrl('85_87_ARA_LT71'),
    BLT72: getSymbolUrl('85_87_ARA_LT72'),
    BLT73: getSymbolUrl('85_87_ARA_LT73'),
    BLT74: getSymbolUrl('85_87_ARA_LT74'),
    IC57: getSymbolUrl('IC57'),
    IR57: getSymbolUrl('IR57'),
    IR90: getSymbolUrl('IR90'),
    RE: getSymbolUrl('RE'),
    SL1: getSymbolUrl('85_000011_SL1'),
    SL2: getSymbolUrl('85_000011_SL2'),
    SL3: getSymbolUrl('85_000011_SL3'),
    SL4: getSymbolUrl('85_000011_SL4'),
    SL5: getSymbolUrl('85_000011_SL5'),
    SL6: getSymbolUrl('85_000011_SL6'),
    T2: getSymbolUrl('85_776_TACTANGO'),
    TANGO: getSymbolUrl('85_776_TACTANGO'),
    TER: getSymbolUrl('85_000011_TER'),
    '85_000199_M1': getSymbolUrl('85_000199_M1'),
    '85_000199_M2': getSymbolUrl('85_000199_M2'),
    '85_000199_M3': getSymbolUrl('85_000199_M3'),
    '85_000199_M4': getSymbolUrl('85_000199_M4'),
};

const lineIconBasePath = 'assets/imgs/lines';

const LINE_ICONS_DICTIONARY = {
    IC1: `${lineIconBasePath}/IC01.svg`,
    EC: `${lineIconBasePath}/EC.svg`,
    IC5: `${lineIconBasePath}/IC05.svg`,
    IR15: `${lineIconBasePath}/IR15.svg`,
    RL1: `${lineIconBasePath}/RL1.svg`,
    RL2: `${lineIconBasePath}/RL2.svg`,
    RL3: `${lineIconBasePath}/RL3.svg`,
    RL4: `${lineIconBasePath}/RL4.svg`,
    RL5: `${lineIconBasePath}/RL5.svg`,
    RL6: `${lineIconBasePath}/RL6.svg`,
};

const LINE_ICONS_RECTANGLE_DICTIONARY = {
    BT71: `${lineIconBasePath}/BT71.svg`,
    BT72: `${lineIconBasePath}/BT72.svg`,
    BT74: `${lineIconBasePath}/BT74.svg`,
    '271': `${lineIconBasePath}/BT71.svg`,
    '272': `${lineIconBasePath}/BT72.svg`,
    '274': `${lineIconBasePath}/BT74.svg`,
};

const SPECIFIC_ICONS_DICTIONARY = {
    tpgFlex: `${lineIconBasePath}/tpg_flex_logo.svg`,
};

@Component({
    selector: 'app-line-icon',
    templateUrl: './line-icon.component.html',
    styleUrls: ['./line-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LineIconComponent {
    @Input({ required: true }) set line(line: Line) {
        if (!line) {
            return;
        }
        // When cdk-virtual-scroll is re-creating components on the fly, he kept an old value there
        // We have to reset this object if we don't want those old values to interfere with the rendering
        this.lineIconPath = undefined;
        this.lineStyle = line.style;
        this.color = line.fontColor || UNKNOWN_LINE_FONT_COLOR;
        this.backgroundColor = line.color || UNKNOWN_LINE_BACKGROUND_COLOR;
        this.lineNumber = line.number;
        this.themeCssClass = getCompanyFromNetworkId(line.networkId);

        this.#determineIcon(line);
    }

    @HostBinding('class')
    themeCssClass = '';

    backgroundColor: string;
    color: string;
    imgWidth: string;
    isLegacyLineIcon: boolean;
    lineIconPath: string | undefined;
    lineNumber: string;
    lineStyle: string;

    #determineIcon(line: Line): void {
        const lineCode = getLineCode(line);
        const iconName = this.#getLineCodeFormattedToSVGSymbol(lineCode);
        if (iconName in LEGACY_LINE_ICONS_DICTIONARY) {
            this.lineIconPath = LEGACY_LINE_ICONS_DICTIONARY[iconName];
            this.isLegacyLineIcon = true;
        } else if (iconName in LINE_ICONS_DICTIONARY) {
            this.lineIconPath = LINE_ICONS_DICTIONARY[iconName];
            this.isLegacyLineIcon = false;
        } else if (iconName in LINE_ICONS_RECTANGLE_DICTIONARY) {
            this.lineIconPath = LINE_ICONS_RECTANGLE_DICTIONARY[iconName];
            this.isLegacyLineIcon = false;
        } else if (iconName in SPECIFIC_ICONS_DICTIONARY) {
            this.lineIconPath = SPECIFIC_ICONS_DICTIONARY[iconName];
            this.isLegacyLineIcon = false;
        }
    }

    #getLineCodeFormattedToSVGSymbol(lineCode: string): string {
        return lineCode ? lineCode.replace('|', '') : '';
    }
}
