import { PassengerType } from '@traas/boldor/graphql-generated/graphql';
import * as moment from 'moment';
import {
    Area,
    ChangePlaceEventSourceEnum,
    Currency,
    isBookmarkArea,
    isStopArea,
    Itinerary,
    Metadata,
    TransportNames,
    YOUR_LOCATION_I18N_KEY,
} from '@traas/boldor/all-models';
import {
    AreaHelper,
    extendMapContentWithItemAtKey,
    isAddress,
    isCitySelection,
    isFollowGps,
    isMyGpsPosition,
    isPoi,
} from '@traas/boldor/all-helpers';
import { ONE_HOUR_IN_MINUTES, PhysicalStopAdapter } from '@traas/common/utils';
import { Company, Line, NetworkId } from '@traas/common/models';
import { CompanyService } from '@traas/boldor/company';
import { BUTTON_STOP_ICON_SRC, getStopHasBookingRequirementsIconName, ICON_BASE_PATH } from '@traas/common/business-rules';

export const MESSAGES_ACKNOWLEDGED_LIMIT = 50;
export const GET_ORDER_STATUS_INTERVAL_MS = 1500;
export const GET_ORDER_POLLING_TIMEOUT = 30000;
export const TRY_CANCEL_ORDER_RETRY_INTERVAL_MS = 1000;
export const MAX_LINES_ICON_LIST_LIMIT = CompanyService.isTraas() ? 4 : 3;
export const THRESHOLD_IN_MINUTES_TO_SHOW_FIRST_WALKING_LEG = 4;

export const DEPARTURE_TIME_ICON_REFRESH_INTERVAL_IN_SECONDS = 10;
export const NB_CLICKS_TO_DISPLAY_DEBUG_INFOS = 20;

export function getCompanyFromNetworkId(networkId: string): Company {
    switch (networkId) {
        case NetworkId.Tpg:
            return Company.Tpg;
        case NetworkId.Travys:
            return Company.Travys;
        case NetworkId.Tpc:
        default:
            return Company.Tpc;
    }
}

export const DELAY_BEFORE_EXECUTING_MAP_MOVE_EVENT = 300;

/** Bookmarks number limit * */
export const MAX_BOOKMARKS = 19;

/** Loading more number * */
export const LOAD_MORE_DEPARTURE_COUNT = 20;
export const LOAD_PREVIOUS_DEPARTURE_COUNT = 20;
export const LOAD_MORE_ITINERARY_COUNT = 8;

/** Line colors * */
export const UNKNOWN_LINE_BACKGROUND_COLOR = '#6b6b6b';
export const UNKNOWN_LINE_FONT_COLOR = '#ffffff';

export function showOnDemandIconOnItinerary(itinerary: Itinerary): boolean {
    return itinerary.hasBookingRequirements && !itinerary.hasSupersaverOffers;
}

export function showAskStopButtonOnItinerary(isStartingWithTrainOptionalStop: boolean, hasBookingRequirements: boolean): boolean {
    return isStartingWithTrainOptionalStop && !hasBookingRequirements;
}

/**
 * These rules must be validated with PO.
 * @param hasBookingRequirements
 * @param isOptional
 * @param isTrainParam
 * @param isDepartureList
 * @param isFirst
 */
export function getStopIconDetailName(
    hasBookingRequirements: boolean,
    isOptional: boolean,
    isTrainParam: boolean,
    isDepartureList = false,
    isFirst = false,
): string {
    const hasBookingRequirementsIconName = getStopHasBookingRequirementsIconName(hasBookingRequirements, isDepartureList, isFirst);
    if (hasBookingRequirementsIconName) {
        return hasBookingRequirementsIconName;
    }
    if (isOptional && isTrainParam) {
        return BUTTON_STOP_ICON_SRC;
    }
    return '';
}

export function getThermoStopIconName(hasBookingRequirements: boolean, isOptional: boolean, isTrain_param: boolean): string {
    if (hasBookingRequirements || isOptionalStop(isOptional, isTrain_param)) {
        return `${ICON_BASE_PATH}/simple-stop-cross.svg`;
    }
    return `${ICON_BASE_PATH}/simple-stop.svg`;
}

export function isOptionalStop(isOptional: boolean, isTrain_param: boolean): boolean {
    return isOptional && isTrain_param;
}

export function isTrain(transportName: string): boolean {
    return transportName === TransportNames.TRAIN;
}

export function getLinePathBackgroundColor(
    lineStyle: string | undefined,
    lineBackgroundColor: string | undefined,
    forceCanceledColor: boolean,
): string {
    if (forceCanceledColor) {
        return 'grey';
    }
    return (lineStyle ? '' : lineBackgroundColor) ?? '';
}

export function getLinePathClass(style: string | undefined, forceCanceledColor: boolean): string {
    return (forceCanceledColor ? '' : style) ?? '';
}

/**
 * In the logic of the endpoint container, there is some placeholder in case of locationName empty.
 * @param area
 */
export function createLocationOf(area: Area): string {
    const areaSource = area.metadata.source;
    if (isAddress(areaSource) || isPoi(areaSource) || isFollowGps(areaSource) || isMyGpsPosition(areaSource) || isBookmarkArea(area)) {
        return area.metadata.locationName;
    }
    if (isStopArea(area) && isCitySelection(areaSource)) {
        return formatNamesOfCityPhysicalStops(AreaHelper.getPhysicalStopsDistinctByName(area));
    }
    if (isStopArea(area)) {
        return formatNamesOfPhysicalStops(AreaHelper.getPhysicalStopsDistinctByName(area));
    }
    return area.metadata.locationName;
}

function groupByCityName(physicalStops: PhysicalStopAdapter[]): Map<string, PhysicalStopAdapter[]> {
    const groupedStops = new Map<string, PhysicalStopAdapter[]>();
    physicalStops.forEach((physicalStop) => {
        return extendMapContentWithItemAtKey(physicalStop.getCityName(), physicalStop, groupedStops);
    });
    return groupedStops;
}

export function formatNamesOfPhysicalStops(physicalStops: PhysicalStopAdapter[]): string {
    const groupedByCityNameStops = groupByCityName(physicalStops);
    let stopsNameFormatted = '';
    groupedByCityNameStops.forEach((stopsByCity) => {
        const nameOfStops = getNameOfStops(stopsByCity);
        stopsNameFormatted = `${stopsNameFormatted} ${nameOfStops}`;
    });
    return stopsNameFormatted.trim();
}

function getNameOfStops(stopsByCity: PhysicalStopAdapter[]): string {
    return stopsByCity.map((stop) => stop.getCommercialStopName()).join(', ');
}

function formatNamesOfCityPhysicalStops(physicalStops: PhysicalStopAdapter[]): string {
    return physicalStops[0] ? physicalStops[0].getCityName() : '';
}

export function getLineCode(line: Line): string {
    let lineCode: string;
    if (line.lineOfficialCode && line.lineOfficialCode !== '') {
        lineCode = line.lineOfficialCode;
    } else {
        lineCode = line.number;
    }

    // |126
    const simpleLineName = /^\|(\d{1,3})$/.exec(lineCode);
    if (simpleLineName) {
        return simpleLineName[1];
    }

    // 85_818_TPC063
    if (/^\d+_\d+_TPC(TAD|\d+)$/.test(lineCode)) {
        return lineCode;
    }

    return lineCode;
}

/** Phone number to buy tickets by SMS for TPG & TPC . * */
export const PHONE_NUMBER_BUY_TICKET_BY_SMS = '788';

export function getIconNameByChangePlaceEvent(source: ChangePlaceEventSourceEnum): string {
    const endpointBasePath = `${ICON_BASE_PATH}/endpoint`;
    switch (source) {
        case ChangePlaceEventSourceEnum.ManualMapMove:
            return `${endpointBasePath}/square.svg`;
        case ChangePlaceEventSourceEnum.ClickFollowGps:
            return 'locate';
        case ChangePlaceEventSourceEnum.MyGpsPositionSelection:
            return 'locate';
        case ChangePlaceEventSourceEnum.AddressSelection:
            return `${endpointBasePath}/map.svg`;
        case ChangePlaceEventSourceEnum.StopSelection:
            return `${endpointBasePath}/stop.svg`;
        case ChangePlaceEventSourceEnum.PoiSelection:
            return `${ICON_BASE_PATH}/poi.svg`;
        default:
            return `${endpointBasePath}/square.svg`;
    }
}

export function sortAlphabeticallyAsc(valueA: string, valueB: string): number {
    return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
}

export const FOR_NEXT_HOURS = '02:00';

export function getDelayInMinutes(realDateTime: Date | null, scheduledDateTime: Date | null): string {
    if (!realDateTime || !scheduledDateTime) {
        return '';
    }

    const technicalDateFormat = 'H:m';
    const realMomentTime = moment(realDateTime, technicalDateFormat);
    const scheduledMomentTime = moment(scheduledDateTime, technicalDateFormat);
    if (scheduledMomentTime) {
        const diffInMinutes = realMomentTime.diff(scheduledMomentTime, 'minutes');
        if (diffInMinutes > 0) {
            const duration = `${diffInMinutes.toString()}'`;
            return `+${duration}`;
        }
        if (diffInMinutes < 0) {
            return `${diffInMinutes}'`;
        }
    }
    return '';
}

export function fromDiffInMinutesToDuration(diffInMinutes: number): string {
    if (diffInMinutes > 599) {
        return `›10h`;
    }
    if (diffInMinutes > 59) {
        let duration: string;
        const hours = Math.trunc(diffInMinutes / ONE_HOUR_IN_MINUTES);
        const minutes = diffInMinutes % ONE_HOUR_IN_MINUTES;
        duration = minutes >= 10 ? `${hours}h` : minutes === 0 ? `${hours}h` : `${hours}h0`;
        duration += minutes ? `${minutes}` : '';
        return duration;
    }
    return `${diffInMinutes}'`;
}

export const RE_ENABLE_ENDPOINT_DELAY = 5000;
export const MAP_MODE_TO_EVENT = ['map__mode_to_full', 'map__mode_to_half', 'map__mode_to_small'];
export const GREY = '#9B9B9B';

export function isElementInViewport(element: any): boolean {
    // First - Calculate the notch + tabs height
    const getRootNotchHeight = getComputedStyle(document.documentElement).getPropertyValue('--notch');
    const notchHeight = parseInt(getRootNotchHeight, 10);
    const tabsAndNotchHeight = 60 + notchHeight;

    // Next - Get the space around the element and the viewport
    const bounding = element.getBoundingClientRect();

    // Finally - Subtract the viewport height by the elementHeight and the tabs and notchHeight
    const elementHeight = element.offsetHeight - tabsAndNotchHeight;
    return bounding.top >= 0 && bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) + elementHeight;
}

export function getPassengerTypeIconName(passengerType: PassengerType | undefined): string {
    switch (passengerType) {
        case PassengerType.Dog:
            return 'paw';
        case PassengerType.Bike:
            return 'bicycle-outline';
        case PassengerType.Person:
        default:
            return 'person';
    }
}

export function isMyLocation({ locationName, source }: Metadata): boolean {
    return (
        locationName === YOUR_LOCATION_I18N_KEY &&
        (source === ChangePlaceEventSourceEnum.ClickFollowGps || source === ChangePlaceEventSourceEnum.MyGpsPositionSelection)
    );
}

export function capitalizeFirstLetter(input: HTMLInputElement): void {
    const inputValue = input.value;
    input.value = inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
}

export const CURRENCIES_WITH_SKIP3DSECURE = [Currency.CHF];
