<ion-item lines="none" class="ticket" [class.isLastChild]="isLastChild" (click)="buy()">
  <div class="ticket-row">
    <div [class.tpg]="isTpg" class="ticket-container">
      <div class="ticket-code">{{ title }}@if (hasOnlyPaymentBySmsAllowed) {
        <span>&nbsp;(SMS)</span>
      }</div>
      <div class="ticket-description">{{ description }}</div>
    </div>
    @if (prices[0]) {
      <div class="ticket-price">{{ prices[0] }}</div>
    }
  </div>
</ion-item>
