<div class="container" #container>
  <ion-row class="departure" [class.cancelled]="departureStop.isCancelled()" (click)="onDepartureClicked(departureStop)">
    <div class="departure-row">
      <div class="flex-item grow departure-infos">
        <div class="line-track">
          <app-track-icon [track]="track"></app-track-icon>
        </div>
        <div class="booking-icon">
          <ion-icon #legPathStart [class.tpg]="isTpg" [src]="departureThermoStopIcon"></ion-icon>
        </div>

        @if (hasDisruptionOnDeparture) {
          <app-warning-icon (click)="onWarningClicked($event, departureStop)"></app-warning-icon>
        }

        <div class="stop-name e2e-departure-stop-name">
          {{ departureStop | stopName }}
        </div>
      </div>
      <div class="departure-stop">
        @if (isTPC) {
          <div class="options">
            <ion-icon [src]="departureStopIconPathItinerary"></ion-icon>
          </div>
        }

        <div class="time">{{ leg.getScheduledDepartureDate() | date: 'shortTime' }}</div>
        <div class="delay">{{ leg.getDepartureDelay() }}</div>
      </div>

      @if (leg.getFirstStop().isCancelled) {
        <div class="flex-item full-width cancelled-message-container">
          <div class="stop-name cancelled-message">
            <traas-cancelled-trip-icon [isBus]="false"></traas-cancelled-trip-icon>
            <span [innerHTML]="cancellationMessageByStops.firstStop?.htmlContent"></span>
          </div>
        </div>
      }
    </div>
  </ion-row>

  <!--Ceci ne s'affiche que en TRAAS-->
  @if ($stopInformation | async; as stopInformation) {
    <ion-row class="transport stop-info">
      <div class="transport-row">
        <div class="transport-infos">
          <div class="line"></div>
          <div class="transport-content e2e-stop-information-itinerary">
            {{ stopInformation }}
          </div>
        </div>
        <div class="dotdotdot" style="opacity: 0">
          <ion-icon class="arrow" src="assets/icons/dotdotdot-idle.svg"></ion-icon>
        </div>
      </div>
    </ion-row>
  }

  <ion-row class="transport" (click)="onTransportClicked()">
    <div class="transport-row">
      <div class="transport-infos">
        <div class="line"></div>
        <div class="transport-content with-arrow">
          <app-vehicle-icon [transport]="transportIconName"></app-vehicle-icon>
          <app-line-icon [line]="leg.getLine()"></app-line-icon>
          <ion-icon class="arrow" src="assets/icons/right-arrow.svg"></ion-icon>
          <div class="direction ion-text-wrap">
            {{ leg.getDirection() }}
          </div>
        </div>
      </div>
      <div class="dotdotdot">
        <ion-icon class="arrow" src="assets/icons/dotdotdot-idle.svg"></ion-icon>
      </div>
    </div>
  </ion-row>

  @if (!!lineCancellationMessage) {
    <ion-row class="line-cancelled-message">
      <div class="flex-item full-width cancelled-message-container">
        <div class="stop-name cancelled-message">
          <traas-cancelled-trip-icon [isBus]="false"></traas-cancelled-trip-icon>
          <span [innerHTML]="lineCancellationMessage.htmlContent"></span>
        </div>
      </div>
    </ion-row>
  }

  <ion-row class="arrival" [class.cancelled]="departureStop.isCancelled()" (click)="onArrivalClicked(arrivalStop)">
    <div class="arrival-row">
      <div class="flex-item grow arrival-infos">
        <div class="line-track">
          <app-track-icon [track]="arrivalTrack"></app-track-icon>
        </div>
        <div class="booking-icon">
          <ion-icon #legPathEnd [class.tpg]="isTpg" [src]="arrivalThermoStopIcon"></ion-icon>
        </div>

        @if (hasDisruptionOnArrival) {
          <app-warning-icon></app-warning-icon>
        }

        <div class="stop-name e2e-arrival-stop-name">
          {{ arrivalStop | stopName }}
        </div>
      </div>
      <div class="arrival-stop">
        @if (isTPC) {
          <div class="options">
            <ion-icon [src]="arrivalStopIconPathItinerary"></ion-icon>
          </div>
        }
        <div class="time">{{ leg.getScheduledArrivalTime() | date: 'shortTime' }}</div>
        <div class="delay">{{ leg.getArrivalDelay() }}</div>
      </div>
      @if (leg.getLastStop().isCancelled) {
        <div class="flex-item full-width cancelled-message-container">
          <div class="stop-name cancelled-message">
            <traas-cancelled-trip-icon [isBus]="false"></traas-cancelled-trip-icon>
            <span [innerHTML]="cancellationMessageByStops.lastStop?.htmlContent"></span>
          </div>
        </div>
      }
    </div>
  </ion-row>
</div>
