import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { CreditCard, GuestCustomer, PaymentMethodsConfiguration } from '@traas/boldor/all-models';
import { CartActions, CartSelectors, CartState } from '../../../../features/cart/store';
import { CreditCardsActions, CreditCardsSelectors } from '../../../../features/credit-cards/store';
import { CreditCardsState } from '../../../../features/credit-cards/store/credit-cards.state';
import { PaymentStoreActions, PaymentStoreSelectors } from '../../../../features/payment/store';
import { PaymentState } from '../../../../features/payment/store/payment.state';
import { ConfigurationService } from '../../../../services/common/configuration/configuration.service';
import { PreferencesService } from '@traas/boldor/common/services/common/preferences/preferences.service';
import { firstValueFrom, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

export const USE_3D_SECURE_THRESHOLD_IN_CHF = 15; // 15 CHF.

@Component({
    selector: 'app-credit-card-selector',
    templateUrl: './credit-card-selector.component.html',
    styleUrls: ['./credit-card-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreditCardSelectorComponent implements OnInit {
    @Input({ required: true }) guestCustomer!: GuestCustomer | undefined;

    $createCreditCard: Observable<boolean>;
    showSkip3DSecure = false;
    $creditCards: Observable<CreditCard[] | undefined>;
    $selectedCreditCard: Observable<CreditCard | null>;
    $selectedCardPaymentMethodName: Observable<string | null>;
    $selectedMobilePaymentMethodName: Observable<string | null>;
    paymentMethodsConfiguration: Promise<PaymentMethodsConfiguration>;
    hasSkip3DSecure = false;

    constructor(
        private cartStore: Store<CartState>,
        private creditCardStore: Store<CreditCardsState>,
        private paymentStore: Store<PaymentState>,
        private preferenceService: PreferencesService,
        private configurationService: ConfigurationService,
    ) {}

    async ngOnInit(): Promise<void> {
        // select
        this.paymentMethodsConfiguration = this.configurationService.getPaymentMethods();
        this.$selectedCardPaymentMethodName = this.paymentStore.select(PaymentStoreSelectors.getCardPaymentMethodName);
        this.$selectedMobilePaymentMethodName = this.paymentStore.select(PaymentStoreSelectors.getMobilePaymentMethodName);

        if (!this.guestCustomer) {
            this.$creditCards = this.creditCardStore.select(CreditCardsSelectors.getCreditCards);
            this.$selectedCreditCard = this.creditCardStore
                .select(CreditCardsSelectors.getSelectedCreditCard)
                .pipe(filter((value) => !!value));
            this.$createCreditCard = this.creditCardStore.select(CreditCardsSelectors.isCreateCreditCard);
            const cartPriceInCts = await firstValueFrom(this.cartStore.select(CartSelectors.getCartPrice));
            this.showSkip3DSecure = await this.preferenceService.showSkip3DSecureForCart(cartPriceInCts ?? 0);

            // dispatch
            this.creditCardStore.dispatch(new CreditCardsActions.LoadCreditCards());
        }
    }

    onSavedCreditCardSelectedChange(card: CreditCard | null): void {
        this.creditCardStore.dispatch(new CreditCardsActions.SetCreditCard(card));
    }

    onCreateCreditCardChange(checked: boolean): void {
        this.creditCardStore.dispatch(new CreditCardsActions.SetCreateCreditCard(checked));
    }

    onPaymentMethodChange(selected: boolean): void {
        this.cartStore.dispatch(new CartActions.SetSelectedPaymentMean(selected));
    }

    onSkip3DSecureChange(checked: boolean): void {
        this.creditCardStore.dispatch(new CreditCardsActions.SetSkip3DSecure(checked));
    }

    onPaymentMethodNameSelected(selected: string | null): void {
        this.paymentStore.dispatch(new PaymentStoreActions.SetPaymentMethodName(selected));
    }

    onCardPaymentMethodNameSelected(selected: string | null): void {
        this.paymentStore.dispatch(new PaymentStoreActions.SetCardPaymentMethodName(selected));
    }

    onMobilePaymentMethodNameSelected(selected: string | null): void {
        this.paymentStore.dispatch(new PaymentStoreActions.SetMobilePaymentMethodName(selected));
    }
}
