import { CategoryTicketsAdapter } from '../../../models/ticket/category-tickets';
import { Ticket } from '@traas/boldor/all-models';
import { GqlQuickArticle } from '../services/quick-article.service';

export interface TicketState {
    isLoading: boolean;
    quickTickets: CategoryTicketsAdapter[];
    quickTicketsByZone: Ticket[];
    ticketList: GqlQuickArticle[];
    isInitialized: boolean;
}

export const initialState: TicketState = {
    isLoading: false,
    quickTickets: [],
    quickTicketsByZone: [],
    ticketList: [],
    isInitialized: false,
};
