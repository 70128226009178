// note : this file is ignored by prettier (in .prettierignore) to make it easier to read with long lines

import { inject, Injectable } from '@angular/core';
import { ObservableTypedStorage, StorageService, UnsecureObservableTypedStorage } from '@traas/common/utils';
import {
    BookmarkStorage,
    ConfigurationStorage,
    CurrencyPreferenceStatus,
    ItineraryFiltersStorage,
    LineStyleStorage,
    PreferencesStorage,
    SearchHistoryStorage,
    TitlesStorage,
} from '@traas/boldor/all-models';
import { HalfFareStorage } from '@traas/common/feature-account';
import { PreferencesService } from '@traas/boldor/common/services/common/preferences/preferences.service';
import { environment } from '@traas/boldor/environments';
import { TicketOrderStorage } from '../order/order-storage.service';
import { StopRequestsStorage } from '../stop-request/stop-request-storage.service';
import { CguStatus, TosVersionStorage } from '@traas/common/feature-tos';
import { APP_CURRENT_DATA_VERSION_NUMBER, DataVersion } from './data-version.service';
import { MessageStorage } from '../../../features/startup-notification/models/message-storage';
import { QuickTicketListStorage } from '../../../features/ticket/services/ticket-list-storage.service';

@Injectable({ providedIn: 'root' })
export class MigrateToSecureStorageService {
    #secureStorageService = inject(StorageService);

    async migrateToSecureStorageData(): Promise<void> {
        console.debug('[MigrateToSecureStorageService] Starting migration to secure data');

        const hasAlreadyMigrated = (await this.#secureStorageService.get('secureDataMigrationDone')) === 'true';
        if (hasAlreadyMigrated) {
            console.debug('[MigrateToSecureStorageService] Migration to secure data ALREADY DONE = skipping');
            return;
        }

        await this.#secureDataVersionWithFallback();

        await this.securePreferencesWithFallback();
        await this.secureBookmarksWithFallback();
        await this.#secureOnboardingWithFallback();
        await this.#secureOrdersWithFallback();
        await this.#secureStaticDataWithFallback();

        await this.#secureStorageService.set('secureDataMigrationDone', 'true');
    }

    #unsecureDataVersionStorage = inject(UnsecureObservableTypedStorage<DataVersion>);
    #secureDataVersionStorage = inject(ObservableTypedStorage<DataVersion>);
    async #secureDataVersionWithFallback(): Promise<void> {
        await this.#getAndUpdateAndValidate<DataVersion>(this.#unsecureDataVersionStorage, this.#secureDataVersionStorage, 'dataVersion', APP_CURRENT_DATA_VERSION_NUMBER);
    }

    #preferencesService = inject(PreferencesService);

    #unsecurePreferencesStorage = inject(UnsecureObservableTypedStorage<PreferencesStorage>);
    #securePreferencesStorage = inject(ObservableTypedStorage<PreferencesStorage>);
    async securePreferencesWithFallback(): Promise<void> {
        const defaultPreferences = this.#preferencesService.getDefaultPreferences();

        await this.#getAndUpdateAndValidate<PreferencesStorage>(this.#unsecurePreferencesStorage, this.#securePreferencesStorage, 'accessibilityMode', defaultPreferences.accessibilityMode);
        await this.#getAndUpdateAndValidate<PreferencesStorage>(this.#unsecurePreferencesStorage, this.#securePreferencesStorage, 'colorMode', defaultPreferences.colorMode);
        await this.#getAndUpdateAndValidate<PreferencesStorage>(this.#unsecurePreferencesStorage, this.#securePreferencesStorage, 'currency', defaultPreferences.currency);
        await this.#getAndUpdateAndValidate<PreferencesStorage>(this.#unsecurePreferencesStorage, this.#securePreferencesStorage, 'currencyChangedWhileLoggedOut', defaultPreferences.currencyChangedWhileLoggedOut);
        await this.#getAndUpdateAndValidate<PreferencesStorage>(this.#unsecurePreferencesStorage, this.#securePreferencesStorage, 'mapDisplayOption', defaultPreferences.mapDisplayOption);
        await this.#getAndUpdateAndValidate<PreferencesStorage>(this.#unsecurePreferencesStorage, this.#securePreferencesStorage, 'searchMode', defaultPreferences.searchMode);
        await this.#getAndUpdateAndValidate<PreferencesStorage>(this.#unsecurePreferencesStorage, this.#securePreferencesStorage, 'skip3DSecure', defaultPreferences.skip3DSecure);
        await this.#getAndUpdateAndValidate<PreferencesStorage>(this.#unsecurePreferencesStorage, this.#securePreferencesStorage, 'skip3DSecureChangedWhileLoggedOut', defaultPreferences.skip3DSecureChangedWhileLoggedOut);
        await this.#getAndUpdateAndValidate<PreferencesStorage>(this.#unsecurePreferencesStorage, this.#securePreferencesStorage, 'timeDisplayMode', defaultPreferences.timeDisplayMode);
        await this.#getAndUpdateAndValidate<PreferencesStorage>(this.#unsecurePreferencesStorage, this.#securePreferencesStorage, 'travelClass', defaultPreferences.travelClass);
    }

    #unsecureBookmarkStorage = inject(UnsecureObservableTypedStorage<BookmarkStorage>);
    #secureBookmarkStorage = inject(ObservableTypedStorage<BookmarkStorage>);
    async secureBookmarksWithFallback(): Promise<void> {
        const defaultBookmarks = environment.defaultBookmarks ?? [];

        await this.#getAndUpdateAndValidate<BookmarkStorage>(this.#unsecureBookmarkStorage, this.#secureBookmarkStorage, 'bookmarksOrderIds');
        await this.#getAndUpdateAndValidate<BookmarkStorage>(this.#unsecureBookmarkStorage, this.#secureBookmarkStorage,'bookmarks', defaultBookmarks);
    }


    #unsecureCguStatusStorage = inject(UnsecureObservableTypedStorage<CguStatus>);
    #secureCguStatusStorage = inject(ObservableTypedStorage<CguStatus>);
    #unsecureTosVersionStorage = inject(UnsecureObservableTypedStorage<TosVersionStorage>);
    #secureTosVersionStorage = inject(ObservableTypedStorage<TosVersionStorage>);
    #unsecureCurrencyPreferenceStatusStorage = inject(UnsecureObservableTypedStorage<CurrencyPreferenceStatus>);
    #secureCurrencyPreferenceStatusStorage = inject(ObservableTypedStorage<CurrencyPreferenceStatus>);
    #unsecureHalfFareStorage = inject(UnsecureObservableTypedStorage<HalfFareStorage>);
    #secureHalfFareStorage = inject(ObservableTypedStorage<HalfFareStorage>);
    // private unsecureHomeStorage = inject(UnsecureObservableTypedStorage<HomeStorage>);
    // private secureHomeStorage = inject(ObservableTypedStorage<HomeStorage>);
    #unsecureMessageStorage = inject(UnsecureObservableTypedStorage<MessageStorage>);
    #secureMessageStorage = inject(ObservableTypedStorage<MessageStorage>);
    async #secureOnboardingWithFallback(): Promise<void> {
        await this.#getAndUpdateAndValidate<CguStatus>(this.#unsecureCguStatusStorage, this.#secureCguStatusStorage, 'cguStatus');
        await this.#getAndUpdateAndValidate<TosVersionStorage>(this.#unsecureTosVersionStorage, this.#secureTosVersionStorage, 'cguVersion');
        await this.#getAndUpdateAndValidate<CurrencyPreferenceStatus>(this.#unsecureCurrencyPreferenceStatusStorage, this.#secureCurrencyPreferenceStatusStorage, 'hasChosenCurrency');
        await this.#getAndUpdateAndValidate<HalfFareStorage>(this.#unsecureHalfFareStorage, this.#secureHalfFareStorage, 'hasHalfFare');
        // await this.getAndUpdateAndValidate<HomeStorage>(this.unsecureHomeStorage, this.secureHomeStorage, 'splitAreaSizes');
        await this.#getAndUpdateAndValidate<MessageStorage>(this.#unsecureMessageStorage, this.#secureMessageStorage, 'acknowledgeMessagesId');
    }

    #unsecureTicketOrderStorage = inject(UnsecureObservableTypedStorage<TicketOrderStorage>);
    #secureTicketOrderStorage = inject(ObservableTypedStorage<TicketOrderStorage>);
    #unsecureStopRequestsStorage = inject(UnsecureObservableTypedStorage<StopRequestsStorage>);
    #secureStopRequestsStorage = inject(ObservableTypedStorage<StopRequestsStorage>);
    #unsecureSearchHistoryStorage = inject(UnsecureObservableTypedStorage<SearchHistoryStorage>);
    #secureSearchHistoryStorage = inject(ObservableTypedStorage<SearchHistoryStorage>);
    #unsecureQuickTicketListStorage = inject(UnsecureObservableTypedStorage<QuickTicketListStorage>);
    #secureQuickTicketListStorage = inject(ObservableTypedStorage<QuickTicketListStorage>);
    async #secureOrdersWithFallback(): Promise<void> {
        await this.#getAndUpdateAndValidate<TicketOrderStorage>(this.#unsecureTicketOrderStorage, this.#secureTicketOrderStorage, 'orders', []);
        await this.#getAndUpdateAndValidate<StopRequestsStorage>(this.#unsecureStopRequestsStorage, this.#secureStopRequestsStorage, 'stopRequests', []);
        await this.#getAndUpdateAndValidate<SearchHistoryStorage>(this.#unsecureSearchHistoryStorage, this.#secureSearchHistoryStorage, 'searchHistory', []);
        await this.#getAndUpdateAndValidate<QuickTicketListStorage>(this.#unsecureQuickTicketListStorage, this.#secureQuickTicketListStorage, 'ticketList', []);
    }

    #unsecureLineStyleStorage = inject(UnsecureObservableTypedStorage<LineStyleStorage>);
    #secureLineStyleStorage = inject(ObservableTypedStorage<LineStyleStorage>);
    #unsecureConfigurationStorage = inject(UnsecureObservableTypedStorage<ConfigurationStorage>);
    #secureConfigurationStorage = inject(ObservableTypedStorage<ConfigurationStorage>);
    #unsecureItineraryFiltersStorage = inject(UnsecureObservableTypedStorage<ItineraryFiltersStorage>);
    #secureItineraryFiltersStorage = inject(ObservableTypedStorage<ItineraryFiltersStorage>);
    #unsecureReferenceStorage = inject(UnsecureObservableTypedStorage<TitlesStorage>);
    #secureReferenceStorage = inject(ObservableTypedStorage<TitlesStorage>);
    async #secureStaticDataWithFallback(): Promise<void> {
        await this.#getAndUpdateAndValidate<LineStyleStorage>(this.#unsecureLineStyleStorage, this.#secureLineStyleStorage, 'linesStyles');
        await this.#getAndUpdateAndValidate<ConfigurationStorage>(this.#unsecureConfigurationStorage, this.#secureConfigurationStorage, 'refreshIntervals');
        await this.#getAndUpdateAndValidate<ConfigurationStorage>(this.#unsecureConfigurationStorage, this.#secureConfigurationStorage, 'thresholds');
        await this.#getAndUpdateAndValidate<ItineraryFiltersStorage>(this.#unsecureItineraryFiltersStorage, this.#secureItineraryFiltersStorage, 'transportModes');
        await this.#getAndUpdateAndValidate<TitlesStorage>(this.#unsecureReferenceStorage, this.#secureReferenceStorage, 'frequentTravelerTitles');
    }

    async #getAndUpdateAndValidate<T>(
        unsecureStorage: UnsecureObservableTypedStorage<T>,
        secureStorage: ObservableTypedStorage<T>,
        key: keyof T,
        defaultValue?: any
    ): Promise<void> {
        try {
            const currentValue = await unsecureStorage.getItem(key);
            if (!currentValue && key !== 'defaultValue') {
                throw new Error(`previous data ${key.toString()} MISSING`);
            }
            await secureStorage.setItem(key, currentValue || defaultValue);
            const newValue = await secureStorage.getItem(key);
            if (JSON.stringify(newValue) !== JSON.stringify(currentValue)) {
                throw new Error(`migrating ${key.toString()} to secure storage FAILED or data are DIFFERENT`);
            }
            console.debug(`[MigrateToSecureStorageService] migrating ${key.toString()} succeeded`);
        } catch (error: any) {
            console.debug(error.message);
            if (defaultValue) {
                console.debug(`[MigrateToSecureStorageService] migrating ${key.toString()} FAILED, setting default value`);
                await secureStorage.setItem(key, defaultValue);
            } else {
                console.debug(`[MigrateToSecureStorageService] migrating ${key.toString()} FAILED, removing key`);
                await secureStorage.removeItem(key);
            }
        } finally {
            console.debug(`[MigrateToSecureStorageService] removing ${key.toString()} from unsecure storage`);
            await unsecureStorage.removeItem(key);
        }
    }
}
