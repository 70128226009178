import { CreditCardsAction, CreditCardsActionTypes } from './credit-cards.action';
import { CreditCardsState, initialState } from './credit-cards.state';

export function creditCardsReducer(state = initialState, action: CreditCardsAction): CreditCardsState {
    switch (action.type) {
        case CreditCardsActionTypes.LoadCreditCardsSuccess:
            return {
                ...state,
                cards: [...action.payload],
            };

        case CreditCardsActionTypes.LoadCreditCardsFail:
            return {
                ...state,
                cards: undefined,
            };

        case CreditCardsActionTypes.SetCreditCard:
            return {
                ...state,
                selectedCreditCard: action.payload,
            };

        case CreditCardsActionTypes.SetCreateCreditCard:
            return {
                ...state,
                createCreditCard: action.payload,
            };

        case CreditCardsActionTypes.ResetCreditCards:
            return {
                ...state,
                cards: undefined,
                selectedCreditCard: null,
                createCreditCard: false,
            };

        case CreditCardsActionTypes.SetSkip3DSecure:
            return {
                ...state,
                skip3DSecure: action.payload,
            };

        default:
            return state;
    }
}
