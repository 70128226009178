import { Injectable } from '@angular/core';
import { Device } from '@capacitor/device';

@Injectable()
export class DeviceIdService {
    #deviceId: string | undefined;

    async getDeviceId(): Promise<string> {
        if (!this.#deviceId) {
            const deviceInfo = await Device.getId();
            this.#deviceId = deviceInfo.identifier;
        }
        return this.#deviceId;
    }
}
