import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { USE_3D_SECURE_THRESHOLD_IN_CHF } from '../../../../pages/cart/payment-page/credit-card-selector/credit-card-selector.component';

@Component({
    selector: 'app-use-3d-secure',
    templateUrl: './use3-d-secure.component.html',
    styleUrls: ['./use3-d-secure.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Use3DSecureComponent {
    @Input({ required: true }) showSkip3DSecure = false;
    @Input({ required: true }) skip3DSecure = false;
    @Output() skip3DSecureChange = new EventEmitter<boolean>();

    onSkip3DSecureChange(event: Event): void {
        event.preventDefault();
        event.stopPropagation();
        const checked = (event as CustomEvent).detail.checked;
        if (this.skip3DSecure !== checked) {
            this.skip3DSecureChange.emit(checked);
        }
    }

    getSkip3DSecureMinimumAmountThreshold(): number {
        return USE_3D_SECURE_THRESHOLD_IN_CHF;
    }
}
