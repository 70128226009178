<ion-header>
  <app-page-title defaultHref="/tabs/bookings" title="" />
</ion-header>
<ion-content fullscreen>
  @if ($order | async; as order) {
    <div class="flex tickets summary ion-padding-top ion-padding-bottom">
      <ion-accordion-group #accordionGroup>
        <ion-accordion [readonly]="isQuick(order)" [toggleIcon]="isQuick(order) ? '' : 'chevron-down'">
          <ion-item slot="header">
            @if (isItinerary(order)) {
              <app-itinerary-ticket-summary
                [itineraryOrder]="order"
                [showOperationsIcons]="true"
                [order]="order"
                (refundOrder)="onConfirmRefundOrder($event)"
                [isRefundable]="($isRefundable | async)!"
                />
            }
            @if (isQuick(order)) {
              <app-quick-ticket-summary-item
                [order]="order"
                [showOperationsIcons]="false"
                [quickTicket]="order"
                (refundOrder)="onConfirmRefundOrder($event)"
                [isRefundable]="($isRefundable | async)!"
                />
            }
            @if (isDeparture(order)) {
              <app-departure-ticket-summary
                [departureOrder]="order"
                [showOperationsIcon]="false"
                [transitStops]="($transitStops | async)!"
                [order]="order"
                (refundOrder)="onConfirmRefundOrder($event)"
                [isRefundable]="($isRefundable | async)!"
                />
            }
          </ion-item>
          @if (accordionGroup.value) {
            <div slot="content">
              @if (isItinerary(order)) {
                <app-itinerary-details-content [legs]="order.itinerary.legs"></app-itinerary-details-content>
              } @else if (isDeparture(order)) {
                <!-- TODO ALEXY FAIRE UN COMPOSANT DE CA GENRE app-departure-details-content-->
                @if ($transitStops | async; as transitStops) {
                  <ion-list>
                    @for (transitStop of transitStops; track transitStop.id; let last = $last; let even = $even) {
                      <ion-item>
                        <app-transit-stop-item
                          [isLast]="last"
                          [lineStyle]="lineStyle"
                          [ngClass]="even ? 'odd' : 'even'"
                          [isTrain]="isTrain(order.departure.transport)"
                          [transitStop]="transitStop"
                          [activateForwardArrow]="false"
                          />
                      </ion-item>
                    }
                  </ion-list>
                } @else {
                  <ion-row>
                    <ion-col class="ion-text-center ion-margin-top">
                      <ion-spinner class="loading-spinner"></ion-spinner>
                    </ion-col>
                  </ion-row>
                }
              }
            </div>
          }
        </ion-accordion>
      </ion-accordion-group>
    </div>
    @if ($hasStopRequest | async) {
      <div>
        <app-itinerary-ticket-stop-request (cancelStopRequest)="onCancelStopRequest(order.id)" />
      </div>
    }
    @for (ticket of order.tickets; track ticket) {
      <div class="list">
        <app-ticket-order [ticket]="ticket" />
      </div>
    }
  } @else {
    <div class="fullscreen">
      <ion-spinner />
    </div>
  }
</ion-content>
