import { Action } from '@ngrx/store';
import { OrderViewModel } from '@traas/boldor/all-models';
import { FailAction } from '@traas/common/utils';

export enum BookingActionTypes {
    LoadOrders = '[Booking] Load orders...',
    LoadOrdersSuccess = '[Booking] Load orders success',
    LoadOrdersFail = '[Booking] Load orders fail',

    SetOrders = '[Booking] Set all orders',
    AddOrder = '[Booking] Add an order',
    UpdateOrderById = '[Booking] Update an order',
    SetIsLoading = '[Booking] Set is loading',
}

export class UpdateOrderById implements Action {
    readonly type = BookingActionTypes.UpdateOrderById;

    constructor(public payload: OrderViewModel) {}
}

export class LoadOrders implements Action {
    readonly type = BookingActionTypes.LoadOrders;

    constructor(public payload?: { preventLoading: boolean }) {}
}

export class LoadOrdersSuccess implements Action {
    readonly type = BookingActionTypes.LoadOrdersSuccess;

    constructor(public payload: { orders: OrderViewModel[] }) {}
}

export class LoadOrdersFail extends FailAction implements Action {
    readonly type = BookingActionTypes.LoadOrdersFail;

    constructor(public override payload: any) {
        super(payload);
    }
}

export class SetOrders implements Action {
    readonly type = BookingActionTypes.SetOrders;

    constructor(public payload: { orders: OrderViewModel[] }) {}
}

export class SetIsLoading implements Action {
    readonly type = BookingActionTypes.SetIsLoading;

    constructor(public isLoading: boolean) {}
}

export class AddOrder implements Action {
    readonly type = BookingActionTypes.AddOrder;

    constructor(public payload: OrderViewModel) {}
}

export type BookingAction = LoadOrders | LoadOrdersSuccess | LoadOrdersFail | SetOrders | AddOrder | UpdateOrderById | SetIsLoading;
