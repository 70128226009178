import { Injectable } from '@angular/core';
import { DepartureCart } from '@traas/boldor/all-models';
import { BuyDepartureTicketArticlesGQL, BuyDepartureTicketArticlesMutationVariables } from '@traas/boldor/graphql-generated/graphql';
import { createPassengerInputFrom } from '../../../models/customer';
import { GqlArticleConverter } from '../../../models/ticket/gql-article-converter';
import { firstValueFrom } from 'rxjs';
import { PurchaseEmailHelperService } from './purchase-email-helper.service';
import { ErrorCodes, TechnicalError } from '@traas/common/models';

@Injectable({
    providedIn: 'root',
})
export class DepartureArticlePurchaseService {
    constructor(
        private buyDepartureTicketArticlesGQL: BuyDepartureTicketArticlesGQL,
        private purchaseEmailHelperService: PurchaseEmailHelperService,
    ) {}

    async buyDepartureArticles(parameters: DepartureCart): Promise<string> {
        const variables = await this.#createBuyDepartureCartVariables(parameters);
        const result = await firstValueFrom(this.buyDepartureTicketArticlesGQL.mutate(variables));
        const orderId = result.data?.purchase.buyDepartureTicketArticles.orderId;
        if (!orderId) {
            throw new TechnicalError(
                'buyDepartureTicketArticlesGQL response must contains an orderId.',
                ErrorCodes.Purchase.BuyArticles,
                undefined,
                {
                    userId: variables.basket.articleSelections[0].passenger.id,
                },
            );
        }
        return orderId;
    }

    async #createBuyDepartureCartVariables(cart: DepartureCart): Promise<BuyDepartureTicketArticlesMutationVariables> {
        let defaultEmail: string | undefined = undefined;
        if (this.purchaseEmailHelperService.shouldFetchDefaultEmail(cart)) {
            defaultEmail = await this.purchaseEmailHelperService.fetchDefaultCustomerEmail();
        }
        return {
            basket: {
                articleSelections: cart.articleSelections.map(({ article, passenger }) => {
                    return {
                        passengerType: passenger.type,
                        isAuthenticatedCustomer: passenger.isCustomer,
                        article: GqlArticleConverter.toDepartureArticleInput(article),
                        passenger: createPassengerInputFrom({ ...passenger, email: passenger.email ?? defaultEmail }),
                    };
                }),
                departure: cart.departure,
                requestStop: !!cart.operations?.stopRequest?.isChecked,
            },
        };
    }
}
