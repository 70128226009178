import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { LineIconComponent } from './line-icon.component';
import { SymbolSvgModule } from '@traas/common/components';

@NgModule({
    imports: [CommonModule, IonicModule, SymbolSvgModule],
    declarations: [LineIconComponent],
    exports: [LineIconComponent],
})
export class LineIconModule {}
