import { Inject, inject, Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { Browser } from '@capacitor/browser';
import { HttpClient } from '@angular/common/http';
import { LoadingController } from '@ionic/angular';
import { RouteUrl } from '@traas/common/routing';
import { Router } from '@angular/router';
import { SWISSPASS_SERVICE_TOKEN, SwisspassService } from './SwisspassService';
import { convertToError, LoggingService } from '@traas/common/logging';
import { ErrorCodes, TechnicalError } from '@traas/common/models';
import { GatewayEndpoints } from '@traas/boldor/common/gateway-endpoints';

@Injectable({
    providedIn: 'root',
})
export class SwisspassSsoService {
    #http = inject(HttpClient);
    #router = inject(Router);
    #loadingController = inject(LoadingController);
    #logger = inject(LoggingService);

    readonly #swisspassSSOUrl: string;

    constructor(
        @Inject(SWISSPASS_SERVICE_TOKEN)
        private swisspassService: SwisspassService,
    ) {
        this.#swisspassSSOUrl = GatewayEndpoints.swisspassSSOUrl;
    }

    async swisspassSSORedirect(redirectLocation: string): Promise<void> {
        const loading = await this.#loadingController.create();
        await loading.present();

        const accessToken = await this.swisspassService.getAccessToken();
        try {
            const url = accessToken ? await this.getSwisspassSSOUrl(accessToken, redirectLocation) : redirectLocation;
            await Browser.open({ url });
        } catch (error) {
            this.#logger.logError(
                new TechnicalError(
                    `Error while redirecting to swisspass SSO on ${redirectLocation}`,
                    ErrorCodes.Swisspass.SsoRedirect,
                    convertToError(error),
                ),
            );
            await Browser.open({ url: redirectLocation });
        } finally {
            await loading.dismiss();
        }
    }

    async navigateToLoginFromSwisspassSSO(returnUrl: string, swisspassSSORedirectUrl: string): Promise<void> {
        await this.#router.navigate([RouteUrl.loginUrl], {
            queryParams: {
                returnUrl,
                swisspassSSORedirectUrl,
            },
        });
    }

    async getSwisspassSSOUrl(accessToken: string, redirectLocation: string): Promise<string> {
        return await firstValueFrom(
            this.#http.post(
                this.#swisspassSSOUrl,
                {
                    accessToken,
                    redirectLocation,
                },
                {
                    responseType: 'text',
                },
            ),
        );
    }
}
