<div
  (throttleClick)="showDatetimePicker()"
  appThrottleClick
  [class.isItinerary]="isModeItinerary()"
  [class.small-map-mode]="($getMapMode | async) === mapModeEnum.Small"
  class="datepicker"
  tappable
  >
  @if (isModeItinerary()) {
    <div class="select-container">
      <ion-select
        appClickStopPropagation
        interface="popover"
        (ionChange)="onSelect($event)"
        [(ngModel)]="this.datePickerConfig.searchOption"
        [disabled]="!!datePickerConfig.locked"
        >
        <ion-select-option [value]="DatePickerBarSearchOption.DEPARTURE"
          >{{ 'date-picker-options.departure' | translate }}
        </ion-select-option>
        <ion-select-option [value]="DatePickerBarSearchOption.ARRIVAL"
          >{{ 'date-picker-options.arrival' | translate }}
        </ion-select-option>
      </ion-select>
    </div>
  }
  @if (isModeItinerary()) {
    <div class="itinerary-dates e2e-date-picker">
      <span class="date e2e-picked-date" [class.isTPC]="isTPC">{{ $formattedDate | async }}</span>
      <span class="time e2e-picked-time" [class.isTPC]="isTPC">{{ $formattedTime | async }}</span>
    </div>
  } @else {
    <div class="departure-dates e2e-date-picker">
      <span class="date e2e-picked-date" [class.isTPC]="isTPC">{{ $formattedDate | async }}</span>
      <span class="time e2e-picked-time" [class.isTPC]="isTPC">{{ $formattedTime | async }}</span>
    </div>
  }
</div>
