import { getDelayInMinutes } from '@traas/boldor/business-rules';
import { SyntheseDateHelper } from '@traas/boldor/all-helpers';
import { AbstractAdapter, HumanReadableStop, ItineraryStop } from '@traas/boldor/all-models';
import { LatLng } from 'leaflet';
import * as moment from 'moment';
import { PhysicalStop } from '@traas/common/models';
import { PhysicalStopAdapter } from '@traas/common/utils';

export class StopAdapter extends AbstractAdapter<ItineraryStop> implements HumanReadableStop {
    constructor(data: ItineraryStop) {
        // TODO Alexy: Check if case of empty messages happend with sentry maybe ?
        // Guard to avoid messages being undefined while we check the length

        if (data) {
            data.messages = data.messages ?? [];
        }
        super(data);
    }

    /**
     * We compare if the stop is the same place, not if stopping there is the same.
     * in french stop !== escale
     */
    isEqual(stop: StopAdapter): boolean {
        return (
            this.getId() === stop.getId() &&
            this.getArrivalTime() === stop.getArrivalTime() &&
            this.getDepartureTime() === stop.getDepartureTime()
        );
    }

    getLatLng(): LatLng {
        return new LatLng(this.getLatitude(), this.getLongitude());
    }

    getLatitude(): number {
        return this.getData().latLon[0];
    }

    getLongitude(): number {
        return this.getData().latLon[1];
    }

    getCityName(): string {
        return this.getData()?.cityName ?? '';
    }

    getName(): string {
        return this.getData()?.name ?? '';
    }

    getId(): string {
        return this.getData()?.id ?? '';
    }

    getTrack(): string | undefined {
        return this.getData().physicalStopAssociated?.letter;
    }

    getPhysicalStop(): PhysicalStopAdapter | null {
        try {
            const physicalStopAssociated = this.getPhysicalStopAssociated();
            return this.getData() && physicalStopAssociated ? new PhysicalStopAdapter(physicalStopAssociated) : null;
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    getDepartureTime(): string {
        return this.getData().departureTime;
    }

    getScheduledDepartureTime(): Date | null {
        const scheduledDepartureTime = this.getData().scheduledDepartureTime;
        if (scheduledDepartureTime) {
            return SyntheseDateHelper.parseSyntheseStringDate(scheduledDepartureTime);
        }
        const departureTime = this.getData().departureTime;
        if (departureTime) {
            return SyntheseDateHelper.parseSyntheseStringDate(departureTime);
        }
        console.error(`Departure date is undefined. Can't get date`);
        return null;
    }

    getDepartureDatetime(): Date | null {
        try {
            return SyntheseDateHelper.parseSyntheseStringDate(this.getDepartureTime());
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    getArrivalTime(): string {
        return this.getData().arrivalTime;
    }

    getScheduledArrivalTime(): Date | null {
        const scheduledArrivalTime = this.getData().scheduledArrivalTime;
        if (scheduledArrivalTime) {
            return SyntheseDateHelper.parseSyntheseStringDate(scheduledArrivalTime);
        }
        const arrivalTime = this.getData().arrivalTime;
        if (arrivalTime) {
            return SyntheseDateHelper.parseSyntheseStringDate(arrivalTime);
        }
        console.error(`Arrival date is undefined. Can't get date`);
        return null;
    }

    getScheduledArrivalDatetime(): Date | null {
        try {
            const scheduledArrivalTime = this.getScheduledArrivalTime();
            if (!scheduledArrivalTime) {
                return null;
            }
            return scheduledArrivalTime;
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    getArrivalDatetime(): Date | null {
        try {
            return moment(this.getArrivalTime()).toDate();
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    getPhysicalStopAssociated(): PhysicalStop | undefined | null {
        return this.getData().physicalStopAssociated;
    }

    isCancelled(): boolean {
        return this.getData().isCancelled;
    }

    isOptional(): boolean {
        return this.getData().isOptional;
    }

    getArrivalDelay(): string {
        const arrivalDate = this.getArrivalDatetime();
        const scheduledArrivalDate = this.getScheduledArrivalDatetime();
        if (!arrivalDate || !scheduledArrivalDate) {
            console.error(
                `Arrival datetime is null. Can't get delay. (arrivalDate: ${!!arrivalDate}, scheduledArrivalDate: ${!!scheduledArrivalDate})`,
            );
            return '';
        }
        return getDelayInMinutes(arrivalDate, scheduledArrivalDate);
    }

    getDepartureDelay(): string {
        const departureDate = this.getDepartureDatetime();
        const scheduledDepartureDate = this.getScheduledDepartureTime();
        if (!departureDate || !scheduledDepartureDate) {
            console.error(
                `Departure datetime is null. Can't get delay. (departureDate: ${!!departureDate}, scheduledDepartureDate: ${!!scheduledDepartureDate})`,
            );
            return '';
        }
        return getDelayInMinutes(departureDate, scheduledDepartureDate);
    }
}
