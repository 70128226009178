import { DepartureState } from './departure.state';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DepartureAdapter } from '../../../models/departure/departure';

export const selectDeparture = createFeatureSelector<DepartureState>('departure');

export const getAllDepartures = createSelector(selectDeparture, (departureState: DepartureState) => {
    if (!departureState.future.departures && !departureState.past.departures) {
        return null;
    }
    const all = [...(departureState.past.departures ?? []), ...(departureState.future.departures ?? [])];
    return all.sort(
        (depA, depB) =>
            new DepartureAdapter(depA).getRealTimeDepartureTimestamp() - new DepartureAdapter(depB).getRealTimeDepartureTimestamp(),
    );
});

export const selectRefresher = createSelector(selectDeparture, (departureState: DepartureState) => departureState.refresher);

export const getLoading = createSelector(
    selectDeparture,
    (departureState: DepartureState) => departureState.future.loading || departureState.past.loading,
);

export const getDeparturesInScrollViewport = createSelector(
    selectDeparture,
    (departureState: DepartureState) => departureState.inScrollViewport,
);

export const getLineFilters = createSelector(selectDeparture, (departureState: DepartureState) => departureState.lineFilters);

export const getActiveLineFilters = createSelector(selectDeparture, (departureState: DepartureState) => departureState.activeLineFilters);

export const isLineFiltersLoading = createSelector(selectDeparture, (departureState: DepartureState) => departureState.lineFiltersLoading);

export const hasActiveLineFilters = createSelector(getActiveLineFilters, (lineFilters) => lineFilters.length > 0);

export const getSelectedDeparture = createSelector(selectDeparture, (departureState: DepartureState) => departureState.selected.departure);

export const getCommercialStopIdsTarget = createSelector(
    selectDeparture,
    (departureState: DepartureState) => departureState.commercialStopIdsTarget,
);

export const getError = createSelector(selectDeparture, (departureState: DepartureState) => departureState.error);
