export const ICON_BASE_PATH = 'assets/icons';
export const ON_DEMAND_ICON_SRC = `${ICON_BASE_PATH}/on-demand-is.svg`;
export const BUBBLE_ICON_SRC = `${ICON_BASE_PATH}/bubble.svg`;
export const BUTTON_STOP_ICON_SRC = `${ICON_BASE_PATH}/button_stop.svg`;

/**
 * @param hasBookingRequirements
 * @param isDepartureList
 * @param isFirst
 */
export function getStopHasBookingRequirementsIconName(hasBookingRequirements: boolean, isDepartureList = false, isFirst = false): string {
    if (!hasBookingRequirements) return '';
    return isDepartureList || isFirst ? ON_DEMAND_ICON_SRC : BUBBLE_ICON_SRC;
}
