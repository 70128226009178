import { inject, Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { combineLatest, Observable } from 'rxjs';
import { environment } from '@traas/boldor/environments';
import { switchMap } from 'rxjs/operators';
import { CurrentUserSession } from '@traas/common/logging';
import { AuthenticationApi } from '@traas/common/feature-account';
import { DeviceIdService } from '@traas/boldor/common/http-interceptors/device-id.service';
import { CorrelationIdService } from '@traas/boldor/common/http-interceptors/correlation-id.service';
import { TypedStorage } from '@traas/common/utils';
import { GuestStorage } from '@traas/boldor/all-models';

@Injectable()
export class RequestHeadersInterceptor implements HttpInterceptor {
    readonly #authService = inject(AuthenticationApi);
    readonly #deviceIdService = inject(DeviceIdService);
    readonly #correlationIdService = inject(CorrelationIdService);
    readonly #typedStorage = inject(TypedStorage<GuestStorage>);

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return combineLatest([
            this.#authService.getAccessToken(),
            this.#deviceIdService.getDeviceId(),
            this.#typedStorage.getItem('guestId'),
        ]).pipe(
            switchMap(([token, deviceId, guestId]) => {
                const customerId = CurrentUserSession.activeUser?.id;

                let headers = req.headers
                    .set('Client-Version', environment.clientVersion)
                    .set('x-correlation-id', this.#correlationIdService.createCorrelationId());

                if (token) {
                    headers = headers.set('Authorization', `Bearer ${token}`);
                }
                if (customerId) {
                    headers = headers.set('x-customer-id', customerId);
                }
                if (deviceId) {
                    headers = headers.set('x-device-id', deviceId);
                }
                if (guestId) {
                    headers = headers.set('x-guest-id', guestId);
                }

                const modifiedReq = req.clone({ headers });
                return next.handle(modifiedReq);
            }),
        );
    }
}
