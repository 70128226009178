<ion-header>
  <app-page-title class="e2e-termsofuse-title" title="{{ 'cgu.title' | translate }}" />
</ion-header>
<ion-content #content>
  <div class="ion-padding">
    <travys-tos />
  </div>
  <hr />
  <ion-item lines="none">
    <ion-checkbox [(ngModel)]="tosAccepted" labelPlacement="end" class="e2e-accept-cgu">{{ 'cgu.consent' | translate }}</ion-checkbox>
  </ion-item>
  @if (!tosAccepted) {
    <ion-text class="ion-text-center" color="danger">
      <h6 class="mandatory">{{ 'cgu.missing-consent' | translate }}</h6>
    </ion-text>
  }
</ion-content>
<ion-footer class="ion-text-center">
  <ion-button size="large" elevation="1" (click)="acceptTos(content)" [class.opacity-50]="!tosAccepted" tappable type="button">
    {{ 'cgu.accept-button' | translate }}
  </ion-button>
</ion-footer>
