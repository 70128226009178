import { Injectable } from '@angular/core';
import { LoadingController, LoadingOptions, ModalController } from '@ionic/angular';
import { ModalOptions } from '@ionic/core';

@Injectable({ providedIn: 'root' })
export class ModalService {
    readonly #activesModalStackView: string[] = [];
    #isLoading = false;

    constructor(
        private modalCtrl: ModalController,
        private loadingCtrl: LoadingController,
    ) {}

    async presentModal<T>(modalOptions: ModalOptions, modalName = 'default'): Promise<T> {
        const modal = await this.modalCtrl.create(modalOptions);
        await modal.present();
        this.#activesModalStackView.push(modalName);
        const response = await modal.onWillDismiss();
        this.#activesModalStackView.shift();
        return response.data as T;
    }

    // todo : maybe create an presentModalOnce() which use isAlreadyShown to check internally

    isAlreadyShown(modalName: string): boolean {
        return this.#activesModalStackView[this.#activesModalStackView.length - 1] === modalName;
    }

    async presentLoading(maxDuration?: number): Promise<void> {
        try {
            const loadingTop = await this.loadingCtrl.getTop();
            if (!this.#isLoading && !loadingTop) {
                this.#isLoading = true;
                const loading = await this.loadingCtrl.create(maxDuration ? this.#createLoadingOptions(maxDuration) : {});
                await loading.present();
            }
        } catch (error) {
            console.warn(`ModalService.presentLoading: Error while trying to close modal : ${error.message}`);
        }
    }

    async hideLoading(): Promise<void> {
        try {
            this.#isLoading = false;
            const loadingTop = await this.loadingCtrl.getTop();
            if (loadingTop) {
                await loadingTop.dismiss();
            }
        } catch (error) {
            console.warn(`ModalService.hideLoading: Error while trying to close modal : ${error.message}`);
        }
    }

    #createLoadingOptions(duration: number): LoadingOptions {
        return {
            duration,
            cssClass: 'custom-loading e2e-custom-loading',
        };
    }
}
