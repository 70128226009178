import { inject, Injectable } from '@angular/core';
import { Title, TitlesStorage } from '@traas/boldor/all-models';
import { sortAlphabeticallyAsc } from '@traas/boldor/business-rules';
import { firstValueFrom } from 'rxjs';
import { ObservableTypedStorage } from '@traas/common/utils';
import { GetFrequentTravelerTitlesGQL } from '@traas/boldor/graphql-generated/graphql';
import { convertToError, LoggingService } from '@traas/common/logging';
import { BoldorLocalizationService } from '@traas/boldor/localization';
import { ErrorCodes, TechnicalError } from '@traas/common/models';
import { environment } from '@traas/boldor/environments';

export const FREQUENT_TRAVELER_TITLES_KEY = 'frequentTravelerTitles';

@Injectable()
export class GenderTitlesService {
    #titlesStorage = inject(ObservableTypedStorage<TitlesStorage>);
    #getFrequentTravelerTitlesGQL = inject(GetFrequentTravelerTitlesGQL);
    #loggingService = inject(LoggingService);
    #boldorLocalizationService = inject(BoldorLocalizationService);

    async getFrequentTravelerTitles(): Promise<Title[]> {
        const lang = this.#boldorLocalizationService.languageCode;
        return (await this.#titlesStorage.getItem(FREQUENT_TRAVELER_TITLES_KEY)) ?? environment.defaultTitles[lang];
    }

    async initTitles(): Promise<void> {
        try {
            const localFrequentTravelerTitles = await this.getFrequentTravelerTitles();
            await this.#titlesStorage.setItem(FREQUENT_TRAVELER_TITLES_KEY, localFrequentTravelerTitles);

            this.#getFrequentTravelerTitlesFromRemote().then(async (frequentTravelerTitles) => {
                await this.#titlesStorage.setItem(FREQUENT_TRAVELER_TITLES_KEY, frequentTravelerTitles);
            });
        } catch (error) {
            this.#loggingService.logError(
                new TechnicalError(
                    'Error while initializing frequent traveler gender-titles',
                    ErrorCodes.GenderTitles.Init,
                    convertToError(error),
                ),
            );
        }
    }

    async #getFrequentTravelerTitlesFromRemote(): Promise<Title[]> {
        try {
            const language = this.#boldorLocalizationService.languageCode;
            const { data } = await firstValueFrom(this.#getFrequentTravelerTitlesGQL.fetch({ language }));
            return data.references.frequentTravelerTitles?.sort(this.#sortTitlesByLabelAsc);
        } catch (error) {
            throw new TechnicalError(
                'Error while fetching frequent traveler gender-titles',
                ErrorCodes.GenderTitles.FetchTitles,
                convertToError(error),
            );
        }
    }

    #sortTitlesByLabelAsc(titleA: Pick<Title, 'label'>, titleB: Pick<Title, 'label'>): number {
        return sortAlphabeticallyAsc(titleA.label, titleB.label);
    }
}
