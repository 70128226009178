import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ItineraryAdapter } from '../../../../models/itinerary/itinerary';
import { LegAdapter } from '../../../../models/itinerary/leg';
import { StopAdapter } from '../../../../models/itinerary/stop';
import { ItineraryOrderViewModel, OrderViewModel, QuickTicketsOrderViewModel } from '@traas/boldor/all-models';
import { areThirdPartyPaidTickets, getFormattedPriceFromPrice, getTotalPrice } from '@traas/boldor/all-helpers';
import { Line } from '@traas/common/models';

@Component({
    selector: 'app-itinerary-ticket-summary',
    templateUrl: './itinerary-ticket-summary.component.html',
    styleUrls: ['./itinerary-ticket-summary.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItineraryTicketSummaryComponent implements OnInit {
    @Input() isRefundable = false;
    @Input({ required: true }) itineraryOrder!: ItineraryOrderViewModel;
    @Input() detail = false;
    @Input() showOperationsIcons = true;
    @Input({ required: true }) order!: OrderViewModel;

    @Output() refundOrder = new EventEmitter<ItineraryOrderViewModel>();

    arrivalStop: StopAdapter;
    departureDate: string;
    departureStop: StopAdapter;
    legs: LegAdapter[];
    lines: Line[];
    paddingCol: any = { top: '.1rem', bottom: '.1rem' };
    totalPrice: string;

    ngOnInit(): void {
        const itinerary = new ItineraryAdapter(this.itineraryOrder.itinerary);

        this.departureStop = itinerary.getDepartureStop();
        this.arrivalStop = itinerary.getArrivalStop();
        this.legs = itinerary.getLegsAdapters();
        const transportLegs = itinerary.getTransportLegs();
        this.lines = transportLegs.map((leg) => leg.getLine());
        this.departureDate = this.itineraryOrder.itinerary.departureDate;

        if (this.itineraryOrder.tickets.length > 0) {
            this.totalPrice = this.#get3ppFormattedTotalPrice();
        }
    }

    #get3ppFormattedTotalPrice(): string {
        const cost = getTotalPrice(this.itineraryOrder.tickets);
        const refunded = getTotalPrice(this.itineraryOrder.tickets, (ticket) => !!ticket.article.thirdPartyPayerInformation?.isEligible);
        const totalPrice = cost - refunded ? cost - refunded : cost;
        const currency = this.itineraryOrder.tickets[0].article.prices[0].currency;

        return getFormattedPriceFromPrice({ currency, value: `${totalPrice}` }, false, false);
    }

    confirmRefundOrder(event: MouseEvent): void {
        event.stopPropagation();
        event.preventDefault();
        this.refundOrder.emit(this.itineraryOrder);
    }

    areThirdPartyPaidTickets(order: QuickTicketsOrderViewModel): boolean {
        return areThirdPartyPaidTickets(order);
    }
}
