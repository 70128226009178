import { ChangeDetectionStrategy, Component } from '@angular/core';

import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { CompanyService } from '@traas/boldor/company';

@Component({
    selector: 'traas-itineraries-list-header-label',
    standalone: true,
    imports: [IonicModule, TranslateModule],
    template: `
        <div class="grid">
            <div class="line-logo" [class.tpc]="isTpc">
                {{ 'itineraries.header.lines' | translate }}
            </div>
            <div class="departure-time" [class.tpc]="isTpc">
                {{ 'itineraries.header.departure' | translate }}
            </div>
            <div class="arrival-time">{{ 'itineraries.header.arrival' | translate }}</div>
            <div class="track" [class.tpc]="isTpc">
                {{ 'itineraries.header.track' | translate }}
            </div>
            <div class="duration" [class.tpc]="isTpc">
                {{ 'itineraries.header.duration' | translate }}
            </div>
        </div>
    `,
    styles: `
        :host {
            --padding: 0rem 0.75rem;
            --gap: 0.5rem;
            @media screen and (max-width: 374px) {
                --padding: 0rem 0.35rem;
                --gap: 0.2rem;
            }
        }

        .grid {
            padding: var(--padding);
            display: grid;
            grid-template-columns: 7rem 1fr 1fr min-content 2.6rem;
            height: var(--height, 1.6rem);
            text-transform: uppercase;
            background-color: var(--default-background);
            font-weight: 600;
            border-bottom: 0.1rem solid var(--form-border);
            font-size: 0.6rem;
            align-items: center;
            gap: var(--gap);
        }

        .departure-time,
        .arrival-time {
            text-align: right;
        }

        .duration {
            text-align: right;
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItinerariesListHeaderLabelComponent {
    isTpc = CompanyService.isTPC();
}
