import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { IconMode } from '@traas/boldor/all-models';

@Component({
    selector: 'app-dynamic-ion-icon',
    templateUrl: 'dynamic-ion-icon.component.html',
    styleUrls: ['dynamic-ion-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicIonIconComponent implements OnInit {
    get nameOrPath(): string {
        return this.#nameOrPathValue;
    }

    @Input({ required: true })
    set nameOrPath(value: string) {
        this.iconMode = this.#getIconMode(value);
        this.#nameOrPathValue = value;
    }

    @Input() mode = '';

    iconMode?: IconMode;
    iconModeSRC = IconMode.SRC;
    iconModeNAME = IconMode.NAME;
    #nameOrPathValue = '';

    static isIonIconPath(iconPathOrName: string): boolean {
        return iconPathOrName.includes('assets/');
    }

    ngOnInit(): void {
        this.iconMode = this.#getIconMode(this.nameOrPath);
    }

    #getIconMode(value: string): IconMode {
        return DynamicIonIconComponent.isIonIconPath(value) ? IconMode.SRC : IconMode.NAME;
    }
}
