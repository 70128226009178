import { StopAdapter } from './stop';
import { AbstractAdapter, City, Place, PlacesTypeInterface, TraasGeolocationCoordinates } from '@traas/boldor/all-models';
import * as hash from 'object-hash';
import { ICON_BASE_PATH } from '@traas/common/business-rules';

export class CityAdapter extends AbstractAdapter<City> implements Place<City> {
    isFromHistory = false;

    constructor(data: City) {
        super(data);
    }

    createCityPlacesHistory(): this {
        this.isFromHistory = true;
        this.getNameOrPath = (): string => 'time';
        return this;
    }

    getAdaptedMainStops(): StopAdapter[] {
        return this.getData().mainStops.map((stop) => new StopAdapter(stop, this.getName()));
    }

    transformToCoordinates(): TraasGeolocationCoordinates {
        return {
            latitude: null,
            longitude: null,
            altitude: null,
            altitudeAccuracy: null,
            heading: null,
            speed: null,
        };
    }

    getName(): string {
        return this.getData().name;
    }

    getNameOrPath(): string {
        return `${ICON_BASE_PATH}/local.svg`;
    }

    isGpsPosition(): boolean {
        return false;
    }

    getPlaceType(): keyof PlacesTypeInterface {
        return 'city';
    }

    getId(): string {
        return hash.sha1(this.getData());
    }
}
