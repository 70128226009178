import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TravysAcceptTosPageComponent } from './travys-accept-tos-page.component';
import { TravysFeatureTermsOfServiceModule } from '../../components/travys-feature-terms-of-service.module';
import { PageTitleModule } from '@traas/boldor/common/components/page-title/page-title.module';
import { BoldorFeatureShellSharedModule } from '@traas/boldor/common/boldor-feature-shell-shared.module';

const routes: Routes = [
    {
        path: '',
        component: TravysAcceptTosPageComponent,
    },
];

@NgModule({
    declarations: [TravysAcceptTosPageComponent],
    imports: [BoldorFeatureShellSharedModule, PageTitleModule, TravysFeatureTermsOfServiceModule, RouterModule.forChild(routes)],
})
export class TravysAcceptTosPageModule {}
