import { Injectable } from '@angular/core';
import { LoggingService } from '@traas/common/logging';
import { StopRequest } from '@traas/boldor/all-models';
import { Observable } from 'rxjs';
import { StopRequestService } from './stop-request.service';
import { ObservableTypedStorage } from '@traas/common/utils';

export interface StopRequestsStorage {
    stopRequests: StopRequest[];
}

const STOP_REQUEST_STORAGE_KEY = 'stopRequests';

@Injectable({ providedIn: 'root' })
export class StopRequestStorageService {
    constructor(
        private logger: LoggingService,
        private stopRequestService: StopRequestService,
        private stopRequestStorage: ObservableTypedStorage<StopRequestsStorage>,
    ) {}

    async addStopRequestToStorage(stopRequest: StopRequest): Promise<void> {
        const stored = await this.getStoredStopRequests();
        const updated = [...stored, stopRequest];
        await this.storeStopRequests(updated);
    }

    async replaceStopRequestById(pStopRequest: StopRequest): Promise<void> {
        const stored = await this.getStoredStopRequests();
        const foundIndex = stored.findIndex(({ id }) => id === pStopRequest.id);
        const updated = [...stored];
        if (foundIndex !== -1) {
            updated[foundIndex] = { ...pStopRequest };
        } else {
            console.warn(`Element to update was not found ${JSON.stringify(pStopRequest)}`);
        }
        await this.storeStopRequests(updated);
    }

    async getStoredStopRequests(): Promise<StopRequest[]> {
        return (await this.stopRequestStorage.getItem(STOP_REQUEST_STORAGE_KEY)) || [];
    }

    $getStoredStopRequests(): Observable<StopRequest[]> {
        return this.stopRequestStorage.$getItem(STOP_REQUEST_STORAGE_KEY, []);
    }

    async storeStopRequests(stopRequests: StopRequest[]): Promise<void> {
        // having zero order is a valid transaction list from middleware and in this case
        // local storage must reflect that it has been initialized
        await this.stopRequestStorage.setItem(STOP_REQUEST_STORAGE_KEY, stopRequests);
    }

    async clear(): Promise<void> {
        await this.stopRequestStorage.removeItem(STOP_REQUEST_STORAGE_KEY);
    }
}
