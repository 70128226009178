import { NgModule } from '@angular/core';
import { NavigationEnd, PreloadAllModules, Router, RouterModule, Routes, withComponentInputBinding } from '@angular/router';
import { SplashScreen } from '@capacitor/splash-screen';
import { Platform } from '@ionic/angular';
import { acceptTosGuardFactory } from '@traas/boldor/common/features/tos/guards/accept-tos.guard';
import { isAuthenticatedGuardFnFactory } from '@traas/boldor/common/guards/is-authenticated.guard';
import { isGuestGuardFnFactory } from '@traas/boldor/common/guards/is-guest-guard';
import { RouteUrl } from '@traas/common/routing';
import { canActivateAllFn, redirectToLoginOnAllReject } from '@traas/common/utils';
import { firstValueFrom, from } from 'rxjs';
import { delayWhen, filter, map } from 'rxjs/operators';
import { TabsPage } from '@traas/boldor/tabs';

const routes: Routes = [
    { path: '', redirectTo: RouteUrl.departureResultUrl, pathMatch: 'full' },
    {
        path: 'web-auth-success',
        loadChildren: () =>
            import('@traas/boldor/common/pages/web-auth-success/web-auth-success.module').then((module) => module.WebAuthSuccessModule),
    },
    {
        path: 'tabs',
        component: TabsPage,
        children: [
            {
                path: 'home',
                loadChildren: () => import('@traas/boldor/common/pages/home/home.module').then((module) => module.HomePageModule),
            },
            {
                path: 'bookings',
                canActivate: [redirectToLoginOnAllReject(isAuthenticatedGuardFnFactory)],
                children: [
                    {
                        path: '',
                        loadChildren: () =>
                            import('@traas/boldor/common/pages/booking/booking.module').then((module) => module.BookingPageModule),
                    },
                ],
            },
            {
                path: 'tickets',
                canActivate: [redirectToLoginOnAllReject(isGuestGuardFnFactory, isAuthenticatedGuardFnFactory)],
                children: [
                    {
                        path: '',
                        loadChildren: () => import('@traas/boldor/travys').then((module) => module.FeatureTicketModule),
                    },
                ],
            },
            {
                path: 'menu',
                children: [
                    {
                        path: '',
                        loadChildren: () => import('@traas/boldor/travys').then((module) => module.FeatureMenuModule),
                    },
                ],
            },
            {
                path: 'debug',
                children: [
                    {
                        path: '',
                        loadComponent: () =>
                            import('@traas/boldor/common/pages/debug-page/debug-page.component').then(
                                (module) => module.DebugPageComponent,
                            ),
                    },
                ],
            },
        ],
        canActivate: [canActivateAllFn(acceptTosGuardFactory)],
    },
    {
        path: 'accept-cgu',
        loadChildren: () => import('@traas/boldor/travys').then((module) => module.TravysAcceptTosPageModule),
    },
    {
        path: 'cart',
        loadChildren: () => import('@traas/boldor/common/pages/cart/cart-page.module').then((module) => module.CartPageModule),
        canActivate: [redirectToLoginOnAllReject(isGuestGuardFnFactory, isAuthenticatedGuardFnFactory)],
    },
    {
        path: 'select-frequent-travelers',
        loadChildren: () =>
            import('@traas/boldor/common/pages/frequent-travelers/select-frequent-travelers/select-frequent-travelers-page.module').then(
                (module) => module.SelectFrequentTravelersPageModule,
            ),
    },
    {
        path: 'update',
        loadChildren: () => import('@traas/boldor/common/pages/update/update.module').then((module) => module.UpdateModule),
    },
    {
        path: 'onboarding-preference',
        loadChildren: () =>
            import('@traas/boldor/common/pages/onboarding-preferences/onboarding-preferences.module').then(
                (module) => module.OnboardingPreferencesModule,
            ),
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, bindToComponentInputs: true })],
    exports: [RouterModule],
})
export class AppRoutingModule {
    constructor(
        private platform: Platform,
        private router: Router,
    ) {
        this.#initHideSplashScreen().then();
    }

    async #initHideSplashScreen(): Promise<void> {
        await this.#onFirstNavigationEnd();
        await SplashScreen.hide({ fadeOutDuration: 25 });
    }

    #onFirstNavigationEnd(): Promise<void> {
        return firstValueFrom(
            this.router.events.pipe(
                filter((event) => event instanceof NavigationEnd),
                delayWhen(() => from(this.platform.ready())),
                map(() => undefined),
            ),
        );
    }
}
