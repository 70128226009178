import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BookingModule } from '../../features/booking/booking.module';
import { CartStoreModule } from '../../features/cart/store/cart.module';
import { CreditCardsModule } from '../../features/credit-cards/credit-cards.module';
import { ItineraryModule } from '../../features/itinerary/itinerary.module';
import { PageTitleModule } from '../../components/page-title/page-title.module';
import { BoldorFeatureShellSharedModule } from '../../boldor-feature-shell-shared.module';
import { LinesIconHorizontalListComponent } from '../../components/lines-icon-horizontal-list/lines-icon-horizontal-list.component';
import { ClassSelectionComponent } from './order-page/configure-ticket/class-selection/class-selection.component';
import { PriceTypeChooserComponent } from './order-page/configure-ticket/price-type-chooser/price-type-chooser.component';
import { CompanyMarketingConsentPreferenceModule } from './payment-page/company-marketing-consent-preference/company-marketing-consent-preference.module';
import { ZonesCheckboxListComponent } from './zones-picker-page/zones-checkbox-list/zones-checkbox-list.component';
import { ZonesPickerPageComponent } from './zones-picker-page/zones-picker-page.component';
import { CartFooterComponent } from './components/cart-footer/cart-footer.component';
import { JourneyActionsPageComponent } from './journey-actions-page/journey-actions-page.component';
import { ConfigureTicketErrorsMessageComponent } from './order-page/configure-ticket-errors-message/configure-ticket-errors-message.component';
import { ConfigureTicketManuallyComponent } from './order-page/configure-ticket-manually/configure-ticket-manually.component';
import { ConfigureTicketPassengerListComponent } from './order-page/configure-ticket-passenger-list/configure-ticket-passenger-list.component';
import { ConfigureTicketSummaryComponent } from './order-page/configure-ticket-summary/configure-ticket-summary.component';
import { ConfigureTicketComponent } from './order-page/configure-ticket/configure-ticket.component';
import { DurationSelectionComponent } from './order-page/configure-ticket/duration-selection/duration-selection.component';
import { TravellerTypeChooserComponent } from './order-page/configure-ticket/traveller-type-chooser/traveller-type-chooser.component';
import { OrderPageComponent } from './order-page/order-page.component';
import { QuickTicketHeaderComponent } from './components/quick-ticket-header.component';
import { SaveJourneyOperationComponent } from './components/save-journey-operation/save-journey-operation.component';
import { PaymentPageComponent } from './payment-page/payment-page.component';

@NgModule({
    imports: [
        BookingModule,
        CartStoreModule,
        CreditCardsModule,
        ItineraryModule,
        CompanyMarketingConsentPreferenceModule,
        PageTitleModule,
        BoldorFeatureShellSharedModule,
        ReactiveFormsModule,
        FormsModule,
        LinesIconHorizontalListComponent,
        ZonesCheckboxListComponent,
    ],
    declarations: [
        CartFooterComponent,
        ConfigureTicketComponent,
        JourneyActionsPageComponent,
        OrderPageComponent,
        DurationSelectionComponent,
        ClassSelectionComponent,
        TravellerTypeChooserComponent,
        ConfigureTicketManuallyComponent,
        PaymentPageComponent,
        QuickTicketHeaderComponent,
        SaveJourneyOperationComponent,
        TravellerTypeChooserComponent,
        ConfigureTicketErrorsMessageComponent,
        ConfigureTicketPassengerListComponent,
        ConfigureTicketSummaryComponent,
        PriceTypeChooserComponent,
        ZonesPickerPageComponent,
    ],
    exports: [],
})
export class CartModule {}
