import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Departure, LeaveOrArriveEnum, MapMoveSource } from '@traas/boldor/all-models';
import { DepartureService } from '../../../features/departure/services/departure.service';
import { DepartureStoreState } from '../../../features/departure/store';
import { DepartureActionTypes, SetSelectedDeparture } from '../../../features/departure/store/departure.action';
import { HomeStoreState } from '../../../features/home/store';
import { EndpointActions, EndpointState } from '../../../features/home/store/endpoint';
import { EndpointActionTypes } from '../../../features/home/store/endpoint/endpoint.action';
import { MapActions } from '../../../features/home/store/map';
import { MapActionTypes } from '../../../features/home/store/map/map.action';
import { MapState } from '../../../features/home/store/map/map.state';
import { MapService } from '../../../features/map/services/map.service';
import { AndroidBackButtonLockService } from './android-back-button-lock.service';
import { RouteUrl, RoutingService } from '@traas/common/routing';
import { LatLng } from 'leaflet';
import * as _ from 'lodash';
import { defaultIfEmpty, firstValueFrom, Observable, Subject, timer } from 'rxjs';
import { filter, map, pairwise, take, takeUntil, tap } from 'rxjs/operators';
import { RouterState } from '../../../router-store/state';
import { selectUrl } from '../../../router-store/selectors';
import { LoggingService } from '@traas/common/logging';
import { isSuccess, Result } from '@traas/common/models';

@Injectable({ providedIn: 'root' })
export class AndroidBackButtonService {
    lastCenterPoint: any = {
        lat: 0,
        lng: 0,
    };

    #$waterFlow = new Subject<boolean>();
    #fromBackEvent = 0;
    #useReplaceState = 0;

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private mapService: MapService,
        private homeStateStore: Store<HomeStoreState.HomeState>,
        private departureStore: Store<DepartureStoreState.DepartureState>,
        private departureService: DepartureService,
        private routingService: RoutingService,
        private routerStore: Store<RouterState>,
        private $actions: Actions,
        private endpointStore: Store<EndpointState>,
        private mapStore: Store<MapState>,
        private androidBackButtonLockService: AndroidBackButtonLockService,
        private logger: LoggingService,
    ) {
        this.#$waterFlow.next(true);
        this.#mainListener();
    }

    #getRouterEvents(): Observable<any> {
        return this.router.events.pipe(
            filter((evt: any) => evt instanceof NavigationStart || evt instanceof NavigationEnd),
            pairwise(),
        );
    }

    #mainListener(): void {
        this.#getRouterEvents().subscribe(async (events: any) => {
            // pairwise() de getRouterEventslastPageIsDetail
            const previousEvent = events[0];
            const currentEvent = events[1];
            const navigationStart = this.#getNavigationStart(events);

            const params = await firstValueFrom(this.activatedRoute.queryParams);

            // navigation is done and was triggered from back (popstate)
            if (this.#isNavigationEnd(currentEvent) && navigationStart.navigationTrigger === 'popstate') {
                if (!params || !params['lat'] || !params['lng']) {
                    // This warning will be triggered when a guestCustomer is in state url.
                    console.warn('Missing lat lng from url', params);
                    this.androidBackButtonLockService.unlock('Missing lat lng from url');
                    return;
                }

                if (params['action'] === MapActionTypes.Moved) {
                    this.endpointStore.dispatch(new EndpointActions.Enable());
                    this.mapStore.dispatch(new MapActions.Enable());
                    await this.#reproduceMovedAction(params['lat'], params['lng'], params['z']);
                    this.androidBackButtonLockService.unlock('reproduceMovedAction done');
                }

                if (params['action'] === DepartureActionTypes.OpenDetails || currentEvent.url.includes(RouteUrl.departureDetailUrl)) {
                    this.endpointStore.dispatch(new EndpointActions.Enable());
                    this.mapStore.dispatch(new MapActions.Enable());
                    await this.#reproduceMovedAction(params['lat'], params['lng'], params['z']);

                    // Inhibit url from being changed by departure-details moveToDepartureOnMap
                    // and also for view departure.details to know that we are in backButtonMode
                    this.incrementFromBackButton();
                    this.incrementFromBackButton();

                    // Waiting for DepartureChanged triggered by flyTo from reproduceMovedAction
                    await firstValueFrom(this.waitForDepartureChanged());
                    await this.#reproduceOpenDetailsAction(params['departureId'], params['commercialStopId'], params['fromDate']);
                    this.androidBackButtonLockService.unlock('reproduceOpenDetailsAction done');
                }
            }
        });
    }

    async onDepartureChanged(): Promise<void> {
        const eventIsFromBackButton = this.getEventIsFromBackButton();
        const url = await firstValueFrom(this.routerStore.select(selectUrl));

        let useReplaceState = false;
        if (this.#useReplaceState > 0) {
            useReplaceState = true;
            this.decrementRS();
        }

        if (!eventIsFromBackButton && url.includes(RouteUrl.departureResultUrl)) {
            await this.updateUrlFromMap(MapActionTypes.Moved, useReplaceState);
        } else if (!eventIsFromBackButton && url.includes(RouteUrl.departureDetailUrl)) {
            await this.updateUrlFromMap(DepartureActionTypes.OpenDetails, true);
        } else {
            this.decrementFromBackButton();
        }
    }

    incrementReplaceState(): void {
        this.#useReplaceState++;
    }

    decrementRS(): void {
        if (this.#useReplaceState === 0) return;
        this.#useReplaceState--;
    }

    incrementFromBackButton(): void {
        this.#fromBackEvent++;
    }

    decrementFromBackButton(): void {
        if (this.#fromBackEvent === 0) return;
        this.#fromBackEvent--;
    }

    resetFromBackButton(): void {
        this.#fromBackEvent = 0;
    }

    getEventIsFromBackButton(): boolean {
        return this.#fromBackEvent > 0;
    }

    async #reproduceOpenDetailsAction(departureId, commercialStopId, fromDate): Promise<void> {
        const result = await this.#getDeparturesFromCommercialStopAndDate(commercialStopId, fromDate);
        if (!isSuccess(result)) {
            this.logger.logLocalError(result.error, { level: 'warning' });
            this.routingService.back();
            return;
        }
        const selectedDeparture = _.find(result.value, ['id', departureId]);
        if (!selectedDeparture) {
            this.routingService.back();
            return;
        }
        if (selectedDeparture) {
            this.departureStore.dispatch(new SetSelectedDeparture(selectedDeparture));
        }
    }

    async #getDeparturesFromCommercialStopAndDate(commercialStopId: string, fromDate: string): Promise<Result<Departure[], string>> {
        try {
            const departure = await firstValueFrom(
                this.departureService.$getDeparturesByCommercialStopsIds([commercialStopId], fromDate, 20, LeaveOrArriveEnum.LeaveAt),
            );
            return { success: true, value: departure };
        } catch (error) {
            return {
                success: false,
                error: 'Error while getting departures from commercial stop and date',
            };
        }
    }

    async #reproduceMovedAction(lat, lng, zoom): Promise<void> {
        return new Promise((resolve, reject) => {
            if (!lat || !lng) {
                console.warn('reproduceMovedAction has undefined lat or lng');
                resolve();
            }

            const latLng = new LatLng(lat, lng);
            const bounds = latLng.toBounds(0);

            this.homeStateStore.dispatch(
                new MapActions.Moved({
                    source: MapMoveSource.Other, // TODO this may not always be the case
                    bounds,
                    centerPoint: latLng,
                    zoomLevel: zoom,
                }),
            );

            // Removing overflow if there is some
            this.resetFromBackButton();

            // Will inhib url change triggered by dispatch Moved
            this.incrementFromBackButton();
            // Will inhib url change triggered by flyTo
            this.incrementFromBackButton();

            this.waitForDepartureChanged().subscribe(() => {
                this.mapService.flyTo(latLng, zoom);
                resolve();
            });
        });
    }

    #getNavigationStart(events: any): NavigationStart {
        return events.find((evt) => evt instanceof NavigationStart);
    }

    #isNavigationEnd(event): boolean {
        return event instanceof NavigationEnd;
    }

    waitForDepartureChanged(): Observable<boolean> {
        const TEN_SECONDS = 10000;
        const timer$ = timer(TEN_SECONDS).pipe(
            tap(() => {
                this.androidBackButtonLockService.unlock('waitForDepartureChanged');
            }),
        );

        return this.$actions.pipe(
            ofType(EndpointActionTypes.DepartureChanged),
            take(1),
            map(() => true),
            takeUntil(timer$),
            defaultIfEmpty(false),
        );
    }

    #isNewLocation(newCenterPoint: LatLng): boolean {
        return newCenterPoint.lat !== this.lastCenterPoint.lat || newCenterPoint.lng !== this.lastCenterPoint.lng;
    }

    async updateUrlFromMap(action: string, useReplaceState = false): Promise<void> {
        const safeMapAreaBoundsResult = this.mapService.getSafeMapAreaBounds();
        if (!isSuccess(safeMapAreaBoundsResult)) {
            this.logger.logError(safeMapAreaBoundsResult.error);
            return;
        }

        const centerPoint = safeMapAreaBoundsResult.value.getCenter();
        if (this.#isNewLocation(centerPoint)) {
            this.lastCenterPoint = centerPoint;
            const zoomLevel = this.mapService.getZoomLevel();

            if (!useReplaceState) {
                await this.routingService.updateUrlFromMap(centerPoint, zoomLevel, action);
            }

            // This navigate start a move on map careful
            if (useReplaceState) {
                // Because going from departure-details to departure-result navigate to a new url
                // and then move the map to a point, it creates 2 elements in the history stack
                // With this function we are overwriting the last history element instead of pushing a second one

                // Exemple departure
                // /home/departure-details?lat=46&lng=6
                // Click on somewhere lat=47 lng=7 -> navigating to departure-result
                // /home/departure-result?lat=46&lng=6      (first history push)
                // Here lat and lng are not synchronized with the map, the map changed so it triggers a url change for lat lng
                // home/departure-details?lat=47&lng=7     (second history push)
                // And here we go with 2 element in the navigation history instead of one
                await this.routingService.updateUrlAndReplaceHistory({
                    lat: centerPoint.lat,
                    lng: centerPoint.lng,
                    z: zoomLevel,
                    action,
                });
            }
        }
    }
}
