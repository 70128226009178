import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TrackHelper } from '@traas/common/utils';

@Component({
    selector: 'app-track-icon',
    templateUrl: './track-icon.component.html',
    styleUrls: ['./track-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrackIconComponent {
    @Input({ required: true }) track!: string | undefined;

    isDoubleDigitTrack(): boolean {
        return TrackHelper.isDoubleDigitTrack(this.track);
    }

    getTrackType(): string {
        return TrackHelper.getTrackType(this.track ?? '');
    }
}
