import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { RouterStateService } from '@traas/common/routing';

export const isGuestGuardFnFactory: () => CanActivateFn = (routerStateService = inject(RouterStateService)) => {
    return () => !!routerStateService.getGuestCustomer();
};

export const isGuestGuardFn: CanActivateFn = (route, state) => {
    const canActivate = isGuestGuardFnFactory();
    return canActivate(route, state);
};
