import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { PurchaseArticleService } from './purchase-article.service';
import { AlertService } from '../alert.service';
import { convertToError, LoggingService } from '@traas/common/logging';
import { Cart, GuestCustomer, OrderType, OrderViewModel, PaymentTransactionStatus } from '@traas/boldor/all-models';
import { CustomerProviderService } from '../customer/customer-provider.service';
import { PreferencesService } from '@traas/boldor/common/services/common/preferences/preferences.service';
import { PaymentStoreActions, PaymentStoreSelectors } from '../../../features/payment/store';
import { ArticleType, PaymentState, PaymentStatus, TicketStatus } from '../../../features/payment/store/payment.state';
import { isDepartureCart, isItineraryCart, isQuickTicketCart } from '../../../models/cart/cart.utils';
import { firstValueFrom } from 'rxjs';
import { CreditCardsSelectors } from '../../../features/credit-cards/store';
import { ErrorCodes, Failure, isSuccess, Result, TechnicalError } from '@traas/common/models';
import { CompanyService } from '@traas/boldor/company';
import { CheckoutError, OrderStatus, PaymentResult } from '@traas/boldor/graphql-generated/graphql';
import { AnalyticsService } from '@traas/common/analytics';
import { GET_ORDER_POLLING_TIMEOUT, GET_ORDER_STATUS_INTERVAL_MS } from '@traas/boldor/business-rules';
import { GenerateTicketSuccess } from '../../../features/payment/store/payment.action';
import { GqlToFrontOrderConverter } from '../../../models/order';
import { OrderService } from '../order/order.service';
import { getFormattedTotalPrice } from '@traas/boldor/all-helpers';
import { BookingStoreActions } from '../../../features/booking/store';
import { ProgressService } from '../../../pages/payment/progress.service';

const ORDER_ID_EMPTY_ERROR_MESSAGE = 'Order id is empty';

@Injectable({ providedIn: 'root' })
export class PaymentService {
    constructor(
        private store: Store,
        private purchaseArticleService: PurchaseArticleService,
        private alertService: AlertService,
        private logger: LoggingService,
        private customerProviderService: CustomerProviderService,
        private preferencesService: PreferencesService,
        private analyticsService: AnalyticsService,
        private orderService: OrderService,
        private paymentProgressionService: ProgressService,
    ) {}

    resetState(): void {
        this.store.dispatch(new PaymentStoreActions.ResetPaymentState());
    }

    isPaymentError(paymentStatus: PaymentStatus): boolean {
        switch (paymentStatus) {
            case PaymentStatus.TIMED_OUT:
            case PaymentStatus.BUY_FAIL:
            case PaymentStatus.FAIL:
            case PaymentStatus.ABORT:
                return true;
            default:
                return false;
        }
    }

    async processPurchase(cart: Cart, guestCustomer: GuestCustomer | undefined): Promise<OrderViewModel> {
        const isValidCustomer = await this.#validateCustomer(guestCustomer);
        if (!isValidCustomer) {
            throw new TechnicalError('Customer is not valid', ErrorCodes.Purchase.CustomerNotValid, undefined, {
                isGuest: !!guestCustomer,
            });
        }

        await this.#processBuying(cart, guestCustomer);

        try {
            await this.#processPayment();
        } catch (error) {
            const paymentState = await firstValueFrom(this.store.select(PaymentStoreSelectors.getPaymentState));
            // Annuler la commande en parallèle sans attendre
            if (paymentState.orderId) {
                void this.#cancelOrder(paymentState.orderId);
            }
            throw error;
        }

        try {
            await this.#validatePayment();
        } catch (error) {
            const paymentState = await firstValueFrom(this.store.select(PaymentStoreSelectors.getPaymentState));
            if (!paymentState.orderId) {
                throw error;
            }
            const orderStatus = await this.#cancelOrder(paymentState.orderId);
            if (orderStatus !== OrderStatus.Ready) {
                await this.#throwErrorOrderStatus(orderStatus, convertToError(error));
            }
        }

        try {
            return this.#finalizeOrder(guestCustomer);
        } catch (error) {
            this.store.dispatch(new PaymentStoreActions.RecoveryFailed());
            throw error;
        }
    }

    async #processBuying(cart: Cart, guestCustomer: GuestCustomer | undefined): Promise<void> {
        try {
            const creditCardState = await firstValueFrom(this.store.select(CreditCardsSelectors.getState));
            const articleType = this.#getArticleTypeOf(cart);
            this.store.dispatch(
                new PaymentStoreActions.BuyArticles({
                    articleType,
                    selectedCreditCard: creditCardState.selectedCreditCard,
                    createCreditCard: creditCardState.createCreditCard,
                }),
            );
            const orderId = await this.purchaseArticleService.buyArticles(cart);
            this.store.dispatch(new PaymentStoreActions.BuyArticlesSuccess({ orderId }));
        } catch (error: any) {
            this.store.dispatch(new PaymentStoreActions.BuyArticlesFail());
            await this.#showSmsAlertIfAvailable();
            throw new TechnicalError(
                'Error during subprocess "buy" of purchase',
                error.errorCode ?? ErrorCodes.Purchase.BuyArticles,
                convertToError(error),
                {
                    isGuest: !!guestCustomer,
                    articleType: this.#getArticleTypeOf(cart),
                    articles: cart.articleSelections?.map((article) => ({
                        title: article.article.title,
                        price: article.article.prices[0].amountInCents,
                        passengerType: article.passenger.type,
                        passengerId: article.passenger.id,
                    })),
                },
            );
        }
    }

    async #processPayment(): Promise<void> {
        try {
            const payResult = await this.#pay();
            if (!isSuccess(payResult)) {
                return;
            }
            await this.#payOnSaferpay(payResult.value.externalPaymentUrl);
        } catch (error: any) {
            throw new TechnicalError(
                'Error during subprocess "pay" of purchase',
                error?.errorCode ?? ErrorCodes.Purchase.Pay,
                convertToError(error),
            );
        }
    }

    async #pay(): Promise<Result<{ externalPaymentUrl?: string | null }, TechnicalError>> {
        this.store.dispatch(new PaymentStoreActions.ProcessPayment());
        let paymentState = await firstValueFrom(this.store.select(PaymentStoreSelectors.getPaymentState));
        const cardAliasId = CompanyService.isTPG() ? paymentState.selectedCreditCard?.id : paymentState.selectedCreditCard?.aliasId;
        const use3DSecure = await this.preferencesService.getSkip3DSecure();
        let paymentResult: PaymentResult;
        if (!paymentState.orderId) {
            throw new TechnicalError(ORDER_ID_EMPTY_ERROR_MESSAGE, ErrorCodes.Purchase.Pay, undefined, {
                ...this.#extractErrorContextFromPaymentState(paymentState),
            });
        }
        try {
            paymentResult = await this.purchaseArticleService.pay(
                paymentState.orderId,
                paymentState.createCreditCard,
                cardAliasId,
                use3DSecure,
                paymentState.paymentMethodName,
            );
        } catch (error) {
            /**
             * In this case, we don't want throw error to not cancel the global purchase process.
             *
             * Because if the error is due to device losing connection during waiting for pay call response,
             * we have to check order status because it can be in a valid state.
             */
            const result: Failure<TechnicalError> = {
                success: false,
                error: new TechnicalError('Error while calling pay', ErrorCodes.Purchase.Pay, convertToError(error), {
                    paymentResultError: undefined,
                    ...this.#extractErrorContextFromPaymentState(paymentState),
                }),
            };
            this.logger.logError(result.error);
            return result;
        }
        if (!paymentResult.done && paymentResult.error) {
            switch (paymentResult.error) {
                case CheckoutError.GenerationOfTicketFailed:
                    this.store.dispatch(new PaymentStoreActions.GenerateTicketFail());
                    break;
                case CheckoutError.GenerationOfPaymentFailed:
                    this.store.dispatch(new PaymentStoreActions.ProcessPaymentFail());
                    break;
            }
            paymentState = await firstValueFrom(this.store.select(PaymentStoreSelectors.getPaymentState));
            throw new TechnicalError('Pay call returned an error', ErrorCodes.Purchase.Pay, undefined, {
                paymentResultError: paymentResult.error,
                ...this.#extractErrorContextFromPaymentState(paymentState),
            });
        }
        return { success: true, value: { externalPaymentUrl: paymentResult.externalPaymentUrl } };
    }

    async #validatePayment(): Promise<void> {
        try {
            this.store.dispatch(new PaymentStoreActions.CheckTicketGeneration());
            const orderStatus = await this.#getOrderStatus();
            if (orderStatus !== OrderStatus.Ready) {
                const paymentState = await firstValueFrom(this.store.select(PaymentStoreSelectors.getPaymentState));
                throw new TechnicalError('Order is not ready', ErrorCodes.Purchase.GenerateTicket, undefined, {
                    ...this.#extractErrorContextFromPaymentState(paymentState),
                    orderStatus,
                });
            }
        } catch (error: any) {
            throw new TechnicalError(
                'Error during subprocess "finalizeOrder" of purchase',
                error?.errorCode ?? ErrorCodes.Purchase.FinalizeOrder,
                convertToError(error),
            );
        }
    }

    async #finalizeOrder(guestCustomer: GuestCustomer | undefined): Promise<OrderViewModel> {
        this.store.dispatch(new GenerateTicketSuccess());
        const order = await this.#getOrderViewModel();
        this.#reportAnalyticsEventPurchaseSuccess(order, !!guestCustomer);
        this.store.dispatch(new BookingStoreActions.AddOrder(order));
        return order;
    }

    async #cancelOrder(orderId: string): Promise<OrderStatus> {
        try {
            const cancelOrderResult = await this.purchaseArticleService.tryCancelOrder(orderId);
            if (cancelOrderResult.success) {
                this.store.dispatch(new PaymentStoreActions.CancelPaymentSuccess());
                return OrderStatus.Canceled;
            }
            let message = 'Fail to cancel order: ';
            switch (cancelOrderResult.orderStatus) {
                case OrderStatus.Error:
                    message += 'No more details.';
                    break;
                case OrderStatus.Ready:
                    return OrderStatus.Ready;
                case OrderStatus.Generating:
                    message += 'Order is still generating';
                    break;
                case OrderStatus.WaitingPaymentNotify:
                    message += 'Payment is still waiting for notify';
                    break;
                default:
                    message += 'Unknown error';
                    break;
            }
            this.logger.logError(
                new TechnicalError(message, ErrorCodes.Purchase.CancelOrder, undefined, {
                    orderId,
                    orderStatus: cancelOrderResult.orderStatus,
                }),
            );
            return cancelOrderResult.orderStatus ?? OrderStatus.Error;
        } catch (error: any) {
            this.logger.logError(
                new TechnicalError('Error during cancellation of order', ErrorCodes.Purchase.CancelOrder, convertToError(error), {
                    orderId,
                }),
            );
            return OrderStatus.Error;
        }
    }

    async #throwErrorOrderStatus(orderStatus: OrderStatus, error: Error | undefined): Promise<void> {
        const paymentState = await firstValueFrom(this.store.select(PaymentStoreSelectors.getPaymentState));
        switch (orderStatus) {
            case OrderStatus.Generating:
                this.store.dispatch(new PaymentStoreActions.RecoveryFailed());
                throw new TechnicalError('Ticket is still generating', ErrorCodes.Purchase.Generating, error, {
                    ...this.#extractErrorContextFromPaymentState(paymentState),
                    orderStatus,
                });

            case OrderStatus.Canceled:
                this.store.dispatch(new PaymentStoreActions.PaymentTimedOut());
                throw new TechnicalError('Order cancelled', ErrorCodes.Purchase.Canceled, error, {
                    ...this.#extractErrorContextFromPaymentState(paymentState),
                    orderStatus,
                });

            case OrderStatus.WaitingPaymentNotify:
                this.store.dispatch(new PaymentStoreActions.GenerateTicketFail());
                throw new TechnicalError('Payment is still waiting for notify', ErrorCodes.Purchase.WaitingSaferpayPayment, error, {
                    ...this.#extractErrorContextFromPaymentState(paymentState),
                    orderStatus,
                });

            case OrderStatus.Error:
            default:
                this.store.dispatch(new PaymentStoreActions.GenerateTicketFail());
                throw new TechnicalError('Unexpected order status error', ErrorCodes.Purchase.GenerateTicket, error, {
                    ...this.#extractErrorContextFromPaymentState(paymentState),
                    orderStatus,
                });
        }
    }

    async #validateCustomer(guestCustomer: GuestCustomer | undefined): Promise<boolean> {
        if (!guestCustomer) {
            return !!(await this.customerProviderService.getCustomer());
        }
        return true;
    }

    async #getOrderViewModel(): Promise<OrderViewModel> {
        let paymentState = await firstValueFrom(this.store.select(PaymentStoreSelectors.getPaymentState));
        if (!paymentState.orderId) {
            throw new Error(ORDER_ID_EMPTY_ERROR_MESSAGE);
        }
        const order = await this.orderService.getOrder(paymentState.orderId, paymentState.articleType);
        const isValidOrderType =
            GqlToFrontOrderConverter.isGqlItineraryOrder(order) ||
            GqlToFrontOrderConverter.isGqlQuickTicketsOrder(order) ||
            GqlToFrontOrderConverter.isGqlDepartureOrder(order);
        if (!isValidOrderType) {
            paymentState = await firstValueFrom(this.store.select(PaymentStoreSelectors.getPaymentState));
            throw new TechnicalError(
                `OrderType is invalid to complete the buying process.`,
                ErrorCodes.Purchase.GenerateTicket,
                undefined,
                {
                    ...this.#extractErrorContextFromPaymentState(paymentState),
                    orderId: paymentState.orderId,
                    orderType: (order as any)?.orderType,
                },
            );
        }
        return GqlToFrontOrderConverter.toOrderViewModel(order);
    }

    #getArticleTypeOf(cart: Cart): ArticleType {
        if (isDepartureCart(cart)) {
            return ArticleType.DEPARTURE;
        }
        if (isItineraryCart(cart)) {
            return ArticleType.ITINERARY;
        }
        if (isQuickTicketCart(cart)) {
            return ArticleType.QUICK;
        }
        throw Error('A Cart must be one of the following types: departure, itinerary, quickTicket.');
    }

    async #payOnSaferpay(externalPaymentUrl: string | undefined | null): Promise<void> {
        try {
            this.paymentProgressionService.pauseTimer();
            const paymentTransactionStatus = await this.purchaseArticleService.waitForSaferpayPayment(externalPaymentUrl);
            switch (paymentTransactionStatus) {
                case PaymentTransactionStatus.Succeed:
                    this.store.dispatch(new PaymentStoreActions.ProcessPaymentSuccess());
                    return;
                case PaymentTransactionStatus.Failed:
                    this.analyticsService.reportEvent('payment_failure');
                    this.store.dispatch(new PaymentStoreActions.ProcessPaymentFail());
                    throw new TechnicalError('Payment failed', ErrorCodes.Purchase.SaferpayPaymentFailed);
                case PaymentTransactionStatus.Aborted:
                default:
                    this.analyticsService.reportEvent('payment_cancel');
                    this.store.dispatch(new PaymentStoreActions.CancelPayment());
                    throw new TechnicalError('Payment aborted', ErrorCodes.Purchase.SaferpayPaymentAborted);
            }
        } finally {
            this.paymentProgressionService.startOrResumeTimer();
        }
    }

    async #getOrderStatus(): Promise<OrderStatus> {
        const paymentState = await firstValueFrom(this.store.select(PaymentStoreSelectors.getPaymentState));
        if (!paymentState.orderId) {
            throw new Error(ORDER_ID_EMPTY_ERROR_MESSAGE);
        }
        return await this.#pollingToGetOrderStatus(paymentState.orderId);
    }

    async #pollingToGetOrderStatus(orderId: string): Promise<OrderStatus> {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            let isPollingTimedOut = false;
            setTimeout(() => {
                isPollingTimedOut = true;
                reject(
                    new TechnicalError('Timeout while getting order status', ErrorCodes.Purchase.GetOrderStatus, undefined, {
                        orderId,
                    }),
                );
            }, GET_ORDER_POLLING_TIMEOUT);

            /**
             * Quoi qu'il arrive, le processus sera stoppé après le GET_ORDER_POLLING_TIMEOUT.
             */
            while (!isPollingTimedOut) {
                try {
                    const paymentState = await firstValueFrom(this.store.select(PaymentStoreSelectors.getPaymentState));
                    if (paymentState.isCanceledByUser) {
                        return resolve(OrderStatus.Canceled);
                    }

                    const orderStatus = await this.purchaseArticleService.getOrderStatus(orderId);
                    const DONE_ORDER_STATUSES = [OrderStatus.Error, OrderStatus.Canceled, OrderStatus.Ready];
                    const isOrderDone = DONE_ORDER_STATUSES.includes(orderStatus);

                    if (isOrderDone) {
                        return resolve(orderStatus);
                    }
                } catch (error) {
                    this.logger.logError(
                        new TechnicalError('Error while getting order status', ErrorCodes.Purchase.GetOrderStatus, convertToError(error), {
                            orderId,
                        }),
                    );
                }

                // Attente avant la prochaine itération
                await new Promise((r) => setTimeout(r, GET_ORDER_STATUS_INTERVAL_MS));
            }
        });
    }

    #extractErrorContextFromPaymentState(paymentState: PaymentState): any {
        return {
            articleType: ArticleType[paymentState.articleType],
            isCanceledByUser: paymentState.isCanceledByUser,
            orderId: paymentState.orderId,
            paymentMethodName: paymentState.paymentMethodName,
            paymentStatus: paymentState.paymentStatus,
            ticketStatus: TicketStatus[paymentState.ticketStatus],
        };
    }

    #reportAnalyticsEventPurchaseSuccess(order: OrderViewModel, isGuestCustomer: boolean): void {
        try {
            if (isGuestCustomer) {
                this.analyticsService.reportEvent('guest_ticket_success');
            }

            const totalPrice = getFormattedTotalPrice(order.tickets);
            const ticketTypes: { [key: string]: string } = {
                [OrderType.BuyDepartureTickets]: 'departure',
                [OrderType.BuyQuickTicket]: 'quick',
                [OrderType.BuyItineraryTickets]: 'ri',
            };
            const ticketType = ticketTypes[order.orderType];
            if (!ticketType) {
                throw new Error(`OrderType ${order.orderType} is not valid for buying process.`);
            }
            this.analyticsService.reportEvent('payment_success', {
                ticket_price: totalPrice.toString(),
                ticket_type: ticketType,
            });
        } catch (error) {
            this.logger.logError(
                new TechnicalError(
                    'Error while reporting analytics event payment success',
                    ErrorCodes.Technical.Analytics,
                    convertToError(error),
                    {
                        orderId: order.id,
                    },
                ),
            );
        }
    }

    async #showSmsAlertIfAvailable(): Promise<void> {
        const paymentState = await firstValueFrom(this.store.select(PaymentStoreSelectors.getPaymentState));
        const smsCode = paymentState.ticket?.article?.smsCode;
        if (paymentState.articleType === ArticleType.QUICK && smsCode) {
            await this.alertService.presentTicketBuyFailure(smsCode);
        }
    }
}
