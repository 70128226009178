<ion-item [detail]="detail" class="ion-no-padding" lines="none">
  <div class="ticket">
    <ion-row class="title">
      <ion-col> {{ 'my-journeys.ticket-summary.departure' | translate }}</ion-col>
      <ion-col>
        <app-lines-icon-horizontal-list [lines]="lines"></app-lines-icon-horizontal-list>
      </ion-col>
    </ion-row>
    <div class="ticket-container">
      <div class="ticket-infos">
        <app-itinerary-ticket-from-to
          [class.show-operations-icons]="showOperationsIcon"
          [customColPadding]="paddingCol"
          [defaultPaddingOnCol]="false"
          [departureStop]="departureStop"
          >
        </app-itinerary-ticket-from-to>

        <ion-row [class.show-operations-icons]="showOperationsIcon" class="departure-datetime">
          <ion-col size="10">
            <app-human-readable-date [departureDatetime]="departureDate"></app-human-readable-date>
          </ion-col>
        </ion-row>

        <div>
          @if (!showOperationsIcon && isRefundable) {
            <div class="ticket-icons-price">
              <ion-icon (click)="confirmRefundOrder($event)" class="ticket-delete" src="assets/icons/grid/delete.svg"></ion-icon>
              <div class="ticket-price">
                {{ totalPrice }}
              </div>
            </div>
          }
          @if (showOperationsIcon) {
            <div class="ticket-icons-price">
              <ion-icon class="operation-icon separation-right" src="assets/icons/tabs/monochrome/journey.svg"></ion-icon>
              @if (departureOrder.tickets.length > 0) {
                <ion-icon
                  class="operation-icon separation-right"
                  src="assets/icons/tabs/monochrome/tickets.svg"
                ></ion-icon>
              }
              @if (departureOrder.departure.hasStopRequest) {
                <ion-icon
                  class="operation-icon"
                  src="assets/icons/stop-on-request.svg"
                ></ion-icon>
              }
              <div class="ticket-price" [class.strikethrough]="areThirdPartyPaidTickets(departureOrder)">
                {{ totalPrice }}
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  </div>
</ion-item>
