import {
    Cart,
    CartOperations,
    CartOperationType,
    DepartureCart,
    ItineraryCart,
    JourneyData,
    NearestStop,
    QuickTicketCart,
    QuickTicketOrigin,
    Ticket,
    TicketViewModel,
} from '@traas/boldor/all-models';
import { FrontToGqlCartConverter } from './front-to-gql-cart-converter';
import { DepartureAdapter } from '../departure/departure';
import { ItineraryAdapter } from '../itinerary/itinerary';
import { BoldorLocalizationService } from '@traas/boldor/localization';
import { createCartOperation, createConditionsMessagesOfTransactionBy } from './cart.utils';
import { ICON_BASE_PATH } from '@traas/common/business-rules';

export class CartFactory {
    // COMMON CREATE

    // common for departure and itinerary
    static async #createCartCommonJourney(
        journey: DepartureAdapter | ItineraryAdapter,
        boldorLocalizationService: BoldorLocalizationService,
    ): Promise<Cart> {
        const operations = this.createCartOperationsFrom(journey.getData());
        const firstDeadlineMessage = await boldorLocalizationService.get('tpc.booking-requirements.deadline-message-first');
        const secondDeadlineMessage = await boldorLocalizationService.get('tpc.booking-requirements.deadline-message-second');
        const thirdDeadlineMessage = await boldorLocalizationService.get('tpc.booking-requirements.deadline-message-third');
        const conditionsMessages = createConditionsMessagesOfTransactionBy(
            journey,
            firstDeadlineMessage,
            secondDeadlineMessage,
            thirdDeadlineMessage,
        );
        const askToStopRequirementMessage = await boldorLocalizationService.get('tpc.request-stop.deadline');
        return {
            operations,
            conditionsMessages,
            currency: '',
            articleSelections: [],
            totalPrice: 0,
            askToStopRequirementMessage: `${askToStopRequirementMessage} ${journey.getBookingDeadlineDisplayText()}`,
            journeyViewModel: journey.getData(), // specific to journey
        };
    }

    // common for quick ticket with or without category id
    static #createCartCommonQuickTicket(ticket?: Ticket, nearestStop: NearestStop | null = null): QuickTicketCart {
        const operations = this.#createCartOperationsForSimpleTicket();
        return {
            operations,
            conditionsMessages: [],
            currency: '',
            articleSelections: [],
            totalPrice: 0,
            askToStopRequirementMessage: '',
            journeyViewModel: undefined,
            article: ticket?.article, // specific to quick article
            nearestStop, // specific to quick article
        };
    }

    // SPECIFIC CREATE

    static async createCartFromDeparture(
        departure: DepartureAdapter,
        boldorLocalizationService: BoldorLocalizationService,
    ): Promise<DepartureCart> {
        const departureCart = (await this.#createCartCommonJourney(departure, boldorLocalizationService)) as DepartureCart;
        departureCart.departure = FrontToGqlCartConverter.toDepartureCartInput(departure.getData());
        return departureCart;
    }

    static async createCartFromItinerary(
        itinerary: ItineraryAdapter,
        boldorLocalizationService: BoldorLocalizationService,
    ): Promise<ItineraryCart> {
        const itineraryCart = (await this.#createCartCommonJourney(itinerary, boldorLocalizationService)) as ItineraryCart;
        itineraryCart.itinerary = FrontToGqlCartConverter.toItineraryCartInput(itinerary.getData());
        return itineraryCart;
    }

    static createCartFromQuickTicket(ticket: TicketViewModel, nearestStop: NearestStop | null): QuickTicketCart {
        return this.#createCartCommonQuickTicket(ticket, nearestStop);
    }

    static createCartFromQuickTicketsByZones(ticket: TicketViewModel): QuickTicketCart {
        return this.#createCartCommonQuickTicket(ticket);
    }

    static createCartFromQuickTicketByOrigin(origin: QuickTicketOrigin): QuickTicketCart {
        const quickTicket = this.#createCartCommonQuickTicket();
        quickTicket.origin = origin;
        return quickTicket;
    }

    // CART OPERATIONS

    static createCartOperationsFrom(journey: JourneyData): CartOperations {
        return {
            buy: createCartOperation({
                available: true,
                type: CartOperationType.BUY,
                isChecked: true,
                iconName: `${ICON_BASE_PATH}/ticket-on.svg`,
            }),
            notify: createCartOperation({
                available: false,
                iconName: `${ICON_BASE_PATH}/notification.svg`,
                type: CartOperationType.NOTIFY,
                isChecked: false,
            }),
            stopRequest: createCartOperation({
                available: journey.hasBookingRequirements,
                type: CartOperationType.STOP,
                isChecked: journey.hasBookingRequirements,
                iconName: `${ICON_BASE_PATH}/stop-on-request.svg`,
            }),
            save: createCartOperation({
                available: true,
                type: CartOperationType.SAVE,
                isChecked: true,
                iconName: `${ICON_BASE_PATH}/tabs/monochrome/journey.svg`,
            }),
        };
    }

    static #createCartOperationsForSimpleTicket(): CartOperations {
        return {
            buy: createCartOperation({
                available: true,
                type: CartOperationType.BUY,
                isChecked: true,
                iconName: `${ICON_BASE_PATH}/ticket-on.svg`,
            }),
            notify: createCartOperation({
                available: false,
                iconName: `${ICON_BASE_PATH}/notification.svg`,
                type: CartOperationType.NOTIFY,
                isChecked: false,
            }),
            stopRequest: createCartOperation({
                available: false,
                type: CartOperationType.STOP,
                isChecked: false,
                iconName: `${ICON_BASE_PATH}/stop-on-request.svg`,
            }),
            save: createCartOperation({
                available: false,
                type: CartOperationType.SAVE,
                isChecked: false,
                iconName: `${ICON_BASE_PATH}/list.svg`,
            }),
        };
    }
}
