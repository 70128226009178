import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AnalyticsService } from '@traas/common/analytics';
import { Observable } from 'rxjs';
import { TravelType } from '@traas/boldor/graphql-generated/graphql';
import {
    ColorModeOptions,
    Currency,
    PreferencesStorage,
    SearchModeOptions,
    TimeDisplayMode,
    TravelClassType,
} from '@traas/boldor/all-models';
import { ObservableTypedStorage } from '@traas/common/utils';
import { MarketingConsentApiService } from './marketing-consent-api.service';
import { AuthenticationApi } from '@traas/common/feature-account';

/**
 * todo possible de transformer toutes les préférences en signal ? et les changer en temps réel quand l'utilisateur les changes.
 * Comme ça on pourrait les utilser dans faire de async await
 */
@Injectable()
export abstract class PreferencesService {
    authenticationApi = inject(AuthenticationApi);
    marketingConsentApiService = inject(MarketingConsentApiService);

    constructor(
        protected preferencesStorage: ObservableTypedStorage<PreferencesStorage>,
        protected analyticsService: AnalyticsService,
        protected store: Store<void>,
    ) {}

    abstract getDefaultPreferences(): PreferencesStorage;

    async getSearchModeOption(): Promise<SearchModeOptions> {
        return (await this.preferencesStorage.getItem('searchMode')) ?? this.getDefaultPreferences().searchMode;
    }

    async setSearchModeOption(searchMode: SearchModeOptions): Promise<void> {
        await this.preferencesStorage.setItem('searchMode', searchMode);
    }

    async getColorModeOption(): Promise<ColorModeOptions> {
        return (await this.preferencesStorage.getItem('colorMode')) ?? this.getDefaultPreferences().colorMode;
    }

    getColorModeOption$(): Observable<ColorModeOptions> {
        return this.preferencesStorage.$getItem('colorMode', this.getDefaultPreferences().colorMode);
    }

    $getTravelClass(): Observable<TravelClassType> {
        return this.preferencesStorage.$getItem('travelClass', this.getDefaultPreferences().travelClass);
    }

    async fetchNationalMarketingConsent(): Promise<undefined | null | boolean> {
        const isAuthenticated = await this.authenticationApi.isAuthenticated();
        if (!isAuthenticated) {
            return undefined;
        }

        const marketingConsents = await this.marketingConsentApiService.getMarketingConsents();
        return marketingConsents.national;
    }

    async setTravelClass(classFilter: TravelClassType): Promise<void> {
        await this.preferencesStorage.setItem('travelClass', classFilter);
    }

    async getTimeDisplayMode(): Promise<TimeDisplayMode> {
        return (await this.preferencesStorage.getItem('timeDisplayMode')) ?? this.getDefaultPreferences().timeDisplayMode;
    }

    $getTimeDisplayMode(): Observable<TimeDisplayMode> {
        return this.preferencesStorage.$getItem('timeDisplayMode', this.getDefaultPreferences().timeDisplayMode);
    }

    async setTimeDisplay(timeDisplay: TimeDisplayMode): Promise<void> {
        await this.preferencesStorage.setItem('timeDisplayMode', timeDisplay);
    }

    abstract showLanguagePicker(): boolean;

    abstract showMultipleCurrencies(): boolean;

    async saveNationalMarketingConsentToRemote(value: boolean): Promise<boolean> {
        return await this.marketingConsentApiService.updateNationalMarketingConsent(value);
    }

    async getCurrency(): Promise<Currency> {
        return (await this.preferencesStorage.getItem('currency')) ?? this.getDefaultPreferences().currency;
    }

    async setCurrency(newCurrency: Currency): Promise<void> {
        await this.preferencesStorage.setItem('currency', newCurrency);
        this.analyticsService.sendCurrency(newCurrency);
        this.saveCurrencyToRemote();
    }

    abstract showSkip3DSecurePreference(): Promise<boolean>;

    abstract showSkip3DSecureForCart(cartPriceInCts: number): Promise<boolean>;

    async getSkip3DSecure(): Promise<boolean | null> {
        return this.preferencesStorage.getItem('skip3DSecure');
    }

    async setSkip3DSecure(skip3DSecure: boolean): Promise<void> {
        await this.preferencesStorage.setItem('skip3DSecure', skip3DSecure);
        this.analyticsService.sendSkip3DSecure(String(skip3DSecure));
        this.saveSkip3DSecureSettingToRemote().then();
    }

    async initializeDefaultPreferences(): Promise<void> {
        const defaultPreferences = this.getDefaultPreferences();

        // we store all the default preferences in the storage
        await Promise.all([
            this.#initializePreference('searchMode', defaultPreferences.searchMode),
            this.#initializePreference('colorMode', defaultPreferences.colorMode),
            this.#initializePreference('timeDisplayMode', defaultPreferences.timeDisplayMode),
            this.#initializePreference('mapDisplayOption', defaultPreferences.mapDisplayOption),
            this.#initializePreference('currency', defaultPreferences.currency),
            this.#initializePreference('currencyChangedWhileLoggedOut', defaultPreferences.currencyChangedWhileLoggedOut),
            this.#initializePreference('travelClass', defaultPreferences.travelClass),
            this.#initializePreference('skip3DSecureChangedWhileLoggedOut', defaultPreferences.skip3DSecureChangedWhileLoggedOut),
        ]);

        // init user properties from preferences
        this.analyticsService.sendCurrency((await this.getCurrency()) ?? defaultPreferences.currency);
        this.analyticsService.sendTimeDisplayMode((await this.getTimeDisplayMode()) ?? defaultPreferences.timeDisplayMode);
    }

    abstract getTravelType(): TravelType | null;

    abstract fetchAndUpdateCurrency(): Promise<void>;

    abstract saveCurrencyToRemote(): Promise<void>;

    abstract fetchAndUpdateSkip3DSecureSetting(): Promise<void>;

    abstract saveSkip3DSecureSettingToRemote(): Promise<void>;

    abstract clearCompanySpecificPreferences(): Promise<void>;

    async #initializePreference<K extends keyof PreferencesStorage>(key: K, defaultValue: PreferencesStorage[K]): Promise<void> {
        const hasOption = await this.preferencesStorage.hasItem(key);
        if (!hasOption) {
            await this.preferencesStorage.setItem(key, defaultValue);
        }
    }
}
