import * as _ from 'lodash';
import { ValueIteratee } from 'lodash';

/*
Set the length of an array, add extra undefined value if needed.
 */
export function padEnd<T>(items: T[], length: number, fill: T): T[] {
    return items.concat(new Array(length).fill(fill)).slice(0, length);
}

export function mergeArrays<T>(overrideWith: T[] = [], baseArray: T[] = [], iteratee: ValueIteratee<T>): T[] {
    // IMPORTANT: https://lodash.com/docs/4.17.15#unionBy left array items will be kept if they are find in the second one by comparator
    return _.unionBy(overrideWith ?? [], baseArray ?? [], iteratee);
}
