import { inject, Injectable, ɵfindLocaleData, ɵLocaleDataIndex } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageCode } from '@traas/boldor/all-models';
import { environment } from '@traas/boldor/environments';
import { PlatformUtilsService } from '@traas/common/utils';
import * as moment from 'moment';
import { firstValueFrom, Observable } from 'rxjs';
import { enGB, frCH } from 'date-fns/locale';
import { Locale, setDefaultOptions } from 'date-fns';

enum LocaleID {
    SwissFrench = 'fr-CH',
    British = 'en-GB',
}

@Injectable({ providedIn: 'root' })
export class BoldorLocalizationService {
    #platform = inject(PlatformUtilsService);
    #translateService = inject(TranslateService);

    async init(): Promise<void> {
        const platformLanguageCode = await this.#platform.getLanguageCode();
        const appLanguageCode = environment.languages.find((l) => l === platformLanguageCode) ?? environment.languages[0];

        this.#initTranslateService(appLanguageCode);
        this.#initMoment(appLanguageCode);
        this.#initDatefns(appLanguageCode);

        this.assertAngularLocales();
    }

    async get(key: string, interpolateParams?: any): Promise<string> {
        return firstValueFrom(this.#translateService.get(key, interpolateParams));
    }

    get languageCode(): string {
        return this.#translateService.currentLang;
    }

    get localeId(): string {
        return this.#localeIdFromLanguageCode(this.languageCode as LanguageCode);
    }

    get dateSeparator(): string {
        return this.localeId === LocaleID.British ? '/' : '.';
    }

    $get(key: string | string[], interpolateParams?: any): Observable<string> {
        return this.#translateService.get(key, interpolateParams);
    }

    assertAngularLocales(): void {
        environment.languages
            .map((languageCode) => this.#localeIdFromLanguageCode(languageCode))
            .forEach((localeId) => {
                const registeredLocaleId = ɵfindLocaleData(localeId)?.[ɵLocaleDataIndex.LocaleId];
                if (registeredLocaleId !== localeId) {
                    throw new Error(`Registered locale is incorrect, expected ${localeId}, found ${registeredLocaleId}`);
                }
            });
    }

    mapLanguageCodeToLocale(languageCode: LanguageCode): Locale {
        switch (languageCode) {
            case LanguageCode.French:
                return frCH;
            case LanguageCode.English:
            default:
                return enGB;
        }
    }

    #localeIdFromLanguageCode(languageCode: LanguageCode): LocaleID {
        switch (languageCode) {
            case LanguageCode.English:
                return LocaleID.British;
            case LanguageCode.French:
                return LocaleID.SwissFrench;
            default:
                throw new Error(`Unknown language code: "${languageCode}"`);
        }
    }

    #initMoment(languageCode: LanguageCode): void {
        const locale = this.#localeIdFromLanguageCode(languageCode);
        moment.locale(locale);
    }

    #initTranslateService(languageCode: LanguageCode): void {
        const selected = languageCode;
        const other = environment.languages.filter((l) => l !== selected);
        const orderedLanguageCodes = [selected, ...other];

        this.#translateService.addLangs(orderedLanguageCodes);
        this.#translateService.setDefaultLang(selected);
        this.#translateService.use(selected);
    }

    #initDatefns(appLanguageCode: LanguageCode): void {
        setDefaultOptions({ locale: this.mapLanguageCodeToLocale(appLanguageCode) });
    }
}
