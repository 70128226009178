import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { BookingButtonModule } from './components/booking-button/booking-button.module';
import { BookingDeadlineMessageComponent } from './components/booking-deadline-message/booking-deadline-message.component';
import { CgvLinkModule } from './components/cgv-link/cgv.module';
import { CollapseArrowModule } from './components/collapse-arrow/collapse-arrow.module';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { DynamicIonIconModule } from './components/dynamic-ion-icon/dynamic-ion-icon.module';
import { EndpointModule } from './components/endpoint/endpoint.module';
import { GridModule } from './components/grid/grid.module';
import { IonCheckboxBoldModule } from './components/ion-checkbox-bold/ion-checkbox-bold.module';
import { JourneyStatusModule } from './components/journey-status/journey-status.module';
import { LegsModule } from './components/legs/legs.module';
import { LineIconModule } from './components/line-icon/line-icon.module';
// tslint:disable-next-line:max-line-length
import { MessageModule } from './components/message/message.module';
import { SkeletonItemModule } from './components/skeleton-item/skeleton-item.module';
import { SymbolSvgModule } from '../../../../common/components/src/lib/symbol-svg/symbol-svg.module';
import { TrackIconModule } from './components/track-icon/track-icon.module';
import { TransitStopItemModule } from './components/transit-stop-item/transit-stop-item.module';
import { VehicleIconModule } from '../../../../common/components/src/lib/vehicle-icon/vehicle-icon.module';
import { VersionNumberModule } from './components/version-number/version-number.module';
import { WarningIconModule } from './components/warning-icon/warning-icon.module';
import { WarningMessageModule } from './components/warning-message/warning-message.module';
import { WebDatetimePickerModalModule } from './components/datetime-picker-modal/datetime-picker-modal.module';
import { BackButtonModule } from './directives/back-button/back-button.module';
import { LeafletMapCustomEventsModule } from './directives/leaflet-map-custom-events/leaflet-map-custom-events.module';
import { MvplusDragModule } from './directives/mvplus-drag/mvplus-drag.module';
import { NoEmojiValidatorDirective } from './directives/no-emoji/no-emoji.validator';
import { ClickStopPropagationDirective } from './directives/stop-click-propagation/stop-click-propagation.directive';
import { ThrottleClickDirective } from './directives/throttle-click/throttle-click.directive';
import { SharedPipeModule } from './pipes/shared-pipe.module';
import { AnalyticsEventDirective } from './directives/analytics-event/analytics-event.directive';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { OfflinePageModule } from './components/offline-message/offline-page/offline-page.module';
import { UserScrollDirective } from './directives/user-scroll.directive';
import { DatePickerBehaviorBadgeComponent } from './components/date-picker-behavior-badge/date-picker-behavior-badge.component';
import { ScrollBehaviorBadgeComponent } from './components/scroll-behavior-badge/scroll-behavior-badge.component';
import { ScrollingModule as CdkScrollingModule } from '@angular/cdk/scrolling';
import { InfiniteScrollListComponent } from './components/infinite-scroll-list/infinite-scroll-list.component';
import { BackLinkComponent } from './components/back-link/back-link.component';
import { SwisspassLoginButtonComponent } from './components/swisspass/swisspass-login-button/swisspass-login-button.component';
import { AdditionalInformationComponent } from './components/additional-information/additional-information.component';

function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

/*
BoldorFeatureShellSharedModule should not have providers (services) nor should any of its imported or re-exported modules have providers. (services)
see: https://angular.io/guide/ngmodule-faq#what-kinds-of-modules-should-i-have-and-how-should-i-use-them
BoldorFeatureShellSharedModule is imported in the feature modules
 */

const MODULES: any[] = [
    BackButtonModule,
    BookingButtonModule,
    CgvLinkModule,
    CollapseArrowModule,
    CommonModule,
    DynamicIonIconModule,
    EndpointModule,
    FormsModule,
    GridModule,
    IonCheckboxBoldModule,
    IonicModule,
    JourneyStatusModule,
    LeafletMapCustomEventsModule,
    LegsModule,
    LineIconModule,
    MessageModule,
    OfflinePageModule,
    MvplusDragModule,
    SkeletonItemModule,
    SymbolSvgModule,
    TrackIconModule,
    VehicleIconModule,
    VersionNumberModule,
    WarningMessageModule,
    WebDatetimePickerModalModule,
    TransitStopItemModule,
    SharedPipeModule,
    WarningIconModule,
    TranslateModule.forChild({
        loader: {
            provide: TranslateLoader,
            useFactory: createTranslateLoader,
            deps: [HttpClient],
        },
    }),
    CdkScrollingModule,
];

@NgModule({
    imports: [MODULES],
    declarations: [
        ThrottleClickDirective,
        DatePickerComponent,
        ClickStopPropagationDirective,
        BookingDeadlineMessageComponent,
        NoEmojiValidatorDirective,
        AnalyticsEventDirective,
        DatePickerBehaviorBadgeComponent,
        UserScrollDirective,
        ScrollBehaviorBadgeComponent,
        InfiniteScrollListComponent,
        BackLinkComponent,
        SwisspassLoginButtonComponent,
        AdditionalInformationComponent,
    ],
    exports: [
        MODULES,
        DatePickerComponent,
        IonCheckboxBoldModule,
        CollapseArrowModule,
        ThrottleClickDirective,
        ClickStopPropagationDirective,
        BookingDeadlineMessageComponent,
        NoEmojiValidatorDirective,
        AnalyticsEventDirective,
        UserScrollDirective,
        DatePickerBehaviorBadgeComponent,
        ScrollBehaviorBadgeComponent,
        InfiniteScrollListComponent,
        BackLinkComponent,
        SwisspassLoginButtonComponent,
        AdditionalInformationComponent,
    ],
})
export class BoldorFeatureShellSharedModule {}
