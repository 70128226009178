<ion-tabs #mainTabs>
  <ion-tab-bar slot="bottom">
    <ion-tab-button #departureTab tab="home/departure/list" class="e2e-tab-button-departure">
      <ion-icon src="assets/icons/tabs/monochrome/schedule.svg"></ion-icon>
      <span>{{ 'tabs.departures' | translate }}</span>
    </ion-tab-button>

    <ion-tab-button #itineraryTab tab="home/itinerary/list" class="e2e-tab-button-itinerary">
      <ion-icon src="assets/icons/tabs/monochrome/itinerary.svg"></ion-icon>
      <span>{{ 'tabs.itineraries' | translate }}</span>
    </ion-tab-button>

    <ion-tab-button #ticketsTab tab="tickets">
      <ion-icon src="assets/icons/tabs/monochrome/tickets.svg" class="e2e-tab-button-tickets"></ion-icon>
      <span>{{ 'tabs.buy' | translate }}</span>
    </ion-tab-button>

    <ion-tab-button #bookingsTab tab="bookings">
      <ion-icon
        src="assets/icons/tabs/monochrome/journey.svg"
        class="e2e-tab-button-bookings"
        (click)="onBookingsTabClick()"
      ></ion-icon>
      <span>{{ 'tabs.journeys' | translate }}</span>
    </ion-tab-button>

    <ion-tab-button #menuTab tab="menu" class="e2e-tab-button-menu" (click)="onMenuTabClick()">
      <ion-icon src="assets/icons/tabs/monochrome/mainmenu.svg"></ion-icon>
      <span>{{ 'tabs.menu' | translate }}</span>
    </ion-tab-button>

    @if (isDebugMode) {
      <ion-tab-button #debugTab tab="debug" class="e2e-tab-button-settings">
        <ion-icon name="bug-outline"></ion-icon>
        <span>Debug</span>
      </ion-tab-button>
    }
  </ion-tab-bar>
</ion-tabs>
