<ion-list>
    <ion-item [disabled]="itemsAreDisabled()" (click)="emitSelectPlace(undefined)">
        <ion-icon slot="start" name="map-sharp" color="primary"></ion-icon>
        <ion-label>{{ 'search-place.map-search' | translate }}</ion-label>
    </ion-item>
    @for (place of places; track place.getId()) {
        <ion-item [disabled]="itemsAreDisabled()" (click)="selectPlace.emit(place)">
            <app-dynamic-ion-icon slot="start" [nameOrPath]="place.getNameOrPath()"></app-dynamic-ion-icon>
            <ion-label
                ><span class="e2e-place-name" [attr.e2e-place-type]="place.getNameOrPath()">{{ place.getName() }}</span></ion-label
            >
        </ion-item>
    }
</ion-list>
