<div class="version ion-padding">
    <div class="version-col" (click)="onClickVader()">
        <ion-icon src="assets/icons/menu/darth-vader.svg"></ion-icon>
    </div>
    <div class="version-col">
        <div>Middleware: {{ env.gatewayUrl }}</div>
        <div>Version: {{ env.version }} ({{ env.currentCommitHash }})</div>
        <div>Environment: {{ env.environment }}</div>
        <div>Device type: {{ deviceType }}</div>
    </div>
</div>
