import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IonContent, IonRefresher } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { OnlineService } from '@traas/common/utils';
import { StopRequestStorageService } from '../../services/common/stop-request/stop-request-storage.service';
import { OrderStorageService } from '../../services/common/order/order-storage.service';
import { GqlToFrontOrderConverter } from '../../models/order';
import { BookingState, BookingStoreActions, BookingStoreSelectors } from '../../features/booking/store';
import { map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TicketState } from '@traas/boldor/all-models';
import { Actions, ofType } from '@ngrx/effects';
import { BookingActionTypes } from '../../features/booking/store/booking.action';
import { AuthenticationApi } from '@traas/common/feature-account';
import { OrderService } from '../../services/common/order/order.service';
import { ToasterService } from '../../services/common/toaster/toaster.service';

@Component({
    selector: 'page-booking',
    templateUrl: 'booking.html',
    styleUrls: ['booking.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookingPage implements OnInit, OnDestroy {
    @ViewChild('ionContent') content: IonContent;
    @ViewChild('refresherRef') refresherRef: IonRefresher;

    readonly #$onDestroy = new Subject<void>();

    constructor(
        private bookingStore: Store<BookingState>,
        private onlineService: OnlineService,
        private stopRequestStorageService: StopRequestStorageService,
        private ticketOrdersStorageService: OrderStorageService,
        private $actions: Actions,
        private orderService: OrderService,
        private authenticationApi: AuthenticationApi,
        private toasterService: ToasterService,
    ) {}

    /**
     * This is the perfect place to fill the state with the storage data
     * https://ionicframework.com/docs/angular/lifecycle
     */
    async ngOnInit(): Promise<void> {
        const orders = await this.ticketOrdersStorageService.getStoredOrders();
        const stopRequests = await this.stopRequestStorageService.getStoredStopRequests();
        const stopRequestsOrders = stopRequests.map((sr) => GqlToFrontOrderConverter.fromStopRequestToOrderViewModel(sr));

        this.bookingStore.dispatch(new BookingStoreActions.SetOrders({ orders: [...orders, ...stopRequestsOrders] }));

        const $ordersLoadingIsDone = this.$actions.pipe(
            ofType(BookingActionTypes.LoadOrdersFail, BookingActionTypes.LoadOrdersSuccess),
            switchMap(() => this.bookingStore.select(BookingStoreSelectors.getAllOrders).pipe(take(1))),
            map((allOrders) =>
                allOrders
                    .filter((order) => {
                        return !order.isProcessing && order.tickets.length > 0 && order.tickets[0].state === TicketState.ConfirmSuccess;
                    })
                    .map(({ id }) => id),
            ),
            tap(() => this.#completeRefresher()),
            takeUntil(this.#$onDestroy),
        );
        $ordersLoadingIsDone.subscribe((ordersIds) => {
            if (ordersIds.length > 0) {
                this.orderService.setTicketsToSeen(ordersIds);
            }
        });
    }

    ngOnDestroy(): void {
        this.#$onDestroy.next();
        this.#$onDestroy.complete();
    }

    async ionViewWillEnter(): Promise<void> {
        await this.refreshOrders(false);
    }

    async refreshOrders(preventLoading: boolean): Promise<void> {
        const isOffline = await this.onlineService.isOffline();
        if (isOffline) {
            await this.toasterService.presentOfflineMessage();
            this.#completeRefresher();
            return;
        }

        const isAuthenticated = await this.authenticationApi.isAuthenticated();
        if (!isAuthenticated) {
            this.#completeRefresher();
            return;
        }

        this.bookingStore.dispatch(new BookingStoreActions.LoadOrders({ preventLoading }));
    }

    #completeRefresher(): void {
        void this.refresherRef?.complete();
    }
}
