import { Injectable } from '@angular/core';
import { NearestStop, TicketDuration, Traveler } from '@traas/boldor/all-models';
import { QuickArticleInputService } from './quick-article-input.service';
import { CustomerProviderService } from '../../../services/common/customer/customer-provider.service';
import { BoldorLocalizationService } from '@traas/boldor/localization';
import { GenerateQuickArticlesInput, PassengerType, QuickArticlesMutationVariables } from '@traas/boldor/graphql/generated/tpc/graphql';
import { createTravelerFromAuthCustomer } from '../../../models/traveler';

@Injectable({
    providedIn: 'root',
})
export class QuickArticleInputServiceTpc extends QuickArticleInputService<QuickArticlesMutationVariables> {
    constructor(
        private customerService: CustomerProviderService,
        private boldorLocalizationService: BoldorLocalizationService,
    ) {
        super();
    }

    async createQuickArticleInputVariables(
        nearestStop: NearestStop,
        traveler?: Traveler,
        durationsFilter: TicketDuration[] = [],
    ): Promise<QuickArticlesMutationVariables> {
        let passenger: Traveler;
        if (!traveler) {
            const connectedCustomer = await this.customerService.getCustomer();
            // false value is a fallback value for auth customer, because in the gateway we don't use this value
            // for auth customer in the traas impl.
            passenger = createTravelerFromAuthCustomer(connectedCustomer, false);
        } else {
            passenger = traveler;
        }
        const langId = this.boldorLocalizationService.languageCode;

        const generateQuickArticlesInput: GenerateQuickArticlesInput = {
            isAuthenticatedCustomer: passenger.isCustomer,
            departureStopDidok: nearestStop.stopDidok,
            travelTypes: durationsFilter.map((duration) => duration.value),
            language: {
                id: langId,
            },
            passengerType: passenger.type ?? PassengerType.Person,
            passenger: {
                title: passenger.title,
                birthDate: passenger.birthDate,
                firstName: passenger.firstName,
                lastName: passenger.lastName,
                hasHalfFare: passenger.hasHalfFare,
                id: passenger.id,
            },
        };
        return {
            generateQuickArticlesInput,
        };
    }
}
