import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'payment-status',
    templateUrl: './payment-status.component.html',
    styleUrls: ['./payment-status.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentStatusComponent {
    @Input({ required: true }) showError!: boolean;
    @Input({ required: true }) isRunningTooLong!: boolean;
    @Input({ required: true }) statusTitle!: string;
    @Input({ required: true }) statusDescription!: string;
    @Input({ required: true }) progressionPercent!: number;
    @Input({ required: true }) isOffline!: boolean;
}
