import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MenuToggleComponent } from './components/menu-toggle/menu-toggle.component';
import { BoldorFeatureShellSharedModule } from '../../boldor-feature-shell-shared.module';
import { DebugInfoDisplayComponent } from './containers/debug-info-display/debug-info-display.component';

@NgModule({
    declarations: [MenuToggleComponent, DebugInfoDisplayComponent],
    imports: [BoldorFeatureShellSharedModule, RouterModule],
    exports: [MenuToggleComponent, DebugInfoDisplayComponent],
})
export class MenuModule {}
