import { ChangeDetectionStrategy, Component, ElementRef, inject, Input, OnInit, ViewChild } from '@angular/core';
import { getStopIconDetailName, getThermoStopIconName } from '@traas/boldor/business-rules';
import { AlertService } from '../../../../services/common/alert.service';
import { CompanyService } from '@traas/boldor/company';
import { StopAdapter } from '../../../../models/itinerary/stop';
import { JourneyMessage } from '@traas/boldor/all-models';
import { JourneyMessageService } from '../../../../services/common/journey-message.service';

@Component({
    selector: 'app-itinerary-leg-stop-list-item',
    templateUrl: './itinerary-leg-stop-list-item.component.html',
    styleUrls: ['./itinerary-leg-stop-list-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItineraryLegStopListItemComponent implements OnInit {
    @ViewChild('thermometerStopIcon', { read: ElementRef }) thermometerStopIcon: ElementRef;
    @Input({ required: true }) isFirst!: boolean;
    @Input({ required: true }) isLast!: boolean;
    @Input({ required: true }) isTrain!: boolean;
    @Input({ required: true }) stop!: StopAdapter;

    arrivalDatetime: Date | null = null;
    departureDatetime: Date | null = null;
    hasDisruption = false;
    thermoStopIconPath = '';
    stopIconPathItinerary = '';
    track: string | undefined;
    cancellationMessage: JourneyMessage | null = null;
    readonly isTPC = CompanyService.isTPC();

    #alertService = inject(AlertService);
    #journeyMessageService = inject(JourneyMessageService);

    ngOnInit(): void {
        this.hasDisruption = this.#journeyMessageService.containsDisruptionMessagesInItineraryStop(this.stop.getData());
        this.departureDatetime = this.stop.getScheduledDepartureTime();
        this.arrivalDatetime = this.stop.getScheduledArrivalTime();
        this.track = this.stop.getTrack();
        const { hasBookingRequirements, isOptional } = this.stop.getData();
        this.thermoStopIconPath = getThermoStopIconName(hasBookingRequirements, isOptional, this.isTrain);
        this.stopIconPathItinerary = getStopIconDetailName(hasBookingRequirements, isOptional, this.isTrain, this.isFirst);
        this.cancellationMessage = this.#journeyMessageService.getFormattedCancellationMessagesOfItineraryStop(this.stop.getData());
    }

    async presentWarningMessage(event: Event): Promise<void> {
        event.stopPropagation();
        event.preventDefault();
        return this.#alertService.presentDisruptionMessagesModal(
            this.#journeyMessageService.getFormattedDisruptionMessagesOfItineraryStop(this.stop.getData()),
        );
    }
}
