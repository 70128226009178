import { ComponentFactory, ComponentFactoryResolver, ComponentRef, Injectable, Injector, Type } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ComponentFactoryService {
    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private injector: Injector,
    ) {}

    createComponent<T>(component: Type<T>): ComponentRef<T> {
        return this.#createComponentFactory(component).create(this.injector);
    }

    #createComponentFactory<T>(component: Type<T>): ComponentFactory<T> {
        return this.componentFactoryResolver.resolveComponentFactory<T>(component);
    }
}
