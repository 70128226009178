<ion-searchbar
    #searchbar
    (ionInput)="onTermChange($event)"
    (ionClear)="onClearTerm()"
    [placeholder]="'search-place.placeholder' | translate"
    show-clear-button="focus"
    show-cancel-button="always"
    cancelButtonText="{{ 'common.cancel' | translate }}"
    (ionCancel)="cancel.emit()"
>
</ion-searchbar>
