import { CreditCard } from '@traas/boldor/all-models';

export interface CreditCardsState {
    cards: CreditCard[] | undefined;
    selectedCreditCard: CreditCard | null;
    createCreditCard: boolean;
    skip3DSecure: boolean | null;
}

export const initialState: CreditCardsState = {
    cards: undefined,
    selectedCreditCard: null,
    createCreditCard: false,
    skip3DSecure: null,
};
