import { AbstractAdapter, Place, PlacesTypeInterface, Poi, TraasGeolocationCoordinates } from '@traas/boldor/all-models';
import * as hash from 'object-hash';
import { ICON_BASE_PATH } from '@traas/common/business-rules';

export class PoiAdapter extends AbstractAdapter<Poi> implements Place<Poi> {
    isFromHistory: boolean;

    constructor(data: Poi) {
        super(data);
    }

    createPoiPlacesHistory(): this {
        this.isFromHistory = true;
        this.getNameOrPath = (): string => 'time';
        return this;
    }

    transformToCoordinates(): TraasGeolocationCoordinates {
        return {
            latitude: this.getData().latLon[0],
            longitude: this.getData().latLon[1],
            altitude: null,
            altitudeAccuracy: null,
            heading: null,
            speed: null,
        };
    }

    getName(): string {
        return this.getData().name;
    }

    getNameOrPath(): string {
        return `${ICON_BASE_PATH}/poi.svg`;
    }

    isGpsPosition(): boolean {
        return false;
    }

    getPlaceType(): keyof PlacesTypeInterface {
        return 'poi';
    }

    getId(): string {
        return hash.sha1(this.getData());
    }

    getLatitude(): number {
        return this.getData().latLon[0];
    }

    getLongitude(): number {
        return this.getData().latLon[1];
    }
}
