import { Env } from '../env.model';
// Ne pas simplifier ces imports en ../ car ce fichier est fileReplacé dans un autre contexte où le ../ sera faux au build
import { SwissPassEnvEnum } from '@traas/boldor/environments/specific/SwissPassEnvEnum';
import { travysAppConfig } from '@traas/boldor/environments/specific/travys/environment.travys.base';

export const environment: Env = {
    ...travysAppConfig,
    appId: 'ch.travys.mobile.QUAL',
    appSchemeUrl: 'qual-travysmobile://boldor.ch/', // should match config.qual.yml [DEEPLINK]
    environment: 'qual',
    gatewayUrl: 'https://gateway.qual.traas.openit.ch',
    isDebugMode: false,
    logSentry: true,
    traceSentry: false,
    showEnvironmentInformations: true,
    analyticsTrackingEnabled: false,
    analyticsLoggingEnabled: false,
    analyticsUserIdHashSalt: '------------',
    mobileSwisspass: {
        // compilera si on enlève cette valeur, mais l'app plantera sur une version mobile
        clientId: 'travys_mobile_inte',
        deeplink: 'travysmobileinte', // IMPORTANT, when changing this deeplink, you must also change the trapeze configuration (config.*.yml)
        environment: SwissPassEnvEnum.Integration,
        provider: 'travys',
    },
  webSwisspassRedirectUri: 'toto'
};
