import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { RoutingService } from '@traas/common/routing';
import { OrderStorageService } from '../../../../services/common/order/order-storage.service';
import { SelectionHelper } from '@traas/boldor/all-helpers';
import { GqlToFrontOrderConverter } from '../../../../models/order';
import {
    DepartureOrderViewModel,
    ItineraryOrderViewModel,
    OrderViewModel,
    QuickTicketsOrderViewModel,
    Ticket,
} from '@traas/boldor/all-models';
import { combineLatest, Observable, Subject, timer } from 'rxjs';
import { filter, first, map, takeUntil, tap } from 'rxjs/operators';
import { BoldorLocalizationService } from '@traas/boldor/localization';
import { BookingState, BookingStoreSelectors } from '../../store';
import { RouterState } from '../../../../router-store/state';
import { RouterSelectors } from '../../../../router-store';
import { environment } from '@traas/boldor/environments';

const TICK_PERIOD_IN_MS = 30000;
const DEFAULT_INDEX = -1;

interface PastCurrentFutureCancel<T> {
    past: T;
    current: T;
    future: T;
    cancelled: T;
}

interface AccordionIndexConfig {
    current: number;
    past: number;
    future: number;
    cancelled: number;
}

const defaultAccordionConfig: AccordionIndexConfig = {
    current: 1,
    future: 2,
    past: 3,
    cancelled: 4,
};

@Component({
    selector: 'journey-list-container',
    templateUrl: './journey-list-container.component.html',
    styleUrls: ['./journey-list-container.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JourneyListContainerComponent implements OnDestroy, OnInit {
    $isLoading: Observable<boolean>;
    $orders: Observable<PastCurrentFutureCancel<OrderViewModel[]>>;
    $timeInterval: Observable<number>;
    accordionIndex: AccordionIndexConfig = defaultAccordionConfig;
    selectionHelper: SelectionHelper;
    ticket: Ticket;

    // i18nPlural maps
    cancelledTicketMapping: { [count: string]: string };
    currentTicketMapping: { [count: string]: string };
    futureTicketMapping: { [count: string]: string };
    pastTicketMapping: { [count: string]: string };
    readonly showCancelledJourneysInBookingList = environment.features.showCancelledJourneysInBookingList;

    readonly #$onDestroy = new Subject<void>();
    #expandedIndex: number;

    constructor(
        protected boldorLocalizationService: BoldorLocalizationService,
        protected bookingStore: Store<BookingState>,
        protected routerStore: Store<RouterState>,
        protected routingService: RoutingService,
        protected orderStorageService: OrderStorageService,
    ) {
        this.initCmp();
    }

    async ngOnInit(): Promise<void> {
        await this.#initCurrentTicketMapping();
        await this.#initFutureTicketMapping();
        await this.#initPassedTicketMapping();

        if (this.showCancelledJourneysInBookingList) {
            await this.#initCanceledTicketMapping();
        }
    }

    refreshAccordionExpandState(): void {
        this.bookingStore
            .select(BookingStoreSelectors.getAllOrders)
            .pipe(
                filter((orders) => orders.length > 0),
                tap((orders) => {
                    const currentOrders = this.orderStorageService.filterCurrentOrders(orders);
                    if (currentOrders.length > 0) {
                        this.selectionHelper.expandOnlyOne(this.accordionIndex.current);
                    }
                }),
                first(),
            )
            .subscribe();
    }

    ngOnDestroy(): void {
        this.#$onDestroy.next();
        this.#$onDestroy.complete();
    }

    collapseAll(): void {
        this.#expandedIndex = DEFAULT_INDEX;
    }

    isItinerary(orderViewModel: OrderViewModel): orderViewModel is ItineraryOrderViewModel {
        return GqlToFrontOrderConverter.isViewModelItineraryOrder(orderViewModel);
    }

    isQuick(orderViewModel: OrderViewModel): orderViewModel is QuickTicketsOrderViewModel {
        return GqlToFrontOrderConverter.isViewModelQuickTicketsOrder(orderViewModel);
    }

    isDeparture(orderViewModel: OrderViewModel): orderViewModel is DepartureOrderViewModel {
        return GqlToFrontOrderConverter.isViewModelDepartureOrder(orderViewModel);
    }

    async onOrderClicked(order: OrderViewModel): Promise<boolean> {
        return this.routingService.navigateToOrder(order);
    }

    trackById(index: number, order: OrderViewModel): string {
        return order.id;
    }

    protected initCmp(): void {
        this.$isLoading = this.bookingStore.select(BookingStoreSelectors.getLoading);
        // BDORAPP-596 Force emit a value every tick (second) to refresh the UI when a ticket is expired
        this.$timeInterval = timer(0, TICK_PERIOD_IN_MS);
        this.selectionHelper = new SelectionHelper();
        this.collapseAll();
        this.$orders = combineLatest([this.$timeInterval, this.bookingStore.select(BookingStoreSelectors.getAllOrders)]).pipe(
            map(([_, orders]) => ({
                past: this.orderStorageService.filterPastOrders(orders),
                current: this.orderStorageService.filterCurrentOrders(orders),
                future: this.orderStorageService.filterFutureOrders(orders),
                cancelled: this.orderStorageService.filterCancelledOrders(orders),
            })),
        );
        this.routerStore
            .select(RouterSelectors.getIsBookingPage)
            .pipe(
                filter((isBookingPage) => isBookingPage),
                takeUntil(this.#$onDestroy),
            )
            .subscribe(() => this.refreshAccordionExpandState());
    }

    #getText(key: string): Promise<string> {
        return this.boldorLocalizationService.get(key);
    }

    async #initCurrentTicketMapping(): Promise<void> {
        const noCurrentJourney = await this.#getText('my-journeys.no-current-journey');
        const oneCurrentJourney = await this.#getText('my-journeys.one-current-journey');
        const multipleCurrentJourney = await this.#getText('my-journeys.multiple-current-journeys');

        this.currentTicketMapping = {
            '=0': noCurrentJourney,
            '=1': oneCurrentJourney,
            other: multipleCurrentJourney,
        };
    }

    async #initFutureTicketMapping(): Promise<void> {
        const noFutureJourney = await this.#getText('my-journeys.no-future-journey');
        const oneFutureJourney = await this.#getText('my-journeys.one-future-journey');
        const multipleFutureJourney = await this.#getText('my-journeys.multiple-future-journeys');

        this.futureTicketMapping = {
            '=0': noFutureJourney,
            '=1': oneFutureJourney,
            other: multipleFutureJourney,
        };
    }

    async #initPassedTicketMapping(): Promise<void> {
        const noPassedJourney = await this.#getText('my-journeys.no-passed-journey');
        const onePassedJourney = await this.#getText('my-journeys.one-passed-journey');
        const multiplePassedJourney = await this.#getText('my-journeys.multiple-passed-journeys');

        this.pastTicketMapping = {
            '=0': noPassedJourney,
            '=1': onePassedJourney,
            other: multiplePassedJourney,
        };
    }

    async #initCanceledTicketMapping(): Promise<void> {
        const noCanceledJourney = await this.#getText('my-journeys.no-canceled-journey');
        const oneCanceledJourney = await this.#getText('my-journeys.one-canceled-journey');
        const multipleCanceledJourney = await this.#getText('my-journeys.multiple-canceled-journeys');

        this.cancelledTicketMapping = {
            '=0': noCanceledJourney,
            '=1': oneCanceledJourney,
            other: multipleCanceledJourney,
        };
    }
}
