import { Injectable } from '@angular/core';
import { SecureStorage } from '@aparajita/capacitor-secure-storage';

@Injectable()
export class StorageService {
    /**
     * Get the value associated with the given key.
     * @param key the key to identify this value
     * @returns Returns a promise with the value of the given key
     */
    async get(key: string): Promise<any> {
        return SecureStorage.get(key);
    }

    /**
     * Set the value for the given key.
     * @param key the key to identify this value
     * @param value the value for this key
     * @returns Returns a promise that resolves when the key and value are set
     */
    async set(key: string, value: any): Promise<any> {
        return SecureStorage.set(key, value);
    }

    /**
     * Remove any value associated with this key.
     * @param key the key to identify this value
     * @returns Returns a promise that resolves when the value is removed
     */
    async remove(key: string): Promise<any> {
        return SecureStorage.remove(key);
    }

    /**
     * Clear the entire key value store. WARNING: HOT!
     * @returns Returns a promise that resolves when the store is cleared
     */
    async clear(): Promise<void> {
        return SecureStorage.clear();
    }

    /**
     * @returns Returns a promise that resolves with the number of keys stored.
     */
    async length(): Promise<number> {
        const keys = await this.keys();
        return keys.length;
    }

    /**
     * @returns Returns a promise that resolves with the keys in the store.
     */
    async keys(): Promise<string[]> {
        return SecureStorage.keys();
    }
}
