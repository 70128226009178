import { Injectable } from '@angular/core';
import { StartupNotificationGQL } from '@traas/boldor/graphql-generated/graphql';
import { GqlToFrontMessageConverter } from '../converters/gql-to-front/gql-to-front-message-converter';
import { MessageStorage } from '../models/message-storage';
import { MessageViewModel } from '../models/message-view-model';
import { MESSAGES_ACKNOWLEDGED_LIMIT } from '@traas/boldor/business-rules';
import { ObservableTypedStorage } from '@traas/common/utils';
import { firstValueFrom } from 'rxjs';
import { ErrorCodes, Result, TechnicalError } from '@traas/common/models';
import { convertToError } from '@traas/common/logging';

const MESSAGE_STORAGE_KEY: keyof MessageStorage = 'acknowledgeMessagesId';

@Injectable({
    providedIn: 'root',
})
export class StartupNotificationService {
    constructor(
        private messageStorage: ObservableTypedStorage<MessageStorage>,
        private startupNotification: StartupNotificationGQL,
    ) {}

    async acknowledgeMessage(messageId: string): Promise<void> {
        let stored = await this.#getIdsOfAcknowledgeMessages();
        if (stored.length === MESSAGES_ACKNOWLEDGED_LIMIT) {
            stored = stored.slice(1);
        }
        stored.push(messageId);
        await this.messageStorage.setItem(MESSAGE_STORAGE_KEY, stored);
    }

    async getPendingMessages(): Promise<Result<MessageViewModel[], TechnicalError>> {
        try {
            const messages = await this.#fetchMessages();
            const idsOfAcknowledgeMessages = await this.#getIdsOfAcknowledgeMessages();
            const result = messages.filter((message) => {
                const isAcknowledged = idsOfAcknowledgeMessages.some((id) => message.id === id);
                return !isAcknowledged;
            });
            return { success: true, value: result };
        } catch (error) {
            return {
                success: false,
                error: new TechnicalError(
                    'Error while fetching startup notifications',
                    ErrorCodes.StartupNotification.Fetch,
                    convertToError(error),
                ),
            };
        }
    }

    async #fetchMessages(): Promise<MessageViewModel[]> {
        const result = await firstValueFrom(this.startupNotification.fetch());
        return result.data.startupNotifications.map(GqlToFrontMessageConverter.toViewModel);
    }

    async #getIdsOfAcknowledgeMessages(): Promise<string[]> {
        // TEMPORARY FIX : it seems that some users have "" in their default value of message storage keys
        const acknowledgedMessages = await this.messageStorage.getItem(MESSAGE_STORAGE_KEY);
        if (!acknowledgedMessages || !acknowledgedMessages.length) {
            await this.messageStorage.setItem(MESSAGE_STORAGE_KEY, []);
            return [];
        }
        return acknowledgedMessages;
    }
}
