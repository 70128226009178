<div class="cgu-header">
    <img class="header-logo" src="assets/logo/travys-logo.png" />
    <h2>Conditions générales d’utilisation - CGU Application «TRAVYS Mobile»</h2>
</div>
<p>
    <em> *Dans le présent texte, le masculin est utilisé comme genre neutre et désigne aussi bien les hommes que les femmes. </em>
</p>
<h4>
    <ol>
        <li>Préambule</li>
    </ol>
</h4>
<p>
    L’entreprise de transport public TRAVYS - Transports Vallée-de-Joux - Yverdon-les-Bains - Sainte-Croix S.A. (CH-550-1025688-5) avec
    siège social au Quai de la Thièle 32, case postale 387, 1401 Yverdon-les-Bains (ci-après TRAVYS) met à disposition de ses clients
    l’application TRAVYS Mobile, une application de transport pour téléphone mobile, qui permet de voyager avec un seul titre de transport
    entre plusieurs régions géographiques.
</p>
<p>Les présentes conditions d’utilisation s’appliquent à l’application TRAVYS Mobile qui offre notamment les fonctionnalités suivantes :</p>
<ul>
    <li><p>affichage des départs dans les environs&nbsp;;</p></li>
    <li><p>recherche d’itinéraires en transports publics&nbsp;;</p></li>
    <li><p>génération d’un ou de plusieurs billets électroniques sur smartphone.</p></li>
</ul>
<p>L’application est disponible gratuitement depuis les stores de différents OS : App Store pour iPhone, PlayStore pour Android.</p>
<p>
    Les personnes accédant aux informations mises à disposition par TRAVYS à travers l’application TRAVYS Mobile acceptent, avant l’action
    de téléchargement de l’application mobile, les conditions générales d’utilisation (CGU) suivantes:
</p>
<h4>
    <ol start="2">
        <li>Disponibilité</li>
    </ol>
</h4>
<p>
    TRAVYS s’efforce de proposer une application autant que possible sans interruption. Malgré tous les soins apportés, des temps
    d’interruption ou des microcoupures ne peuvent pas être exclus. TRAVYS n’assume aucune responsabilité ni garantie pour les cas
    d’interruptions ou autres perturbations.
</p>
<h4>
    <ol start="3">
        <li>Exclusion de la responsabilité</li>
    </ol>
</h4>
<p>
    TRAVYS n'assume aucune responsabilité pour les dommages directs, indirects ou consécutifs qui pourraient résulter de l'action de
    téléchargement de l’application ou de l’accès aux ressources et aux informations proposées.
</p>
<p>TRAVYS peut, ponctuellement et si nécessaire, mettre à disposition gratuitement des mises à jour de l’application.</p>
<p>
    L’utilisateur de l’application TRAVYS Mobile, est seul responsable de ses activités (de type financier ou autre) sur ladite application
    ; ceci vaut notamment pour l’utilisation prudente du nom d’utilisateur et du mot de passe. L’utilisateur garantit en outre que les
    informations fournies sur l’application concernant sa personne sont correctes.
</p>
<h4>
    <ol start="4">
        <li>Politique de confidentialité</li>
    </ol>
</h4>
<p>
    Le respect de la confidentialité est un pilier primordial pour TRAVYS en qualité d’entreprise de transport public ayant mis en place
    l’application TRAVYS Mobile. Lorsque vous utilisez cette application, vous nous confiez des informations que nous traitons avec soin,
    conformément aux dispositions légales en vigueur relatives à la protection des données et à la législation européenne et suisse. Nous
    nous engageons à respecter votre confiance. Nous travaillons en continu pour protéger vos données contre la fraude, les utilisations
    abusives et l’accès non autorisé, par un hébergement sécurisé.
</p>
<p>
    Dans la présente déclaration de confidentialité, nous vous informons sur nos méthodes de traitement. Nous vous expliquons, quelles
    données nous traitons lorsque vous accédez à notre présence numérique.
</p>
<p>
    Que vous résidiez ou non dans un pays de l’Espace économique européen, vous bénéficiez du même seuil de protection élevé pour vos
    données personnelles. Le standard de référence pour la protection de vos données est le Règlement européen sur la protection des données
    du 26 avril 2016 (RGPD).
</p>
<p>
    TRAVYS Mobile doit recevoir ou recueillir des informations pour exploiter, fournir, améliorer, comprendre, personnaliser, prendre en
    charge et commercialiser ses services. Les types d’informations que TRAVYS reçoit dépendent de la manière dont vous utilisez les
    services de TRAVYS Mobile.
</p>
<h6>A. Qui est maître du fichier des données ?</h6>
<p>
    TRAVYS est maître des données fournies par les personnes qui décident de télécharger l’application TRAVYS Mobile. A cet égard, TRAVYS
    constitue une base de données regroupant les clients qui auront téléchargé l’application et fait usage des services proposés. Un seuil
    de sécurité élevé est garanti par TRAVYS.
</p>
<p>
    Pour toute question, suggestion ou réclamation relative à la protection des données en lien avec ladite application, vous pouvez à tout
    moment contacter le préposé cantonal vaudois ou nos spécialistes : <a href="mailto:info@travys.ch">faq&#64;travys.ch</a>
</p>
<p>
    Les questions, les commentaires et les réclamations relatives aux données (traitement, finalité, action corrective, suppression) peuvent
    donc être envoyés à l’adresse électronique indiquée .
</p>
<h6>B. Pourquoi recueillons-nous des données personnelles ?</h6>
<p>
    Le traitement de données s’effectue dans des buts spécifiques pouvant résulter d’impératifs techniques, d’obligations contractuelles, de
    prescriptions légales ou d’un intérêt prépondérant, sur consentement explicite de votre part.
</p>
<p>
    Nous recueillons, stockons et traitons les données personnelles pour autant que cela soit nécessaire, notamment pour la gestion de la
    relation-clientèle, l’exécution des commandes et contrats, la vente et la facturation, les réponses aux questions et aux demandes ainsi
    que le soutien d’ordre technique. Le stockage permet également à l’utilisateur de recevoir des informations sur nos services et produits
    ainsi que leur fourniture, leur évaluation, leur commercialisation et leur développement.
</p>
<p>
    En tant qu’entreprise de transport public au bénéfice d’une concession fédérale, TRAVYS est autorisé à utiliser les données pour la
    garantie des recettes (contrôler la validité et encaissement des titres de transports ou prévenir les abus). Ces données peuvent être
    partagées avec des tiers à des fins de contrôle (nationaux ou internationaux) de validité des titres de transport.
</p>
<p>
    TRAVYS n’utilise vos données personnelles que dans le cadre de la réalisation des prestations et pour vous offrir une valeur ajoutée le
    long de la chaîne de mobilité (p. ex. offres sur mesure, informations, assistance ou dédommagement en cas de perturbation).
</p>
<p>
    Nous pouvons utiliser, dans les limites du cadre légal, les informations que nous recueillons pour répondre à des réclamations et à des
    litiges concernant votre utilisation des services proposés par TRAVYS Mobile, pour répondre à la demande d’organisations de branche,
    comme celles qui fixent les tarifs, ou dans le cadre d’enquêtes conduites par les autorités.
</p>
<p>
    Les données à indiquer lors de l’inscription doivent impérativement, et en tout temps, être complètes et correctes. TRAVYS peut en tout
    temps demander des informations supplémentaires pour des fonctions spécifiques et/ou procéder à des vérifications.
</p>
<p>
    TRAVYS est tenu par la loi d’assurer certaines prestations de transport avec d’autres entreprises et communautés tarifaires (« Service
    direct »). A cette fin, ces données sont transmises Service Direct National (SDN), qui regroupe plus de 250 entreprises et communautés
    de transports publics. Les données collectées lors de l’achat de prestations et les coordonnées pour prise de contact sont stockées dans
    une base de données centrale, gérée par les CFF sur mandat du SDN (« Base de données SD »). L’étendue de l’accès aux bases de données
    communes par les différentes entreprises de transport et communautés est définie et délimitée dans le cadre d’un accord validé par les
    autorités régulatrices. La transmission de données qu’implique le stockage centralisé et le traitement par les autres participants se
    limitent à l’exécution du contrat, au service après-vente et à la répartition des recettes.
</p>
<h6>C. Vos données ne sont pas vendues</h6>
<p>
    Vos données sont diffusées exclusivement auprès de tiers sélectionnés et mentionnés dans la présente déclaration de protection des
    données et ne le sont qu’aux fins citées. Si nous confions à des tiers le traitement des données, ceux-ci sont tenus de respecter nos
    exigences en matière de protection des données.
</p>
<h6>D. Quelles données collectons-nous ?</h6>
<p>
    Pour pouvoir utiliser pleinement les différents services proposés par l’application, vous serez préalablement amenés à nous fournir
    certaines données personnelles, en particulier lors de la création d’un compte client et de l’achat d’un titre de transport
    électronique.
</p>
<p>
    Nous ne traitons vos données que lorsqu’elles sont nécessaires à la fourniture des prestations proposées et uniquement lorsque vous nous
    les avez transmises de manière consentie par le biais d’un formulaire ou à la suite d’un acte concluant.
</p>
<p>
    Dans les paramètres de l’application ou de votre appareil, vous pouvez, par exemple, choisir quand partager votre position et gérer en
    tout temps les données que vous nous avez fournies via l’application. Il est également possible de supprimer votre compte.
</p>
<h6>E. Quelles données utilisons-nous ?</h6>
<p>Nous utilisons également des données collectées par enregistrement automatique aux fins suivantes.</p>
<ul>
    <li>
        <u>Informations que vous fournissez en rapport avec l’acquisition d’un titre de transport : SMS ou E-Ticket</u>. En fonction des
        produits ou services achetés, nous pouvons recueillir, entre autres, certaines parmi les données suivantes :
        <ul>
            <li>sexe, nom, prénom, adresse e-mail valable de l’acheteur ou du voyageur ;</li>
            <li>date de naissance</li>
            <li>adresse postale</li>
            <li>type de produit ou de service acheté (E-Ticket)</li>
            <li>lieu de départ et de destination</li>
            <li>date d’achat ou de voyage</li>
            <li>numéro de mobile</li>
            <li>numéro Swisspass.</li>
        </ul>
    </li>
    <br />
    <li>
        <u>Informations collectées automatiquement</u>
        <ul>
            <li>
                <u>Informations relatives à l’utilisation</u>. Pour le fonctionnement de l’application TRAVYS Mobile, nous recueillons les
                informations concernant votre activité de voyageur dans le cadre exclusif de l’application. Cela inclut vos paramètres et
                réglages dans l’application, ainsi que l’heure, la fréquence et la durée de vos voyages.
            </li>
            <li>
                <u>Informations de localisation</u>. Nous collectons des informations de localisation de l’appareil si vous utilisez les
                fonctionnalités de localisation. Celle-ci est déterminée grâce à l’IP, le GPS, les signaux Bluetooth et les informations
                concernant les points d’accès Wi-Fi, les balises et les tours de télécommunication à proximité. Nous ne stockons pas les
                informations de localisation, qui sont collectées afin de calculer votre position pour vous offrir un service de proximité,
                ainsi que pour déterminer votre trajet.
            </li>
            <li>
                <u>Géolocalisation</u>. L’application TRAVYS Mobile fonctionne parfaitement sans la fonction de géolocalisation. Nous ne
                stockons pas votre position GPS. Votre localisation est transmise à nos serveurs afin de vous proposer des départs et un
                itinéraire. Certains services de l’application nécessitent la connaissance de la géolocalisation.
            </li>
            <li>
                <u>Suivi de bugs</u>. L'application TRAVYS Mobile utilise les plugins <a href="https://sentry.io/">Sentry.io</a>. Sentry est
                exploité par Functional Software, Inc. dba Sentry, 132 Hawthorne Street, San Francisco, CA 94107. Lorsque vous utilisez
                l'application, une connexion aux serveurs de <a href="https://sentry.io/">sentry.io</a> est établie.
                <a href="https://sentry.io/">Sentry.io</a>
                fournit un suivi des erreurs (bugs) en temps réel pour les applications mobiles et web, permettant ainsi aux développeurs de
                disposer des informations dont ils ont besoin pour reproduire les pannes et les corriger. Nous recueillons des données qui
                nous permettent d'exploiter efficacement nos services et de vous offrir une expérience optimale lors de l'utilisation de
                l'application.
                <br />
                <br />
                Déclaration de protection des données de Functional Software, Inc. Dba Sentry:
                <a href="https://sentry.io/privacy/">https://sentry.io/privacy/</a> Certification des Boucliers de protection des
                renseignements personnels UE-États-Unis et Suisse-États-Unis:
            </li>
            <li>
                <a href="https://www.privacyshield.gov/participant?id=a2zt0000000TNDzAAO">
                    https://www.privacyshield.gov/participant?id=a2zt0000000TNDzAAO</a
                >
            </li>
        </ul>
    </li>
    <br />
    <li>
        <u>Informations relatives aux transactions</u>. Nous recueillons les détails des transactions financières associées à l’utilisation
        de nos services, à savoir le montant total de vos acquisitions aussi bien en matière de SMS que de billets électroniques. Pour
        l’achat d’un billet électronique, il vous faut simplement un appareil (tablette ; smartphone) sur lequel vous avez préalablement
        téléchargé l’application TRAVYS Mobile et une carte de crédit valable. De fait, nous avons besoin que vous nous fournissiez, outre
        votre nom et prénom (détenteur de la carte bancaire), les informations suivantes :
        <ul>
            <li>Le nom de la carte de crédit ou de débit ou de paiement</li>
            <li>La date d’expiration</li>
            <li>Le numéro de carte</li>
            <li>
                Le code de vérification de la carte ou CVC (Card Verification Code = code à trois (3) chiffres sur la bande de signature)
            </li>
        </ul>
    </li>
</ul>
<p>
    Dès qu’un billet électronique est consommé, l’application procède à un récapitulatif dans l’historique de votre compte client. Pour les
    transactions effectuées à l’aide d’une carte bancaire en vue de l’acquisition de billets électroniques, TRAVYS fait appel à SIX
    Saferpay. Ce dernier est un organisme mandataire servant d’intermédiaire entre TRAVYS et votre banque, qui permet que l’encaissement
    puisse avoir lieu en toute sécurité. A cet effet, vos données relatives aux transactions sont partagées avec SIX. En cas de transaction
    effectuée à l’aide d’une carte bancaire non suisse, d’éventuels frais ne dépendant pas de TRAVYS peuvent vous être imputés. Il en va de
    même pour le cas d’une transaction en devise Euro (EUR) et qui serait effectuée à l’aide d’une carte bancaire suisse. Ces frais, de même
    que leurs intérêts, relèvent strictement de l’organisme bancaire, émetteur de votre carte, et ne concernent nullement TRAVYS.
</p>
<ul>
    <li>
        <u>Notifications push en lien avec vos consommations</u>. Une fois l’application TRAVYS Mobile téléchargée, TRAVYS suit vos
        consommations d’E-Tickets, et les services Apple (Apple Push Notification Service pour iOS), respectivement Google (Google Cloud
        Messaging pour Android) peuvent acheminer des notifications en lien avec l’achat de titres de transports à TRAVYS.
    </li>
    <br />
    <li>
        <u>Informations partagées en cas de voyage sans titre de transport valable</u>. Sur une base légale entrée en vigueur en 2015, la
        branche des transports a mis en place un registre national destiné à la saisie des voyageurs sans titre de transport valable. En cas
        de constatation de voyage sans titre de transport, d’abus ou de falsification de titre de transport, TRAVYS doit fournir des données
        personnelles aux services internes ou aux entreprises de transport pour inscription dans le Registre national des resquilleurs.
        https://www.allianceswisspass.ch/fr/Qui-sommes-nous/Mandats-Alliance-SwissPass/Registre-national-des-resquilleurs. La base de
        données de ce Registre est exploitée par CarPostal sur mandat de la branche.
    </li>
    <br />
    <li>
        <u>Informations traitées à des fins de marketing</u>. Avec l’accord explicite du client uniquement, TRAVYS peut utiliser à des fins
        de marketing les données personnelles (p.ex. nom, sexe, date de naissance, adresse e-mail, numéro de client Swisspass). Nous
        analysons ces données afin de vous envoyer ou d’afficher les informations et les offres les plus susceptibles de vous intéresser
        (p.ex. par e-mail, SMS, notification push). Nous n’utilisons que les données pouvant être attribuées clairement au client, par
        exemple à la suite d’une identification par un login lors de l’achat un titre de transport à l’aide de TRAVYS Mobile.
    </li>
    <br />
    <li>
        <u>Informations traitées à des fins d’étude de marché et/ou de statistiques</u>. TRAVYS peut analyser les données fournies par le
        client au niveau interne et de manière anonyme, afin d’identifier les tendances, d’établir des statistiques et d’améliorer l’offre.
        A cet égard, TRAVYS peut être amené à utiliser Firebase ou Google Analytics, pour étudier des aspects qualitatifs et quantitatifs de
        son application afin de connaître le taux de fréquentation, le nombre d’achats de billets électroniques, les destinations les plus
        fréquentes, les erreurs techniques, etc.
    </li>
    <br />
    <li>
        <u>Conservation des données</u>. Nous conservons les données vous concernant uniquement le temps nécessaire et les traitons de
        manière confidentielle. Vous pouvez cependant décider en tout temps de ne plus recevoir d’informations de notre part. Votre adresse
        de courriel sera alors conservée pendant un an, à moins qu’une demande de suppression complète soit demandée. Font exception les
        données personnelles transmises à des autorités et organismes publics, ayant le droit d’obtenir ces informations en vertu de
        dispositions légales ou de décisions judiciaires. Vos droits : rectification, blocage, suppression / retrait du consentement
    </li>
</ul>
<p>
    À tout moment, vous êtes en droit d’exiger d’être informé sur le traitement de vos données personnelles et pouvez demander leur
    rectification, leur blocage ou leur suppression via le formulaire approprié
    <a href="http://www.travys.ch/telechargements"><u>[</u><u>http://www.travys.ch/</u><u>telechargements</u><u>]</u></a>
</p>
<p>
    et le transmettre au service client de TRAVYS, à l’adresse&nbsp;:
    <u><a href="mailto:faq@travys.ch.">faq&#64;travys.ch.</a></u>
</p>
<p>Vous pouvez en tout temps révoquer le consentement explicite accordé précédemment au traitement de vos données.</p>
<h6>F. Comment sécurisons-nous vos données ?</h6>
<p>
    Nos collaborateurs ainsi que les prestataires externes que nous mandatons sont tenus à des obligations de confidentialité et au respect
    des dispositions de la loi sur la protection des données. Nous prenons des mesures de précaution adaptées afin de protéger vos données.
</p>
<p>
    Toutefois, nous vous invitons à rester vigilants dans la transmission d’informations via Internet et d’autres moyens électroniques,
    sachant que nous ne pouvons garantir la sécurité des informations transmises de cette manière. Nous n’assumons aucune responsabilité
    pour tout accès non autorisé ou toute perte d’informations personnelles échappant à notre contrôle.
</p>
<h4>
    <ol start="5">
        <li>Utilisation</li>
    </ol>
</h4>
<p>L’application TRAVYS Mobile ne peut être utilisée que dans les buts suivants :</p>
<ul>
    <li>
        <p>vérification de l’horaire de passage des véhicules ;</p>
    </li>
    <li>
        <p>calcul du trajet pour le parcours souhaité ;</p>
    </li>
    <li>
        <p>achat de titres de transport à l’aide de SMS ou E-Ticket.</p>
    </li>
    <li>
        <p>consultation des plans et perturbations du réseau</p>
    </li>
</ul>
<p>Toute autre utilisation de cette application sans l’accord écrit de TRAVYS est interdite.</p>
<p>
    Par ailleurs, toutes les mesures pouvant interférer dans la fonction de l’application TRAVYS Mobile sont à prohiber. Cela concerne
    également les mesures qui pourraient avoir comme conséquence une charge intolérable ou excessive de l’infrastructure technique TRAVYS.
    TRAVYS se réserve le droit de bloquer à tout moment l’accès à l’application TRAVYS Mobile en cas de violation des CGU. Dans ce cas,
    toute prétention financière de la part de l’utilisateur est exclue.
</p>
<h4>
    <ol start="6">
        <li>Droit de propriété de l’application TRAVYS Mobile et droit à la marque</li>
    </ol>
</h4>
<p>Le code source de l’application TRAVYS Mobile appartient pour partie à</p>
<ul>
    <li>
        <p>
            Transports publics genevois - tpg (CHE-108.954.323) dont le siège social est sis à la route de la Chapelle 1, 1212 Grand-Lancy
            (Suisse),
        </p>
    </li>
    <li>
        <p>Transports Publics du Chablais - TPC (CHE-106.834.613) dont le siège social est sis au 38 Rue de la Gare, 1860 Aigle (Suisse)</p>
    </li>
    <li>
        <p>Pour partie conjointement à tpg et TPC</p>
    </li>
</ul>
<p>
    L'ensemble du contenu de l’application mobile TRAVYS Mobile est couvert par le droit d'auteur. TPC et les tpg en détiennent tous les
    droits : à ce titre, ils ont la qualité de coauteur au sens de la loi fédérale sur le droit d’auteur [LDA, RS 231.1].
</p>
<p>Les noms et les logos de chaque entreprise de transport affiliée à l’application mobile TRAVYS Mobile sont protégés.</p>
<p>
    Sans l’accord préalable écrit des coauteurs, l’ensemble des contenus de l’application ou les codes de logiciel sous-jacents ne peuvent
    être partiellement ou totalement dupliqués, édités, distribués, diffusés publiquement ou utilisés sous quelque forme que ce soit.
</p>
<p>
    Est également illicite l’utilisation de systèmes automatisés ou de logiciels automatisés afin d’extraire les contenus de l’application
    TRAVYS Mobile, de même que tout accès aux contenus de l’application effectué autrement que par le biais de l’interface utilisateur de
    cette application. Demeurent réservées les cas d’utilisation des données issues de l’application par des tiers avec l’accord des
    coauteurs (opendata).
</p>
<p>
    Si l’application est modifiée, tous les droits relatifs à l’application, y compris les informations visées par la modification,
    demeurent propriété des coauteurs.
</p>
<h4>
    <ol start="7">
        <li>Modifications</li>
    </ol>
</h4>
<p>
    TRAVYS se réserve le droit de modifier les CGU et la déclaration de confidentialité à tout moment, notamment en cas de modifications
    légales. Nous vous recommandons par conséquent de relire cette déclaration de confidentialité ainsi que les CGU à intervalles réguliers.
</p>
<p>
    Les seules CGU à valeur juridique faisant partie intégrante du contrat sont publiées sous forme électronique et peuvent être consultées
    sur notre application. La version papier des CGU n’a qu’une valeur informative si elle diffère à une date donnée de la version
    numérique.
</p>
<h4>
    <ol start="8">
        <li>Dispositions finales</li>
    </ol>
</h4>
<p>
    Si des dispositions des présentes CGU devaient être totalement ou partiellement nulles et/ou inapplicables, la validité et/ou
    l’application des autres dispositions ou une partie de celles-ci n’est (ne sont) pas touchée(s). Les dispositions inapplicables et/ou
    nulles seront alors remplacées par des dispositions juridiquement valides les plus proches du sens et de la raison économique des
    dispositions inapplicables et/ou nulles. Il en est de même en cas de lacunes dans la réglementation.
</p>
<h4>
    <ol start="9">
        <li>For et droit applicable</li>
    </ol>
</h4>
<p>
    Le for pour tout différend lié à l’application ou aux services proposés est au siège de TRAVYS, Yverdon-les-Bains. Le droit suisse est
    applicable.
</p>
<h4>
    <ol start="10">
        <li>Adresses de contact</li>
    </ol>
</h4>
<p>
    Pour toute question ou réclamation en lien avec l’application TRAVYS Mobile vous pouvez contacter le Service client de TRAVYS.
    <a href="http://www.travys.ch/service_clients">faq&#64;travys.ch.</a>
</p>
<p>
    Si vous pensez avoir reçu un message (ou un appel téléphonique) frauduleux au nom de TRAVYS et souhaitez en avoir le cœur net, alors
    n’hésitez pas à vous renseigner auprès du Service clients concerné.
</p>
<p class="align-right">TRAVYS - Transports Vallée-de-Joux - Yverdon-les-Bains - Sainte-Croix S.A., juin 2022</p>
