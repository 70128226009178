import { inject, Injectable } from '@angular/core';
import { PaymentMethodsConfiguration, RefreshIntervals, ThresholdConfiguration } from '@traas/boldor/all-models';
import {
    GetPaymentMethodsGQL,
    GetPaymentMethodsQueryVariables,
    GetRefreshIntervalsGQL,
    GetThresholdsGQL,
} from '@traas/boldor/graphql-generated/graphql';
import { CreditCardsService } from '../../../features/credit-cards/services/credit-cards.service';
import { ClientPlatformConverter } from '../converters/client-platform.converter';
import { ObservableTypedStorage, PlatformUtilsService } from '@traas/common/utils';
import { firstValueFrom } from 'rxjs';
import { environment } from '@traas/boldor/environments';
import { PreferencesService } from '../preferences/preferences.service';

@Injectable()
export class ConfigurationService {
    #getRefreshIntervalsGQL = inject(GetRefreshIntervalsGQL);
    #getThresholdsGQL = inject(GetThresholdsGQL);
    #getPaymentMethodsGQL = inject(GetPaymentMethodsGQL);
    #configurationStorage = inject(ObservableTypedStorage);
    #preferencesService = inject(PreferencesService);
    #platformUtilsService = inject(PlatformUtilsService);

    async assertConfiguration(): Promise<void> {
        await this.#initRefreshIntervals();
        await this.#initThresholds();
    }

    async getPaymentMethods(): Promise<PaymentMethodsConfiguration> {
        const currency = await this.#preferencesService.getCurrency();
        const variable: GetPaymentMethodsQueryVariables = {
            currency: CreditCardsService.convertCurrency(currency),
            clientPlatform: ClientPlatformConverter.toGql(this.#platformUtilsService.isIos()),
        };
        const { data } = await firstValueFrom(this.#getPaymentMethodsGQL.fetch(variable));
        const paymentMethods = data.configuration?.paymentMethods;
        if (!paymentMethods) {
            return {
                cards: [],
                mobiles: [],
            };
        }
        return paymentMethods;
    }

    shouldShowDateItemInJourneysList(): boolean {
        return environment.features.showDateItemInJourneysList ?? false;
    }

    async getThresholds(): Promise<ThresholdConfiguration> {
        return (await this.#configurationStorage.getItem('thresholds')) ?? this.#getDefaultThresholds();
    }

    async getRefreshIntervals(): Promise<RefreshIntervals> {
        return (await this.#configurationStorage.getItem('refreshIntervals')) ?? this.#getDefaultRefreshIntervals();
    }

    #getDefaultThresholds(): ThresholdConfiguration {
        return {
            minimumThresholdToShowIconInSeconds: environment.defaultThresholds.minimumThresholdToShowIconInSeconds,
            minutesThresholdToDisplayWaitingMinutes: environment.defaultThresholds.minutesThresholdToDisplayWaitingMinutes,
        };
    }

    #getDefaultRefreshIntervals(): RefreshIntervals {
        return {
            departureRefreshIntervalInSeconds: environment.defaultRefreshIntervals.departureRefreshIntervalInSeconds,
            itineraryRefreshIntervalInSeconds: environment.defaultRefreshIntervals.itineraryRefreshIntervalInSeconds,
            autoScrollRefreshIntervalInSeconds: environment.defaultRefreshIntervals.autoScrollRefreshIntervalInSeconds,
        };
    }

    async #initRefreshIntervals(): Promise<void> {
        let localRefreshIntervals = await this.#configurationStorage.getItem('refreshIntervals', undefined);

        if (!localRefreshIntervals) {
            localRefreshIntervals = this.#getDefaultRefreshIntervals();
            await this.#configurationStorage.setItem('refreshIntervals', localRefreshIntervals);
        }

        this.#fetchRefreshIntervals().then(async (refreshIntervals) => {
            await this.#configurationStorage.setItem('refreshIntervals', refreshIntervals);
        });
    }

    async #initThresholds(): Promise<void> {
        let localThresholds = await this.#configurationStorage.getItem('thresholds', undefined);

        if (!localThresholds) {
            localThresholds = this.#getDefaultThresholds();
            await this.#configurationStorage.setItem('thresholds', localThresholds);
        }

        this.#fetchThresholds().then(async (thresholds) => {
            await this.#configurationStorage.setItem('thresholds', thresholds);
        });
    }

    async #fetchRefreshIntervals(): Promise<RefreshIntervals> {
        const { data } = await firstValueFrom(this.#getRefreshIntervalsGQL.fetch());
        const refreshIntervals = data.configuration.refreshIntervals;
        if (!refreshIntervals) {
            return this.#getDefaultRefreshIntervals();
        }
        return refreshIntervals;
    }

    async #fetchThresholds(): Promise<ThresholdConfiguration> {
        const { data } = await firstValueFrom(this.#getThresholdsGQL.fetch());
        const thresholds = data.configuration.thresholds;
        if (!thresholds) {
            return this.#getDefaultThresholds();
        }
        return thresholds;
    }
}
