<ion-item [detail]="detail" class="ion-no-padding" lines="none">
  <div class="ticket">
    <ion-row class="ion-align-items-center title">
      {{ 'my-journeys.ticket-summary.quick-ticket' | translate }}
    </ion-row>
    <div class="ticket-container">
      <div class="ticket-infos">
        @if ($article() | async; as article) {
          <ion-row class="article">
            <ion-col size="12">
              {{ article.title }}
            </ion-col>
          </ion-row>
          @if (article.zones.length > 0) {
            <ion-row class="zone">
              <ion-col size="12">
                {{ getZonesTextFromZones(article.zones) }}
              </ion-col>
            </ion-row>
          }
          <ion-row class="departure-datetime">
            <ion-col size="12">
              <app-human-readable-date [departureDatetime]="article.validity.from"></app-human-readable-date>
            </ion-col>
          </ion-row>
        }
        @if (quickTicket.isProcessing) {
          <app-human-readable-date [departureDatetime]="quickTicket.tickets[0].purchaseDate"></app-human-readable-date>
        }

        @if (!this.showOperationsIcons && isRefundable) {
          <div class="ticket-icons-price">
            <ion-icon (click)="confirmRefundOrder($event)" class="ticket-delete" src="assets/icons/grid/delete.svg"></ion-icon>
            <div class="ticket-price">
              {{ totalPrice }}
            </div>
          </div>
        }
        @if (showOperationsIcons) {
          <div class="ticket-icons-price">
            <ion-icon class="operation-icon separation-right" src="assets/icons/tabs/monochrome/tickets.svg"></ion-icon>
            <div class="ticket-price" [class.strikethrough]="areThirdPartyPaidTickets(order)">
              {{ totalPrice }}
            </div>
          </div>
        }
      </div>
    </div>
  </div>
</ion-item>
