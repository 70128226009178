import { Injectable } from '@angular/core';
import { ChangePlaceEventSourceEnum, DynamicPlace, TraasGeolocationCoordinates } from '@traas/boldor/all-models';
import { LayerBuilderService } from '../../../features/map/services/layer-builder.service';
import { MapService } from '../../../features/map/services/map.service';
import { AddressAdapter } from '../../../features/place/adapters/address';
import { StopAdapter } from '../../../features/place/adapters/stop';
import * as LayersKey from '@traas/common/utils';
import { DebugLayerName } from '@traas/common/utils';
import { LatLng, LatLngBounds, Layer } from 'leaflet';
import { firstValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { PhysicalStopService } from '../physical-stop/physical-stop.service';
import { AddressDynamicPlaceAdapter } from './models/address-dynamic-place.adapter';
import { StopDynamicPlaceAdapter } from './models/stop-dynamic-place.adapter';
import { environment } from '@traas/boldor/environments';
import { ErrorCodes, Failure, isSuccess, Result, Success, TechnicalError } from '@traas/common/models';

@Injectable()
export class DynamicPlaceService {
    constructor(
        private mapService: MapService,
        private physicalStopService: PhysicalStopService,
        private layerBuilder: LayerBuilderService,
    ) {}

    createLayerFrom(place: DynamicPlace, coordinates: TraasGeolocationCoordinates): Layer {
        switch (place.getChangePlaceEvent()) {
            case ChangePlaceEventSourceEnum.StopSelection:
            case ChangePlaceEventSourceEnum.AddressSelection:
            case ChangePlaceEventSourceEnum.PoiSelection:
                return this.#createLayerFromStopDynamicPlace(coordinates, place);
            default:
                throw new Error(`Behavior unknown to the event ${ChangePlaceEventSourceEnum[place.getChangePlaceEvent()]}`);
        }
    }

    async createBoundsFrom(dynamicPlace: DynamicPlace): Promise<Result<LatLngBounds | null, TechnicalError>> {
        let bounds: LatLngBounds | null = null;
        switch (dynamicPlace.getChangePlaceEvent()) {
            case ChangePlaceEventSourceEnum.StopSelection:
                {
                    const boundsFromStopsAroundStopResult = await this.#fetchBoundsFromStopsAroundStopDynamicPlace(
                        dynamicPlace as StopDynamicPlaceAdapter,
                    );
                    if (!isSuccess(boundsFromStopsAroundStopResult)) {
                        return { success: false, error: boundsFromStopsAroundStopResult.error };
                    }
                    bounds = boundsFromStopsAroundStopResult.value;
                }
                break;
            case ChangePlaceEventSourceEnum.AddressSelection:
            case ChangePlaceEventSourceEnum.PoiSelection:
                {
                    const boundsFromStopsAroundAddressResult = await this.#fetchBoundsFromStopsAroundAddressDynamicPlace(
                        dynamicPlace as AddressDynamicPlaceAdapter,
                    );
                    if (!isSuccess(boundsFromStopsAroundAddressResult)) {
                        return { success: false, error: boundsFromStopsAroundAddressResult.error };
                    }
                    bounds = boundsFromStopsAroundAddressResult.value;
                }
                break;
            case ChangePlaceEventSourceEnum.MyGpsPositionSelection: {
                const gpsBoundsResult = await this.#createBoundsFromMyGpsPositionDynamicPlace();
                if (!isSuccess(gpsBoundsResult)) {
                    return { success: false, error: gpsBoundsResult.error };
                }
                bounds = gpsBoundsResult.value;
                break;
            }
            default:
                return {
                    success: false,
                    error: new TechnicalError(
                        `Unknown behavior to this event ${ChangePlaceEventSourceEnum[dynamicPlace.getChangePlaceEvent()]}`,
                        ErrorCodes.SearchPlace.PickPlace,
                    ),
                };
        }
        return { success: true, value: bounds };
    }

    createLatLngFromStopDynamicPlace(dynamicPlace: StopDynamicPlaceAdapter): LatLng {
        const stopPlace = dynamicPlace.getData() as StopAdapter;
        return new LatLng(stopPlace.getLatitude(), stopPlace.getLongitude());
    }

    async #fetchBoundsFromStopsAroundStopDynamicPlace(
        dynamicPlace: StopDynamicPlaceAdapter,
    ): Promise<Result<LatLngBounds | null, TechnicalError>> {
        const positionOfStop = this.createLatLngFromStopDynamicPlace(dynamicPlace);
        const stopsResult = await firstValueFrom(this.physicalStopService.$getPhysicalStopsByLatLng(positionOfStop));
        return this.mapService.createBoundsFromPhysicalStops(stopsResult);
    }

    async #createBoundsFromMyGpsPositionDynamicPlace(): Promise<Result<LatLngBounds, TechnicalError>> {
        const result = await this.mapService.fetchDataNearBoundsOfGpsMarker();
        if (!isSuccess(result)) {
            return { success: false, error: result.error };
        }
        return { success: true, value: result.value.bounds };
    }

    async #fetchBoundsFromStopsAroundAddressDynamicPlace(
        dynamicPlace: AddressDynamicPlaceAdapter,
    ): Promise<Result<LatLngBounds, TechnicalError>> {
        const addressPoint = this.#createLatLngFromAddressDynamicPlace(dynamicPlace);
        const $bounds = this.physicalStopService.$getPhysicalStopsByLatLng(addressPoint).pipe(
            map((stopsResult) => {
                if (isSuccess(stopsResult)) {
                    const circle = this.mapService.createCircleIncluding(
                        stopsResult.value.map((stop) => stop.getLatLng(environment.defaultPlace)),
                        addressPoint,
                    );
                    this.mapService.setLayer(LayersKey.CIRCLE_BOUNDS_CALCULATOR, circle);
                    this.mapService.addLayerToMap(LayersKey.CIRCLE_BOUNDS_CALCULATOR);
                    this.mapService.redrawDebugLayer(circle.getBounds(), DebugLayerName.GpsArea, circle.getRadius());
                    return { success: true, value: circle.getBounds() } as Success<LatLngBounds>;
                }
                return { success: false, error: stopsResult.error } as Failure<TechnicalError>;
            }),
        );
        return firstValueFrom($bounds);
    }

    #createLatLngFromAddressDynamicPlace(dynamicPlace: AddressDynamicPlaceAdapter): LatLng {
        const address = dynamicPlace.getData() as AddressAdapter;
        return new LatLng(address.getLatitude(), address.getLongitude());
    }

    #createLayerFromStopDynamicPlace(coordinates: TraasGeolocationCoordinates, place: DynamicPlace): Layer {
        if (!coordinates || coordinates.latitude === null || coordinates.longitude === null) {
            throw new Error('[createLayerFromStopDynamicPlace] coordinates should not be null.');
        }
        const marker = this.mapService.createMarker(new LatLng(coordinates.latitude, coordinates.longitude), place.getDivIcon());
        return this.layerBuilder.buildLayerFromMarkers([marker]);
    }
}
