import { AddressAdapter } from './address';
import { CityAdapter } from './city';
import { PoiAdapter } from './poi';
import { StopAdapter } from './stop';
import { AbstractAdapter, Place, SearchPlacesResponse } from '@traas/boldor/all-models';
import { BookmarkAdapter } from '../../../models/bookmark/bookmark-adapter.model';

export class SearchPlacesResponseAdapter extends AbstractAdapter<SearchPlacesResponse> {
    readonly #customPlaces: Place[];

    constructor(data: SearchPlacesResponse, customPlaces: Place[] = []) {
        super({ ...data });
        if (!this.data.bookmarks) {
            this.data.bookmarks = [];
        }
        this.#customPlaces = customPlaces;
    }

    getPois(): PoiAdapter[] {
        return this.getData().poi.map((poi) => new PoiAdapter(poi));
    }

    getCities(): CityAdapter[] {
        return this.getData().cities.map((city) => new CityAdapter(city));
    }

    getAddress(): AddressAdapter[] {
        return this.getData().address.map((address) => new AddressAdapter(address));
    }

    getStops(): StopAdapter[] {
        return this.getData().stops.map((stop) => new StopAdapter(stop));
    }

    getBookmarksWithPhysicalStopAdapters(): BookmarkAdapter[] {
        return this.getData().bookmarks.map((bookmark) => new BookmarkAdapter(bookmark));
    }

    addCustomPlaces(places: Place[]): void {
        for (const place of places) {
            this.#addCustomPlace(place, true);
        }
    }

    getCustomPlaces(): Place[] {
        return this.#customPlaces;
    }

    #addCustomPlace(place: Place, isUnique = false): void {
        if (isUnique) {
            if (!this.#customPlaces.some((customPlace) => customPlace.getId() === place.getId())) {
                this.#customPlaces.push(place);
            }
        } else {
            this.#customPlaces.push(place);
        }
    }
}

export function createEmptySearchPlacesResponse(): SearchPlacesResponse {
    return {
        address: [],
        cities: [],
        stops: [],
        poi: [],
        bookmarks: [],
    };
}
