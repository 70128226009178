import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { IonSearchbar, SearchbarCustomEvent } from '@ionic/angular';

@Component({
    selector: 'app-search-bar',
    templateUrl: './search-bar.component.html',
    styleUrls: ['./search-bar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchBarComponent implements AfterViewInit {
    @Output() clearTerm = new EventEmitter<void>();
    @Output() termChange = new EventEmitter<string>();
    @Output() cancel = new EventEmitter<void>();

    @ViewChild('searchbar') searchbar: IonSearchbar;

    ngAfterViewInit(): void {
        setTimeout(async () => {
            await this.searchbar.setFocus();
        }, 200);
    }

    onTermChange(event: SearchbarCustomEvent): void {
        const value = event.detail.value;
        if (value === null || value === undefined) {
            return;
        }
        this.termChange.emit(value);
    }

    onClearTerm(): void {
        this.clearTerm.emit();
    }

    protected readonly close = close;
}
