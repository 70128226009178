import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { LeaveOrArriveEnum, ResultMode, SearchModeOptions, TimeDisplayMode } from '@traas/boldor/all-models';
import { DatePickerSelectors } from '../../features/home/store/datepicker';
import { HomeState } from '../../features/home/store';
import { CompanyService } from '@traas/boldor/company';
import { DatepickerService } from '../../services/common/datepicker.service';
import { AndroidBackButtonLockService } from '../../services/common/home/android-back-button-lock.service';
import { merge, Observable } from 'rxjs';
import { debounceTime, map, shareReplay } from 'rxjs/operators';
import { DatePickerConfig } from '../date-picker/date-picker.component';
import { GridStoreSelectors } from '../grid/store';
import { Line } from '@traas/common/models';
import { BoldorFeatureShellSharedModule } from '../../boldor-feature-shell-shared.module';
import { TimeDisplayModeModalComponent } from './time-display-mode-modal.component';
import { LineFilterModalComponent } from './line-filter-modal.component';

export enum DatePickerBarSearchOption {
    DEPARTURE,
    ARRIVAL,
}

@Component({
    selector: 'app-date-picker-bar',
    templateUrl: './date-picker-bar.component.html',
    styleUrls: ['date-picker-bar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [BoldorFeatureShellSharedModule, TimeDisplayModeModalComponent, LineFilterModalComponent],
})
export class DatePickerBarComponent implements OnInit {
    protected readonly TimeDisplayMode = TimeDisplayMode;

    @Input() allowOldDates = false;
    @Input() isDetail = false;
    @Input() isFilterActive = false;
    @Input({ required: true }) mode!: ResultMode;
    @Input() queryKind = LeaveOrArriveEnum.LeaveAt;
    @Input() searchOption: DatePickerBarSearchOption | undefined;
    @Input() showFilter = false;

    @Output() clickOnFilter = new EventEmitter<void>();
    @Output() dateSelected = new EventEmitter<Date>();
    @Output() goBack = new EventEmitter<void>();
    @Output() LineFiltersChange = new EventEmitter<Line[]>();
    @Output() queryKindChange = new EventEmitter<LeaveOrArriveEnum>();
    @Output() searchOptionChange = new EventEmitter<DatePickerBarSearchOption>();

    readonly $isGridMode: Observable<boolean>;
    readonly $pickedDatetime: Observable<Date>;
    readonly isTPG = CompanyService.isTPG();

    datePickerConfig: DatePickerConfig;
    isDepartureMode: boolean;
    isItineraryMode: boolean;
    ResultMode = ResultMode;
    title: string;
    $datepickerMode = this.store.select(DatePickerSelectors.getDatepickerMode);
    $vibrateBackButton: Observable<boolean>;
    $isBackButonLocked: Observable<boolean>;

    constructor(
        private store: Store<HomeState>,
        private readonly androidBackButtonLockService: AndroidBackButtonLockService,
        private datepickerService: DatepickerService,
    ) {
        this.$isGridMode = this.#$buildIsGridMode();
        this.$pickedDatetime = this.datepickerService.$getAutoRefreshedDatepickerValue();
    }

    ngOnInit(): void {
        this.isDepartureMode = this.mode === ResultMode.DEPARTURE;
        this.isItineraryMode = this.mode === ResultMode.ITINERARY;
        this.datePickerConfig = {
            mode: this.mode,
            allowOldDates: this.allowOldDates,
            searchOption: this.searchOption,
            locked: this.isDetail,
        };

        switch (this.mode) {
            case ResultMode.DEPARTURE:
                this.title = 'DÉPARTS';
                break;
            case ResultMode.ITINERARY:
                this.title = 'ITINÉRAIRES';
                break;
        }

        this.$vibrateBackButton = merge(
            this.androidBackButtonLockService.$alreadyLocked.pipe(map(() => true)),
            this.androidBackButtonLockService.$alreadyLocked.pipe(
                debounceTime(300),
                map(() => false),
            ),
        );
        this.$isBackButonLocked = this.androidBackButtonLockService.$isLocked();
    }

    emitDateSelected(pickedDateTime: Date): void {
        this.dateSelected.emit(pickedDateTime);
    }

    emitQueryKindChange(queryKind: LeaveOrArriveEnum): void {
        this.queryKindChange.emit(queryKind);
    }

    emitSearchOptionChange(datePickerBarSearchOption: DatePickerBarSearchOption): void {
        this.searchOptionChange.emit(datePickerBarSearchOption);
    }

    onBackButtonClicked(): void {
        this.goBack.emit();
    }

    onFilterButtonClicked(): void {
        this.clickOnFilter.emit();
    }

    #$buildIsGridMode(): Observable<boolean> {
        return this.store.select(GridStoreSelectors.getSearchMode).pipe(
            map((searchMode) => searchMode === SearchModeOptions.GRID),
            shareReplay(1),
        );
    }
}
