import { NgModule } from '@angular/core';
import { BoldorFeatureShellSharedModule } from '../../../../boldor-feature-shell-shared.module';
import { CompanyMarketingConsentPreferenceComponent } from './company-marketing-consent-preference.component';

@NgModule({
    declarations: [CompanyMarketingConsentPreferenceComponent],
    imports: [BoldorFeatureShellSharedModule],
    exports: [CompanyMarketingConsentPreferenceComponent],
})
export class CompanyMarketingConsentPreferenceModule {}
