import { Injectable } from '@angular/core';
import { isApolloAuthenticationError } from '@traas/common/logging';
import { RoutingService } from '@traas/common/routing';
import { AuthenticationRequiredError, ErrorHandler } from '@traas/boldor/all-models';
import { AccountManagementService } from '@traas/boldor/common/services/common/customer/account-management.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationRequiredErrorHandler implements ErrorHandler {
    constructor(
        private routingService: RoutingService,
        private accountManagementService: AccountManagementService,
    ) {}

    async handle(error: Error): Promise<boolean> {
        if (error instanceof AuthenticationRequiredError) {
            await this.#handleError(error.message);
            return true;
        }
        if (isApolloAuthenticationError(error)) {
            await this.#handleError(error.message);
            return true;
        }

        return false;
    }

    async #handleError(message: string): Promise<void> {
        console.log(`Catching Authentication exception: '${message}'\nClearing user and redirecting to login`);
        await this.accountManagementService.forceLogout();
        await this.routingService.navigateToLogin(null);
    }
}
