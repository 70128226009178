export const FROM_TRANSIT_STOP_PARAM = 'from_transit_stop';
export const RETURN_URL_PARAM_NAME = 'returnUrl';
export const FROM_HEADER_LOGO = 'HEADER_LOGO';
export const GUEST_CUSTOMER_PARAM_NAME = 'guestCustomer';

export const enum RouteUrl {
    menuTabUrl = '/tabs/menu',
    // TABS
    ticketsTabUrl = '/tabs/tickets',
    bookingsTabUrl = '/tabs/bookings',

    // GUEST URL
    guestTicketsPageUrl = 'guest-tickets',

    // URL SIMULATED (Analytics)
    favouriteUrl = '/tabs/home/favorites',
    // TODO these departureFilterUrl, itineraryFilterUrl seems useless
    departureFilterUrl = '/tabs/home/departure-filter', // Only used by analytics
    itineraryFilterUrl = '/tabs/home/itinerary-filter', // Only used by analytics
    startupNotificationUrl = '/tabs/startup-notification',
    departureResultUrl = '/tabs/home/departure/list',
    itineraryResultUrl = '/tabs/home/itinerary/list',
    departureDetailUrl = '/tabs/home/departure/details',
    itineraryDetailUrl = '/tabs/home/itinerary/details',
    // URL FROM CART
    cartOrdersUrl = '/cart/orders',
    cartPaymentUrl = '/cart/payment',
    cartOperationChooser = '/cart/operation-chooser',
    cartZonesPicker = '/cart/zones-picker',
    cartError = '/cart/error',
    // URL FROM CART FOR FREQUENT TRAVELERS
    frequentTravelersUrlFromCart = '/select-frequent-travelers',

    loginUrl = '/tabs/menu/login',
    signAsGuestUrl = '/guest-form',
}
