import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { SelectedZone } from '@traas/boldor/all-models';
import { RadioGroupChangeEventDetail } from '@ionic/core';
import { BoldorFeatureShellSharedModule } from '../../../../boldor-feature-shell-shared.module';

@Component({
    selector: 'traas-zones-checkbox-list',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [BoldorFeatureShellSharedModule],
    template: `
        <ion-list>
            <ion-radio-group [value]="initialSelectedZoneValue?.places" (ionChange)="onZoneChange($event)">
                @for (zone of zones; track zone; let last = $last) {
                    <ion-item [lines]="last ? 'none' : ''">
                        <ion-radio [value]="zone.places"></ion-radio>
                        <ion-label [innerHTML]="formatZone(zone)"></ion-label>
                    </ion-item>
                }
            </ion-radio-group>
        </ion-list>
    `,
    styles: [
        `
            ion-radio {
                margin-right: 1rem;
            }
        `,
    ],
})
export class ZonesCheckboxListComponent {
    @Input() zones: SelectedZone[] = [];
    @Input() initialSelectedZoneValue: SelectedZone | null | undefined;

    @Output() selected = new EventEmitter<SelectedZone>();

    formatZone(selectedZone: SelectedZone): string {
        return `${selectedZone.zones.join(', ')}<br>(${selectedZone.places})`;
    }

    onZoneChange(event: Event): void {
        const customEvent = event as CustomEvent<RadioGroupChangeEventDetail<string>>;
        const selectedZonePlaces = customEvent.detail.value;
        const selectedZone = this.zones.find((z) => z.places === selectedZonePlaces);
        if (selectedZone) {
            this.selected.emit(selectedZone);
        }
    }
}
