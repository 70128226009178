import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { IonicModule } from '@ionic/angular';
import { ICON_BASE_PATH } from '@traas/common/business-rules';

@Component({
    selector: 'traas-cancelled-trip-icon',
    standalone: true,
    imports: [IonicModule],
    templateUrl: './cancelled-trip-icon.component.html',
    styleUrls: ['./cancelled-trip-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CancelledTripIconComponent {
    @Input() isBus = true;
    ICON_BASE_PATH = ICON_BASE_PATH;
}
