import { Injectable, NgZone } from '@angular/core';
import { ErrorPage } from '../../../../pages/error/error.page';
import { convertToError, LoggingService } from '@traas/common/logging';
import { first } from 'rxjs/operators';
import { ErrorHandler } from '@traas/boldor/all-models';
import { ModalService } from '../../modal.service';
import { FatalError } from '@traas/common/models';

@Injectable({ providedIn: 'root' })
export class FatalErrorHandler implements ErrorHandler {
    constructor(
        private modalService: ModalService,
        private zone: NgZone,
        private logger: LoggingService,
    ) {}

    async handle(originalError: unknown): Promise<boolean> {
        const innerError = convertToError(originalError) ?? new Error('unknown');
        const fatalError = originalError instanceof FatalError ? originalError : new FatalError(innerError);
        this.logger.logError(fatalError);

        this.zone.onStable.pipe(first()).subscribe(async () => {
            if (!this.modalService.isAlreadyShown('error')) {
                await this.zone.run(async () => {
                    await this.modalService.presentModal(
                        {
                            component: ErrorPage,
                        },
                        'error',
                    );
                });
            }
        });
        return true;
    }
}
