import { Directive, EventEmitter, HostListener, inject, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, startWith, take, throttleTime } from 'rxjs/operators';
import { ScrollDispatcher } from '@angular/cdk/scrolling';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Capacitor } from '@capacitor/core';

@Directive({
    selector: '[traasUserScroll]',
})
export class UserScrollDirective {
    @Output() userScrollStart = new EventEmitter<void>();
    @Output() userScrollEnd = new EventEmitter<void>();

    #isUserScrolling = false;
    #$wheel = new Subject<void>();
    readonly #scrollDispatcher = inject(ScrollDispatcher);

    constructor() {
        this.#listenToWheelScrollOnWeb();
    }

    @HostListener('wheel')
    private onWheel(): void {
        this.#$wheel.next();
    }

    @HostListener('touchstart')
    private touchStart(): void {
        if (!this.#isUserScrolling) {
            this.userScrollStart.emit();
            this.#isUserScrolling = true;
        }
    }

    /**
     * Touchend se déclenche lorsque le doigt est retiré de l'écran, pas forcément lorsque le scroll s'arrête
     * Surtout avec les effets de physique du scroll qui peuvent continuer après le touchend
     * @param event
     * @private
     */
    @HostListener('touchend', ['$event'])
    private touchEnd(event: TouchEvent): void {
        if (event.touches.length === 0) {
            this.#scrollDispatcher
                .scrolled()
                .pipe(
                    startWith(null),
                    debounceTime(100), // Attend 100ms après la dernière émission avant de passer à la suite
                    take(1),
                )
                .subscribe((e) => {
                    this.userScrollEnd.emit();
                    this.#isUserScrolling = false;
                });
        }
    }

    /**
     * It is a workaround to listen to wheel scroll on web
     * It is to simulate the touch event on web
     */
    #listenToWheelScrollOnWeb(): void {
        if (!Capacitor.isNativePlatform()) {
            // This is workaround for web mode
            this.#$wheel.pipe(throttleTime(2000), takeUntilDestroyed()).subscribe(() => {
                if (!this.#isUserScrolling) {
                    this.userScrollStart.emit();
                    this.#isUserScrolling = true;
                }
            });
            this.#$wheel.pipe(debounceTime(400), takeUntilDestroyed()).subscribe(() => {
                this.userScrollEnd.emit();
                this.#isUserScrolling = false;
            });
        }
    }
}
