import { Injectable } from '@angular/core';
import { Itinerary, TicketDuration, Traveler } from '@traas/boldor/all-models';
import {
    GenerateItineraryArticlesGQL,
    GenerateItineraryArticlesMutationVariables,
    GenerateSupersaverItineraryArticlesGQL,
    GenerateSupersaverItineraryArticlesMutation,
} from '@traas/boldor/graphql/generated/tpc/graphql';
import { BoldorLocalizationService } from '@traas/boldor/localization';
import { firstValueFrom } from 'rxjs';
import { ItineraryAdapter } from '../../../models/itinerary/itinerary';
import { FrontToGqlItineraryConverter } from '../converters/front-to-gql';
import { ItineraryArticleService } from './itinerary-article.service';
import { GqlItineraryArticle } from './itinerary.service';

export type GqlSupersaverItineraryArticle =
    GenerateSupersaverItineraryArticlesMutation['articlePublic']['generateItineraryArticles'][number];

@Injectable()
export class ItineraryArticleServiceTpc extends ItineraryArticleService {
    constructor(
        private generateItineraryArticlesGQL: GenerateItineraryArticlesGQL,
        private generateSupersaverItineraryArticlesGQL: GenerateSupersaverItineraryArticlesGQL,
        private boldorLocalizationService: BoldorLocalizationService,
    ) {
        super();
    }

    async generateItineraryTicketArticles(
        itinerary: Itinerary,
        passenger: Traveler,
        durationsFilter: TicketDuration[],
    ): Promise<GqlItineraryArticle[]> {
        const variables = await this.#createItineraryArticlesInput(itinerary, passenger, durationsFilter);
        const result = await firstValueFrom(this.generateItineraryArticlesGQL.mutate(variables));
        return result.data?.article.generateItineraryArticles ?? [];
    }

    async generateSupersaverItineraryArticles(
        itinerary: Itinerary,
        passenger: Traveler,
        durationsFilter: TicketDuration[],
    ): Promise<GqlSupersaverItineraryArticle[]> {
        const defaultValue: GqlSupersaverItineraryArticle[] = [];
        const variables = await this.#createItineraryArticlesInput(itinerary, passenger, durationsFilter);
        const result = await firstValueFrom(this.generateSupersaverItineraryArticlesGQL.mutate(variables));
        if (!result.data) {
            return defaultValue;
        }
        return result.data.articlePublic.generateItineraryArticles ?? defaultValue;
    }

    async #createItineraryArticlesInput(
        itinerary: Itinerary,
        passenger: Traveler,
        durationsFilter: TicketDuration[],
    ): Promise<GenerateItineraryArticlesMutationVariables> {
        const itineraryAdapter = new ItineraryAdapter(itinerary);
        const legs = itineraryAdapter.getLegsAdapters();
        const legsInput = FrontToGqlItineraryConverter.toItineraryLegInputCollection(legs);
        const languageId = this.boldorLocalizationService.languageCode;

        const generateItineraryArticlesInput: GenerateItineraryArticlesMutationVariables['generateItineraryArticlesInput'] = {
            legs: legsInput,
            language: { id: languageId },
            travelTypes: durationsFilter.map(({ value }) => value),
            isAuthenticatedCustomer: passenger.isCustomer,
            passengerType: passenger.type,
            passenger: {
                title: passenger.title,
                birthDate: passenger.birthDate,
                hasHalfFare: passenger.hasHalfFare,
                firstName: passenger.firstName,
                lastName: passenger.lastName,
                id: passenger.id,
            },
        };
        return {
            generateItineraryArticlesInput,
        };
    }
}
