import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'app-itinerary-ticket-stop-request',
    templateUrl: './itinerary-ticket-stop-request.component.html',
    styleUrls: ['./itinerary-ticket-stop-request.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItineraryTicketStopRequestComponent {
    @Output() cancelStopRequest = new EventEmitter<void>();

    onCancelClicked(): void {
        this.cancelStopRequest.emit();
    }
}
