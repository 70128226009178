<ion-header>
  <ion-toolbar>
    <ion-title>{{ 'search-place.modal.title' | translate }}</ion-title>
  </ion-toolbar>
  <ion-toolbar>
    @if ($isOnline | async) {
      <app-search-bar
        (clearTerm)="onClearTerm()"
        (termChange)="onTermChange($event)"
        (cancel)="cancel()"
      ></app-search-bar>
    }
  </ion-toolbar>
</ion-header>
<ion-content>
  @if ($isOnline | async) {
    <ion-row class="loader">
      <ion-col size="12" class="ion-text-center">
        @if ($isLoading | async) {
          <ion-spinner
            class="ion-justify-content-center e2e-network-search-spinner"
          ></ion-spinner>
        } @else {
          <span class="e2e-loading-finished"></span>
        }
      </ion-col>
    </ion-row>
    @if ($searchPlacesResponse | async; as placesResponse) {
      <app-places-list
        (selectPlace)="onSelectPlace($event)"
        [searchPlacesResponse]="placesResponse"
        [term]="($term | async)!"
      ></app-places-list>
    }
  } @else {
    <div class="offline-message">
      <app-offline-page message="{{ 'common.offline-message' | translate }}"></app-offline-page>
    </div>
  }

  <ng-template #offline>
    <div class="offline-message">
      <app-offline-page message="{{ 'common.offline-message' | translate }}"></app-offline-page>
    </div>
  </ng-template>
</ion-content>
