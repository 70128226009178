import { Injectable } from '@angular/core';
import { LegAdapter } from '../../models/itinerary/leg';
import { MAX_LINES_ICON_LIST_LIMIT, THRESHOLD_IN_MINUTES_TO_SHOW_FIRST_WALKING_LEG } from '@traas/boldor/business-rules';
import { ONE_MINUTE_IN_MS, ONE_MINUTE_IN_SECONDS, ONE_SECOND_IN_MS } from '@traas/common/utils';

export const BY_FOOT_ICON_OFFSET = 2;
export const IS_CANCELLED_ICON_OFFSET = 1;
export const HAS_DISRUPTION_ICON_OFFSET = 1;
export const HAS_ITINERARY_STATUS_ICON_OFFSET = 1;

@Injectable({
    providedIn: 'root',
})
export class LegsDisplayRulesService {
    shouldDisplayFirstLegAsWalking(legs: LegAdapter[]): boolean {
        if (legs.length === 0) return false;

        const firstLeg = legs[0];
        if (!firstLeg.isByFoot()) return false;

        const walkingLegDurationMs = this.#durationStringToMilliseconds(firstLeg.getWalkDuration());
        const thresholdMs = THRESHOLD_IN_MINUTES_TO_SHOW_FIRST_WALKING_LEG * ONE_MINUTE_IN_MS;
        return walkingLegDurationMs >= thresholdMs;
    }

    areLegArraysEqual(legsA: LegAdapter[], legsB: LegAdapter[]): boolean {
        const stringify = (legs: LegAdapter[]): string =>
            legs.map((leg) => leg.getData().serviceNumber ?? leg.getData().serviceId ?? 'empty').join('-');
        return stringify(legsA) === stringify(legsB);
    }

    exceedsLineDisplayLimit(
        linesLength: number,
        isStartByFoot: boolean,
        isCancelled: boolean,
        hasDisruption: boolean,
        hasItineraryStatus: boolean,
    ): boolean {
        let numberOfLines = isStartByFoot ? linesLength + BY_FOOT_ICON_OFFSET : linesLength;
        if (isCancelled) numberOfLines += IS_CANCELLED_ICON_OFFSET;
        if (hasDisruption) numberOfLines += HAS_DISRUPTION_ICON_OFFSET;
        if (hasItineraryStatus) numberOfLines += HAS_ITINERARY_STATUS_ICON_OFFSET;
        return numberOfLines > MAX_LINES_ICON_LIST_LIMIT;
    }

    getDurationInMinutes(leg: LegAdapter): number {
        const durationInMilliseconds = this.#durationStringToMilliseconds(leg.getWalkDuration());
        return durationInMilliseconds > 0 ? durationInMilliseconds / ONE_SECOND_IN_MS / ONE_MINUTE_IN_SECONDS : 0;
    }

    #durationStringToMilliseconds(durationStr: string): number {
        const [hours, minutes, seconds] = durationStr.split(':').map(Number);
        const totalMinutes = hours * ONE_MINUTE_IN_SECONDS + minutes + (seconds > 0 ? 1 : 0);
        const totalInMilliseconds = totalMinutes * ONE_MINUTE_IN_MS;
        return isNaN(totalInMilliseconds) ? 0 : totalInMilliseconds;
    }
}
