@if ($isLoading | async) {
  <ion-progress-bar type="indeterminate"></ion-progress-bar>
}

@if ($orders | async; as orders) {
  <ion-accordion-group>
    <ion-accordion [disabled]="orders.current.length === 0">
      <ion-item slot="header">
        <ion-icon slot="start" class="ticket-icon" src="assets/icons/tabs/monochrome/journey.svg"></ion-icon>
        @if (currentTicketMapping) {
          <ion-label class="ticket-label"> {{ orders.current.length | i18nPlural: currentTicketMapping }}</ion-label>
        }
      </ion-item>
      @for (order of orders.current; track order.id) {
        <div slot="content" (click)="onOrderClicked(order)" class="tickets-order-item item" tappable>
          @if (isItinerary(order)) {
            <app-itinerary-ticket-summary [detail]="true" [itineraryOrder]="order" [order]="order" />
          }
          @if (isDeparture(order)) {
            <app-departure-ticket-summary [departureOrder]="order" [order]="order" [detail]="true" />
          }
          @if (isQuick(order)) {
            <app-quick-ticket-summary-item [order]="order" [detail]="true" [quickTicket]="order" />
          }
        </div>
      }
    </ion-accordion>

    <ion-accordion [disabled]="orders.future.length === 0">
      <ion-item slot="header">
        <ion-icon slot="start" class="ticket-icon" src="assets/icons/tabs/monochrome/journey.svg"></ion-icon>
        @if (futureTicketMapping) {
          <ion-label class="ticket-label"> {{ orders.future.length | i18nPlural: futureTicketMapping }}</ion-label>
        }
      </ion-item>
      @for (order of orders.future; track order.id) {
        <div slot="content" (click)="onOrderClicked(order)" class="tickets-order-item item" tappable>
          @if (isItinerary(order)) {
            <app-itinerary-ticket-summary [detail]="true" [itineraryOrder]="order" [order]="order" />
          }
          @if (isDeparture(order)) {
            <app-departure-ticket-summary [detail]="true" [departureOrder]="order" [order]="order" />
          }
          @if (isQuick(order)) {
            <app-quick-ticket-summary-item [detail]="true" [order]="order" [quickTicket]="order" />
          }
        </div>
      }
    </ion-accordion>

    <ion-accordion [disabled]="orders.past.length === 0">
      <ion-item slot="header">
        <ion-icon slot="start" class="ticket-icon" src="assets/icons/history.svg"></ion-icon>
        @if (pastTicketMapping) {
          <ion-label class="ticket-label"> {{ orders.past.length | i18nPlural: pastTicketMapping }}</ion-label>
        }
      </ion-item>
      @for (order of orders.past; track order.id) {
        <div slot="content" (click)="onOrderClicked(order)" class="tickets-order-item item" tappable>
          @if (isItinerary(order)) {
            <app-itinerary-ticket-summary [detail]="true" [itineraryOrder]="order" [order]="order" />
          }
          @if (isDeparture(order)) {
            <app-departure-ticket-summary [detail]="true" [departureOrder]="order" [order]="order" />
          }
          @if (isQuick(order)) {
            <app-quick-ticket-summary-item [detail]="true" [order]="order" [quickTicket]="order" />
          }
        </div>
      }
    </ion-accordion>

    @if (showCancelledJourneysInBookingList) {
      <ion-accordion [disabled]="orders.cancelled.length === 0">
        <ion-item slot="header">
          <ion-icon slot="start" class="ticket-icon" src="assets/icons/cancelled_journey.svg"></ion-icon>
          @if (cancelledTicketMapping) {
            <ion-label class="ticket-label"> {{ orders.cancelled.length | i18nPlural: cancelledTicketMapping }}</ion-label>
          }
        </ion-item>
        @for (order of orders.cancelled; track order.id) {
          <div slot="content" (click)="onOrderClicked(order)" class="tickets-order-item item" tappable>
            @if (isItinerary(order)) {
              <app-itinerary-ticket-summary [itineraryOrder]="order" [order]="order" />
            }
            @if (isDeparture(order)) {
              <app-departure-ticket-summary [departureOrder]="order" [order]="order" [detail]="true" />
            }
            @if (isQuick(order)) {
              <app-quick-ticket-summary-item [detail]="true" [order]="order" [quickTicket]="order" />
            }
          </div>
        }
      </ion-accordion>
    }
  </ion-accordion-group>
}
