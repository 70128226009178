import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationBehaviorOptions, NavigationExtras, Params, Router, UrlCreationOptions } from '@angular/router';
import { NavController } from '@ionic/angular';
import { GuestCustomer, OrderViewModel, Traveler } from '@traas/boldor/all-models';
import { LatLng } from 'leaflet';
import { FROM_TRANSIT_STOP_PARAM, GUEST_CUSTOMER_PARAM_NAME, RETURN_URL_PARAM_NAME, RouteUrl } from './url-constants';

/**
 * To see bug about more router-outlet :
 * https://github.com/ionic-team/ionic/issues/15798
 * https://github.com/ionic-team/ionic/issues/16411
 */
@Injectable()
export class RoutingService {
    constructor(
        protected readonly location: Location,
        protected readonly router: Router,
        protected readonly navCtrl: NavController,
        protected readonly activatedRoute: ActivatedRoute,
    ) {}

    back(): void {
        this.navCtrl.back();
    }

    pop(): Promise<boolean> {
        return this.navCtrl.pop();
    }

    getRouteUrl(): string {
        return this.router.url;
    }

    navigateTo(target: string, extra: NavigationExtras = {}): Promise<boolean> {
        return this.router.navigate([target], extra);
    }

    // SPECIFIC

    async navigateToGuestForm(returnUrl: string | null): Promise<void> {
        await this.router.navigate([RouteUrl.signAsGuestUrl], {
            queryParams: {
                returnUrl,
            },
        });
    }

    async navigateToGuestTickets(extras?: NavigationBehaviorOptions): Promise<void> {
        if (extras) {
            await this.router.navigateByUrl(RouteUrl.guestTicketsPageUrl, extras);
        } else {
            await this.navigateToQuickTicketsTab();
        }
    }

    navigateToHome(fromTransitStop = false): Promise<boolean> {
        if (fromTransitStop) {
            const navigationExtras: NavigationExtras = {
                state: {
                    [FROM_TRANSIT_STOP_PARAM]: fromTransitStop,
                },
                queryParamsHandling: 'preserve',
            };
            return this.router.navigate([RouteUrl.departureResultUrl], navigationExtras);
        }
        return this.router.navigate([RouteUrl.departureResultUrl], {
            replaceUrl: true, // Prevents native back behaviour on iOS
        });
    }

    async navigateToLogin(
        returnUrl: string | null,
        options?: { showBuyTicketBySmsButton?: boolean; showGuestCheckoutButton?: boolean },
    ): Promise<void> {
        await this.router.navigate([RouteUrl.loginUrl], {
            queryParams: {
                returnUrl,
                showBuyTicketBySmsButton: options?.showBuyTicketBySmsButton ?? false,
                showGuestCheckoutButton: options?.showGuestCheckoutButton ?? false,
            },
        });
    }

    navigateToItineraryDetails(navigateBack?: boolean): Promise<boolean> {
        if (navigateBack) {
            return this.router.navigateByUrl(RouteUrl.itineraryDetailUrl);
        }
        return this.router.navigate([RouteUrl.itineraryDetailUrl], {
            queryParamsHandling: 'preserve',
        });
    }

    navigateToDepartureDetails(): Promise<boolean> {
        return this.router.navigateByUrl(RouteUrl.departureDetailUrl);
    }

    async navigateToSelectFrequentTravelers(passengerType: string, selectedTravelers?: Traveler[]): Promise<void> {
        const navigationExtras: NavigationExtras = {
            state: {
                passengerType,
                selectedTravelers,
            },
        };
        const url = RouteUrl.frequentTravelersUrlFromCart;
        /**
         * Permet de forcer l'animation, car dans le panier, si on fait le bouton "home" et qu'on clique sur "annuler" pour rester sur le
         * panier, après la navigation pour ajouter un passager va en arrère...
         */
        await this.navCtrl.navigateForward(url, navigationExtras);
    }

    async navigateToBookings(): Promise<void> {
        await this.router.navigateByUrl(RouteUrl.bookingsTabUrl);
    }

    async navigateToQuickTicketsTab(navigateBack = false): Promise<boolean> {
        if (navigateBack) {
            return this.navCtrl.navigateBack(RouteUrl.ticketsTabUrl);
        }
        return this.router.navigateByUrl(RouteUrl.ticketsTabUrl);
    }

    async navigateToOrder({ id }: OrderViewModel): Promise<boolean> {
        return this.router.navigateByUrl(`${RouteUrl.bookingsTabUrl}/details/${id}`);
    }

    async navigateToCartPayment(guestCustomer?: GuestCustomer): Promise<void> {
        const options: NavigationExtras = { state: { [GUEST_CUSTOMER_PARAM_NAME]: guestCustomer } };
        // Pour forcer une navigation avec animation vers l'avant. Sinon, après avoir cliqué 1 fois sur le bouton "home" depuis
        // le panier, les sens de navigations sont cassés
        await this.navCtrl.navigateForward(RouteUrl.cartPaymentUrl, options);
    }

    async navigateToCartJourneyActions(): Promise<void> {
        await this.navCtrl.navigateForward(RouteUrl.cartOperationChooser);
    }

    async navigateToCartZonesPicker(guestCustomer?: GuestCustomer): Promise<void> {
        const options: NavigationExtras = { state: { [GUEST_CUSTOMER_PARAM_NAME]: guestCustomer } };
        await this.router.navigateByUrl(RouteUrl.cartZonesPicker, options);
    }

    async navigateToCartTicketConfiguration(guestCustomer?: GuestCustomer): Promise<void> {
        const options: NavigationExtras = { state: { [GUEST_CUSTOMER_PARAM_NAME]: guestCustomer } };
        // Pour forcer une navigation avec animation vers l'avant. Sinon, dans le cas ou nous revenons à la home page
        // via le bouton "home", après, si nous cliquons sur le bouton "acheter", nous avons une navigation en arrière
        await this.navCtrl.navigateForward(RouteUrl.cartOrdersUrl, options);
    }

    async updateUrlFromMap(centerPoint: LatLng, zoomLevel: number, action: string): Promise<void> {
        const queryParams: Params = {
            lat: centerPoint.lat,
            lng: centerPoint.lng,
            z: zoomLevel,
            action,
        };

        await this.router.navigate([], this.#getUrlExtras(queryParams));
    }

    // With this function we are overwriting the last history element instead of pushing a second one
    async updateUrlAndReplaceHistory(queryParams: Params): Promise<void> {
        const tree = this.router.createUrlTree([], this.#getUrlExtras(queryParams));

        const path = this.router.serializeUrl(tree);

        // We need to keep this value alive in the state
        const fromTransit = window.history.state[FROM_TRANSIT_STOP_PARAM];

        this.location.replaceState(path, '', { from_transit_stop: fromTransit });
    }

    async navigateToSwissPassPage(): Promise<boolean> {
        return this.router.navigateByUrl(`${RouteUrl.menuTabUrl}/swisspass`);
    }

    getCurrentReturnUrl(): string {
        return this.activatedRoute.snapshot.queryParams[RETURN_URL_PARAM_NAME];
    }

    getSwisspassSSORedirectUrl(): string {
        return this.activatedRoute.snapshot.queryParams['swisspassSSORedirectUrl'];
    }

    #getUrlExtras(queryParams: Params): UrlCreationOptions {
        return {
            relativeTo: this.activatedRoute,
            queryParams,
            queryParamsHandling: 'merge',
        };
    }
}
