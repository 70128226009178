<ion-row>
  <ion-col size="12" class="ion-text-center progress-bar-container">
    @if (!showError) {
      <div class="margin-top">
        <ion-progress-bar [value]="progressionPercent / 100"></ion-progress-bar>
      </div>
    } @else {
      <ion-icon name="alert-circle-outline" color="danger" class="error-icon"></ion-icon>
    }
  </ion-col>
  <ion-col size="12" class="ion-text-center">
    <ion-text class="title">
      <p>{{ statusTitle }}</p>
    </ion-text>
  </ion-col>
  <ion-col size="12" class="ion-text-center">
    @if (isOffline && !showError) {
      <ion-text color="danger">
        <p>{{ 'payment.offline' | translate }}</p>
      </ion-text>
    }
    @if (!isOffline) {
      <ion-text>
        <p>{{ statusDescription }}</p>
      </ion-text>
    }
  </ion-col>
  @if (isRunningTooLong && !showError) {
    <ion-col size="12" class="ion-text-center">
      <ion-text color="danger">
        <p>{{ 'payment.is-running-too-long' | translate }}</p>
      </ion-text>
    </ion-col>
  }
</ion-row>
