import { ChangeDetectionStrategy, Component, inject, Input, OnInit } from '@angular/core';
import { getCompanyFromNetworkId } from '@traas/boldor/business-rules';
import { LegAdapter } from '../../models/itinerary/leg';
import { Line } from '@traas/common/models';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { LineIconModule } from '../line-icon/line-icon.module';
import { LegsDisplayRulesService } from './legs-display-rules.service';

@Component({
    selector: 'app-lines-icon-horizontal-list',
    templateUrl: './lines-icon-horizontal-list.component.html',
    styleUrls: ['./lines-icon-horizontal-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CommonModule, IonicModule, LineIconModule],
})
export class LinesIconHorizontalListComponent implements OnInit {
    #_lines: Line[] = [];
    #_legs: LegAdapter[] = [];
    #legsDisplayRulesService = inject(LegsDisplayRulesService);

    @Input() hasDisruption = false;
    @Input() isCancelled = false;
    @Input() hasItineraryStatus = false;

    @Input({ required: true })
    set lines(value: Line[]) {
        const oldLinesIds = this.#_lines.map(({ id }) => id).join('-');
        const newLinesIds = value.map(({ id }) => id).join('-');
        if (oldLinesIds === newLinesIds) {
            return;
        }
        this.#_lines = value;
        this.#updateViewModel();
    }

    get lines(): Line[] {
        return this.#_lines;
    }

    @Input()
    set legs(value: LegAdapter[]) {
        if (this.#legsDisplayRulesService.areLegArraysEqual(this.#_legs, value)) {
            return;
        }
        this.#_legs = value;
        this.#updateViewModel();
    }

    get legs(): LegAdapter[] {
        return this.#_legs;
    }

    delayOfWalkingLegInMinutes: number;
    linesToShow: Line[] = [];
    hasTooManyLines: boolean;
    showLastLine: boolean;
    isStartByFoot: boolean;

    ngOnInit(): void {
        this.linesToShow = [];
        this.#updateViewModel();
    }

    trackById(index: number, { id }: Line): string | number {
        return id ? id : index;
    }

    getCompanyFromNetworkId(networkId: string): string {
        return getCompanyFromNetworkId(networkId);
    }

    hasRightJoin(index: number, isLastItem: boolean): boolean {
        return this.hasTooManyLines ? (this.isStartByFoot ? false : index < 1) : !isLastItem;
    }

    hasRightDashedJoin(index: number): boolean {
        return this.hasTooManyLines ? (this.isStartByFoot ? index < 1 : index === 1) : false;
    }

    #updateViewModel(): void {
        this.isStartByFoot = this.#legsDisplayRulesService.shouldDisplayFirstLegAsWalking(this.legs);
        this.hasTooManyLines = this.#legsDisplayRulesService.exceedsLineDisplayLimit(
            this.lines.length,
            this.isStartByFoot,
            this.isCancelled,
            this.hasDisruption,
            this.hasItineraryStatus,
        );

        this.showLastLine = !(this.hasTooManyLines && (this.isCancelled || this.hasDisruption || this.hasItineraryStatus));
        this.linesToShow = this.hasTooManyLines ? this.#filterLinesToShow() : [...this.lines];
        if (this.isStartByFoot) {
            this.delayOfWalkingLegInMinutes = this.#legsDisplayRulesService.getDurationInMinutes(this.legs[0]);
        }
    }

    #filterLinesToShow(): Line[] {
        const firstLinesOrWalk = this.isStartByFoot ? [this.lines[0]] : this.lines.slice(0, 2);
        if (this.showLastLine) {
            const lastLine = this.lines[this.lines.length - 1];
            return [...firstLinesOrWalk, lastLine];
        }
        return [...firstLinesOrWalk];
    }
}
