import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';
import { Capacitor } from '@capacitor/core';

/**
 * @deprecated NE PLUS UTILISER
 */
@Injectable()
export class UnsecureStorage {
    #storage!: Promise<Storage>;
    #_driver: string | null = null;

    constructor(storageParam: Storage) {
        let init = Promise.resolve();
        if (Capacitor.isNativePlatform()) {
            init = storageParam.defineDriver(CordovaSQLiteDriver);
        }
        this.#storage = init.then(() => storageParam.create());
        this.#storage.then((storage) => (this.#_driver = storage.driver));
    }

    /**
     * @deprecated NE PLUS UTILISER
     */
    get driver(): string | null {
        return this.#_driver;
    }

    /**
     * @deprecated NE PLUS UTILISER
     */
    async get(key: string): Promise<any> {
        const storage = await this.#storage;
        return storage.get(key);
    }

    /**
     * @deprecated NE PLUS UTILISER
     */
    async set(key: string, value: any): Promise<any> {
        const storage = await this.#storage;
        return storage.set(key, value);
    }

    /**
     * @deprecated NE PLUS UTILISER
     */
    async remove(key: string): Promise<any> {
        const storage = await this.#storage;
        return storage.remove(key);
    }

    /**
     * @deprecated NE PLUS UTILISER
     */
    async clear(): Promise<void> {
        const storage = await this.#storage;
        return storage.clear();
    }

    /**
     * @deprecated NE PLUS UTILISER
     */
    async length(): Promise<number> {
        const storage = await this.#storage;
        return storage.length();
    }

    /**
     * @deprecated NE PLUS UTILISER
     */
    async keys(): Promise<string[]> {
        const storage = await this.#storage;
        return storage.keys();
    }

    /**
     * @deprecated NE PLUS UTILISER
     */
    async forEach(iteratorCallback: (value: any, key: string, iterationNumber: number) => any): Promise<void> {
        const storage = await this.#storage;
        // @ts-ignore
        return storage.forEach(iteratorCallback);
    }
}
