import { Injectable } from '@angular/core';
import { ONE_MINUTE_IN_MS } from '@traas/common/utils';
import { Observable, timer } from 'rxjs';
import { skip, startWith } from 'rxjs/operators';

@Injectable()
export class TimerService {
    /**
     * Emit only on the period. For instance a period of 60000 will emit an event every minute
     * @param periodInMS By default, the value is one minutes
     * @param immediatelyFire Default false
     * @param skipFirst
     */
    $synchronizedTimer(periodInMS = ONE_MINUTE_IN_MS, immediatelyFire = false, skipFirst = false): Observable<number> {
        const now = new Date().getTime();
        const delayToPeriod = periodInMS - (now % periodInMS);
        const $timer = timer(delayToPeriod, periodInMS);
        if (immediatelyFire && skipFirst) {
            throw new Error(`Can't define immediatelyFire && skipFirst at same time.`);
        }
        if (immediatelyFire) {
            return $timer.pipe(startWith(0));
        }
        if (skipFirst) {
            return $timer.pipe(skip(1));
        }
        return $timer;
    }
}
