import { ChangeDetectionStrategy, Component } from '@angular/core';
import { environment } from '@traas/boldor/environments';
import { PlatformUtilsService } from '@traas/common/utils';
import { Router } from '@angular/router';

@Component({
    selector: 'debug-info-display',
    templateUrl: './debug-info-display.component.html',
    styleUrls: ['./debug-info-display.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DebugInfoDisplayComponent {
    env = environment;
    deviceType: string;

    constructor(
        private readonly platformUtilsService: PlatformUtilsService,
        private readonly router: Router,
    ) {
        const isAndroid = this.platformUtilsService.isAndroid();
        const isIos = this.platformUtilsService.isIos();

        if (isAndroid) {
            this.deviceType = 'android';
        } else if (isIos) {
            this.deviceType = 'ios';
        } else {
            this.deviceType = 'other';
        }
        this.deviceType += this.platformUtilsService.isApp() ? ' app' : ' web';
    }

    nbTimesClickedVador = 0;
    onClickVader(): void {
        this.nbTimesClickedVador += 1;
        if (this.nbTimesClickedVador > 20) {
            this.router.navigateByUrl('/tabs/debug');
        }
    }
}
