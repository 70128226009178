import { GqlToFrontDepartureConverter } from '../features/departure/converters/gql-to-front';
import {
    GetConfirmedDepartureOrderQuery,
    GetConfirmedItineraryOrderQuery,
    GetConfirmedQuickTicketsOrdersQuery,
    GetDepartureOrderQuery,
    GetItineraryStopRequestsQuery,
    ItineraryOrderQuery,
    OrderType,
    Passenger,
    PassengerType,
    PaymentState,
    ProcessingOrder,
    QuickTicketOrderQuery,
    SaveItineraryMutation,
    TicketState as GqlTicketState,
    TicketsTransaction,
} from '@traas/boldor/graphql-generated/graphql';
import {
    Currency,
    DepartureOrderViewModel,
    DepartureStopRequest,
    Itinerary,
    ItineraryOrderViewModel,
    ItineraryStopRequest,
    OrderViewModel,
    QuickTicketsOrderViewModel,
    ReductionType,
    StopRequest,
    Ticket,
    TicketState,
    TravelClassType,
} from '@traas/boldor/all-models';
import { GqlToFrontStopRequestConverter } from './stop-request/gql-to-front';
import * as moment from 'moment';

export type GqlItineraryOrder =
    | SaveItineraryMutation['purchase']['saveItinerary']
    | ItineraryOrderQuery['orderPublic']['getItineraryOrder']
    // @ts-ignore ignore type checking
    | GetConfirmedItineraryOrderQuery['order']['getConfirmedItineraryOrders'][number];

export type GqlDepartureOrder =
    | GetDepartureOrderQuery['order']['getDepartureOrder']
    // @ts-ignore ignore type checking
    | GetConfirmedDepartureOrderQuery['order']['getConfirmedDepartureOrders'][number];

export type GqlQuickTicketsOrder =
    | QuickTicketOrderQuery['orderPublic']['getQuickTicketsOrder']
    // @ts-ignore ignore type checking
    | GetConfirmedQuickTicketsOrdersQuery['order']['getConfirmedQuickTicketsOrders'][number];

// @ts-ignore ignore type checking
export type GqlTicketsTransaction = ItineraryOrderQuery['orderPublic']['getItineraryOrder']['ticketsTransaction'];

type GqlItineraryArticle =
    // @ts-ignore ignore type checking
    GetConfirmedItineraryOrderQuery['order']['getConfirmedItineraryOrders'][number]['ticketsTransaction']['tickets'][number]['article'] & {
        __typename: 'ItineraryArticle';
    };

// @ts-ignore ignore type checking
type GqlDepartureArticle = GetDepartureOrderQuery['order']['getDepartureOrder']['ticketsTransaction']['tickets'][number]['article'] & {
    __typename: 'DepartureArticle';
};

type GqlQuickArticle =
    // @ts-ignore ignore type checking
    GetConfirmedQuickTicketsOrdersQuery['order']['getConfirmedQuickTicketsOrders'][number]['ticketsTransaction']['tickets'][number]['article'] & {
        __typename: 'QuickArticle';
    };

// @ts-ignore ignore type checking
export type GqlItinerary = GetItineraryStopRequestsQuery['order']['getItineraryStopRequests'][number]['itinerary'];

export type GqlOrder = GqlItineraryOrder | GqlQuickTicketsOrder | GqlDepartureOrder;

export class GqlToFrontOrderConverter {
    static toOrderViewModel(order: GqlOrder): OrderViewModel {
        if (GqlToFrontOrderConverter.isGqlItineraryOrder(order) || GqlToFrontOrderConverter.isGqlSaveItineraryOrder(order)) {
            return GqlToFrontOrderConverter.fromItineraryOrderToViewModel(order);
        }
        if (GqlToFrontOrderConverter.isGqlQuickTicketsOrder(order)) {
            return GqlToFrontOrderConverter.fromQuickTicketsOrderToViewModel(order);
        }
        if (GqlToFrontOrderConverter.isGqlDepartureOrder(order)) {
            return GqlToFrontOrderConverter.fromDepartureOrderToViewModel(order);
        }
        throw new Error('Error: An order must be oe of the following types: itinerary, saveItinerary, quickTicket, departure.');
    }

    static isGqlItineraryOrder(order: GqlOrder): order is GqlItineraryOrder {
        return order.orderType === OrderType.BuyItineraryTickets;
    }

    static isGqlSaveItineraryOrder(order: GqlOrder): order is GqlItineraryOrder {
        return order.orderType === OrderType.SaveItinerary;
    }

    static isGqlQuickTicketsOrder(order: GqlOrder): order is GqlQuickTicketsOrder {
        return this.#isQuickTicketOrderType(order.orderType);
    }

    static isGqlDepartureOrder(order: GqlOrder): order is GqlDepartureOrder {
        return order.orderType === OrderType.BuyDepartureTickets;
    }

    static fromItineraryOrderToViewModel(order: GqlItineraryOrder): ItineraryOrderViewModel {
        let tickets: Ticket[] = [];
        if (order.orderType === OrderType.BuyItineraryTickets) {
            const ticketsTransaction = order.ticketsTransaction as GqlTicketsTransaction;
            tickets = ticketsTransaction.tickets.map((ticket) => {
                const article = ticket.article as unknown as GqlItineraryArticle;
                return {
                    ...ticket,
                    state: this.#getTicketState(ticketsTransaction.ticketState),
                    refundDate: ticket.refundDate,
                    passenger: {
                        ...ticket.passenger,
                        birthDate: moment(ticket.passenger.birthDate).toDate(),
                    },
                    prices: [
                        {
                            value: `${ticket.price.amountInCents}`,
                            currency: ticket.price.currency as unknown as Currency,
                        },
                    ],
                    article: {
                        ...article,
                        id: null,
                        prices: [
                            {
                                value: `${ticket.price.amountInCents}`,
                                currency: ticket.price.currency as unknown as Currency,
                            },
                        ],
                        reduction: {
                            id: article.reduction ? (article.reduction.id as unknown as ReductionType) : null,
                            description: article.reduction ? article.reduction.description : '',
                        },
                        travelClass: {
                            description: article.travelClass ? article.travelClass.description : '',
                            id: article.travelClass ? (article.travelClass.id as unknown as TravelClassType) : null,
                        },
                        tarifOwner: article.tarifOwner,
                        isSupersaver: article.isSupersaver,
                    },
                    type: null,
                    duration: null,
                    paymentMeans: article.paymentMeans,
                    isCancelled: this.#isCancelled(ticketsTransaction),
                    isProcessing: false,
                } as Ticket;
            });
        }
        return {
            isProcessing: false,
            id: order.id,
            tickets,
            customerId: order.customerId,
            orderType: order.orderType,
            itinerary: order.itinerary as unknown as Itinerary,
        };
    }

    static #getTicketState(ticketState?: TicketState | null): TicketState {
        switch (ticketState) {
            case GqlTicketState.AuthorizeError:
                return TicketState.AuthorizeError;
            case GqlTicketState.AuthorizeSuccess:
                return TicketState.AuthorizeSuccess;
            case GqlTicketState.BoldorCreateSuccess:
                return TicketState.BoldorCreateSuccess;
            case GqlTicketState.CanceledInApp:
                return TicketState.CanceledInApp;
            case GqlTicketState.ConfirmError:
                return TicketState.ConfirmError;
            case GqlTicketState.ConfirmRefundError:
                return TicketState.ConfirmRefundError;
            case GqlTicketState.ConfirmRefundSuccess:
                return TicketState.ConfirmRefundSuccess;
            case GqlTicketState.ConfirmSuccess:
                return TicketState.ConfirmSuccess;
            case GqlTicketState.DocumentUpdateError:
                return TicketState.DocumentUpdateError;
            case GqlTicketState.DocumentUpdateSuccess:
                return TicketState.DocumentUpdateSuccess;
            case GqlTicketState.Initial:
                return TicketState.Initial;
            case GqlTicketState.RefundAuthorizeError:
                return TicketState.RefundAuthorizeError;
            case GqlTicketState.RefundAuthorizeSuccess:
                return TicketState.RefundAuthorizeSuccess;
            case GqlTicketState.RefundBoldorCreateSuccess:
                return TicketState.RefundBoldorCreateSuccess;
            case GqlTicketState.RefundCreateError:
                return TicketState.RefundCreateError;
            case GqlTicketState.RefundCreateSuccess:
                return TicketState.RefundCreateSuccess;
            case GqlTicketState.RefundDocumentUpdateError:
                return TicketState.RefundDocumentUpdateError;
            case GqlTicketState.RefundDocumentUpdateSuccess:
                return TicketState.RefundDocumentUpdateSuccess;
            case GqlTicketState.RefundError:
                return TicketState.RefundError;
            case GqlTicketState.RefundRecordError:
                return TicketState.RefundRecordError;
            case GqlTicketState.RefundRecordSuccess:
                return TicketState.RefundRecordSuccess;
            case GqlTicketState.RefundSuccess:
                return TicketState.RefundSuccess;
            case GqlTicketState.SdsCreateError:
                return TicketState.SdsCreateError;
            case GqlTicketState.SdsCreateSuccess:
                return TicketState.SdsCreateSuccess;
            case GqlTicketState.SdsRecordError:
                return TicketState.SdsRecordError;
            case GqlTicketState.SdsRecordSuccess:
                return TicketState.SdsRecordSuccess;
            case GqlTicketState.SeenByClient:
                return TicketState.SeenByClient;
            default:
                return TicketState.Unknown;
        }
    }

    static fromQuickTicketsOrderToViewModel(order: GqlQuickTicketsOrder): QuickTicketsOrderViewModel {
        let tickets: Ticket[] = [];
        if (order.ticketsTransaction) {
            const { ticketsTransaction } = order;

            tickets = ticketsTransaction.tickets.map((ticket) => {
                const article: GqlQuickArticle = ticket.article as unknown as GqlQuickArticle;
                return {
                    ...ticket,
                    state: this.#getTicketState(ticketsTransaction.ticketState),
                    refundDate: ticket.refundDate,
                    passenger: {
                        ...ticket.passenger,
                        birthDate: moment(ticket.passenger.birthDate).toDate(),
                    },
                    prices: [
                        {
                            value: `${ticket.price.amountInCents}`,
                            currency: ticket.price.currency as unknown as Currency,
                        },
                    ],
                    article: {
                        ...article,
                        id: null,
                        prices: [
                            {
                                value: `${ticket.price.amountInCents}`,
                                currency: ticket.price.currency as unknown as Currency,
                            },
                        ],
                        reduction: {
                            id: article?.reduction ? (article?.reduction?.id as unknown as ReductionType) : null,
                            description: article?.reduction ? article?.reduction?.description : '',
                        },
                        travelClass: {
                            description: article?.travelClass ? article.travelClass?.description : '',
                            id: article?.travelClass ? (article?.travelClass?.id as unknown as TravelClassType) : null,
                        },
                        tarifOwner: article?.tarifOwner,
                    },
                    type: null,
                    duration: null,
                    paymentMeans: article?.paymentMeans,
                    isCancelled: this.#isCancelled(ticketsTransaction),
                    isProcessing: false,
                } as Ticket;
            });
        }
        return {
            isProcessing: false,
            id: order.id,
            tickets,
            customerId: order.customerId,
            orderType: order.orderType,
        };
    }

    static fromDepartureOrderToViewModel(order: GqlDepartureOrder): DepartureOrderViewModel {
        const { ticketsTransaction } = order;
        const tickets = ticketsTransaction.tickets.map((ticket) => {
            const article = ticket.article as unknown as GqlDepartureArticle;
            return {
                ...ticket,
                state: this.#getTicketState(ticketsTransaction.ticketState),
                refundDate: ticket.refundDate,
                passenger: {
                    ...ticket.passenger,
                    birthDate: moment(ticket.passenger.birthDate).toDate(),
                },
                prices: [
                    {
                        value: `${ticket.price.amountInCents}`,
                        currency: ticket.price.currency as unknown as Currency,
                    },
                ],
                article: {
                    ...article,
                    category: article.category,
                    smsCode: article.smsCode,
                    description: article.description,
                    title: article.title,
                    paymentMeans: article.paymentMeans,
                    id: article.articleId,
                    prices: [
                        {
                            value: `${ticket.price.amountInCents}`,
                            currency: ticket.price.currency as unknown as Currency,
                        },
                    ],
                    reduction: {
                        id: article.reduction ? (article.reduction.id as unknown as ReductionType) : null,
                        description: article.reduction ? article.reduction.description : '',
                    },
                    travelClass: {
                        description: article.travelClass ? article.travelClass.description : '',
                        id: article.travelClass ? (article.travelClass.id as unknown as TravelClassType) : null,
                    },
                },
                type: null,
                duration: null,
                paymentMeans: article.paymentMeans,
                isCancelled: this.#isCancelled(ticketsTransaction),
                isProcessing: false,
            } as Ticket;
        });
        return {
            isProcessing: false,
            id: order.id,
            tickets,
            customerId: order.customerId,
            orderType: order.orderType,
            departure: GqlToFrontDepartureConverter.toDeparture(order.departure),
        };
    }

    // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
    static fromStopRequestToOrderViewModel(stopRequest: StopRequest): OrderViewModel {
        switch (stopRequest.orderType) {
            case OrderType.ItineraryStopRequest:
                return {
                    tickets: [],
                    customerId: stopRequest.customerId,
                    id: stopRequest.id,
                    orderType: OrderType.ItineraryStopRequest,
                    itinerary: (stopRequest as ItineraryStopRequest).itinerary,
                    isProcessing: false,
                } as ItineraryOrderViewModel;
            case OrderType.DepartureStopRequest:
                return {
                    tickets: [],
                    customerId: stopRequest.customerId,
                    id: stopRequest.id,
                    orderType: OrderType.DepartureStopRequest,
                    departure: (stopRequest as DepartureStopRequest).departure,
                    isProcessing: false,
                } as DepartureOrderViewModel;
            default:
                throw new Error(`Unknown orderType of stopRequest : ${JSON.stringify(stopRequest)}`);
        }
    }

    /**
     * Tweak in processingOrder type is because ProcessingOrder type is not the same as the type received from the middleware...
     * @param processingOrder
     */
    static fromProcessingTicketToOrderViewModel(
        processingOrder: Omit<ProcessingOrder, 'passenger'> & {
            passenger: Partial<Passenger>;
        },
    ): OrderViewModel {
        return {
            tickets: [
                {
                    passenger: {
                        ...processingOrder.passenger,
                        birthDate: moment(processingOrder.passenger.birthDate).toDate(),
                    },
                    passengerType: PassengerType.Person,
                    isProcessing: true,
                    purchaseDate: processingOrder.purchaseDate,
                } as Partial<Ticket>,
            ],
            customerId: processingOrder.passenger.id,
            orderType: processingOrder.orderType,
            isProcessing: true,
            id: processingOrder.id,
        } as OrderViewModel;
    }

    static isViewModelQuickTicketsOrder(order: OrderViewModel): order is QuickTicketsOrderViewModel {
        return this.#isQuickTicketOrderType(order?.orderType);
    }

    static isViewModelBuyItineraryOrder(order: OrderViewModel): order is ItineraryOrderViewModel {
        return this.#isItineraryOrderType(order?.orderType);
    }

    static isViewModelSaveItineraryOrder(order: OrderViewModel): order is ItineraryOrderViewModel {
        return this.#isSaveItineraryOrderType(order?.orderType);
    }

    static isViewModelItineraryOrder(order: OrderViewModel): order is ItineraryOrderViewModel {
        return (
            this.isViewModelSaveItineraryOrder(order) ||
            this.isViewModelBuyItineraryOrder(order) ||
            GqlToFrontStopRequestConverter.isItineraryStopRequest(order)
        );
    }

    static isViewModelDepartureOrder(order: OrderViewModel): order is DepartureOrderViewModel {
        return this.#isDepartureOrderType(order?.orderType) || GqlToFrontStopRequestConverter.isDepartureStopRequest(order);
    }

    static #isQuickTicketOrderType(orderType: OrderType): boolean {
        return orderType === OrderType.BuyQuickTicket;
    }

    static #isItineraryOrderType(orderType: OrderType): boolean {
        return orderType === OrderType.BuyItineraryTickets;
    }

    static #isDepartureOrderType(orderType: OrderType): boolean {
        return orderType === OrderType.BuyDepartureTickets;
    }

    static #isSaveItineraryOrderType(orderType: OrderType): boolean {
        return orderType === OrderType.SaveItinerary;
    }

    static #isCancelled(ticketsTransaction: Pick<TicketsTransaction, 'paymentState' | 'ticketState'>): boolean {
        const { paymentState, ticketState } = ticketsTransaction;
        const isPrepaid = paymentState === PaymentState.PrepaidByThirdPartyApp || paymentState === PaymentState.PrepaidByThirdPartyBatch;
        return (paymentState === PaymentState.RefundSuccess || isPrepaid) && ticketState === TicketState.ConfirmRefundSuccess;
    }
}
