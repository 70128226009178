import {
    ArticleViewModel,
    Cart,
    CartOperation,
    ConfigureTicketType,
    Departure,
    DepartureCart,
    Itinerary,
    ItineraryCart,
    QuickTicketCart,
} from '@traas/boldor/all-models';
import { isDepartureJourney } from '../departure/departure';
import { isItineraryJourney } from '../itinerary/itinerary';
import { BookableAdapter } from '../booking/bookable.adapter';

// departure-cart
export function isDepartureCart(cart: Cart | null): cart is DepartureCart {
    return !!cart && !!cart.journeyViewModel && isDepartureJourney(cart.journeyViewModel);
}

// itinerary-cart
export function isItineraryCart(cart: Cart | null): cart is ItineraryCart {
    return !!cart && !!cart.journeyViewModel && isItineraryJourney(cart.journeyViewModel);
}

// quick-ticket-cart
export function isQuickTicketCart(cart: Cart | null): cart is QuickTicketCart {
    return !!cart && cart.journeyViewModel === undefined;
}

export function isQuickTicketByZoneCart(cart: Cart | null): cart is QuickTicketCart {
    return isQuickTicketCart(cart) && !!cart.article?.zones && !cart.nearestStop;
}

export function isQuickTicketCartByCategory(cart: Cart | null): cart is QuickTicketCart {
    return isQuickTicketCart(cart) && !!cart.origin?.categoryId;
}

// cart-operation

export function createCartOperation(operationPartial: Partial<CartOperation>): CartOperation {
    return {
        available: operationPartial.available || false,
        type: operationPartial.type || null,
        iconName: operationPartial.iconName || '',
        isChecked: operationPartial.isChecked || false,
    };
}

export function createConditionsMessagesOfTransactionBy(
    bookableAdapter: BookableAdapter<Departure | Itinerary>,
    firstDeadlineMessage: string,
    secondDeadlineMessage: string,
    thirdDeadlineMessage: string,
): string[] {
    const conditionsMessages: string[] = [];
    if (bookableAdapter.hasBookingRequirements()) {
        conditionsMessages.push(
            `${firstDeadlineMessage} <br/>${secondDeadlineMessage}<b> ${bookableAdapter.getBookingDeadlineDisplayText()}</b> <br/>${thirdDeadlineMessage}`,
        );
    }
    return conditionsMessages;
}

// configure-ticket

export function createConfigureTicketTypeFromArticle(
    productId: string,
    article: ArticleViewModel,
    isSelected: boolean,
): ConfigureTicketType {
    return {
        id: productId,
        label: article.title,
        description: article.categoryDescription,
        isSelected,
        priceInCents: 0,
        currency: article.prices[0]?.currency,
        routeQualifiers: article.routeQualifiers,
        supplementQualifiers: article.supplementQualifiers,
        tripType: article.tripType,
    };
}
