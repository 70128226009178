import { inject, Injectable } from '@angular/core';
import { ApolloClientOptions, ApolloLink, InMemoryCache } from '@apollo/client/core';
import { environment } from '@traas/boldor/environments';
import { HttpLink } from 'apollo-angular/http';
import { onError } from '@apollo/client/link/error';
import { TraasGraphQlExtension } from '@traas/boldor/all-models';

/**
 * In case of error, add the details of the operation that caused the error in the graphql extensions
 */
export const apolloErrorLink = onError(({ graphQLErrors, operation, response }) => {
    if (graphQLErrors && response) {
        (response.errors as any) = graphQLErrors.map((error) => {
            const gqlExtension: TraasGraphQlExtension = {
                ...error.extensions,
                operation: {
                    operationName: operation.operationName,
                },
            };
            return {
                ...error,
                extensions: gqlExtension,
            };
        });
    }
});

@Injectable()
export class ApolloClientOptionsBuilderService {
    #httpLink = inject(HttpLink);

    build(): ApolloClientOptions<any> {
        return {
            cache: new InMemoryCache(),
            defaultOptions: {
                mutate: { errorPolicy: 'none' },
                query: { errorPolicy: 'none', fetchPolicy: 'no-cache' },
            },
            link: ApolloLink.from([apolloErrorLink, this.#httpLink.create({ uri: `${environment.gatewayUrl}/graphql` })]),
        };
    }
}
