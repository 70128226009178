<ion-list #container>
    @for (leg of legs; let even = $even; let first = $first; let last = $last; track leg.getData().serviceId) {
        <ion-item>
            <app-itinerary-leg
                (svgAnchorChange)="processLegs()"
                #leg
                [leg]="leg"
                [ngClass]="even ? 'odd' : 'even'"
                [isFirstLeg]="first"
                [isLastLeg]="last"
            />
        </ion-item>
    }
</ion-list>
