import { inject, Injectable, InjectionToken } from '@angular/core';
import * as cryptojs from 'crypto-js';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import { FirebaseInitOptions } from '@capacitor-community/firebase-analytics/dist/esm/definitions';
import { Capacitor } from '@capacitor/core';
import { Customer } from '@traas/boldor/all-models';

const AUTHENTICATED_PROPERTY = 'authentication';
const AUTHENTICATED_PROPERTY_IDP = 'IDP';
const AUTHENTICATED_PROPERTY_GUEST = 'Guest';
const TIME_DISPLAY_MODE = 'timeDisplayMode';
const BIRTHDATE = 'birthdate';
const GENDER = 'gender';
const FARE = 'fare';
const SKIP_3D_SECURE = 'Skip3DSecureEnabled';
const CURRENCY = 'currency';

interface EventParameters {
    [parameterName: string]: string;
}

interface AnalyticsConfigParams {
    isTrackingEnabled: boolean;
    isLoggingEnabled: boolean;
    userIdHashSalt: string;
    webAnalyticsConfig: FirebaseInitOptions;
}
export const AnalyticsConfig = new InjectionToken<AnalyticsConfigParams>('');

@Injectable()
export class AnalyticsService {
    #config = inject(AnalyticsConfig);
    #isFirebaseInitialized = false;

    initializeWebTrackingIfEnabled(): void {
        if (!Capacitor.isNativePlatform()) {
            // for web, we must initialize the project in angular app.
            // in native version, the plugin does it automatically when it loads
            if (this.#config.isTrackingEnabled && !this.#config.webAnalyticsConfig) {
                throw new Error(`Expect to have webAnalyticsConfig if tracking is enabled.`);
            }
            if (this.#config.isTrackingEnabled && this.#config.webAnalyticsConfig) {
                FirebaseAnalytics.initializeFirebase(this.#config.webAnalyticsConfig).then(() => {
                    console.log('web initialisation finished');
                    this.#isFirebaseInitialized = true;
                });
            }
        } else {
            this.#isFirebaseInitialized = true;
        }
    }

    reportPageView(screenName: string): void {
        if (!Capacitor.isNativePlatform()) {
            // the capacitor plugin does not support web for this operation. Moreover, there is a bug in the implementation
            // and the promise never return: https://github.com/capacitor-community/firebase-analytics/blob/0e30af2cc8d290eb019162a02e41eb32f7d020f1/src/web.ts#L142
            return;
        }
        this.#executeAnalytics(
            () =>
                FirebaseAnalytics.setScreenName({
                    screenName,
                    nameOverride: screenName,
                }),
            () =>
                this.#logEvent({
                    function: 'setScreenName',
                    screenName,
                }),
        );
    }

    reportEvent(eventName: string, params: EventParameters = {}): void {
        this.#executeAnalytics(
            () =>
                FirebaseAnalytics.logEvent({
                    name: eventName,
                    params,
                }),
            () =>
                this.#logEvent({
                    function: 'logEvent',
                    eventName,
                    params,
                }),
        );
    }

    sendSkip3DSecure(skip3DSecureEnabled: string): void {
        this.#setUserProperty(SKIP_3D_SECURE, skip3DSecureEnabled);
    }

    sendCurrency(currency: string): void {
        this.#setUserProperty(CURRENCY, currency);
    }

    sendTimeDisplayMode(timeDisplayMode: string): void {
        this.#setUserProperty(TIME_DISPLAY_MODE, timeDisplayMode);
    }

    sendAuthenticatedUserInformation(customer: Customer | null): void {
        if (!customer) {
            throw new Error('Authenticated customer is empty');
        }
        const birthdate = customer.birthDate?.split(/[T]/)[0];
        this.#setUserProperty(BIRTHDATE, birthdate || 'unset');
        this.#setUserProperty(GENDER, customer.title?.label || 'unset');
        this.setAuthenticatedUserProperty(true);
    }

    sendUnsetAuthenticatedUserInformation(): void {
        this.#setUserProperty(BIRTHDATE, 'unset');
        this.#setUserProperty(GENDER, 'unset');
        this.#setUserProperty(FARE, 'unset');
        this.setAuthenticatedUserProperty(false);
    }

    setAuthenticatedUserProperty(authenticated: boolean): void {
        this.#setUserProperty(AUTHENTICATED_PROPERTY, authenticated ? AUTHENTICATED_PROPERTY_IDP : AUTHENTICATED_PROPERTY_GUEST);
    }

    setUserId(userID: string): void {
        const userIdHash = this.#createHash(userID);
        this.#executeAnalytics(
            () =>
                FirebaseAnalytics.setUserId({
                    userId: userIdHash,
                }),
            () =>
                this.#logEvent({
                    function: 'setUserId',
                    userId: userIdHash,
                }),
        );
    }

    #setUserProperty(name: string, value: string): void {
        this.#executeAnalytics(
            () =>
                FirebaseAnalytics.setUserProperty({
                    name,
                    value,
                }),
            () =>
                this.#logEvent({
                    function: 'setUserProperty',
                    name,
                    value,
                }),
        );
    }

    #createHash(text: string): string {
        const algo = cryptojs.algo.SHA256.create();
        algo.update(text);
        algo.update(cryptojs.SHA256(this.#config.userIdHashSalt));
        return algo.finalize().toString(cryptojs.enc.Base64);
    }

    #executeAnalytics(callbackTracking: () => Promise<void>, callbackLogging: () => void): void {
        if (!this.#isFirebaseInitialized) {
            if (this.#config.isTrackingEnabled && this.#config.webAnalyticsConfig) {
                console.error('Firebase not initialized. Ignoring analytics event');
            }
            return;
        }

        // we use the "then form" because we don't want this function to be async
        // if this function would be async, since we want to run it without waiting for the result,
        // we would get a warning in every method of this class
        if (this.#config.isLoggingEnabled) {
            callbackLogging();
        }
        if (this.#config.isTrackingEnabled) {
            callbackTracking();
        }
    }

    #logEvent(event: any): void {
        console.log('AnalyticsService -> ', event);
    }
}
