<ion-content>
  <ion-row class="ion-justify-content-center ion-margin-top">
    <ion-col size="auto">
      <ion-button id="open-date-modal" fill="solid" color="light" size="large">{{ pickerDate | date: 'mediumDate' }}</ion-button>
    </ion-col>
    <ion-col size="auto">
      <ion-button id="open-time-modal" fill="solid" color="light" size="large">{{ pickerDate | date: 'shortTime' }}</ion-button>
    </ion-col>
  </ion-row>
  <ion-popover trigger="open-date-modal" side="top" arrow="false" class="fit-content" [keepContentsMounted]="true">
    <ng-template>
      <ion-datetime mode="ios" presentation="date" [firstDayOfWeek]="1" [locale]="localeId" [(ngModel)]="pickerDate"> </ion-datetime>
    </ng-template>
  </ion-popover>

  <ion-popover trigger="open-time-modal" side="top" arrow="false" class="fit-content" [keepContentsMounted]="true">
    <ng-template>
      <ion-datetime
        mode="ios"
        presentation="time"
        minuteValues="0,5,10,15,20,25,30,35,40,45,50,55"
        [locale]="localeId"
        [(ngModel)]="pickerDate"
        >
      </ion-datetime>
    </ng-template>
  </ion-popover>
  <ion-row>
    <ion-col size="4">
      <ion-button (click)="cancel()" fill="outline" color="medium" expand="block" class="e2e-cancel-button">
        {{ 'common.cancel' | translate }}
      </ion-button>
    </ion-col>
    <ion-col size="5">
      <ion-button (click)="now()" fill="outline" expand="block">{{ 'date-picker-options.now' | translate }}</ion-button>
    </ion-col>
    <ion-col size="3">
      <ion-button (click)="submit()" expand="block" class="e2e-datetime-picker-confirm" elevation="1">{{
        'common.ok' | translate
      }}</ion-button>
    </ion-col>
  </ion-row>
  @if (showDebugButtons) {
    <ion-row>
      <ion-col size="6">
        <ion-button (click)="debug(-2)" color="danger" expand="block" class="e2e-date-2-days-ago">
          DEBUG: -2 {{ 'date-picker-options.days' | translate }}
        </ion-button>
      </ion-col>
      <ion-col size="6">
        <ion-button (click)="debug(2)" color="danger" expand="block" class="e2e-date-in-2-days">
          DEBUG: +2 {{ 'date-picker-options.days' | translate }}
        </ion-button>
        <ion-button (click)="debug(4)" color="danger" expand="block" class="e2e-date-in-4-days">
          DEBUG: +4 {{ 'date-picker-options.days' | translate }}
        </ion-button>
        <ion-button (click)="debug(7)" color="danger" expand="block" class="e2e-date-in-7-days">
          DEBUG: +7 {{ 'date-picker-options.days' | translate }}
        </ion-button>
      </ion-col>
    </ion-row>
  }
</ion-content>
