import { NgModule } from '@angular/core';
// tslint:disable-next-line:max-line-length
import { ItineraryLegStopListItemComponent } from './components/itinerary-leg-stop-list-item/itinerary-leg-stop-list-item.component';
// tslint:disable-next-line:max-line-length
import { ItineraryDetailsContentComponent } from './containers/itinerary-details-content/itinerary-details-content.component';
// tslint:disable-next-line:max-line-length
import { ItineraryDetailsFooterComponent } from './containers/itinerary-details-footer/itinerary-details-footer.component';
import { ItineraryHeaderComponent } from './containers/itinerary-header/itinerary-header.component';
// tslint:disable-next-line:max-line-length
import { ItineraryLegStopListContainerComponent } from './containers/itinerary-leg-stop-list-container/itinerary-leg-stop-list-container.component';
import { ItineraryStoreModule } from './store/itinerary-store.module';
import { PaymentModule } from '../payment/payment.module';
import { BoldorFeatureShellSharedModule } from '../../boldor-feature-shell-shared.module';
import { ItineraryListItemComponent } from './components/itinerary-list-item/itinerary-list-item.component';
import { ItinerariesListContainerComponent } from './containers/itineraries-list-container/itineraries-list-container.component';
import { ItinerariesComponent } from './containers/itineraries/itineraries.component';
import { ItineraryLegTransportComponent } from './containers/itinerary-leg-transport/itinerary-leg-transport.component';
import { ItineraryLegWalkComponent } from './containers/itinerary-leg-walk/itinerary-leg-walk.component';
import { ItineraryLegComponent } from './containers/itinerary-leg/itinerary-leg.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ItineraryListItemSkeletonComponent } from './components/itinerary-list-item-skeleton/itinerary-list-item-skeleton.component';
import { TripDepartureTimeComponent } from '../../components/trip-departure-time/trip-departure-time.component';
import { CancelledTripIconComponent } from '../../components/cancelled-trip-icon/cancelled-trip-icon.component';
import { PlaceholderListItemComponent } from '../../components/placeholder-list-item/placeholder-list-item.component';
import { LinesIconHorizontalListComponent } from '../../components/lines-icon-horizontal-list/lines-icon-horizontal-list.component';
import { DatePickerBarComponent } from '../../components/date-picker-bar/date-picker-bar.component';
import { ItinerariesListHeaderLabelComponent } from './containers/itineraries/itineraries-list-header-label.component';

@NgModule({
    imports: [
        BoldorFeatureShellSharedModule,
        ItineraryStoreModule,
        PaymentModule,
        ScrollingModule,
        TripDepartureTimeComponent,
        CancelledTripIconComponent,
        PlaceholderListItemComponent,
        LinesIconHorizontalListComponent,
        DatePickerBarComponent,
        ItinerariesListHeaderLabelComponent,
    ],
    declarations: [
        ItinerariesListContainerComponent,
        ItineraryListItemComponent,
        ItineraryListItemSkeletonComponent,
        ItinerariesComponent,
        ItineraryLegComponent,
        ItineraryLegWalkComponent,
        ItineraryLegTransportComponent,
        ItineraryLegStopListContainerComponent,
        ItineraryLegStopListItemComponent,
        ItineraryDetailsFooterComponent,
        ItineraryDetailsContentComponent,
        ItineraryHeaderComponent,
    ],
    exports: [
        ItinerariesListContainerComponent,
        ItinerariesComponent,
        ItineraryLegComponent,
        ItineraryLegWalkComponent,
        ItineraryLegTransportComponent,
        ItineraryLegStopListContainerComponent,
        ItineraryDetailsFooterComponent,
        ItineraryDetailsContentComponent,
        ItineraryHeaderComponent,
    ],
})
export class ItineraryModule {}
