import { Component, Input } from '@angular/core';

@Component({
    selector: 'traas-additional-information',
    templateUrl: './additional-information.component.html',
    styleUrls: ['./additional-information.component.scss'],
})
export class AdditionalInformationComponent {
    @Input({ required: true }) additionalInformation!: string[];
}
