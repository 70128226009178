import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { redirectToLoginOnAllReject } from '@traas/common/utils';
import { isAuthenticatedGuardFnFactory } from '@traas/boldor/common/guards/is-authenticated.guard';
import { isGuestGuardFnFactory } from '@traas/boldor/common/guards/is-guest-guard';
import { TabsPage } from './tabs';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
    },
    {
        path: '',
        component: TabsPage,
        children: [
            {
                path: 'home',
                loadChildren: () => import('@traas/boldor/common/pages/home/home.module').then((module) => module.HomePageModule),
            },
            {
                path: 'bookings',
                children: [
                    {
                        path: '',
                        loadChildren: () =>
                            import('@traas/boldor/common/pages/booking/booking.module').then((module) => module.BookingPageModule),
                    },
                ],
            },
            {
                path: 'tickets',
                children: [
                    {
                        path: '',
                        loadChildren: () =>
                            import('@traas/boldor/common/pages/tickets/tickets.module').then((module) => module.TicketsPageModule),
                    },
                ],
                canActivate: [redirectToLoginOnAllReject(isGuestGuardFnFactory, isAuthenticatedGuardFnFactory)],
            },
            {
                path: 'menu',
                children: [
                    {
                        path: '',
                        loadChildren: () => import('@traas/boldor/company-page').then((module) => module.MenuPageModule),
                    },
                ],
            },
            {
                path: 'debug',
                children: [
                    {
                        path: '',
                        loadComponent: () =>
                            import('@traas/boldor/common/pages/debug-page/debug-page.component').then(
                                (module) => module.DebugPageComponent,
                            ),
                    },
                ],
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class TabsRoutingModule {}
