import { Env } from '@traas/boldor/environments';
import { SentryInitParams } from '@traas/common/logging';

export function mapEnvToSentryInitParams(env: Env): SentryInitParams {
    return {
        clientVersion: env.clientVersion,
        company: env.company,
        currentCommitHash: env.currentCommitHash,
        environment: env.environment,
        gatewayUrl: env.gatewayUrl,
        isDebugMode: env.isDebugMode,
        sentryDsn: env.sentryDsn,
        sentryRelease: env.sentryRelease,
        traceSentry: env.traceSentry,
    };
}
