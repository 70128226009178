import { ComparableError } from './ComparableError';
import { I18nMessage } from '../i18n-message.model';

export class InvalidPlaceError extends ComparableError {
    constructor(
        errorMessage?: string,
        public messages?: I18nMessage,
    ) {
        super(errorMessage);
    }
}
