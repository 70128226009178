import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { AuthenticationApi } from '@traas/common/feature-account';

export const isAuthenticatedGuardFnFactory: () => CanActivateFn = (authenticationService = inject(AuthenticationApi)) => {
    return () => authenticationService.isAuthenticated();
};

export const isAuthenticatedGuardFn: CanActivateFn = (route, state) => {
    const canActivate = isAuthenticatedGuardFnFactory();
    return canActivate(route, state);
};
