@if (linesToShow; as lines) {
  @if (isStartByFoot) {
    <div class="walk-icon">
      <ion-icon src="assets/icons/walk.svg"></ion-icon>
    </div>
    <div class="walk-info">
      <div class="walk-duration">{{ delayOfWalkingLegInMinutes }}'</div>
      <div class="dashed-duration">...</div>
    </div>
  }
  @for (line of lines; track trackById(i, line); let i = $index; let isLast = $last) {
    <span
      [ngClass]="getCompanyFromNetworkId(line.networkId)"
      [class.join-right]="hasRightJoin(i, isLast)"
      [class.dashed-join-right]="hasRightDashedJoin(i)"
      >
      <app-line-icon [line]="line"></app-line-icon>
    </span>
  }
}
