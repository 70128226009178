interface ExpandableItem {
    collapseAll(): void;

    isExpanded(index: number): boolean;

    toggle(mainIndex: number): void;

    setExpandedId(index: number): void;
}

type expandedId = number | string;

export class SelectionHelper implements ExpandableItem {
    #expandedId = '';

    constructor(initialId: expandedId = '') {
        this.setExpandedId(initialId);
    }

    isExpanded(index: expandedId): boolean {
        return this.#normalize(index) === this.#expandedId;
    }

    toggle(id: expandedId): void {
        if (this.isExpanded(id)) {
            this.collapseAll();
        } else {
            this.setExpandedId(id);
        }
    }

    collapseAll(): void {
        this.setExpandedId('');
    }

    setExpandedId(id: expandedId): void {
        this.#expandedId = this.#normalize(id);
    }

    expandOnlyOne(index: expandedId): void {
        if (!this.isExpanded(index)) {
            this.collapseAll();
            this.setExpandedId(index);
        }
    }

    /**
     * both string and numbers can be used but we compare the string value only
     * */
    #normalize(itemId: expandedId): string {
        return `${itemId}`;
    }
}
