import { Inject, Injectable } from '@angular/core';
import { SWISSPASS_SERVICE_TOKEN, SwisspassService } from '../../../features/swisspass/services/SwisspassService';
import { Cart } from '@traas/boldor/all-models';

@Injectable({
    providedIn: 'root',
})
export class PurchaseEmailHelperService {
    constructor(
        @Inject(SWISSPASS_SERVICE_TOKEN)
        protected swisspassService: SwisspassService,
    ) {}

    shouldFetchDefaultEmail(cart: Cart): boolean {
        if (!cart.articleSelections) {
            return false;
        }
        const passengers = cart.articleSelections.map((articleSelection) => articleSelection.passenger);
        const somePassengerHasNoEmail = passengers.some((passenger) => !passenger.email);
        const everyPassengerAreTravelers = passengers.every((passenger) => !passenger.isCustomer);

        return somePassengerHasNoEmail && everyPassengerAreTravelers;
    }

    async fetchDefaultCustomerEmail(): Promise<string | undefined> {
        const customerInfo = await this.swisspassService.getUserInfo();
        return customerInfo?.authenEmail;
    }
}
