import { Inject, Injectable } from '@angular/core';
import { AuthenticationRequiredError, CountryIsoAlpha2, Customer, LanguageCode, Title } from '@traas/boldor/all-models';
import { UserInfo } from '@traas/capacitor/swisspass-plugin';
import { SWISSPASS_SERVICE_TOKEN, SwisspassService } from '../../../features/swisspass/services/SwisspassService';
import { CustomerProviderService } from './customer-provider.service';

@Injectable()
export class SwisspassCustomerProviderService extends CustomerProviderService {
    constructor(
        @Inject(SWISSPASS_SERVICE_TOKEN)
        private swisspassService: SwisspassService,
    ) {
        super();
    }

    #userInfoToCustomer(userInfo: UserInfo): Customer {
        function userInfoCountryToIsoAlpha2(_userInfo: UserInfo): CountryIsoAlpha2 {
            return _userInfo.country === 'Switzerland' ? CountryIsoAlpha2.Switzerland : CountryIsoAlpha2.Unknown;
        }

        function userInfoLanguageToEnum(_userInfo: UserInfo): LanguageCode {
            switch (_userInfo?.displayLanguage) {
                case 'English':
                    return LanguageCode.English;
                case 'French':
                default:
                    return LanguageCode.French;
            }
        }

        function userInfoTitleToEnum(_userInfo: UserInfo): Title {
            if (_userInfo.salutation) {
                return {
                    value: _userInfo.salutation,
                    label: userInfoSalutationToLabel(_userInfo.salutation) ?? undefined,
                };
            }
            return {
                value: '?',
                label: '',
            };
        }

        function userInfoSalutationToLabel(salutation: string): string {
            switch (salutation) {
                case 'FRAU':
                    return 'common.miss';
                case 'HERR':
                    return 'common.mister';
                default:
                    return '';
            }
        }

        return {
            address: {
                street: userInfo.street ?? '',
                zip: userInfo.zip ?? '',
                city: userInfo.city ?? '',
                country: userInfoCountryToIsoAlpha2(userInfo),
            },
            birthDate: userInfo.birthdate ?? '',
            email: userInfo.authenEmail ?? '',
            firstName: userInfo.firstName ?? '',
            id: userInfo.userId ?? '',
            language: userInfoLanguageToEnum(userInfo),
            lastName: userInfo.lastName ?? '',
            phoneNumber: userInfo.contactMobilePhoneNumber ?? '',
            title: userInfoTitleToEnum(userInfo),
            passengerId: '',
        };
    }

    async getCustomer(): Promise<Customer> {
        const userInfo = await this.swisspassService.getUserInfo();
        if (!userInfo) {
            throw new AuthenticationRequiredError('User info retrieved by swisspassService is null.');
        }

        return this.#userInfoToCustomer(userInfo);
    }

    async updateCustomer(): Promise<void> {
        await this.swisspassService.openAccountManagementPage();
    }
}
