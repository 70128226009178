import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    inject,
    Input,
    NgZone,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { JourneyMessage, LineStyle } from '@traas/boldor/all-models';
import { BoldorLocalizationService } from '@traas/boldor/localization';
import { ReplaySubject } from 'rxjs';
import { LegAdapter } from '../../../../models/itinerary/leg';
import { StopAdapter } from '../../../../models/itinerary/stop';
import { CompanyService } from '@traas/boldor/company';
import { LineService } from '../../../../services/common/line/line.service';
import {
    getStopIconDetailName,
    getThermoStopIconName,
    isOptionalStop,
    isTrain,
    UNKNOWN_LINE_BACKGROUND_COLOR,
} from '@traas/boldor/business-rules';
import { ItineraryThermometerDrawerService } from '../../services/itinerary-thermometer-drawer.service';
import { JourneyMessageService } from '../../../../services/common/journey-message.service';
import { SvgAnchor } from '../svg-anchor.model';

@Component({
    selector: 'app-itinerary-leg-transport',
    templateUrl: './itinerary-leg-transport.component.html',
    styleUrls: ['./itinerary-leg-transport.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItineraryLegTransportComponent implements OnInit, AfterViewInit, SvgAnchor {
    @ViewChild('legPathStart', { read: ElementRef }) legPathStart: ElementRef;
    @ViewChild('legPathEnd', { read: ElementRef }) legPathEnd: ElementRef;
    @ViewChild('container') container: ElementRef;

    @Input({ required: true }) leg!: LegAdapter;

    @Output() arrivalClicked = new EventEmitter<StopAdapter>();
    @Output() departureClicked = new EventEmitter<StopAdapter>();
    @Output() transportClicked = new EventEmitter<void>();
    @Output() warningClicked = new EventEmitter<StopAdapter>();

    $stopInformation = new ReplaySubject<string>();

    arrivalStop: StopAdapter;
    arrivalThermoStopIcon: string;
    arrivalTrack: string | undefined;
    delayOfLeg: string;
    departureStop: StopAdapter;
    departureThermoStopIcon: string;
    hasDisruptionOnArrival: boolean;
    hasDisruptionOnDeparture: boolean;
    lineBackgroundColor: string;
    lineStyle: LineStyle;
    lineStyleClass: string;
    track: string | undefined;
    transportIconName: string;
    departureStopIconPathItinerary: string;
    arrivalStopIconPathItinerary: string;
    isTrainLeg: boolean;
    readonly isTpg = CompanyService.isTPG();
    readonly isTPC = CompanyService.isTPC();
    cancellationMessageByStops: {
        firstStop: JourneyMessage | null;
        lastStop: JourneyMessage | null;
    };
    lineCancellationMessage: JourneyMessage | null;

    #ngZone = inject(NgZone);
    #localizationService = inject(BoldorLocalizationService);
    #itineraryThermometerDrawerService = inject(ItineraryThermometerDrawerService);
    #journeyMessageService = inject(JourneyMessageService);
    #lineService = inject(LineService);

    ngAfterViewInit(): void {
        this.#drawLegPathWhenReady();
    }

    getEndPoint(): any {
        return this.legPathEnd.nativeElement;
    }

    getStartPoint(): any {
        return this.legPathStart.nativeElement;
    }

    async ngOnInit(): Promise<void> {
        const line = this.leg.getLine();
        this.lineStyleClass = line.style;
        this.lineBackgroundColor = line.color ?? UNKNOWN_LINE_BACKGROUND_COLOR;
        this.lineStyle = this.#lineService.from(this.leg);

        const firstStop = this.leg.getFirstStop();
        const firstStopAdapter = new StopAdapter(firstStop);
        const lastStop = this.leg.getLastStop();
        const lastStopAdapter = new StopAdapter(lastStop);

        this.track = firstStopAdapter.getTrack();
        this.arrivalTrack = lastStopAdapter.getTrack();
        this.hasDisruptionOnDeparture = this.#journeyMessageService.containsDisruptionMessagesInItineraryStop(firstStopAdapter.getData());
        this.hasDisruptionOnArrival = this.#journeyMessageService.containsDisruptionMessagesInItineraryStop(lastStopAdapter.getData());

        this.departureStop = new StopAdapter(firstStop);
        this.arrivalStop = new StopAdapter(lastStop);

        this.transportIconName = this.leg.getVehicle().transport;
        this.delayOfLeg = this.#computeDelayOfLeg();
        this.isTrainLeg = isTrain(this.leg.getTransportName());
        const isDepartureList = false;
        this.#loadStopIconsAndCssClasses();

        this.departureStopIconPathItinerary = getStopIconDetailName(
            firstStop.hasBookingRequirements,
            firstStop.isOptional,
            this.isTrainLeg,
            isDepartureList,
            true,
        );
        this.arrivalStopIconPathItinerary = getStopIconDetailName(
            lastStop.hasBookingRequirements,
            lastStop.isOptional,
            this.isTrainLeg,
            isDepartureList,
            false,
        );

        if (this.leg.getLastStop().hasBookingRequirements) {
            const title = await this.#localizationService.get('tpc.booking-requirements.explanation.on-demand-stop.title');
            const content = await this.#localizationService.get('tpc.booking-requirements.explanation.on-demand-stop.content');
            this.$stopInformation.next(`${title} ${content}`);
        } else if (isOptionalStop(this.leg.getLastStop().isOptional, this.isTrainLeg)) {
            const title = await this.#localizationService.get('tpc.booking-requirements.explanation.optional-stop.title');
            const content = await this.#localizationService.get('tpc.booking-requirements.explanation.optional-stop.content');
            this.$stopInformation.next(`${title} ${content}`);
        }

        this.cancellationMessageByStops = {
            firstStop: this.#journeyMessageService.getFormattedCancellationMessagesOfItineraryStop(this.leg.getFirstStop()),
            lastStop: this.#journeyMessageService.getFormattedCancellationMessagesOfItineraryStop(this.leg.getLastStop()),
        };
        this.lineCancellationMessage = this.#journeyMessageService.getFormattedCancellationMessageOfLeg(this.leg.getData());
    }

    onWarningClicked(event: Event, stop: StopAdapter): void {
        event.preventDefault();
        event.stopPropagation();
        this.warningClicked.emit(stop);
    }

    onDepartureClicked(stop: StopAdapter): void {
        this.departureClicked.emit(stop);
    }

    onTransportClicked(): void {
        this.transportClicked.emit();
    }

    onArrivalClicked(stop: StopAdapter): void {
        this.arrivalClicked.emit(stop);
    }

    #computeDelayOfLeg(): string {
        const duration = this.leg.getDuration();
        const minutes = duration.minutes();
        if (duration.hours() > 0) {
            return `${duration.hours()}h${minutes < 10 ? `0${minutes}` : minutes}`;
        }
        if (duration.seconds() > 0 || duration.milliseconds() > 0) {
            return `${minutes + 1} MIN`;
        }
        return `${minutes} MIN`;
    }

    #loadStopIconsAndCssClasses(): void {
        const firstStop = this.leg.getFirstStop();
        this.departureThermoStopIcon = getThermoStopIconName(firstStop.hasBookingRequirements, firstStop.isOptional, this.isTrainLeg);

        const lastStop = this.leg.getLastStop();
        this.arrivalThermoStopIcon = getThermoStopIconName(lastStop.hasBookingRequirements, lastStop.isOptional, this.isTrainLeg);
    }

    #drawLegPathWhenReady(): void {
        let intervalId: any;
        const MAX_TIME_MS = 2000;
        let elapsedTime = 0;

        this.#ngZone.runOutsideAngular(() => {
            intervalId = setInterval(() => {
                elapsedTime += 10;

                const isReadyToDraw = this.#itineraryThermometerDrawerService.isReadyToDraw(
                    this.legPathStart.nativeElement,
                    this.legPathEnd.nativeElement,
                    this.container,
                );

                if (isReadyToDraw) {
                    clearInterval(intervalId);
                    this.#ngZone.run(() => {
                        this.#itineraryThermometerDrawerService.drawTransportLegPathBetween(
                            this.legPathStart.nativeElement,
                            this.legPathEnd.nativeElement,
                            this.container,
                            {
                                lineBackgroundColor: this.lineBackgroundColor,
                                fallbackLineStyle: this.lineStyleClass,
                                formattedDelayOfLeg: this.delayOfLeg,
                                withBorder: !this.isTpg, //is dark theme serait plus approprié
                            },
                        );
                    });
                    return;
                }

                // Si le temps maximal est atteint, arrêter la boucle
                if (elapsedTime >= MAX_TIME_MS) {
                    clearInterval(intervalId);
                    console.warn('Time limit reached, stopping the loop to draw itinerary path.');
                    return;
                }
            }, 10);
        });
    }
}
