import * as L from 'leaflet';
import { DivIcon, DivIconOptions } from 'leaflet';
import { Observable } from 'rxjs';
import { ChangePlaceEventSourceEnum, Place, PlaceStop, TraasGeolocationCoordinates } from '@traas/boldor/all-models';
import { DynamicPlaceAdapter } from './dynamic-place.adapter';

export class StopDynamicPlaceAdapter extends DynamicPlaceAdapter<Place<PlaceStop>> {
    readonly #changePlaceSourceEvent = ChangePlaceEventSourceEnum.StopSelection;

    constructor(data: Place<PlaceStop>) {
        super(data);
    }

    override getChangePlaceEvent(): ChangePlaceEventSourceEnum {
        return this.#changePlaceSourceEvent;
    }

    override getDivIcon(iconOptions: DivIconOptions = {}): DivIcon {
        return L.divIcon({
            html: `
                <div class="place-focus">
                </div>
            `,
            className: `ni-cluster`,
            iconSize: [10, 10],
            popupAnchor: [-10 / 2, -10 / 2],
            ...iconOptions,
        });
    }

    override getEndpointIconName(): string {
        return super.getEndpointIconName();
    }

    override isDrawOnMapRequired(): boolean {
        return true;
    }

    override isDrawOnEndpointRequired(): boolean {
        return true;
    }

    override isNeededToFitMap(): boolean {
        return true;
    }

    override $getHtmlLeafletIcon(): Observable<string> {
        return super.$getHtmlLeafletIcon();
    }

    override transformToCoordinates(): TraasGeolocationCoordinates {
        return this.getData().transformToCoordinates();
    }
}
