import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-offline-page',
    templateUrl: './offline-page.component.html',
    styleUrls: ['./offline-page.component.scss'],
})
export class OfflinePageComponent {
    @Input({ required: true }) message!: string;
}
