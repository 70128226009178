import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NearestStop, TicketViewModel } from '@traas/boldor/all-models';
import { CompanyService } from '@traas/boldor/company';
import { ArticleAdapter } from '../../../../models/article';

@Component({
    selector: 'app-ticket-list-item',
    templateUrl: './ticket-list-item.component.html',
    styleUrls: ['./ticket-list-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TicketListItemComponent implements OnInit {
    @Input({ required: true }) nearestStop!: NearestStop | null;
    @Input({ required: true }) isLastChild!: boolean;
    @Input({ required: true }) ticket!: TicketViewModel;

    @Output() ticketBuy = new EventEmitter<string>();
    @Output() buyTicketByZone = new EventEmitter<TicketViewModel>();

    readonly isTpg = CompanyService.isTPG();
    hasOnlyPaymentBySmsAllowed = false;
    code = '';
    description = '';
    prices: string[] = [];
    title = '';

    ngOnInit(): void {
        const article = this.ticket.article;
        const articleAdapter = new ArticleAdapter(article);
        this.code = this.ticket.article.smsCode;
        this.description = articleAdapter.getDescription();
        this.prices = articleAdapter.getPrices();
        this.title = this.#getTitle(articleAdapter);
        this.hasOnlyPaymentBySmsAllowed = articleAdapter.isPaymentBySmsAllowed() && !articleAdapter.isPaymentByCreditCardAllowed();
    }

    buy(): void {
        this.ticketBuy.emit(this.code);
        this.buyTicketByZone.emit(this.ticket);
    }

    #getTitle(articleAdapter: ArticleAdapter): string {
        return !this.isTpg && this.nearestStop ? `De: ${this.nearestStop.stopName}` : articleAdapter.getTitle();
    }
}
