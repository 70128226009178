import { subSeconds } from 'date-fns';

export const ONE_SECOND_IN_MS = 1000;
export const ONE_MINUTE_IN_SECONDS = 60;
export const ONE_HOUR_IN_MINUTES = 60;
export const NINETY_SECONDS = 90;
export const ONE_MINUTE_IN_MS = ONE_SECOND_IN_MS * ONE_MINUTE_IN_SECONDS;
export const ONE_DAY_IN_HOURS = 24;

export function subtractOneSecond(aDate: Date): Date {
    return subSeconds(aDate, 1);
}
