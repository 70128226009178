import { Injectable } from '@angular/core';
import {
    ActionItem,
    Place,
    PlaceAddress,
    PlaceHistory,
    PlaceStop,
    PlacesTypeInterface,
    Poi,
    SearchHistoryStorage,
    SearchPlacesResponse,
} from '@traas/boldor/all-models';
import { AddressAdapter } from '../adapters/address';
import { PoiAdapter } from '../adapters/poi';
import { SearchPlacesResponseAdapter } from '../adapters/search-places-response';
import { StopAdapter } from '../adapters/stop';
import { LoggingService } from '@traas/common/logging';
import { flatten } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActionItemAdapter } from '../adapters/action-item';
import { GetPlacesGQL, GetPlacesQuery, GetPlacesQueryVariables } from '@traas/boldor/graphql-generated/graphql';
import { ObservableTypedStorage } from '@traas/common/utils';

const HISTORY_MAX_LENGTH = 6;

@Injectable()
export class PlaceService {
    constructor(
        private logger: LoggingService,
        private searchHistoryStorage: ObservableTypedStorage<SearchHistoryStorage>,
        private getPlacesGQL: GetPlacesGQL,
    ) {}

    createActionItem(actionItem: PlaceHistory & ActionItem): Place {
        return this.#createAdapterFrom(actionItem);
    }

    async clearHistory(): Promise<void> {
        await this.searchHistoryStorage.removeItem('searchHistory');
    }

    $searchByTerm(term: string, currentLatLng: { latitude: number; longitude: number }): Observable<SearchPlacesResponseAdapter> {
        const variable: GetPlacesQueryVariables = {
            searchQueryInput: {
                term,
                currentLatLng,
            },
        };
        const $response = this.getPlacesGQL.fetch(variable);
        return $response.pipe(
            map((response) => {
                const searchPlacesResponse = this.#gqlToSearchPlaceResponse(response.data);
                return new SearchPlacesResponseAdapter(searchPlacesResponse);
            }),
        );
    }

    async addPlaceInHistory(place: Place<any>): Promise<void> {
        if (place.isGpsPosition()) {
            return;
        }

        const placesHistory = await this.getPlacesHistory();
        const placesWithoutPlaceToAdd: Place<any>[] = placesHistory.filter((aPlace) => place.getId() !== aPlace.getId());
        const placesHistoryData: Place<any>[] = placesWithoutPlaceToAdd.map((placeHistory) => {
            return { ...placeHistory.getData(), _placeType: placeHistory.getPlaceType() };
        });
        const historyDataToAdd = {
            ...place.getData(),
            _placeType: place.getPlaceType(),
        };
        const updatedPlacesHistoryData: Place<any>[] = [...placesHistoryData, historyDataToAdd].slice(-HISTORY_MAX_LENGTH);
        await this.searchHistoryStorage.setItem('searchHistory', updatedPlacesHistoryData);
    }

    async getPlacesHistory(): Promise<Place[]> {
        if (await this.searchHistoryStorage.hasItem('searchHistory')) {
            const placesHistory: PlaceHistory[] = await this.searchHistoryStorage.getItem('searchHistory');
            return placesHistory.map((place) => {
                return this.#createAdapterFrom(place);
            });
        }
        return [];
    }

    async getReversedPlacesHistory(): Promise<Place[]> {
        const placesHistory = await this.getPlacesHistory();
        return placesHistory.reverse();
    }

    getOrderedPlacesByTermScoring(term: string, places: SearchPlacesResponseAdapter): Place[] {
        if (!term) {
            return this.#getDefaultPlacesOrder(places);
        }
        if (this.#hasSpace(term)) {
            return this.#getPlacesOrderedByTermWithSpace(term, places);
        }
        return this.#getPlacesOrderedByTermWithoutSpace(term.trim(), places);
    }

    #getPlacesOrderedByTermWithoutSpace(term: string, places: SearchPlacesResponseAdapter): Place[] {
        const placesOrdered: Place[] = [];
        const citiesContainingTerm = this.#getPlaceHavingTermMatchingInName(term, places.getCities());
        const citiesAdapterContainingTerm = places.getCities().filter((city) => {
            return citiesContainingTerm.some((cityPlace) => cityPlace.getName() === city.getName());
        });
        const mainStopsOfCitiesContainingTerm = citiesAdapterContainingTerm.map((city) => city.getAdaptedMainStops());
        const flattenedMainStopsOfCities = flatten(mainStopsOfCitiesContainingTerm);
        const mainStopsIds = flattenedMainStopsOfCities.map((stop) => stop.getId());
        const placesStopsFilteredForDuplicates = places.getStops().filter((stop) => {
            const isDuplicate = mainStopsIds.includes(stop.getId());
            return !isDuplicate;
        });

        placesOrdered.push(...flattenedMainStopsOfCities);
        placesOrdered.push(...places.getBookmarksWithPhysicalStopAdapters());
        placesOrdered.push(...placesStopsFilteredForDuplicates);
        placesOrdered.push(...places.getPois());
        placesOrdered.push(...places.getAddress());
        placesOrdered.push(...places.getCustomPlaces());
        return placesOrdered;
    }

    #getPlacesOrderedByTermWithSpace(term: string, places: SearchPlacesResponseAdapter): Place[] {
        const placesOrdered: Place[] = [];
        const stops = places.getStops();
        const stopsContainingTerm = this.#getPlaceHavingTermMatchingInName(term, stops);
        const otherStops = this.#getPlacesWithout(stops, stopsContainingTerm);

        const adresses = places.getAddress();
        const addressContainingTerm = this.#getPlaceHavingTermMatchingInName(term, adresses);
        const otherAddress = this.#getPlacesWithout(adresses, addressContainingTerm);

        placesOrdered.push(...stopsContainingTerm);
        placesOrdered.push(...addressContainingTerm);
        // placesOrdered.push(...places.getBookmarksWithPhysicalStopAdapters());
        placesOrdered.push(...otherStops);
        placesOrdered.push(...places.getPois());
        placesOrdered.push(...otherAddress);
        placesOrdered.push(...places.getCustomPlaces());
        return placesOrdered;
    }

    #hasSpace(data: string): boolean {
        return data.includes(' ');
    }

    #getPlacesWithout(places: Place[], toExclude: Place[]): Place[] {
        if (toExclude.length === 0) {
            return places;
        }

        return places.filter((place) => {
            const normalizedName = this.#normalizeStr(place.getName());
            return !toExclude.some((placeToExclude) => {
                const normalizedNameToExclude = this.#normalizeStr(placeToExclude.getName());
                return normalizedNameToExclude === normalizedName;
            });
        });
    }

    #getPlaceHavingTermMatchingInName(term: string, places: Place[]): Place[] {
        const placeMatching: Place[] = [];
        const normalizedTerm = this.#normalizeStr(term);
        for (const place of places) {
            const normalizedName = this.#normalizeStr(place.getName());
            if (normalizedName.includes(normalizedTerm)) {
                placeMatching.push(place);
            }
        }
        return placeMatching;
    }

    #normalizeStr(str: string): string {
        try {
            return str
                .toLowerCase()
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '');
        } catch (error) {
            this.logger.logLocalError(error);
            return '';
        }
    }

    #gqlToSearchPlaceResponse(getPlacesQuery: GetPlacesQuery): SearchPlacesResponse {
        const responses = getPlacesQuery.places.search;
        return {
            ...responses,
            address: responses.address.map((address) => {
                return {
                    ...address,
                    latLon: [address.latLon[0], address.latLon[1]],
                };
            }),
            cities: responses.cities.map((city) => {
                return {
                    ...city,
                    mainStops: city.mainStops.map((mainStop) => {
                        return {
                            ...mainStop,
                            latLon: [mainStop.latLon[0], mainStop.latLon[1]],
                        };
                    }),
                };
            }),
            stops: responses.stops.map((stop) => {
                return {
                    ...stop,
                    latLon: [stop.latLon[0], stop.latLon[1]],
                };
            }),
            bookmarks: [],
        };
    }

    #getDefaultPlacesOrder(places: SearchPlacesResponseAdapter): Place[] {
        const placesOrdered: Place[] = [];
        // placesOrdered.push(...places.getBookmarksWithPhysicalStopAdapters());
        placesOrdered.push(...places.getStops());
        placesOrdered.push(...places.getPois());
        placesOrdered.push(...places.getAddress());
        placesOrdered.push(...places.getCustomPlaces());
        return placesOrdered;
    }

    #getTypeOfPlace(place: PlaceHistory): keyof PlacesTypeInterface {
        return place._placeType;
    }

    #createAdapterFrom(place: PlaceHistory): Place {
        const placeType = this.#getTypeOfPlace(place);
        switch (placeType) {
            case 'stop':
                return new StopAdapter(place as any as PlaceStop).createStopPlacesHistory();
            case 'address':
                return new AddressAdapter(place as any as PlaceAddress).createAddressPlacesHistory();
            case 'poi':
                return new PoiAdapter(place as any as Poi).createPoiPlacesHistory();
            case 'actionItem':
                return new ActionItemAdapter(place as any as ActionItem).createActionItem();
            default:
                throw new Error(`Unknown behavior to this place type ${placeType}`);
        }
    }
}
