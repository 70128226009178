import {
    AbstractAdapter,
    Area,
    Bookmark,
    isBookmarkArea,
    isStopArea,
    Place,
    PlacesTypeInterface,
    TraasGeolocationCoordinates,
    YOUR_LOCATION_I18N_KEY,
} from '@traas/boldor/all-models';
import { createLocationOf } from '@traas/boldor/business-rules';
import * as _ from 'lodash';

export class BookmarkAdapter extends AbstractAdapter<Bookmark> implements Place<Bookmark> {
    // This is used to don't push the favorite clicked in history list (because is it already in favorite list)
    isFromHistory = true;

    constructor(data: Bookmark | undefined = undefined) {
        super(data as Bookmark);
    }

    transformToCoordinates(): TraasGeolocationCoordinates {
        return null;
    }

    getNameOrPath(): string {
        return 'star';
    }

    getId(): string {
        return this.data.metadata.locationName.toLowerCase().trim();
    }

    getName(): string {
        return this.data.metadata.locationName;
    }

    isEqual(otherBookmark: BookmarkAdapter): boolean {
        return (
            _.isEqual(new Set(this.getData().stops?.id), new Set(otherBookmark.getData().stops?.id)) ||
            _.isEqual(new Set(this.getData().stops.didok), new Set(otherBookmark.getData().stops?.didok))
        );
    }

    getPlaceType(): keyof PlacesTypeInterface {
        return 'bookmark';
    }

    isGpsPosition(): boolean {
        return false;
    }

    isEmpty(): boolean {
        return !this.getData();
    }
}

export function createBookmark(area: Area): Bookmark {
    const locationNames = createLocationOf(area);

    if (isBookmarkArea(area)) {
        return {
            boundsRect: area.boundsRect,
            metadata: { ...area.metadata, locationName: locationNames ? locationNames : YOUR_LOCATION_I18N_KEY },
            stops: area.originalBookmark.stops,
        };
    } else if (isStopArea(area)) {
        const didoks = _.uniq(
            area.physicalStops.map((stop) => stop.associatedCommercialStop?.didok).filter((didok): didok is number => !!didok),
        );
        const ids = _.uniq(area.physicalStops.map((stop) => stop.associatedCommercialStop?.id).filter((id): id is string => !!id));
        return {
            boundsRect: area.boundsRect,
            metadata: { ...area.metadata, locationName: locationNames ? locationNames : YOUR_LOCATION_I18N_KEY },
            stops: {
                didok: didoks,
                id: ids,
            },
        };
    }
    return {
        boundsRect: area.boundsRect,
        metadata: { ...area.metadata, locationName: locationNames ? locationNames : YOUR_LOCATION_I18N_KEY },
        stops: {
            didok: [],
            id: [],
        },
    };
}
