import { Injectable } from '@angular/core';
import { ColorModeOptions, CreditCard, Currency } from '@traas/boldor/all-models';
import * as Gql from '@traas/boldor/graphql-generated/graphql';
import {
    GetCreditCardsGQL,
    GetCreditCardsQueryVariables,
    RemoveCreditCardGQL,
    RemoveCreditCardMutationVariables,
} from '@traas/boldor/graphql-generated/graphql';
import { ServiceToGql } from '@traas/boldor/all-helpers';
import { ICON_BASE_PATH } from '@traas/common/business-rules';
import { PreferencesService } from '@traas/boldor/common/services/common/preferences/preferences.service';
import { firstValueFrom } from 'rxjs';

export const ICONS_PATH = `${ICON_BASE_PATH}/payment-methods/bitmap/bloc_`;
export const CREDIT_CARD_BASE_PATH = `${ICON_BASE_PATH}/payment-methods/vector/`;

@Injectable({ providedIn: 'root' })
export class CreditCardsService {
    colorMode: ColorModeOptions;

    constructor(
        private getCreditCardsGQL: GetCreditCardsGQL,
        private removeCreditCardGQL: RemoveCreditCardGQL,
        private preferencesService: PreferencesService,
    ) {
        preferencesService.getColorModeOption$().subscribe((colorMode) => (this.colorMode = colorMode));
    }

    static convertCurrency(aCurrency: Currency): Gql.Currency {
        return ServiceToGql.currency(aCurrency);
    }

    async getCreditCards(currency: Currency): Promise<CreditCard[]> {
        const variables: GetCreditCardsQueryVariables = {
            currency: CreditCardsService.convertCurrency(currency),
            onlyValid: true,
        };
        const result = await firstValueFrom(this.getCreditCardsGQL.fetch(variables));
        const creditCards = result.data.customer.creditCards;
        return creditCards.map((creditCard) => this.#convertCreditCard(creditCard));
    }

    async removeCreditCard(creditCardId: string): Promise<boolean> {
        const currency = await this.preferencesService.getCurrency();
        const variables: RemoveCreditCardMutationVariables = {
            creditCardId,
            currency: CreditCardsService.convertCurrency(currency),
        };

        const result = await firstValueFrom(this.removeCreditCardGQL.mutate(variables));
        const removeCreditCard = result.data?.customer.removeCreditCard;
        if (!removeCreditCard) {
            return false;
        }
        return removeCreditCard.success === true;
    }

    getCreditCardIconPath(creditCardBrand: string): string {
        const supportedIcons = ['mastercard', 'visa', 'maestro', 'americanexpress', 'postfinance', 'dinersclub'];
        const sanitizedCreditCardBrand = creditCardBrand.toLowerCase().replace(/\s/g, '');
        return this.#computeIconPath(CREDIT_CARD_BASE_PATH, supportedIcons, sanitizedCreditCardBrand);
    }

    getCardPaymentMethodIconPath(creditCard: string): string {
        const supportedBrandIcons = ['mastercard', 'visa', 'maestro', 'postfinance', 'amex', 'diners'];
        return this.#computeIconPath(ICONS_PATH, supportedBrandIcons, creditCard);
    }

    getMobilePaymentMethodIconPath(mobileMethod: string): string {
        const supportedMobileIcons = ['googlepay', 'applepay', 'twint'];
        return this.#computeIconPath(ICONS_PATH, supportedMobileIcons, mobileMethod);
    }

    #computeIconPath(basePath: string, supportedIcons: string[], paymentMethod: string): string {
        const foundIndex = supportedIcons.indexOf(paymentMethod.toLowerCase());
        if (foundIndex !== -1) {
            const isPaymentMethodIcon = basePath === ICONS_PATH;
            const colorModeSuffix = isPaymentMethodIcon && this.colorMode === ColorModeOptions.Dark ? '_dark' : '';
            return `${basePath}${supportedIcons[foundIndex]}${colorModeSuffix}.svg`;
        }
        return `${CREDIT_CARD_BASE_PATH}credit-card.svg`;
    }

    #convertCreditCard(creditCard: Gql.GetCreditCardsQuery['customer']['creditCards'][number]): CreditCard {
        return {
            id: creditCard.id,
            brand: creditCard.brand,
            maskedNumber: creditCard.maskedNumber,
            expirationYear: creditCard.expirationYear,
            expirationMonth: creditCard.expirationMonth,
            primary: creditCard.primary,
            countryCode: creditCard.countryCode,
            holderName: creditCard.holderName,
            aliasId: creditCard.gatewayReference.reference ?? undefined,
        };
    }
}
