import { NgModule } from '@angular/core';
import { PageTitleModule } from '../../../../components/page-title/page-title.module';
import { BoldorFeatureShellSharedModule } from '../../../../boldor-feature-shell-shared.module';
import { ItineraryDetailsComponent } from './itinerary-details';
import { BookingModule } from '../../../booking/booking.module';
import { ItineraryModule } from '../../itinerary.module';
import { ItinerariesListHeaderLabelComponent } from '../../containers/itineraries/itineraries-list-header-label.component';

@NgModule({
    declarations: [ItineraryDetailsComponent],
    imports: [BoldorFeatureShellSharedModule, BookingModule, ItineraryModule, PageTitleModule, ItinerariesListHeaderLabelComponent],
    exports: [ItineraryDetailsComponent],
})
export class ItineraryDetailsModule {}
