import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { BackButtonDirective } from './back-button.directive';

@Injectable({
    providedIn: 'root',
})
export class BackButtonService {
    #backButtonStack: BackButtonDirective[] = [];

    addOnTop(directive: BackButtonDirective): void {
        this.#backButtonStack.push(directive);
    }

    remove(directive: BackButtonDirective): void {
        this.#backButtonStack = _.without(this.#backButtonStack, directive);
    }

    isOnTop(directive: BackButtonDirective): boolean {
        return directive === _.last(this.#backButtonStack);
    }
}
