@if (!ticket.isProcessing) {
  <ion-row class="ion-align-items-center ion-padding-top ion-padding-bottom">
    <ion-col>
      <div class="ticket-title-grid">
        <ion-icon class="operation-icon" src="assets/icons/tabs/monochrome/tickets.svg"></ion-icon>
        <p class="ion-no-margin">
          {{ ticket.article.title }}
        </p>
      </div>
      <ion-row class="ticket-passenger-grid">
        <ion-icon name="{{ getPassengerTypeIconName(ticket.passengerType) }}"></ion-icon>
        <p class="ion-no-margin">{{ ticket.passenger?.firstName }} {{ ticket.passenger?.lastName }}</p>
      </ion-row>
    </ion-col>
    <ion-col size="4" class="price">
      <p class="ion-no-margin" [class.strikethrough]="isTppEligible(ticket)">
        {{ getFormattedPrice(ticket.article.prices[0]) }}
      </p>
      @if (isTppEligible(ticket)) {
        <span class="third-party-payer">
          Pris en charge par {{ getTppName(ticket) }}
        </span>
      }
    </ion-col>
  </ion-row>
}
