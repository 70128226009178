import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { TimeDisplayMode } from '@traas/boldor/all-models';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { RadioGroupCustomEvent } from '@ionic/core';
import { PreferencesService } from '@traas/boldor/common/services/common/preferences/preferences.service';
import { AnalyticsService } from '@traas/common/analytics';
import { AnalyticsHelper } from '@traas/boldor/all-helpers';

@Component({
    selector: 'traas-time-display-mode-modal',
    standalone: true,
    imports: [IonicModule, TranslateModule],
    template: `
        <ion-modal class="time-display-modal" [trigger]="trigger">
            <ng-template>
                <div class="ion-padding">
                    <h5>{{ 'time-display-mode-popover.title' | translate }}</h5>

                    @if (timeDisplayMode) {
                        <ion-list lines="none">
                            <ion-radio-group [value]="timeDisplayMode" (ionChange)="setTimeDisplayMode($event)">
                                <ion-item>
                                    <ion-radio
                                        justify="start"
                                        value="end"
                                        labelPlacement="end"
                                        [value]="TimeDisplayMode.Absolute"
                                        class="e2e-hour-format"
                                        >{{ 'time-display-mode-popover.hour-mode' | translate }}
                                    </ion-radio>
                                    <br />
                                </ion-item>

                                <ion-item>
                                    <ion-radio
                                        justify="start"
                                        value="end"
                                        labelPlacement="end"
                                        [value]="TimeDisplayMode.Relative"
                                        class="e2e-waiting-time-format"
                                        >{{ 'time-display-mode-popover.waiting-time-mode' | translate }}
                                    </ion-radio>
                                    <br />
                                </ion-item>
                            </ion-radio-group>
                        </ion-list>
                    }
                </div>
            </ng-template>
        </ion-modal>
    `,
    styles: `
        .time-display-modal {
            --width: fit-content;
            --min-width: 250px;
            --height: fit-content;
            --border-radius: 6px;
            --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeDisplayModeModalComponent {
    @Input({ required: true }) trigger!: string;
    #preferencesService = inject(PreferencesService);
    #analyticsService = inject(AnalyticsService);
    #analyticsHelper = inject(AnalyticsHelper);

    protected readonly TimeDisplayMode = TimeDisplayMode;
    timeDisplayMode: TimeDisplayMode | undefined = undefined;

    constructor() {
        this.#preferencesService.getTimeDisplayMode().then((timeDisplay) => {
            this.timeDisplayMode = timeDisplay;
        });
    }

    setTimeDisplayMode(event: RadioGroupCustomEvent<TimeDisplayMode>): void {
        const newValue = event.detail.value;
        this.timeDisplayMode = newValue;
        this.#analyticsService.reportEvent(this.#analyticsHelper.getEventForTimeDisplayModeSwitch(newValue));
        this.#analyticsService.sendTimeDisplayMode(this.#analyticsHelper.getUserPropertyValueForTimeDisplayMode(newValue));
        void this.#preferencesService.setTimeDisplay(newValue);
    }
}
