<div #container class="container">
    @for (stop of stopsAdapter; track stop.getId(); let even = $even) {
        <app-itinerary-leg-stop-list-item
            #item
            [isTrain]="isTrainLeg"
            [stop]="stop"
            [isLast]="false"
            [isFirst]="false"
            [ngClass]="even ? 'odd' : 'even'"
            (click)="onItemClicked(stop)"
        >
        </app-itinerary-leg-stop-list-item>
    }
</div>
