import { BreadcrumbHint } from '@sentry/angular-ivy';
import { Breadcrumb } from '@sentry/capacitor';

type ParsedGraphQLInfo = {
    operationType: string | null;
    operationName: string | null;
};

type ParsedGraphQLResponse = {
    data: any;
    extensions: {
        correlationId: string;
    };
    errors?: any;
};

export class SentryBreadcrumbsUtils {
    static isGraphqlBreadcrumb(breadcrumb: Breadcrumb): boolean {
        return breadcrumb.category === 'xhr' && breadcrumb.data?.['url'].includes('/graphql');
    }

    static isSvgAssetsLoadingBreadcrumb(breadcrumb: Breadcrumb): boolean {
        return breadcrumb.category === 'fetch' && breadcrumb.data?.['url'].includes('.svg');
    }

    static setGraphQLDataOnBreadcrumb(breadcrumb: Breadcrumb, hint: BreadcrumbHint): void {
        const gqlInfo = this.#parseGraphQLInfo(hint);
        breadcrumb.data = breadcrumb.data ?? {};
        breadcrumb.data['operation'] = `${gqlInfo.operationType ? gqlInfo.operationType : ''} ${
            gqlInfo.operationName ? gqlInfo.operationName : ''
        }`;

        const graphQLResponse = this.#parseGraphQLResponse(hint);
        breadcrumb.data['correlationId'] = graphQLResponse?.extensions.correlationId;
        breadcrumb.level = graphQLResponse?.errors ? 'error' : breadcrumb.level;
    }

    static #parseGraphQLInfo(hint: BreadcrumbHint): ParsedGraphQLInfo {
        try {
            const payload = JSON.parse(hint['input']);
            const operationTypeMatch = payload.query.trim().match(/^(query|mutation)/);
            const operationType = operationTypeMatch[1] ?? 'Unkown operation type';

            return {
                operationType,
                operationName: payload.operationName,
            };
        } catch (error) {
            console.error('Error parsing GraphQL info:', error);
            return {
                operationType: null,
                operationName: null,
            };
        }
    }

    static #parseGraphQLResponse(hint: BreadcrumbHint): ParsedGraphQLResponse | undefined {
        try {
            return JSON.parse(hint['xhr']?.['response']);
        } catch (error) {
            console.error('Error parsing graphql response:', error);
            return undefined;
        }
    }
}
