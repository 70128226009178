import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { getPassengerTypeIconName } from '@traas/boldor/business-rules';
import { PassengerType } from '@traas/boldor/graphql-generated/graphql';
import { getFormattedPriceFromPrice } from '@traas/boldor/all-helpers';
import { Price, Ticket } from '@traas/boldor/all-models';

@Component({
    selector: 'app-ticket-resume',
    templateUrl: './ticket-resume.component.html',
    styleUrls: ['./ticket-resume.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TicketResumeComponent {
    @Input({ required: true }) ticket!: Ticket;

    getFormattedPrice(price: Price): string {
        return getFormattedPriceFromPrice(price, false, false);
    }

    getPassengerTypeIconName(passengerType: PassengerType | undefined): string {
        return getPassengerTypeIconName(passengerType);
    }

    isTppEligible(ticket: Ticket): boolean {
        return !!ticket.article.thirdPartyPayerInformation?.isEligible;
    }

    getTppName(ticket: Ticket): string {
        return ticket.article.thirdPartyPayerInformation?.thirdPartyName ?? '';
    }
}
