import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BuyTicketEvent, CheckoutStep, Currency, NearestStop, TicketState, TravelClassType } from '@traas/boldor/all-models';
import { Store } from '@ngrx/store';
import { catchError, finalize, map, shareReplay, switchMap } from 'rxjs/operators';
import { ObservableTypedStorage, OnlineService } from '@traas/common/utils';
import { Actions, ofType } from '@ngrx/effects';
import { BehaviorSubject, combineLatest, from, Observable, of, Subject } from 'rxjs';
import { SmsTicketDescription, SMSTickets } from '../tickets-data';
import * as _ from 'lodash';
import { RoutingService } from '@traas/common/routing';
import { GqlQuickArticle, QuickArticleService } from '@traas/boldor/common/features/ticket/services/quick-article.service';
import { NearestStopService } from '@traas/boldor/common/features/map/services/nearest-stop.service';
import { MenuService } from '@traas/boldor/common/features/menu/services/menu.service';
import { CartActions, CartState } from '@traas/boldor/common/features/cart/store';
import { TicketStoreActions, TicketStoreSelectors } from '@traas/boldor/common/features/ticket/store';
import { CategoryTicketsViewModel, TicketListMapper } from '@traas/boldor/common/features/ticket/models/ticket-list-mapper';
import { TicketActionTypes } from '@traas/boldor/common/features/ticket/store/ticket.action';
import { CartFactory } from '@traas/boldor/common/models/cart/cart.factory';
import { TicketState as StoreTicketState } from '@traas/boldor/common/features/ticket/store/ticket.state';

interface QuickTicketListStorage {
    ticketList: GqlQuickArticle[];
}

@Component({
    selector: 'travys-ticket',
    templateUrl: './ticket.page.html',
    styleUrls: ['./ticket.page.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TicketPage implements OnInit, OnDestroy {
    @Input({ required: true }) preferredTravelClass!: TravelClassType;
    $locationTicketsCategory: Observable<CategoryTicketsViewModel | null>;
    $otherTicketsCategories: Observable<CategoryTicketsViewModel | null>;
    smsTicketsCategories: CategoryTicketsViewModel[];

    $nearestStop = new Subject<NearestStop | null>();
    $isLoadingNearestStop = new BehaviorSubject<boolean>(true);

    $errorMessage: Observable<string>;
    $isLoading: Observable<boolean>;
    $isOnline: Observable<boolean>;

    readonly #$onDestroy = new Subject<void>();

    constructor(
        private $actions: Actions,
        private cartStore: Store<CartState>,
        private nearestStopService: NearestStopService,
        private menuService: MenuService,
        private onlineService: OnlineService,
        private quickArticleService: QuickArticleService,
        private ticketStore: Store<StoreTicketState>,
        private storageQuickArticles: ObservableTypedStorage<QuickTicketListStorage>,
        private routingService: RoutingService,
    ) {
        this.$isOnline = this.onlineService.$getIsOnline();
        this.$isLoading = this.#$buildIsLoading();
        this.$errorMessage = this.$actions.pipe(
            ofType(TicketActionTypes.LoadError),
            map(({ errorMessage }: TicketStoreActions.LoadError) => errorMessage),
            shareReplay(1),
        );
    }

    ngOnInit(): void {
        this.$locationTicketsCategory = this.#$buildLocationTicketsCategory();
        this.$otherTicketsCategories = this.#$buildOtherTicketsCategories();
        this.smsTicketsCategories = this.#createSmsTicketsCategories();
        this.ticketStore.dispatch(new TicketStoreActions.LoadTicketList());
    }

    async ionViewWillEnter(): Promise<void> {
        this.$isLoadingNearestStop.next(true);
        from(this.nearestStopService.getNearestStop())
            .pipe(finalize(() => this.$isLoadingNearestStop.next(false)))
            .subscribe((nearestStop) => {
                this.$nearestStop.next(nearestStop);
                if (nearestStop) {
                    this.ticketStore.dispatch(new TicketStoreActions.Load({ nearestStop }));
                }
            });
    }

    ngOnDestroy(): void {
        this.#$onDestroy.next();
        this.#$onDestroy.complete();
    }

    async openCartUsingTicketByPosition({ ticket, nearestStop }: BuyTicketEvent): Promise<void> {
        const cart = CartFactory.createCartFromQuickTicket(ticket, nearestStop);
        this.cartStore.dispatch(new CartActions.InitCart({ cart, chooseTicketManually: false }));
        this.routingService.navigateToCartTicketConfiguration();
    }

    async openCartUsingTicketByZone({ ticket }: BuyTicketEvent): Promise<void> {
        const cart = CartFactory.createCartFromQuickTicketsByZones(ticket);
        this.cartStore.dispatch(new CartActions.InitCart({ cart, chooseTicketManually: false }));
        await this.routingService.navigateToCartTicketConfiguration();
    }

    async navigateToItinerary(): Promise<boolean> {
        return this.menuService.navigateToItinerary();
    }

    #$buildLocationTicketsCategory(): Observable<CategoryTicketsViewModel | null> {
        return this.$isOnline.pipe(switchMap((isOnline) => (isOnline ? this.#$getQuickArticles() : of(null))));
    }

    /**
     * This code transform an array of category to a single element. It's because
     * of bad impl. after many changes of logic. But now, we will receive only one
     * type of quick tickets. But we need more times to refacto all this bad logic.
     * And Travys can't paid this time.
     * @private
     */
    #$getQuickArticles(): Observable<CategoryTicketsViewModel | null> {
        return this.ticketStore.select(TicketStoreSelectors.getCategoryTicketAdapter).pipe(
            map((categoryTicketsAdapters) => {
                const categories = categoryTicketsAdapters.map((categoryTicketsAdapter) =>
                    TicketListMapper.mapIntoFilteredTicketsViewModel(categoryTicketsAdapter, this.preferredTravelClass),
                );
                return categories[0];
            }),
            catchError(() => {
                return of(null);
            }),
        );
    }

    /**
     * If some tickets was already initialized (updated with the current currency), we will not show the loading spinner because
     * the refresh is just made in background.
     */
    #$buildIsLoading(): Observable<boolean> {
        return combineLatest([
            this.ticketStore.select(TicketStoreSelectors.getIsQuickArticlesLoading),
            this.ticketStore.select(TicketStoreSelectors.getIsInitialized),
        ]).pipe(map(([isLoading, isInitialized]) => isLoading && !isInitialized));
    }

    #$buildOtherTicketsCategories(): Observable<CategoryTicketsViewModel | null> {
        return this.$isOnline.pipe(switchMap((isOnline) => (isOnline ? this.#$getAndCreateOtherTicketsCategory() : of(null))));
    }

    #$getAndCreateOtherTicketsCategory(): Observable<CategoryTicketsViewModel> {
        return this.storageQuickArticles.$getItem('ticketList', []).pipe(
            map((data) =>
                this.quickArticleService.adaptQuickArticles(data).map((ticket) => {
                    return {
                        ...ticket,
                        isDisplayed: true,
                    };
                }),
            ),
            map((ticketAdaptersViewModel) => {
                return {
                    category: {
                        title: 'Autres Billets',
                        description: '',
                    },
                    tickets: ticketAdaptersViewModel,
                    displayed: true,
                };
            }),
        );
    }

    #createSmsTicketsCategories(): CategoryTicketsViewModel[] {
        const ticketsByCategories: { [key: string]: SmsTicketDescription[] } = _.groupBy(SMSTickets, (ticket) => ticket.category);

        return Object.entries(ticketsByCategories).map(([categoryName, smsTickets]) => {
            return {
                tickets: smsTickets.map((ticket) => {
                    return {
                        articleNumber: undefined,
                        departureStopName: '',
                        duration: undefined,
                        fqCode: '',
                        id: '',
                        isCancelled: false,
                        orderNumber: undefined,
                        passenger: undefined,
                        prices: [],
                        purchaseDate: '',
                        qrCodeData: undefined,
                        passengerType: undefined,
                        referenceNumber: undefined,
                        ticketNumber: undefined,
                        type: undefined,
                        article: {
                            locationsChoice: {
                                label: '',
                                values: [],
                            },
                            smsCode: ticket.code,
                            title: ticket.description,
                            prices: ticket.prices.map((price) => ({
                                value: price.value,
                                currency: price.currency as Currency,
                            })),
                            category: undefined,
                            travelType: undefined,
                            description: '',
                            id: '',
                            travelClass: undefined,
                            validity: {
                                from: '',
                                to: '',
                            },
                            zones: [],
                            locationsValidity: {
                                label: '',
                                values: [],
                            },
                            tarifOwner: '',
                            paymentMeans: [],
                            isSupersaver: undefined,
                        },
                        isDisplayed: true,
                        state: TicketState.Unknown,
                    };
                }),
                category: {
                    title: categoryName,
                    description: '',
                },
                displayed: true,
            };
        });
    }
}
