import { APP_BASE_HREF, registerLocaleData } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import localeFrCH from '@angular/common/locales/fr-CH';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BoldorFeatureShellCoreModule } from '@traas/boldor/common/boldor-feature-shell-core.module';
import { WebDatetimePickerModalModule } from '@traas/boldor/common/components/datetime-picker-modal/datetime-picker-modal.module';
import { CartModule } from '@traas/boldor/common/pages/cart/cart.module';
import { ItineraryArticleService } from '@traas/boldor/common/features/itinerary/services/itinerary-article.service';
import { ItineraryArticleServiceTpc } from '@traas/boldor/common/features/itinerary/services/itinerary-article.service.tpc';
import { ItineraryService } from '@traas/boldor/common/features/itinerary/services/itinerary.service';
import { PlaceModule } from '@traas/boldor/common/features/place/place.module';
import { StartupNotificationModule } from '@traas/boldor/common/features/startup-notification/startup-notification.module';
import { ArticleCategoryService } from '@traas/boldor/common/features/ticket/services/article-category.service';
import { ArticleCategoryTpcService } from '@traas/boldor/common/features/ticket/services/article-category.service.tpc';
import { QuickArticleInputService } from '@traas/boldor/common/features/ticket/services/quick-article-input.service';
import { QuickArticleInputServiceTpc } from '@traas/boldor/common/features/ticket/services/quick-article-input.service.tpc';
import { QuickArticleService } from '@traas/boldor/common/features/ticket/services/quick-article.service';
import { QuickArticleServiceTpc } from '@traas/boldor/common/features/ticket/services/quick-article.service.tpc';
import { ErrorPageModule } from '@traas/boldor/common/pages/error/error.module';
import { ModalCgvPageModule } from '@traas/boldor/common/pages/modal-cgv/modal-cgv.module';
import { PaymentPageModule } from '@traas/boldor/common/pages/payment/payment.module';
import { TestPageModule } from '@traas/boldor/common/pages/test/test.module';
import { ApolloClientModule } from '@traas/boldor/common/services/common/apollo/apollo-client.module';
import { SwisspassAuthenticationApiService } from '@traas/boldor/common/services/common/authentication/swisspass-authentication-api.service';
import { CustomerProviderService } from '@traas/boldor/common/services/common/customer/customer-provider.service';
import { SwisspassCustomerProviderService } from '@traas/boldor/common/services/common/customer/swisspass-customer-provider.service';
import { GlobalErrorHandler } from '@traas/boldor/common/services/common/errors/global.error-handler';
import { environment } from '@traas/boldor/environments';
import { TravysConditionsOfSaleModule, TravysPreferencesService } from '@traas/boldor/travys';
import { AnalyticsConfig, AnalyticsService } from '@traas/common/analytics';
import { AuthenticationApi, HalfFareService, OnAuthenticateService } from '@traas/common/feature-account';
import { PreferencesService } from '@traas/boldor/common/services/common/preferences/preferences.service';
import { TosService, UnversionedTosService } from '@traas/common/feature-tos';
import { BoldorLocalizationService } from '@traas/boldor/localization';
import { LOG_REMOTE, LoggingService, sentryInit } from '@traas/common/logging';
import { RoutingService } from '@traas/common/routing';
import { AngularSplitModule } from 'angular-split';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TravysItineraryService } from './travys-itinerary.service';
import { SWISSPASS_SERVICE_TOKEN } from '@traas/boldor/common/features/swisspass/services/SwisspassService';
import { PlatformUtilsService } from '@traas/common/utils';
import { AlertService } from '@traas/boldor/common/services/common/alert.service';
import { WebSwisspassService } from '@traas/boldor/common/features/swisspass/services/web-swisspass.service';
import { MobileSwisspassService } from '@traas/boldor/common/features/swisspass/services/mobile-swisspass.service';
import { TraasOnAuthenticateService } from '@traas/boldor/common/services/common/authentication/traas-on-authenticate.service';
import { TraasHalfFareService } from '@traas/boldor/common/services/common/customer/traas-half-fare.service';
import { MigrateToSecureStorageService } from '@traas/boldor/common/services/common/data-version/migrate-to-secure-storage.service';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AccountManagementService } from '@traas/boldor/common/services/common/customer/account-management.service';
import { mapEnvToSentryInitParams } from '@traas/boldor/all-helpers';

if (environment.logSentry) {
    sentryInit(mapEnvToSentryInitParams(environment));
}

registerLocaleData(localeFrCH);

// Modules which needs to be loaded first and in the right order
const orderSensitiveModules = [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    IonicModule.forRoot({
        scrollPadding: true,
        scrollAssist: true,
        innerHTMLTemplatesEnabled: true,
    }),
    BoldorFeatureShellCoreModule.forRoot(),
];

const devModules = environment.isDebugMode ? [StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: false, connectInZone: true })] : [];

@NgModule({
    declarations: [AppComponent],
    bootstrap: [AppComponent],
    imports: [
        ...orderSensitiveModules,
        AngularSplitModule,
        ApolloClientModule,
        ErrorPageModule,
        ModalCgvPageModule,
        TravysConditionsOfSaleModule,
        PaymentPageModule,
        PlaceModule,
        TestPageModule,
        CartModule,
        WebDatetimePickerModalModule,
        StartupNotificationModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (http: HttpClient) => new TranslateHttpLoader(http, './assets/i18n/', '.json'),
                deps: [HttpClient],
            },
        }),
        ...devModules,
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: (boldorLocalizationService: BoldorLocalizationService) => (): Promise<void> => boldorLocalizationService.init(),
            deps: [BoldorLocalizationService],
            multi: true,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: (migrateToSecureStorageService: MigrateToSecureStorageService) => (): Promise<void> =>
                migrateToSecureStorageService.migrateToSecureStorageData(),
            deps: [MigrateToSecureStorageService],
            multi: true,
        },
        { provide: APP_BASE_HREF, useValue: '/' },
        {
            provide: LOCALE_ID,
            useFactory: (boldorLocalizationService: BoldorLocalizationService): string => boldorLocalizationService.localeId,
            deps: [BoldorLocalizationService],
        },
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        {
            provide: AnalyticsConfig,
            useValue: {
                isTrackingEnabled: environment.analyticsTrackingEnabled,
                isLoggingEnabled: environment.analyticsLoggingEnabled,
                userIdHashSalt: environment.analyticsUserIdHashSalt,
                webAnalyticsConfig: environment.analyticsWebConfig,
            },
        },
        AnalyticsService,
        { provide: AuthenticationApi, useClass: SwisspassAuthenticationApiService },
        {
            provide: SWISSPASS_SERVICE_TOKEN,
            useFactory: (
                platformUtilsService: PlatformUtilsService,
                alertService: AlertService,
                boldorLocalizationService: BoldorLocalizationService,
                logger: LoggingService,
            ): WebSwisspassService | MobileSwisspassService => {
                return platformUtilsService.isWeb()
                    ? new WebSwisspassService()
                    : new MobileSwisspassService(alertService, platformUtilsService, boldorLocalizationService, logger);
            },
            deps: [PlatformUtilsService, AlertService, BoldorLocalizationService, LoggingService],
        },
        { provide: QuickArticleInputService, useClass: QuickArticleInputServiceTpc },
        { provide: ItineraryArticleService, useClass: ItineraryArticleServiceTpc },
        { provide: ArticleCategoryService, useClass: ArticleCategoryTpcService },
        { provide: QuickArticleService, useClass: QuickArticleServiceTpc },
        { provide: ItineraryService, useClass: TravysItineraryService },
        { provide: PreferencesService, useClass: TravysPreferencesService },
        { provide: OnAuthenticateService, useClass: TraasOnAuthenticateService },
        { provide: HalfFareService, useClass: TraasHalfFareService },
        { provide: TosService, useClass: UnversionedTosService },
        RoutingService,
        AccountManagementService,
        { provide: LOG_REMOTE, useValue: environment.logSentry },
        {
            provide: CustomerProviderService,
            useClass: SwisspassCustomerProviderService,
        },
    ],
    exports: [AppComponent],
})
export class AppModule {}
