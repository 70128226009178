<ion-grid>
  <ion-row class="header" [class.header-itinerary]="isItineraryMode">
    <ion-col
      [class.is-departure-detail]="isDepartureMode && isDetail"
      [class.is-itinerary]="isItineraryMode"
      [class.no-padding-left]="!isDetail && (isTPG || isItineraryMode)"
      >
      @if (isDetail) {
        <div class="back-button" [class.back-button-itinerary]="isItineraryMode">
          @if (mode === ResultMode.DEPARTURE) {
            <ion-button
              [class.vibrate]="$vibrateBackButton | async"
              [disabled]="$isBackButonLocked | async"
              size="small"
              (click)="onBackButtonClicked()"
              >
              <ion-icon name="arrow-back"></ion-icon>
            </ion-button>
          }

          @if (mode !== ResultMode.DEPARTURE) {
            <ion-button size="small" oitClickUsingBackButton (click)="onBackButtonClicked()">
              <ion-icon name="arrow-back"></ion-icon>
            </ion-button>
          }
        </div>
      } @else {
        <div [class.is-itinerary]="isItineraryMode" class="subtitle">
          @if (showFilter) {
            <ion-button
              [ngClass]="{ active: isFilterActive }"
              size="small"
              elevation="1"
              class="filter-button e2e-filters"
              (click)="onFilterButtonClicked()"
              >
              {{ 'home.options-button' | translate }}
            </ion-button>
          }
          <ion-button size="small" elevation="1" class="e2e-time-display-mode" id="open-time-display-modal">
            <ion-icon src="assets/icons/timer_choice.svg"></ion-icon>
          </ion-button>
        </div>
      }
    </ion-col>

    <ion-col class="datepicker" [class.is-itinerary]="isItineraryMode">
      @if (!($isGridMode | async) && ($pickedDatetime | async); as datetime) {
        <app-date-picker
          (dateSelected)="emitDateSelected($event)"
          (queryKindChange)="emitQueryKindChange($event)"
          (searchOptionChange)="emitSearchOptionChange($event)"
          [datePickerConfig]="datePickerConfig"
          [pickedDateTime]="datetime"
        ></app-date-picker>
      }
    </ion-col>
  </ion-row>
</ion-grid>
<app-date-picker-behavior-badge [behavior]="($datepickerMode | async)!"></app-date-picker-behavior-badge>

<traas-time-display-mode-modal trigger="open-time-display-modal" />
