import * as SentryAngular from '@sentry/angular-ivy';
import * as Sentry from '@sentry/capacitor';
import { SentryBreadcrumbsUtils } from './sentry-breadcrumbs.utils';
import { Device } from '@capacitor/device';
import { CurrentUserSession } from './current-user-session.service';

export type SentryInitParams = {
    clientVersion: string;
    company: string;
    currentCommitHash: string;
    environment: 'local' | 'dev' | 'int' | 'qual' | 'prod';
    gatewayUrl: string;
    isDebugMode: boolean;
    sentryDsn: string;
    sentryRelease: string;
    traceSentry: boolean;
};

export const sentryInit = (environment: SentryInitParams): void => {
    const tracesSampleRate = !environment.traceSentry ? undefined : environment.isDebugMode ? 1 : 1 / 100;
    const dropBreadcrumb = (): null => null;

    Sentry.init(
        {
            maxValueLength: 1000,
            dsn: environment.sentryDsn,
            release: environment.sentryRelease,
            environment: environment.environment,
            dist: environment.currentCommitHash,
            /*
        Explanation about integrations
        XHR request errors are sent by sentry by defaut with TryCatch integration.
        Since boldor-error-dispatcher service manage sending errors to sentry (catch exceptions caught outside of angular), and
        we want to filter out network errors (normal cases for mobile), we deactivate TryCatch integration.

        More info here: https://moviplus.atlassian.net/wiki/spaces/BDOR/pages/278593709/Sentry

        https://docs.sentry.io/platforms/javascript/guides/capacitor/configuration/integrations/
        https://docs.sentry.io/platforms/javascript/guides/angular/configuration/integrations/
         */
            integrations: (integrations) => {
                let integrationsArray = integrations.filter((i) => i.name !== 'TryCatch');
                integrationsArray = integrationsArray.map((i) =>
                    i.name === 'Breadcrumbs'
                        ? new SentryAngular.Integrations.Breadcrumbs({
                              console: false,
                              sentry: false,
                          })
                        : i,
                );
                if (environment.traceSentry) {
                    integrationsArray.push(new Sentry.BrowserTracing());
                }
                return integrationsArray;
            },
            tracesSampleRate,
            // Transport option exist only in angular doc, not capacitor : https://docs.sentry.io/platforms/javascript/guides/angular/configuration/transports/
            transport: SentryAngular.makeBrowserOfflineTransport(SentryAngular.makeFetchTransport) as any,
            beforeBreadcrumb(breadcrumb, hint) {
                if (SentryBreadcrumbsUtils.isSvgAssetsLoadingBreadcrumb(breadcrumb)) {
                    return dropBreadcrumb();
                }

                if (SentryBreadcrumbsUtils.isGraphqlBreadcrumb(breadcrumb) && hint) {
                    SentryBreadcrumbsUtils.setGraphQLDataOnBreadcrumb(breadcrumb, hint);
                }

                return breadcrumb;
            },
        },
        SentryAngular.init,
    );

    Sentry.addGlobalEventProcessor(function (event, _) {
        if (!event.exception || !event.exception.values?.length) {
            return event;
        }
        event.exception.values.forEach((exception) => {
            if (exception.mechanism && exception.type === 'FatalError') {
                exception.mechanism.handled = false;
            }
        });
        return event;
    });

    Sentry.configureScope((scope) => {
        scope.setTags({
            gatewayUrl: environment.gatewayUrl,
            clientVersion: environment.clientVersion,
            company: environment.company,
        });
        Device.getId().then((deviceId) => {
            scope.setTag('device.id', deviceId.identifier);
        });
        CurrentUserSession.onActiveUserChanged.subscribe((user) => {
            console.log('[Sentry] user changed:', user);
            if (!user) {
                scope.setUser({
                    id: undefined,
                });
                return;
            }
            scope.setUser({
                id: user.id,
            });
        });
    });
};
