import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MenuService } from '@traas/boldor/common/features/menu/services/menu.service';

const TRAVYS_PLANS_URL = 'https://www.travys.ch/plans/';
const TRAVYS_TRAFFIC_INFORMATION_URL = 'https://m.travys.ch/disruptions';
const TRAVYS_FAQ_URL = 'https://faq.traas.ch/travys';
const TRAVYS_CUSTOMER_SERVICE_URL = 'https://faq.traas.ch/travys/service-clients';

@Component({
    selector: 'traas-travys-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuComponent {
    constructor(private menuService: MenuService) {}

    navigateToHome(): Promise<boolean> {
        return this.menuService.navigateToHome();
    }

    navigateToItinerary(): Promise<boolean> {
        return this.menuService.navigateToItinerary();
    }

    navigateToFavorites(): Promise<boolean> {
        return this.menuService.navigateToFavorites();
    }

    async openPlans(): Promise<void> {
        await this.menuService.openWebPageInBrowser(TRAVYS_PLANS_URL);
    }

    async openTrafficInformation(): Promise<void> {
        await this.menuService.openWebPageInBrowser(TRAVYS_TRAFFIC_INFORMATION_URL);
    }

    async openFaq(): Promise<void> {
        await this.menuService.openWebPageInBrowser(TRAVYS_FAQ_URL);
    }

    async openCustomerService(): Promise<void> {
        await this.menuService.openWebPageInBrowser(TRAVYS_CUSTOMER_SERVICE_URL);
    }
}
