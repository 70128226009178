@if ($departureTimeVM | async; as departureTimeVM) {
  @if (!(($departureTimeInput | async)!).isCancelled) {
    <app-departure-time
      class="flex"
      [departureTimeViewModel]="departureTimeVM"
      >
    </app-departure-time>
  } @else {
    <traas-departure-time-cancelled class="flex" [departureTimeViewModel]="departureTimeVM"></traas-departure-time-cancelled>
  }
}
