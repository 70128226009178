import { Marker } from 'leaflet';

export class EdgeMarker extends Marker {
    #originalMarker: Marker;

    getOriginalMarker(): Marker {
        return this.#originalMarker;
    }

    setOriginalMarker(marker: Marker): void {
        this.#originalMarker = marker;
    }
}
