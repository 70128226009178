import { Injectable, InjectionToken, Type } from '@angular/core';

export const COMPANY_COMPONENTS = {
    Cgv: new InjectionToken<Type<unknown>>('CgvComponent'),
    Cgu: new InjectionToken<Type<unknown>>('CguComponent'),
};

@Injectable({
    providedIn: 'root',
})
export class DynamicComponentService {
    #componentMap = new Map<string | InjectionToken<Type<unknown>>, Type<unknown>>();

    setComponentType(token: string | InjectionToken<Type<unknown>>, componentType: Type<unknown>): void {
        this.#componentMap.set(token, componentType);
    }

    getComponentType(token: string | InjectionToken<Type<unknown>>): Type<unknown> | undefined {
        return this.#componentMap.get(token);
    }
}
