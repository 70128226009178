import { Injectable } from '@angular/core';
import { CaptureContext, Extras } from '@sentry/types';
import { BaseError, FatalError, TechnicalError } from '@traas/common/models';
import { ApolloError } from '@apollo/client/core';
import { getCorrelationIdFromGraphQLError, getGqlOperationName, isGraphqlSchemaValidationFailed } from './error-utils';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class ErrorContextBuilder {
    composeErrorMessageWithAllCauses(error: Error): string {
        if (error instanceof FatalError) return this.composeErrorMessageWithAllCauses(error.innerError);
        if (error instanceof BaseError && error.innerError) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const causeMessage = this.composeErrorMessageWithAllCauses(error.innerError);
            return causeMessage ? `${error.message} - Caused by: ${causeMessage}` : error.message;
        }

        return this.#getCauseMessage(error);
    }

    buildContext(error: Error): CaptureContext & {
        tags: any;
        extra: any;
    } {
        return {
            tags: this.#collectTags(error),
            extra: this.#collectExtras(error),
        };
    }

    #collectTags(error: Error): {
        [key: string]: string;
    } {
        const tags = {};

        const correlationId = this.#findCorrelationIdInErrorChain(error);
        if (correlationId) {
            Object.assign(tags, { correlationId });
        }

        if (error instanceof FatalError || error instanceof TechnicalError) {
            Object.assign(tags, { errorCode: error.errorCode, serverErrorCode: error.serverErrorCode });
            if (error.innerError instanceof ApolloError) {
                const operationName = getGqlOperationName(error.innerError);
                if (operationName) {
                    Object.assign(tags, { gqlOperation: operationName });
                }
            }
        }

        return tags;
    }

    #collectExtras(error: Error): Extras {
        if (!(error instanceof BaseError)) {
            return {};
        }

        const contextExtrasFromErrorChain = this.#flattenErrorContext(error);
        const originalStack = error.innerError ? { originalStackTrace: error.getInnerErrorStack() } : {};

        return {
            ...contextExtrasFromErrorChain,
            ...originalStack,
        };
    }

    #findCorrelationIdInErrorChain(error: Error): string | undefined {
        if (error instanceof ApolloError) {
            return getCorrelationIdFromGraphQLError(error);
        }
        if (error instanceof BaseError) {
            const innerError = error.innerError;
            if (innerError) {
                return this.#findCorrelationIdInErrorChain(innerError);
            }
        }
        return undefined;
    }

    #flattenErrorContext(error: BaseError, count = 0): Extras {
        const errorContext = error.context ?? {};
        const result =
            count > 0 ? Object.fromEntries(Object.entries(errorContext).map(([key, value]) => [`${count}-${key}`, value])) : errorContext;

        if (error.innerError && error.innerError instanceof BaseError) {
            const innerExtras = this.#flattenErrorContext(error.innerError, count + 1);
            return { ...result, ...innerExtras };
        }
        return result;
    }

    #getApolloErrorMessage(apolloError: ApolloError): string {
        if (apolloError.graphQLErrors.length > 0) {
            const gqlErrorsPath = apolloError.graphQLErrors
                .map((graphqlError) => (graphqlError.path ? `[${graphqlError.path.join('.')}]` : '[empty path]'))
                .join(', ');
            return `ApolloError: ${apolloError.message} due to path request ${gqlErrorsPath}`;
        } else if (isGraphqlSchemaValidationFailed(apolloError)) {
            const networkErrorsMessage = (apolloError.networkError as HttpErrorResponse).error.errors
                .map((networkError: any) => networkError.message)
                .join(', ');
            return `ApolloError: ${apolloError.message} due to [${networkErrorsMessage}]`;
        }
        return `ApolloError: ${apolloError.message}`;
    }

    #getCauseMessage(error: Error): string {
        if (error instanceof ApolloError) return this.#getApolloErrorMessage(error);
        return error.message;
    }
}
