<ion-accordion-group [value]="'ticket'">
    <ion-accordion value="ticket">
        <ion-item slot="header">
            <app-ticket-resume [ticket]="ticket"></app-ticket-resume>
        </ion-item>
        <div slot="content">
            <ticket-detail [ticket]="ticket"></ticket-detail>
        </div>
    </ion-accordion>
</ion-accordion-group>
