import { ArticleType } from '@traas/boldor/graphql-generated/graphql';
import { ArticleViewModel } from './article-view-model.model';

export interface QuickArticleViewModel extends ArticleViewModel {
    articleId: string;
    type: ArticleType.QuickArticle;
    categoryDescription: string;
}

export function isQuickArticleViewModel(article: ArticleViewModel): article is QuickArticleViewModel {
    return article.type === ArticleType.QuickArticle;
}
