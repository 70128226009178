import { Departure, LineFilter } from '@traas/boldor/all-models';
import { Placeholder } from '../../../components/placeholder-list-item/placeholder.model';

export interface DepartureState {
    activeLineFilters: LineFilter[];
    error: any;
    future: {
        departures: Departure[] | null;
        loading: boolean;
        nbExpected: number;
    };
    inScrollViewport: (Departure | Placeholder)[];
    lineFilters: LineFilter[];
    lineFiltersLoading: boolean;
    past: {
        departures: Departure[] | null;
        loading: boolean;
        nbExpected: number;
    };
    commercialStopIdsTarget: string[];
    preventNextResetLineFilters: boolean;
    refresher: {
        date: Date;
        departuresCount: number | undefined;
    };
    selected: {
        departure: Departure | null;
    };
}

export const initialState: DepartureState = {
    activeLineFilters: [],
    error: null,
    future: {
        departures: null,
        nbExpected: 0,
        loading: false,
    },
    inScrollViewport: [],
    lineFilters: [],
    lineFiltersLoading: false,
    past: {
        departures: null,
        nbExpected: 0,
        loading: false,
    },
    commercialStopIdsTarget: [],
    preventNextResetLineFilters: false,
    refresher: {
        departuresCount: 0,
        date: new Date(),
    },
    selected: {
        departure: null,
    },
};
