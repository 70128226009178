import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

/*
A simple component used to show symbols instead of using the <use href> syntax.
The tpc-symbol-svg will have the class loading when the svg is not resolved
In the case the symbol id does not exists in the passed imgURL in this case
we display the symbol as a fallback (if any provided)
 */
@Component({
    selector: 'app-symbol-svg',
    templateUrl: './symbol-svg.component.html',
    styleUrls: ['./symbol-svg.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SymbolSvgComponent {
    // imgURL with the fragment as the id. example: http://domain.ch/some.svg#symbolName
    @Input({ required: true }) symbolURL!: string;
}
