import { NgModule } from '@angular/core';
import { BookingListItemSkeletonComponent } from './components/booking-list-item-skeleton/booking-list-item-skeleton.component';
import { DepartureTicketSummaryComponent } from './components/departure-ticket-summary/departure-ticket-summary.component';
import { ItineraryTicketFromToComponent } from './components/itinerary-ticket-from-to/itinerary-ticket-from-to.component';
import { ItineraryTicketStopRequestComponent } from './components/itinerary-ticket-stop-request/itinerary-ticket-stop-request.component';
import { ItineraryTicketSummaryComponent } from './components/itinerary-ticket-summary/itinerary-ticket-summary.component';
import { QuickTicketSummaryComponent } from './components/quick-ticket-summary/quick-ticket-summary.component';
import { TicketOrderComponent } from './components/ticket-order/ticket-order.component';
import { HumanReadableDateComponent } from './components/human-readable-date/human-readable-date.component';
import { TicketResumeComponent } from './components/ticket-resume/ticket-resume.component';
import { TicketSummaryComponent } from './components/ticket-summary/ticket-summary.component';
import { JourneyListContainerComponent } from './container/journey-list-container/journey-list-container.component';
import { BookingStoreModule } from './store/booking-store.module';
import { ItineraryModule } from '../itinerary/itinerary.module';
import { TicketModule } from '../ticket/ticket.module';
import { BoldorFeatureShellSharedModule } from '../../boldor-feature-shell-shared.module';
import { LinesIconHorizontalListComponent } from '../../components/lines-icon-horizontal-list/lines-icon-horizontal-list.component';

@NgModule({
    imports: [BoldorFeatureShellSharedModule, TicketModule, BookingStoreModule, ItineraryModule, LinesIconHorizontalListComponent],
    declarations: [
        BookingListItemSkeletonComponent,
        QuickTicketSummaryComponent,
        JourneyListContainerComponent,
        ItineraryTicketFromToComponent,
        ItineraryTicketStopRequestComponent,
        ItineraryTicketSummaryComponent,
        HumanReadableDateComponent,
        TicketResumeComponent,
        TicketOrderComponent,
        TicketSummaryComponent,
        DepartureTicketSummaryComponent,
    ],
    exports: [
        JourneyListContainerComponent,
        HumanReadableDateComponent,
        TicketSummaryComponent,
        ItineraryTicketFromToComponent,
        ItineraryTicketStopRequestComponent,
        ItineraryTicketSummaryComponent,
        DepartureTicketSummaryComponent,
        QuickTicketSummaryComponent,
        TicketOrderComponent,
    ],
})
export class BookingModule {}
