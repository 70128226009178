import { Injectable } from '@angular/core';
import { Price as PriceGql } from '@traas/boldor/graphql-generated/graphql';
import { Category, Currency, Price, Ticket } from '@traas/boldor/all-models';
import { CategoryTicketsAdapter } from '../../../models/ticket/category-tickets';

@Injectable({
    providedIn: 'root',
})
export class ArticleService {
    static formatAmountInCentsInHumanReadableValue(amountInCents: number): string {
        return (amountInCents / 100).toFixed(2);
    }

    formatPrices(unitPrices: PriceGql[] = []): Price[] {
        return unitPrices.map((currentUnitPrice) => {
            return this.#formatPrice(currentUnitPrice);
        });
    }

    castTicketsIntoCategoryTickets(tickets: Ticket[]): CategoryTicketsAdapter[] {
        const distinctCategories = Array.from(
            new Set(
                tickets
                    .map((ticket) => ticket.article.category)
                    .filter((category): category is Category => !!category)
                    .map((category) => category.id),
            ),
        );
        return distinctCategories.map((category) => {
            return this.#createCategoryTicketsAdapter(category, tickets);
        });
    }

    #createCategoryTicketsAdapter(category: string, tickets: Ticket[]): CategoryTicketsAdapter {
        const filteredTicketAdapters = tickets.filter((ticket) => {
            return ticket.article.category?.id === category;
        });

        const [firstTicket] = filteredTicketAdapters;
        const firstArticle = firstTicket.article;
        const categoryDescription = firstArticle.categoryDescription ?? '';
        return new CategoryTicketsAdapter({
            category: {
                title: category,
                description: categoryDescription,
            },
            tickets: filteredTicketAdapters,
        });
    }

    #formatPrice(currentUnitPrice: PriceGql): Price {
        const unitPrice = ArticleService.formatAmountInCentsInHumanReadableValue(currentUnitPrice.amountInCents);
        return { currency: currentUnitPrice.currency as unknown as Currency, value: unitPrice };
    }
}
