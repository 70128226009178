import { inject, Injectable } from '@angular/core';
import { AuthenticationApi, HalfFareService, OnAuthenticateService } from '@traas/common/feature-account';
import { PreferencesService } from '../preferences/preferences.service';
import { TicketListStorageService } from '../../../features/ticket/services/ticket-list-storage.service';
import { OrderStorageService } from '../order/order-storage.service';
import { StopRequestStorageService } from '../stop-request/stop-request-storage.service';
import { Store } from '@ngrx/store';
import { CreditCardsState } from '../../../features/credit-cards/store/credit-cards.state';
import { CartActions, CartState } from '../../../features/cart/store';
import { CreditCardsActions } from '../../../features/credit-cards/store';
import { BookingState, BookingStoreActions } from '../../../features/booking/store';
import { AnalyticsService } from '@traas/common/analytics';
import { convertToError, CurrentUserSession, LoggingService } from '@traas/common/logging';
import { Router } from '@angular/router';
import { LoadingController } from '@ionic/angular';
import { BoldorLocalizationService } from '@traas/boldor/localization';
import { ErrorCodes, TechnicalError } from '@traas/common/models';
import { CustomerProviderService } from './customer-provider.service';

/**
 * @fileoverview Ce service gère les opérations de session d'utilisateur,
 * y compris la déconnexion et la suppression de compte.
 *
 * @author Ismaël
 */
@Injectable()
export class AccountManagementService {
    readonly #authenticationApi = inject(AuthenticationApi);
    readonly #preferencesService = inject(PreferencesService);
    readonly #ticketListStorageService = inject(TicketListStorageService);
    readonly #orderStorageService = inject(OrderStorageService);
    readonly #stopRequestStorageService = inject(StopRequestStorageService);
    readonly #creditCardStore = inject(Store<CreditCardsState>);
    readonly #cartStore = inject(Store<CartState>);
    readonly #bookingStore = inject(Store<BookingState>);
    readonly #analyticsService = inject(AnalyticsService);
    readonly #logger = inject(LoggingService);
    readonly #router = inject(Router);
    readonly #onAuthenticateService = inject(OnAuthenticateService);
    readonly #loadingCtrl = inject(LoadingController);
    readonly #boldorLocalisationService = inject(BoldorLocalizationService);
    readonly #customerProviderService = inject(CustomerProviderService);
    readonly #halfFareService = inject(HalfFareService);

    /**
     * Déconnecte l'utilisateur actuellement connecté avec popup de confirmation.
     * @returns True if logout succeeds, false otherwise.
     */
    async logout(): Promise<boolean> {
        const loggedOut = await this.#authenticationApi.logout();
        if (loggedOut) {
            await this.clearUserDataAndSendUnsetAnalytics();
        }
        return loggedOut;
    }

    /**
     * Attempts to authenticate the user using the SDK and the gateway and redirects to the nextPageUrl..
     * @param nextPageUrl
     * @returns True if the authentication succeeds, false otherwise.
     */
    async authenticateAndRedirectTo(nextPageUrl: string | null): Promise<boolean> {
        const isAuthenticated = await this.#authenticate();
        if (isAuthenticated && nextPageUrl) {
            await this.#router.navigateByUrl(nextPageUrl, { replaceUrl: true });
        }
        return isAuthenticated;
    }

    /**
     * Déconnecte l'utilisateur actuellement connecté.
     */
    async forceLogout(): Promise<void> {
        await this.#authenticationApi.forceLogout();
        await this.clearUserDataAndSendUnsetAnalytics();
    }

    /**
     * Ouvre la page Swisspass qui permet entre autre de Supprimer le compte de l'utilisateur actuellement connecté.
     */
    async deleteAccount(): Promise<void> {
        await this.#authenticationApi.deleteAccount();
    }

    /**
     * Efface les infos locales du client et gère les analytics.
     */
    async clearUserDataAndSendUnsetAnalytics(): Promise<void> {
        await this.#clearUserStorage();
        await this.#clearNgrxState();
        CurrentUserSession.clear();
        this.#analyticsService.sendUnsetAuthenticatedUserInformation();
    }

    async #authenticate(): Promise<boolean> {
        const isLoggedIn = await this.#authenticationApi.authenticate();
        if (!isLoggedIn) {
            console.error(`L'utilisateur n'est pas connecté.`);
            return false;
        }
        try {
            const customer = await this.#customerProviderService.getCustomer();
            CurrentUserSession.activeUser = { id: customer.id };
        } catch (error) {
            this.#logger.logError(
                new TechnicalError('Fail to init currentUserSession.', ErrorCodes.Authentication.GetCustomer, convertToError(error)),
            );
        }
        return await this.#tryAuthenticateOnGateway();
    }

    /**
     * Efface le localStorage.
     */
    async #clearUserStorage(): Promise<void> {
        await this.#preferencesService.clearCompanySpecificPreferences();
        await this.#ticketListStorageService.clearTicketList();
        await this.#orderStorageService.clear();
        await this.#stopRequestStorageService.clear();
    }

    /**
     * Efface le state NGRX.
     */
    async #clearNgrxState(): Promise<void> {
        this.#creditCardStore.dispatch(new CreditCardsActions.ResetCreditCards());
        this.#cartStore.dispatch(new CartActions.ResetCart());
        this.#bookingStore.dispatch(new BookingStoreActions.SetOrders({ orders: [] }));
    }

    /**
     * Authentifie le client sur la gateway (sds, base de données) puis redirige sur la nextPageUrl.
     */
    async #tryAuthenticateOnGateway(): Promise<boolean> {
        try {
            const isAuthOnGateway = await this.#onAuthenticateService.onAuthenticate();
            if (!isAuthOnGateway) {
                throw new Error(`Fail to authenticate on gateway.`);
            }
            void this.#halfFareService.fetchAndStoreHasHalfFare();
            this.#bookingStore.dispatch(new BookingStoreActions.LoadOrders());
            return true;
        } catch (error) {
            this.#logger.logError(
                new TechnicalError(
                    'Fail to authenticate on gateway.',
                    ErrorCodes.Authentication.AuthenticateOnGateway,
                    convertToError(error),
                ),
            );
            await this.#presentForceLogoutToast();
            await this.forceLogout();
            return false;
        }
    }

    /**
     * Présente un toast pour prévenir le client de la déconnexion forcée.
     */
    async #presentForceLogoutToast(): Promise<void> {
        const forceLogoutToastDescription = await this.#boldorLocalisationService.get('swisspass.force-logout-alert.description');
        const forceLogoutToast = await this.#loadingCtrl.create({
            message: forceLogoutToastDescription,
            duration: 3000,
        });
        await forceLogoutToast.present();
    }
}
