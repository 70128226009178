import { latLng, LatLng } from 'leaflet';
import { getCityName, getLatLng, getName } from '../../index';
import { Line, PhysicalStop } from '@traas/common/models';
import { AbstractAdapter, AssociatedCommercialStopAdapter, HumanReadableStop } from '@traas/boldor/all-models';

export class PhysicalStopAdapter extends AbstractAdapter<PhysicalStop> implements HumanReadableStop {
    get lines(): Line[] {
        return this.data.lines;
    }

    // todo remove the default place on getLatLng ?
    getLatLng(defaultLatLng: { latitude: number; longitude: number }): LatLng {
        const defaultPlace = latLng(defaultLatLng.latitude, defaultLatLng.longitude);
        return getLatLng(this.getData(), defaultPlace);
    }

    constructor(data: PhysicalStop) {
        super(data);
    }

    getId(): string {
        return this.data.id;
    }

    getGeometry(): string {
        return this.data.geometry;
    }

    getCommercialStopId(): string | undefined {
        return this.data.associatedCommercialStop?.id;
    }

    getAssociatedCommercialStop(): AssociatedCommercialStopAdapter | undefined {
        if (this.data.associatedCommercialStop) {
            return new AssociatedCommercialStopAdapter(this.data.associatedCommercialStop);
        }
        return undefined;
    }

    // implement HumanReadableStop
    getCityName(): string {
        return this.getCommercialCityName();
    }

    // implement HumanReadableStop
    getName(): string {
        return this.getCommercialStopName();
    }

    getCommercialCityName(): string {
        return getCityName(this.data);
    }

    getCommercialStopName(): string {
        return getName(this.data);
    }
}
