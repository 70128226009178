import { Injectable } from '@angular/core';
import { FatalError, TechnicalError } from '@traas/common/models';
import { ApolloError } from '@apollo/client/core';
import { getGqlOperationName } from '@traas/common/logging';

type UrlPattern = {
    pattern: RegExp;
    replacement: string;
};

@Injectable({ providedIn: 'root' })
export class SentryErrorFingerprintService {
    createFingerprint(params: { error: FatalError | TechnicalError; url: string }): string[] | undefined {
        if (!params || !params.error || !params.url) {
            return undefined;
        }

        const errorCodes: string[] = [];
        if (params.error.serverErrorCode) {
            errorCodes.push(params.error.serverErrorCode);
        }
        if (params.error.errorCode) {
            errorCodes.push(params.error.errorCode);
        }
        if (params.error.innerError instanceof ApolloError) {
            const operationName = getGqlOperationName(params.error.innerError);
            if (operationName) {
                errorCodes.push(operationName);
            }
        }
        if (errorCodes.length) {
            return [params.error.name, ...errorCodes];
        }

        const url = this.#removeDynamicValuesFromUrl(params.url, [
            { pattern: /\/tabs\/bookings\/details\/[^/]+/, replacement: '/tabs/bookings/details/xxx' },
        ]);
        return [params.error.name, url, params.error.message];
    }

    #removeDynamicValuesFromUrl(url: string, patterns: UrlPattern[]): string {
        let modifiedUrl = url;

        patterns.forEach((pattern) => {
            modifiedUrl = modifiedUrl.replace(pattern.pattern, pattern.replacement);
        });

        return modifiedUrl;
    }
}
