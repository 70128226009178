import { Component } from '@angular/core';
import { environment } from '@traas/boldor/environments';
import { ModalController, NavParams } from '@ionic/angular';
import * as moment from 'moment';
import { formatISO, subMilliseconds } from 'date-fns';
import { ONE_MINUTE_IN_MS } from '@traas/common/utils';
import { BoldorLocalizationService } from '@traas/boldor/localization';

@Component({
    selector: 'app-datetime-picker-modal',
    templateUrl: './datetime-picker-modal.component.html',
    styleUrls: ['./datetime-picker-modal.component.scss'],
})
export class DatetimePickerModalComponent {
    showDebugButtons: boolean;
    readonly #env = environment;
    pickerDate: string;
    localeId: string;

    constructor(
        private modalCtrl: ModalController,
        navParams: NavParams,
        localizationService: BoldorLocalizationService,
    ) {
        const date: Date = navParams.data['pickedDate'] || new Date();
        const offset = date.getTime() % (5 * ONE_MINUTE_IN_MS);
        this.pickerDate = formatISO(subMilliseconds(date, offset));

        this.showDebugButtons = this.#env.isDebugMode; // || this.env.gatewayEndpoint.includes('qual');
        this.localeId = localizationService.localeId;
    }

    async submit(): Promise<void> {
        await this.modalCtrl.dismiss(new Date(this.pickerDate));
    }

    async now(): Promise<void> {
        await this.modalCtrl.dismiss(new Date());
    }

    async cancel(): Promise<void> {
        await this.modalCtrl.dismiss(null);
    }

    async debug(days: number): Promise<void> {
        const day = moment(new Date()).add(days, 'days').toDate();

        await this.modalCtrl.dismiss(new Date(day.getFullYear(), day.getMonth(), day.getDate(), 12, 0, 0, 0));
    }
}
