import { TrackTypes } from '../models/track-types.enum';

export class TrackHelper {
    static getTrackType(track: string): TrackTypes {
        const isNumeric = /^\d+$/.test(track);
        return isNumeric ? TrackTypes.WAY : TrackTypes.TRACK;
    }

    static isDoubleDigitTrack(track: string | undefined): boolean {
        if (!track) {
            return false;
        }
        return track.length === 2;
    }

    static getTrackIconPath(track: string): string {
        switch (this.getTrackType(track)) {
            case TrackTypes.TRACK: {
                const positionInAlphabetNumber = track.charCodeAt(0) - 'A'.charCodeAt(0);
                const positionInAlphabetString = positionInAlphabetNumber.toString().padStart(2, '0');
                return `assets/sbb-cff-icons/03_Kanten/23${positionInAlphabetString}_Kante-${track.toUpperCase()}_g_fr.svg`;
            }
            case TrackTypes.WAY:
            default:
                return `assets/sbb-cff-icons/01_Gleise/21${track.padStart(2, '0')}_Gleis-${track}_g_fr.svg`;
        }
    }
}
