import {
    AssociatedCommercialStopInput,
    CommercialStopInput,
    DepartureBasketInput,
    ItineraryBasketInput,
    JourneyMessageInput,
    LegInput,
    LineInput,
    PhysicalStopInput,
    VehicleInput,
} from '@traas/boldor/graphql-generated/graphql';
import { Departure, Itinerary, ItineraryStop, JourneyMessage, Leg, Vehicle } from '@traas/boldor/all-models';
import { AssociatedCommercialStop, ErrorCodes, Line, PhysicalStop, TechnicalError } from '@traas/common/models';
import { toJourneyMessageType } from 'libs/boldor/all-helpers/src/lib/service-to-gql.converter';

export class FrontToGqlCartConverter {
    static toItineraryCartInput(itinerary: Itinerary): ItineraryBasketInput {
        return {
            arrivalDate: itinerary.scheduledArrivalDate,
            arrivalTime: itinerary.scheduledArrivalTime,
            bookingDeadline: itinerary.bookingDeadline,
            departureDate: itinerary.scheduledDepartureDate,
            departureTime: itinerary.scheduledDepartureTime,
            scheduledArrivalDate: itinerary.scheduledArrivalDate,
            scheduledArrivalTime: itinerary.scheduledArrivalTime,
            scheduledDepartureDate: itinerary.scheduledDepartureDate,
            scheduledDepartureTime: itinerary.scheduledDepartureTime,
            hasBookingRequirements: itinerary.hasBookingRequirements,
            isBookable: itinerary.isBookable,
            isCancelled: itinerary.isCancelled,
            remainingTimeBeforeBooking: itinerary.remainingTimeBeforeBooking,
            hasStopRequest: itinerary.hasStopRequest,
            legs: this.toLegs(itinerary.legs),
            id: itinerary.id,
        };
    }

    static toLegs(legs: Leg[]): LegInput[] {
        return legs.map((leg) => ({
            bookingDeadline: leg.bookingDeadline,
            byFoot: leg.byFoot,
            duration: leg.duration,
            isBookable: leg.isBookable,
            length: leg.length,
            line: this.#toLine(leg.line),
            serviceId: leg.serviceId,
            transportCompanyId: leg.transportCompanyId,
            isFirstClassAuthorized: leg.isFirstClassAuthorized,
            vehicle: this.#toVehicle(leg.vehicle),
            messages: this.toMessages(leg.messages),
            stops: leg.stops.map((stop) => {
                return this.#toCommercialStop(stop);
            }),
            additionalInformation: leg.additionalInformation,
        }));
    }

    static toMessages(messages: JourneyMessage[] = []): JourneyMessageInput[] {
        return messages.map((message) => {
            return {
                id: message.id,
                title: message.title,
                htmlContent: message.htmlContent,
                type: toJourneyMessageType(message.type),
            };
        });
    }

    static toDepartureCartInput(departure: Departure): DepartureBasketInput {
        return {
            id: departure.id,
            departureDate: departure.plannedDepartureTime,
            serviceId: `${departure.serviceId}`,
            isBookable: departure.isBookable,
            hasBookingRequirements: departure.hasBookingRequirements,
            remainingTimeBeforeBooking: departure.remainingTimeBeforeBooking,
            bookingDeadline: departure.bookingDeadline,
            stop: {
                // todo on the return of departure.cms.json there is missing information in departure.stop
                // todo so we have to access to physicalStop
                name: departure.stop.physicalStop.associatedCommercialStop.name,
                id: departure.stop.id,
                line: {
                    id: departure.line.id,
                },
            },
        };
    }

    static #toLine(line: Line): LineInput {
        return {
            color: line.color,
            number: line.number,
            style: line.style,
            destination: line.destination,
            lineOfficialCode: line.lineOfficialCode,
            id: line.id,
            fontColor: line.fontColor,
            networkId: line.networkId,
        };
    }

    static #toVehicle(vehicle: Vehicle): VehicleInput {
        return {
            code: vehicle.code,
            transport: vehicle.transport,
            transportModeId: vehicle.transportModeId,
        };
    }

    static #toCommercialStop(stop: ItineraryStop): CommercialStopInput {
        const didok = stop.didok;
        if (!didok) {
            console.warn(
                new TechnicalError('[toCommercialStop] didok should be defined.', ErrorCodes.Graphql.Mapping, undefined, { stop }),
            );
        }

        return {
            physicalStopAssociated: this.#toPhysicalStop(stop.physicalStopAssociated),
            arrivalTime: stop.arrivalTime,
            cityName: stop.cityName ?? '',
            departureTime: stop.departureTime,
            didok: didok ?? 0,
            hasBookingRequirements: stop.hasBookingRequirements,
            id: stop.id,
            isArrivalPlace: stop.isArrivalPlace,
            isCancelled: stop.isCancelled,
            isDeparturePlace: stop.isDeparturePlace,
            isOptional: stop.isOptional,
            latLon: stop.latLon,
            name: stop.name,
            scheduledArrivalTime: stop.scheduledArrivalTime,
            scheduledDepartureTime: stop.scheduledDepartureTime,
            messages: this.toMessages(stop.messages),
            rank: stop.rank,
        };
    }

    static #toPhysicalStop(stop: PhysicalStop | undefined | null): PhysicalStopInput | undefined {
        if (!stop) {
            return undefined;
        }
        return {
            id: stop.id,
            letter: stop.letter,
            geometry: stop.geometry,
            lines: stop.lines.map((line) => {
                return {
                    id: line.id,
                    color: line.color,
                    destination: line.destination,
                    fontColor: line.fontColor,
                    lineOfficialCode: line.lineOfficialCode,
                    number: line.number,
                    style: line.style,
                    networkId: line.networkId,
                };
            }),
            transportMode: stop.transportMode,
            associatedCommercialStop: this.#toAssociatedCommercialStop(stop.associatedCommercialStop),
        };
    }

    static #toAssociatedCommercialStop(stop: AssociatedCommercialStop): AssociatedCommercialStopInput {
        return {
            cityName: stop.cityName ?? '',
            coordinates: {
                latitude: stop.coordinates.latitude,
                longitude: stop.coordinates.longitude,
            },
            didok: stop.didok,
            id: stop.id,
            lines: stop.lines.map((line) => {
                return {
                    id: line.id,
                    color: line.color,
                    destination: line.destination,
                    fontColor: line.fontColor,
                    lineOfficialCode: line.lineOfficialCode,
                    number: line.number,
                    style: line.style,
                    networkId: line.networkId,
                };
            }),
            name: stop.name,
        };
    }
}
