import { ArticlesBundle, Cart, CartOperationStatus, CheckoutStep, ConfigureTicket, TicketDuration } from '@traas/boldor/all-models';

export interface CartState {
    articlesBundles: ArticlesBundle[] | null;
    cart: Cart | null;
    cartOperations: {
        save: CartOperationStatus;
        buy: CartOperationStatus;
        requestStop: CartOperationStatus;
    };
    checkoutStep: CheckoutStep | undefined;
    configureTicket: ConfigureTicket;
    connectedUserArticlesBundle: ArticlesBundle | null;
    durationsFilter: TicketDuration[]; // Used only by app wich filter automatically tickets (for exemple TPC)
    isCartUpdating: boolean;
    isDefaultArticlesBundleLoading: boolean;
    isInitialized: boolean;
    isPaymentMeanSelected: boolean;
    nationalMarketingConsent?: boolean;
}

export const initialState: CartState = {
    articlesBundles: null,
    cart: null,
    cartOperations: {
        buy: CartOperationStatus.INITIAL,
        requestStop: CartOperationStatus.INITIAL,
        save: CartOperationStatus.INITIAL,
    },
    checkoutStep: undefined,
    configureTicket: {
        chooseTicketManually: null,
        selectedClass: null,
        selectedPriceType: null,
        selectedProductId: null,
        selectedTravelType: null,
        selectedZones: null,
        ticketTypes: [],
    },
    connectedUserArticlesBundle: null,
    durationsFilter: [],
    isCartUpdating: false,
    isDefaultArticlesBundleLoading: false,
    isInitialized: false,
    isPaymentMeanSelected: false,
};
