<traas-scroll-behavior-badge [mode]="scrollMode"></traas-scroll-behavior-badge>
<ion-content [scrollY]="false">
  <cdk-virtual-scroll-viewport
    traasUserScroll
    (userScrollStart)="onUserScrollStart()"
    (userScrollEnd)="onUserScrollEnd()"
    [maxBufferPx]="viewportBufferSize * itemHeightInPx"
    [minBufferPx]="viewportBufferSize * itemHeightInPx"
    [itemSize]="itemHeightInPx"
    (scrolledIndexChange)="scrollIndexChange($event)"
    >
    <ion-list class="journeys-list e2e-journeys-list">
      @if (showSkeletons) {
        <ng-content class="skeleton" select="[skeleton-start]"></ng-content>
      }
      <div [@items]="items.length" *cdkVirtualFor="let item of items; trackBy: identify" tappable>
        <ng-container *ngTemplateOutlet="itemTemplate; context: { $implicit: item.getData ? item.getData() : item }"></ng-container>
      </div>
      @if (showSkeletons) {
        <ng-content class="skeleton" select="[skeleton-end]"></ng-content>
      }
    </ion-list>
  </cdk-virtual-scroll-viewport>
</ion-content>
