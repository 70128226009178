import { GqlItineraryArticle } from './itinerary.service';
import { Itinerary, TicketDuration, Traveler } from '@traas/boldor/all-models';
import { GqlSupersaverItineraryArticle } from './itinerary-article.service.tpc';

export abstract class ItineraryArticleService {
    abstract generateItineraryTicketArticles(
        itinerary: Itinerary,
        passenger: Traveler,
        durationsFilter: TicketDuration[],
    ): Promise<GqlItineraryArticle[]>;

    abstract generateSupersaverItineraryArticles(
        itinerary: Itinerary,
        passenger: Traveler,
        durationsFilter: TicketDuration[],
    ): Promise<GqlSupersaverItineraryArticle[]>;
}
