import { JourneyAdapter } from '../../features/booking/models/journey';
import { mergeToFeatureCollection, SyntheseDateHelper } from '@traas/boldor/all-helpers';
import { ONE_MINUTE_IN_SECONDS } from '@traas/common/utils';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Itinerary, JourneyData, JourneyEnum, Leg } from '@traas/boldor/all-models';
import * as L from 'leaflet';
import { LatLngBounds } from 'leaflet';
import { GeoJSON } from 'geojson';
import { Line } from '@traas/common/models';
import { fromDiffInMinutesToDuration, isTrain } from '@traas/boldor/business-rules';
import { StopAdapter } from './stop';
import { LegAdapter } from './leg';

const ITINERARY_PATH_BOUNDS_PAD = 0.2;

export class ItineraryAdapter extends JourneyAdapter<Itinerary> {
    constructor(data: Itinerary | null) {
        if (!data) {
            throw new Error('Cannot create ItineraryAdapter with null or undefined data');
        }
        super(data);
        this.setType(JourneyEnum.Itinerary);
    }

    isStartingWithPedestrianLeg(): boolean {
        const firstLeg = this.getLegsAdapters()[0];
        if (!firstLeg) {
            throw new Error(`First leg is missing.`);
        }
        return firstLeg.isByFoot();
    }

    getDirection(): string {
        return '';
    }

    getDepartureStop(): StopAdapter {
        const leg = this.#getFirstTransportLeg();
        return new StopAdapter(leg.getFirstStop());
    }

    getTrack(): string | undefined {
        return this.getDepartureStop().getTrack();
    }

    getLegs(): Leg[] {
        return this.getData().legs;
    }

    getLegsAdapters(): LegAdapter[] {
        return this.getLegs().map((leg) => new LegAdapter(leg));
    }

    getTransportLegs(): LegAdapter[] {
        return this.getLegs()
            .filter(({ byFoot }) => !byFoot)
            .map((leg) => new LegAdapter(leg));
    }

    getArrivalStop(): StopAdapter {
        const transportLeg = _.last(this.getTransportLegs());
        if (!transportLeg) {
            throw new Error(`Can't found last transport leg.`);
        }
        return new StopAdapter(transportLeg.getLastStop());
    }

    getRealTimeDepartureDate(): Date {
        const transportLegs = this.getTransportLegs();
        if (transportLegs.length > 0) {
            const firstTransportLeg = this.#getFirstTransportLeg();
            return firstTransportLeg.getDepartureDate();
        } else {
            return SyntheseDateHelper.parseSyntheseStringDate(this.getData().departureDate);
        }
    }

    getRealTimeArrivalDate(): Date {
        const realTimeArrivalDate = SyntheseDateHelper.parseSyntheseStringDate(this.getData().arrivalDate);
        return SyntheseDateHelper.roundUpMinute(realTimeArrivalDate);
    }

    getDepartureDelay(): number {
        const realTime = moment(this.getRealTimeDepartureDate());
        const scheduledTime = moment(this.getScheduledDepartureDate());
        return realTime.diff(scheduledTime, 'seconds');
    }

    getItineraryDuration(): string {
        const realTimeArrivalDate = moment(this.getArrivalDate());
        const firstTransportLegRealTimeDepartureDate = this.getRealTimeDepartureDate();
        const realTimeDepartureDate = moment(firstTransportLegRealTimeDepartureDate);

        const realTimeArrivalDateRoundedUpMinute =
            realTimeArrivalDate.second() || realTimeArrivalDate.millisecond()
                ? realTimeArrivalDate.add(1, 'minute').startOf('minute')
                : realTimeArrivalDate.startOf('minute');

        const diffInSeconds = realTimeArrivalDateRoundedUpMinute.diff(realTimeDepartureDate, 'seconds');
        const diffInMinutes =
            diffInSeconds % ONE_MINUTE_IN_SECONDS === 0
                ? diffInSeconds / ONE_MINUTE_IN_SECONDS
                : Math.floor(diffInSeconds / ONE_MINUTE_IN_SECONDS) + 1;
        return fromDiffInMinutesToDuration(diffInMinutes);
    }

    getArrivalDate(): Date {
        return SyntheseDateHelper.parseSyntheseStringDate(this.getData().arrivalDate);
    }

    getScheduledDepartureDate(): Date {
        const transportLegs = this.getTransportLegs();
        if (transportLegs.length > 0) {
            const firstTransportLeg = this.#getFirstTransportLeg();
            return firstTransportLeg.getScheduledDepartureDate();
        } else {
            const scheduledDepartureDate = this.getData().scheduledDepartureDate;
            if (scheduledDepartureDate) {
                return SyntheseDateHelper.parseSyntheseStringDate(scheduledDepartureDate);
            }
        }
        throw new Error(`No scheduled departure date found.`);
    }

    getScheduledDepartureTimestamp(): number {
        return this.getScheduledDepartureDate().getTime();
    }

    getLines(): Line[] {
        return this.getTransportLegs().map((leg) => leg.getLine());
    }

    getId(): string {
        return this.data.id;
    }

    isOutdated(): boolean {
        return moment(this.getRealTimeDepartureDate()).isBefore(new Date());
    }

    getGeoJson(): GeoJSON.FeatureCollection {
        const geoJsons = this.getLegsAdapters()
            .map((leg) => leg.getGeoJson())
            .filter((geoJson) => !!geoJson) as GeoJSON[];
        return mergeToFeatureCollection(...geoJsons);
    }

    getLegWithStop(stop: StopAdapter): LegAdapter | undefined {
        return this.getLegsAdapters().find((leg) => leg.hasStop(stop));
    }

    getPreviousLeg(leg: LegAdapter): LegAdapter | undefined {
        return this.#getLegAtIndex(leg, this.#getLegIndex(leg) - 1);
    }

    getNextLeg(leg: LegAdapter): LegAdapter | undefined {
        return this.#getLegAtIndex(leg, this.#getLegIndex(leg) + 1);
    }

    isStartingWithTrainOptionalStop(): boolean {
        const firstTransportLegAdapter = this.#getFirstTransportLeg();
        const firstStopAdapter = new StopAdapter(firstTransportLegAdapter.getFirstStop());
        const transportName = firstTransportLegAdapter.getTransportName();
        return firstStopAdapter.isOptional() && isTrain(transportName);
    }

    getPathBounds(): LatLngBounds {
        const geoJSON = L.geoJSON(this.getGeoJson());
        return geoJSON.getBounds().pad(ITINERARY_PATH_BOUNDS_PAD);
    }

    #getLegAtIndex(leg: LegAdapter, index: number): LegAdapter | undefined {
        const legIndex = this.#getLegIndex(leg);
        if (legIndex === -1) {
            return undefined;
        }
        return this.getLegsAdapters()[index];
    }

    #getLegIndex(leg: LegAdapter): number {
        return this.getLegsAdapters().findIndex((aLeg) => aLeg.isEqual(leg));
    }

    #getFirstTransportLeg(): LegAdapter {
        const transportLeg = this.getTransportLegs()[0];
        if (!transportLeg) {
            throw new Error(`Can't found first transport leg.`);
        }
        return transportLeg;
    }
}

export function createEmptyItinerary(): Itinerary {
    return {
        arrivalDate: '',
        arrivalTime: '',
        scheduledArrivalDate: '',
        scheduledArrivalTime: '',
        legs: [],
        __type__: JourneyEnum.Itinerary,
        bookingDeadline: '',
        departureDate: '',
        departureTime: '',
        scheduledDepartureDate: '',
        scheduledDepartureTime: '',
        hasStopRequest: false,
        hasBookingRequirements: false,
        isBookable: false,
        isCancelled: false,
        remainingTimeBeforeBooking: 0,
        id: '',
    };
}

export function isItineraryJourney(journey: JourneyData | undefined): journey is Itinerary {
    return !!journey && journey.__type__ === JourneyEnum.Itinerary;
}
