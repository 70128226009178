import { inject, Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { filter } from 'rxjs/operators';
import { PreflightService } from './preflight/preflight.service';
import { NavController } from '@ionic/angular';
import { OnlineService } from '@traas/common/utils';

@Injectable({
    providedIn: 'root',
})
export class ForceUpdateService {
    #navCtrl = inject(NavController);
    #onlineService = inject(OnlineService);
    #preflightService = inject(PreflightService);

    async redirectToUpdatePageIfOutdatedApp(): Promise<void> {
        const redirectIfOutdated = async (): Promise<void> => {
            if (await this.#preflightService.fetchIsOutdated()) {
                await this.#navCtrl.navigateRoot('/update');
            }
        };

        const whenBackOnline = (): Promise<boolean> =>
            firstValueFrom(this.#onlineService.$getIsOnline().pipe(filter((isOnline) => isOnline)));

        try {
            if (await this.#onlineService.isOffline()) {
                whenBackOnline().then(() => redirectIfOutdated());
            } else {
                await redirectIfOutdated();
            }
        } catch (error) {
            console.error('Error checking online status or redirecting:', error);
        }
    }
}
