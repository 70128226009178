import { inject, Injectable } from '@angular/core';
import { OnAuthenticateService } from '@traas/common/feature-account';
import { firstValueFrom } from 'rxjs';
import { OnAuthenticationGQL } from '@traas/boldor/graphql-generated/graphql';

@Injectable()
export class TraasOnAuthenticateService extends OnAuthenticateService {
    readonly #onAuthenticateGql = inject(OnAuthenticationGQL);

    constructor() {
        super();
    }

    async onAuthenticate(): Promise<boolean> {
        const result = await firstValueFrom(this.#onAuthenticateGql.mutate());
        return result.data?.onAuthentication ?? false;
    }
}
