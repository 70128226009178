@if (lineIconPath) {
    @if (isLegacyLineIcon) {
        <app-symbol-svg [symbolURL]="lineIconPath"></app-symbol-svg>
    } @else {
        {{ '' }}
        <!--Ugly hack to fix the issue with img tag not being rendered in the DOM when used into a new ion-checkbox-->
        <img [src]="lineIconPath" [ngStyle]="{ width: imgWidth }" alt="{{ lineNumber }}" />
    }
} @else {
    <div
        [ngClass]="lineStyle"
        [style.background-color]="lineStyle ? null : backgroundColor"
        [style.color]="lineStyle ? null : color"
        class="line-badge"
    >
        <span>{{ lineNumber }}</span>
    </div>
}
