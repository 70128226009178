<ion-row [class.cancelled]="stop.isCancelled()">
  <div class="itinerary">
    <div class="itinerary-infos">
      <div class="line-track">
        @if (isFirst || isLast || !!track) {
          <app-track-icon [track]="track"></app-track-icon>
        }
      </div>

      <div class="booking-icon">
        <ion-icon
          #thermometerStopIcon
          [class.is-first]="isFirst"
          [class.is-last]="isLast"
          [class.tpg]="!isTPC"
          [src]="thermoStopIconPath"
        ></ion-icon>
      </div>

      @if (hasDisruption) {
        <app-warning-icon (click)="presentWarningMessage($event)"></app-warning-icon>
      }

      <div class="stop-name">
        {{ stop | stopName }}
      </div>
    </div>
    <div class="departure-time">
      @if (isTPC) {
        <div class="itinerary-options">
          <ion-icon [src]="stopIconPathItinerary"></ion-icon>
        </div>
      }

      @if (isFirst && departureDatetime) {
        <span>
          {{ departureDatetime | date: 'HH:mm' }}
        </span>
        <span class="delay">{{ stop.getDepartureDelay() }}</span>
      } @else {
        @if (arrivalDatetime) {
          <span>
            {{ arrivalDatetime | date: 'HH:mm' }}
          </span>
          <span class="delay">{{ stop.getArrivalDelay() }}</span>
        }
      }

    </div>
  </div>
  @if (stop.isCancelled()) {
    <div class="cancelled-message-container">
      <div class="stop-name cancelled-message">
        <traas-cancelled-trip-icon [isBus]="false"></traas-cancelled-trip-icon>
        @if (!!cancellationMessage) {
          <span [innerHTML]="cancellationMessage.htmlContent"></span>
        }
      </div>
    </div>
  }
</ion-row>
