import { inject, Injectable } from '@angular/core';
import { UserInfo, UserInfoGender } from '@traas/capacitor/swisspass-plugin';
import { SwisspassService } from './SwisspassService';
import { HttpClient } from '@angular/common/http';
import { environment } from '@traas/boldor/environments';
import { firstValueFrom } from 'rxjs';
import { TokensResponse } from './tokens.response';
import { PkceService } from '../../../PKCE/pkce.service';
import {
    getTokenExpirationDurationInSeconds,
    isTokenExpired,
    isTokenValid,
    isValidString,
    NINETY_SECONDS,
    ONE_MINUTE_IN_MS,
    ONE_SECOND_IN_MS,
} from '@traas/common/utils';
import { Tokens } from './tokens';
import { GatewayEndpoints } from '@traas/boldor/common/gateway-endpoints';

const LOCAL_STORAGE_ACCESS_TOKEN_KEY = 'accessToken';
const LOCAL_STORAGE_REFRESH_TOKEN_KEY = 'refreshToken';

export interface SwisspassUserInfoWeb {
    authenEmail: string;
    birthdate: string;
    gender: string; // MALE, FEMALE
    givenname: string; //firstname
    salutation: string; //HERR, FRAU
    sn: string; //lastname
    sub: string;
    tkid: string;
}

@Injectable({
    providedIn: 'root',
})
export class WebSwisspassService implements SwisspassService {
    readonly #http = inject(HttpClient);
    readonly #pkceService = inject(PkceService);
    #timeout!: number;
    #cachedUserInfo: UserInfo | null = null;

    readonly #loginUrl: string;
    readonly #redirectUri: string;
    readonly #refreshTokenUrl: string;
    readonly #userInfoUrl: string;

    constructor() {
        if (!environment.webSwisspassRedirectUri) {
            throw new Error('Swisspass redirectUri is not defined');
        }
        this.#loginUrl = GatewayEndpoints.webSwisspassLoginUrl;
        this.#redirectUri = environment.webSwisspassRedirectUri;
        this.#refreshTokenUrl = GatewayEndpoints.webSwisspassRefreshTokenUrl;
        this.#userInfoUrl = GatewayEndpoints.webSwisspassUserInfoUrl;

        void this.#startRefreshIfTokensAreValid();
    }

    async getUserInfo(): Promise<UserInfo | null> {
        if (this.#cachedUserInfo) {
            // this function is called a lot by the app
            // so in order to not spam the swisspass api we cache the result
            return this.#cachedUserInfo;
        }

        const accessToken = await this.getAccessToken();
        if (!accessToken) {
            return null;
        }

        const userInfo = await this.fetchUserInfo(accessToken);
        this.#cachedUserInfo = this.#mapUserInfo(userInfo);
        return this.#cachedUserInfo;
    }

    async isAuthenticatedOnSwissPass(): Promise<boolean> {
        return (await this.#getTokensIfValid()) !== null;
    }

    saveTokensToLocalStorage(accessToken: string, refreshToken: string): void {
        if (!isTokenValid(accessToken) || !isValidString(refreshToken)) {
            console.error('tokens are invalid and therefore will not be saved in localstorage');
            return;
        }
        localStorage.setItem(LOCAL_STORAGE_REFRESH_TOKEN_KEY, refreshToken);
        localStorage.setItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY, accessToken);
    }

    async fetchUserInfo(accessToken: string): Promise<SwisspassUserInfoWeb> {
        try {
            return await firstValueFrom(
                this.#http.post<SwisspassUserInfoWeb>(
                    this.#userInfoUrl,
                    {
                        accessToken,
                    },
                    {
                        responseType: 'json',
                    },
                ),
            );
        } catch (error) {
            console.error('Failed to fetch user info:', error);
            throw new Error('Failed to fetch user info');
        }
    }

    async getAccessToken(): Promise<string | null> {
        return localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY);
    }

    async logout(): Promise<boolean> {
        this.#removeTokensFromLocalStorage();
        this.#cachedUserInfo = null;
        return true;
    }

    async forceLogout(): Promise<void> {
        console.log('forceLogout method is not implemented.');
    }

    async openSwissPassMobile(): Promise<void> {
        console.log('openSwissPassMobile method is not implemented.');
    }

    async openSwisspassLoginPage(): Promise<{ isLoggedIn: boolean }> {
        const { codeChallenge, verifierKey } = this.#pkceService.getVerifierAndChallenge();
        const spLoginUrl = await firstValueFrom(
            this.#http.get(this.#loginUrl, {
                responseType: 'text',
                params: {
                    codeChallenge,
                    verifierKey,
                    redirectUri: this.#redirectUri,
                },
            }),
        );

        // Browser window
        const features = 'menubar=no, status=no, scrollbars=no, width=600, height=1200';
        const target = 'Swisspass';
        const browser = window.open(spLoginUrl, target, features) as Window;
        const closed = await this.#getClosedEventForWebPlatform(browser);

        if (closed) {
            await this.#startRefreshIfTokensAreValid();
            return { isLoggedIn: true };
        }

        return { isLoggedIn: false };
    }

    async isSwissPassAvailable(): Promise<boolean> {
        console.log('isSwissPassAvailable method is not implemented.');
        return false;
    }

    async registerSwissPass(): Promise<string | null> {
        console.log('registerSwissPass method is not implemented.');
        return null;
    }

    setConfig(config: { seeSubscriptionsUrl: string }): void {
        console.log('setConfig method is not implemented.');
    }

    async activateSwissPassMobile(): Promise<boolean> {
        console.log('activateSwissPassMobile method is not implemented.');
        return false;
    }

    async canActivateSwissPass(): Promise<{ canActivate: boolean; status: string }> {
        console.log('canActivateSwissPass method is not implemented.');
        return { canActivate: false, status: '' };
    }

    async linkSwissPassToAccount(): Promise<void> {
        console.log('linkSwissPassToAccount method is not implemented.');
    }

    async openLoginDataManagementPage(): Promise<void> {
        console.log('openLoginDataManagementPage method is not implemented.');
    }

    async openAccountManagementPage(): Promise<void> {
        console.log('openAccountManagementPage method is not implemented.');
    }

    async #getTokensIfValid(): Promise<Tokens | null> {
        const tokens = await this.#getTokensFromStorage();
        if (tokens === null) {
            return null;
        }

        if (isTokenExpired(tokens.accessToken)) {
            this.#removeTokensFromLocalStorage();
            return null;
        }

        return tokens;
    }

    async #startRefreshIfTokensAreValid(): Promise<void> {
        const tokens = await this.#getTokensIfValid();
        if (tokens === null) {
            return;
        }

        await this.#refreshWhenReadyToRefresh(tokens.accessToken, tokens.refreshToken);
    }

    // This function recursive
    // We refresh the token if it's expired or expired in less than 90 seconds
    // If not expired or expire in more than 90 seconds we set a timeout to call this function again 1 minute before the token expires
    async #refreshWhenReadyToRefresh(accessToken: string, refreshToken: string): Promise<void> {
        window.clearTimeout(this.#timeout);

        if (isTokenExpired(accessToken, NINETY_SECONDS)) {
            const tokens = await this.#refreshTokens(refreshToken);
            // Recursive call
            void this.#refreshWhenReadyToRefresh(tokens.accessToken, tokens.refreshToken);
            return;
        }

        const tokenExpirationInMs = getTokenExpirationDurationInSeconds(accessToken) * ONE_SECOND_IN_MS;
        const oneMinuteBeforeExpirationInMS = tokenExpirationInMs - ONE_MINUTE_IN_MS;
        // Delayed recursive call
        this.#timeout = window.setTimeout(() => this.#refreshWhenReadyToRefresh(accessToken, refreshToken), oneMinuteBeforeExpirationInMS);
    }

    async #refreshTokens(refreshToken: string): Promise<Tokens> {
        const { access_token, refresh_token } = await this.#getSwisspassRefreshToken(refreshToken);
        this.saveTokensToLocalStorage(access_token, refresh_token);
        return { accessToken: access_token, refreshToken: refresh_token };
    }

    #removeTokensFromLocalStorage(): void {
        localStorage.removeItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY);
        localStorage.removeItem(LOCAL_STORAGE_REFRESH_TOKEN_KEY);
    }

    async #getTokensFromStorage(): Promise<Tokens | null> {
        const [accessToken, refreshToken] = await Promise.all([
            this.getAccessToken(),
            localStorage.getItem(LOCAL_STORAGE_REFRESH_TOKEN_KEY),
        ]);

        if (!accessToken || !refreshToken) {
            this.#removeTokensFromLocalStorage();
            return null;
        }
        return { accessToken, refreshToken };
    }

    async #getSwisspassRefreshToken(refreshToken: string): Promise<TokensResponse> {
        try {
            return await firstValueFrom(
                this.#http.post<TokensResponse>(
                    this.#refreshTokenUrl,
                    {
                        refreshToken,
                    },
                    {
                        responseType: 'json',
                    },
                ),
            );
        } catch (error) {
            console.error('Failed to fetch refresh token:', error);
            throw new Error('Failed to fetch refresh token');
        }
    }

    #getClosedEventForWebPlatform(popupWindow: Window): Promise<boolean> {
        return new Promise((resolve) => {
            // eslint-disable-next-line prefer-const
            let timer: number;
            const checkBrowserClosedState = (): void => {
                if (popupWindow.closed) {
                    clearInterval(timer);
                    resolve(true);
                }
            };
            timer = window.setInterval(checkBrowserClosedState, 500);
        });
    }

    #mapUserInfo(userInfo: SwisspassUserInfoWeb): UserInfo {
        return {
            gender: userInfo.gender as UserInfoGender,
            salutation: userInfo.salutation,
            birthdate: userInfo.birthdate,
            firstName: userInfo.givenname,
            lastName: userInfo.sn,
            tkid: userInfo.tkid,
            userId: userInfo.sub,
            authenEmail: userInfo.authenEmail,
        };
    }
}
