<ion-header>
  <app-page-title
    [canGoBack]="false"
    [showHomeLogo]="false"
    [title]="'startup-notification.title' | translate"
    iconUrl="assets/icons/bubble-stroke.svg"
  ></app-page-title>
</ion-header>
<ion-content>
  @if ($message | async; as message) {
    <div class="message">
      <h1 class="message-title">{{ message.title }}</h1>
      <div class="message-content" [innerHTML]="message.htmlContent | safeHtml"></div>
    </div>
  }
</ion-content>
<ion-footer class="message-footer">
  <ion-button class="e2e-mark-as-read" elevation="1" (click)="acknowledge()">{{
    'startup-notification.mark-as-read-button' | translate
  }}</ion-button>
  <ion-button class="e2e-see-later" elevation="1" (click)="postpone()">{{
    'startup-notification.see-later-button' | translate
  }}</ion-button>
</ion-footer>
