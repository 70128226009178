import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { LeaveOrArriveEnum, MapMode, ResultMode } from '@traas/boldor/all-models';
import { HomeState } from '../../features/home/store';
import { MapSelectors } from '../../features/home/store/map';
import { CompanyService } from '@traas/boldor/company';
import { DatePickerBarSearchOption } from '../date-picker-bar/date-picker-bar.component';
import { DatetimePickerModalComponent } from '../datetime-picker-modal/datetime-picker-modal.component';
import { BehaviorSubject, Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { format } from 'date-fns';

export interface DatePickerConfig {
    mode: ResultMode;
    allowOldDates?: boolean;
    locked?: boolean;
    searchOption?: DatePickerBarSearchOption;
}

@Component({
    selector: 'app-date-picker',
    templateUrl: './date-picker.component.html',
    styleUrls: ['date-picker.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatePickerComponent implements OnInit, AfterViewInit {
    @Output() dateSelected = new EventEmitter<Date>();
    @Output() searchOptionChange = new EventEmitter<DatePickerBarSearchOption>();
    @Output() queryKindChange = new EventEmitter<LeaveOrArriveEnum>();

    @Input()
    set pickedDateTime(value: Date) {
        this.pickedDateTimeValue = value;
        this.#updateDisplayedDate();
    }

    mapModeEnum: typeof MapMode = MapMode;
    pickedDateTimeValue = new Date();
    $formattedDate = new BehaviorSubject<string>('');
    $formattedTime = new BehaviorSubject<string>('');
    DatePickerBarSearchOption = DatePickerBarSearchOption;

    readonly isTPC = CompanyService.isTPC();
    readonly $getMapMode: Observable<MapMode>;

    #_datePickerConfig: DatePickerConfig;

    readonly #defaultConfig: Partial<DatePickerConfig> = {
        locked: false,
        allowOldDates: false,
        searchOption: DatePickerBarSearchOption.DEPARTURE,
    };

    constructor(
        private store: Store<HomeState>,
        private modalController: ModalController,
    ) {
        this.$getMapMode = this.#$buildGetMapMode();
    }

    get datePickerConfig(): DatePickerConfig {
        return this.#_datePickerConfig;
    }

    @Input() set datePickerConfig(datePickerOptions: DatePickerConfig) {
        this.#_datePickerConfig = {
            ...this.#defaultConfig,
            ...datePickerOptions,
        };
    }

    ngOnInit(): void {
        this.#updateDisplayedDate();
    }

    ngAfterViewInit(): void {
        this.#updateDisplayedDate();
    }

    async showDatetimePicker(): Promise<void> {
        if (this.datePickerConfig.locked) {
            return;
        }
        const modal = await this.modalController.create({
            component: DatetimePickerModalComponent,
            componentProps: { pickedDate: this.pickedDateTimeValue },
            breakpoints: [0, 0.5],
            initialBreakpoint: 0.5,
        });
        await modal.present();
        const { data } = await modal.onDidDismiss();
        if (data) {
            this.#updateAndEmitDate(data, this.datePickerConfig.allowOldDates);
        }
    }

    onSelect(event: Event): void {
        const selectedSearchOption = (event as CustomEvent).detail.value;
        this.searchOptionChange.emit(selectedSearchOption);
        this.datePickerConfig.searchOption = selectedSearchOption;
        if (selectedSearchOption === DatePickerBarSearchOption.DEPARTURE) {
            this.updateQueryKindBy(LeaveOrArriveEnum.LeaveAt);
        } else {
            this.updateQueryKindBy(LeaveOrArriveEnum.ArriveBy);
        }
    }

    updateQueryKindBy(queryKind: LeaveOrArriveEnum): void {
        this.queryKindChange.emit(queryKind);
    }

    isModeItinerary(): boolean {
        return this.datePickerConfig.mode === ResultMode.ITINERARY;
    }

    #updateAndEmitDate(date: Date, allowOldDates = false): void {
        let newDate = date || new Date();
        if (!allowOldDates) {
            const now = new Date();
            if (!newDate || newDate < now) {
                newDate = now;
            }
        }
        this.pickedDateTimeValue = newDate;
        this.dateSelected.emit(this.pickedDateTimeValue);
        this.#updateDisplayedDate();
    }

    #updateDisplayedDate(): void {
        this.#updateFormattedDateAndTime(this.pickedDateTimeValue);
    }

    #updateFormattedDateAndTime(date: Date): void {
        this.$formattedDate.next(format(date, 'eee dd.MM'));
        this.$formattedTime.next(format(date, 'HH:mm'));
    }

    #$buildGetMapMode(): Observable<MapMode> {
        return this.store.select(MapSelectors.getMapMode).pipe(shareReplay(1));
    }
}
