import { environment } from '@traas/boldor/environments';

export class GatewayEndpoints {
    static get swisspassSSOUrl(): string {
        return environment.gatewayUrl + '/swisspass/getSwisspassSingleSignOnUrl';
    }

    static get webSwisspassUserInfoUrl(): string {
        return environment.gatewayUrl + '/swisspass/getSwisspassUserInfo';
    }

    static get webSwisspassRefreshTokenUrl(): string {
        return environment.gatewayUrl + '/swisspass/getSwisspassRefreshTokenForDevOnly';
    }

    static get webSwisspassLoginUrl(): string {
        return environment.gatewayUrl + '/swisspass/getSwisspassLoginUrl';
    }

    static get webSwisspassTokensUrl(): string {
        return environment.gatewayUrl + '/swisspass/getSwisspassTokensForDevOnly';
    }

    static get bookmarksMigrationUrl(): string {
        return environment.gatewayUrl + '/bookmarks/conversion/synthese-to-hafas';
    }
}
