<ion-toolbar>
  @if (canGoBack) {
    <ion-buttons slot="start">
      <ion-back-button oitClickUsingBackButton [defaultHref]="defaultHref" text=""></ion-back-button>
    </ion-buttons>
  }
  <ion-title>
    <div class="center">
      @if (iconUrl || iconName) {
        <ion-icon [name]="iconName" [src]="iconUrl" [ngStyle]="{ 'font-size': iconSize }"></ion-icon>
      }
      <span class="e2e-page-title">{{ title }}</span>
    </div>
  </ion-title>
  <ion-buttons slot="end">
    @if (showHomeLogo) {
      <ion-button
        (click)="homeButtonClicked()"
        [disabled]="isHomeButtonDisabled"
        [style.opacity]="isHomeButtonDisabled ? '1' : ''"
        >
        <ion-icon src="assets/icon/headerlogo.svg"></ion-icon>
      </ion-button>
    }
  </ion-buttons>
</ion-toolbar>
