/* eslint-disable @typescript-eslint/member-ordering */
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BookingState, BookingStoreActions, BookingStoreSelectors } from '../../../features/booking/store';
import {
    Departure,
    DepartureOrderViewModel,
    ItineraryOrderViewModel,
    LineStyle,
    OrderViewModel,
    QuickTicketsOrderViewModel,
    Ticket,
    TransitStop,
} from '@traas/boldor/all-models';
import { DepartureService } from '../../../features/departure/services/departure.service';
import { isTrain } from '@traas/boldor/business-rules';
import { OrderType } from '@traas/boldor/graphql-generated/graphql';
import { LoadingController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { AlertService } from '../../../services/common/alert.service';
import { CompanyService } from '@traas/boldor/company';
import { RefundOrderService } from '../../../services/common/purchase/refund-order.service';
import { RoutingService } from '@traas/common/routing';
import { StopRequestService } from '../../../services/common/stop-request/stop-request.service';
import { OrderStorageService } from '../../../services/common/order/order-storage.service';
import { GqlToFrontOrderConverter } from '../../../models/order';
import * as moment from 'moment';
import { Observable, of } from 'rxjs';
import { catchError, filter, first, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { LineService } from '../../../services/common/line/line.service';

interface OrderDetailsPageUrlParams {
    orderType: OrderType;
    orderId: string;
}

@Component({
    selector: 'app-order-details',
    templateUrl: './order-details.component.html',
    styleUrls: ['./order-details.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderDetailsPage {
    #orderStorageService = inject(OrderStorageService);
    #lineService = inject(LineService);

    $order: Observable<OrderViewModel>;
    $transitStops: Observable<TransitStop[]>;
    $hasStopRequest: Observable<boolean>;
    $isRefundable: Observable<boolean>;
    lineStyle: LineStyle;

    constructor(
        protected activateRoute: ActivatedRoute,
        protected alertService: AlertService,
        protected refundService: RefundOrderService,
        protected routingService: RoutingService,
        protected departureService: DepartureService,
        protected stopRequestService: StopRequestService,
        protected store: Store<BookingState>,
        protected loadingController: LoadingController,
    ) {
        this.$order = this.#$buildOrder().pipe(
            filter((order): order is OrderViewModel | ItineraryOrderViewModel => !!order),
            tap((order) => {
                if (this.isDeparture(order)) {
                    this.lineStyle = this.#lineService.fromDeparture(order.departure);
                    this.$transitStops = this.#$getTransitStops(order.departure);
                }
            }),
            shareReplay({ bufferSize: 1, refCount: true }),
        );

        this.$hasStopRequest = this.#$buildHasStopRequest();
        this.$isRefundable = this.#$buildIsRefundable();
    }

    isItinerary(orderViewModel: OrderViewModel): orderViewModel is ItineraryOrderViewModel {
        return GqlToFrontOrderConverter.isViewModelItineraryOrder(orderViewModel);
    }

    isQuick(orderViewModel: OrderViewModel): orderViewModel is QuickTicketsOrderViewModel {
        return GqlToFrontOrderConverter.isViewModelQuickTicketsOrder(orderViewModel);
    }

    isDeparture(orderViewModel: OrderViewModel): orderViewModel is DepartureOrderViewModel {
        return GqlToFrontOrderConverter.isViewModelDepartureOrder(orderViewModel);
    }

    async onCancelStopRequest(orderId: string): Promise<void> {
        if (await this.alertService.confirmationAlert(`Annuler la demande de passage ?`, `Cette action n'est pas réversible`)) {
            const loader = await this.#presentCancelLoader();
            const success = await this.stopRequestService.cancelStopRequest(orderId);
            await loader.dismiss();
            if (success) {
                await this.alertService.presentCancelStopRequestSucceeded();
                this.$order.pipe(first()).subscribe((order) => {
                    this.#setHasStopRequestToFalse(order);
                    this.store.dispatch(new BookingStoreActions.UpdateOrderById(order));
                });
            } else {
                await this.alertService.presentCancelStopRequestFailure();
            }
        }
    }

    async onConfirmRefundOrder(ticketOrder: OrderViewModel): Promise<void> {
        const hasStopRequest = this.#computeHasStopRequest(ticketOrder);
        const refundConfirmed = await this.alertService.presentConfirmRefundOrder(hasStopRequest);
        if (refundConfirmed) {
            const loader = await this.#presentRefundLoader();
            const refundResponse = await this.refundService.refundOrder(ticketOrder);
            if (refundResponse?.success) {
                void this.alertService.presentSuccessRefundOrder();

                const updatedOrderViewModel = GqlToFrontOrderConverter.toOrderViewModel(refundResponse.order);
                this.store.dispatch(new BookingStoreActions.UpdateOrderById(updatedOrderViewModel));
                await loader.dismiss();
            } else {
                await loader.dismiss();
                const errorMessage = `Le remboursement a échoué, veuillez contacter le service client.
                                      Détails: ${refundResponse?.error}`;
                await this.alertService.confirmationAlert('Erreur', errorMessage);
            }
        }
    }

    canRefundOrder(order: OrderViewModel | undefined): boolean {
        if (!order) {
            return false;
        }

        const { orderType, tickets } = order;
        const allowedOrderTypes = [OrderType.BuyItineraryTickets, OrderType.BuyQuickTicket, OrderType.BuyDepartureTickets];
        const isOrderTypeAllowed = allowedOrderTypes.includes(orderType);
        if (!isOrderTypeAllowed) {
            return false;
        }

        const isCompanyAllowed = CompanyService.isTraas();
        if (!isCompanyAllowed) {
            return false;
        }

        const isSupersaverOrder = tickets.some(({ article: { isSupersaver } }) => isSupersaver);
        const hasUncancelledTickets = tickets.length > 0 && tickets.some(({ isCancelled }) => !isCancelled);

        const validFrom = this.#orderStorageService.convertStringDateToDateWithTimezoneOffset(this.#getValidityDateFrom(order));
        const now = new Date();
        const hasStartValidityDateNotReachedYet = now <= validFrom;

        return hasUncancelledTickets && hasStartValidityDateNotReachedYet && !isSupersaverOrder;
    }

    #getValidityDateFrom(order: OrderViewModel): string {
        const { orderType } = order;
        const { BuyQuickTicket, BuyDepartureTickets, BuyItineraryTickets } = OrderType;

        if (orderType === BuyQuickTicket) {
            return order.tickets[0].article.validity.from;
        }
        if (orderType === BuyDepartureTickets || orderType === BuyItineraryTickets) {
            const copy = [...order.tickets];
            const sortedTickets = copy.sort(this.#sortTicketsByValidityDateFromAsc);
            const [soonerTicket] = sortedTickets;
            return soonerTicket.article.validity.from;
        }
        throw new Error(`[getValidityDateFrom] orderType ${orderType} not supported.`);
    }

    #$buildHasStopRequest(): Observable<boolean> {
        return this.activateRoute.params.pipe(
            first(),
            map((params) => params as OrderDetailsPageUrlParams),
            switchMap(({ orderId }: OrderDetailsPageUrlParams) =>
                this.store.select(BookingStoreSelectors.getAllOrders).pipe(map((orders) => orders.find(({ id }) => id === orderId))),
            ),
            map((order) => {
                return this.#computeHasStopRequest(order);
            }),
            shareReplay(1),
        );
    }

    #presentCancelLoader(): Promise<HTMLIonLoadingElement> {
        return this.#presentLoader('Annulation en cours');
    }

    #presentRefundLoader(): Promise<HTMLIonLoadingElement> {
        return this.#presentLoader('Remboursement en cours');
    }

    async #presentLoader(message: string): Promise<HTMLIonLoadingElement> {
        const loader: HTMLIonLoadingElement = await this.loadingController.create({
            message: message,
            cssClass: 'custom-loading',
        });
        await loader.present();
        return loader;
    }

    #setHasStopRequestToFalse(order: OrderViewModel): void {
        if (this.isItinerary(order)) {
            order.itinerary.hasStopRequest = false;
        } else if (this.isDeparture(order)) {
            order.departure.hasStopRequest = false;
        }
    }

    /**
     * The first here is to not reload the stored data if there is a call to resfresh it in
     * background then re-emit more times this observable.
     */
    #$buildOrder(): Observable<OrderViewModel | ItineraryOrderViewModel | undefined> {
        return this.activateRoute.params.pipe(
            map((params) => params as OrderDetailsPageUrlParams),
            switchMap(({ orderId }: OrderDetailsPageUrlParams) =>
                this.store.select(BookingStoreSelectors.getAllOrders).pipe(map((orders) => orders.find(({ id }) => id === orderId))),
            ),
            catchError((error) => {
                console.error('Error occurred : ', error);
                this.routingService.navigateToBookings();
                return of(undefined);
            }),
        );
    }

    #$getTransitStops(departure: Departure): Observable<TransitStop[]> {
        const departureStop = departure.stop;
        return this.departureService
            .$getDepartureDetails(
                departure.serviceId,
                departureStop.id,
                departureStop.rank,
                moment(departure.plannedDepartureTime).toDate(),
            )
            .pipe(map((departureDetails) => departureDetails.getTransitStops()));
    }

    #$buildIsRefundable(): Observable<boolean> {
        return this.activateRoute.params.pipe(
            first(),
            map((params) => params as OrderDetailsPageUrlParams),
            switchMap(({ orderId }: OrderDetailsPageUrlParams) =>
                this.store.select(BookingStoreSelectors.getAllOrders).pipe(map((orders) => orders.find(({ id }) => id === orderId))),
            ),
            filter((order) => !!order?.orderType),
            filter((order) => !!order),
            map((order) => !order?.isProcessing && this.canRefundOrder(order)),
            shareReplay(1),
        );
    }

    #computeHasStopRequest(ticketOrder: OrderViewModel | undefined): boolean {
        if (!ticketOrder) {
            return false;
        }
        if (this.isItinerary(ticketOrder)) {
            return ticketOrder.itinerary.hasStopRequest;
        }
        if (this.isDeparture(ticketOrder)) {
            return ticketOrder.departure.hasStopRequest;
        }
        return false;
    }

    protected readonly isTrain = isTrain;

    trackById(index: number, stop: TransitStop): string {
        return stop.id;
    }

    #sortTicketsByValidityDateFromAsc(ticketA: Ticket, ticketB: Ticket): number {
        const a_before_b = -1;
        const b_before_a = 1;
        return ticketA.article.validity.from > ticketB.article.validity.to ? b_before_a : a_before_b;
    }
}
