<ion-grid>
  @if (!ticket.isProcessing) {
    <ion-row class="ticket">
      @if (!!ticket) {
        <ion-col class="ion-text-center">
          @if (ticket.isCancelled) {
            <p class="cancelled-ticket">{{ 'my-journeys.canceled-ticket' | translate }}</p>
            <p class="cancelled-ticket">
              {{ 'my-journeys.refunded-on' | translate }}
              {{ ticket.refundDate | date: 'dd.MM.yyyy' }}
              {{ 'my-journeys.at' | translate }}
              {{ ticket.refundDate | date: 'HH:mm' }}
            </p>
            <p class="cancelled-ticket">
              {{ ticket.referenceNumber?.label || ('my-journeys.ticket-detail.reference-number' | translate) }}
              {{ getReferenceNumber() }}
            </p>
          } @else {
            @if (ticket.qrCodeData) {
              <img [src]="ticket.qrCodeData" alt="QrCode" />
            } @else {
              <p class="expired-ticket">{{ 'my-journeys.expired-ticket' | translate }}</p>
            }
          }
        </ion-col>
      } @else {
        <p>{{ 'my-journeys.ticket-not-found' | translate }}</p>
      }
    </ion-row>
    @if (ticket.article; as article) {
      <div class="details">
        @if (ticket.passenger; as passenger) {
          <div class="passenger-info">
            <ion-row>
              <ion-col size="12">{{ passenger.firstName }} {{ passenger.lastName }}</ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="12">{{ passenger.birthDate | date: 'dd.MM.yyyy' }}</ion-col>
            </ion-row>
          </div>
        }
        <ion-row class="travel-type title">
          <ion-col size="12">{{ getBilletTypeLabel(article) }}</ion-col>
        </ion-row>
        @if (thereIsLocationsValidityToShow(article)) {
          <div class="travel-type-validity">
            @for (locationValidity of article.locationsValidity.values; track locationValidity) {
              <ion-row>
                @if (isDifferentThanZonesText(article, locationValidity)) {
                  <ion-col
                    [ngClass]="isViaLocation(locationValidity) ? 'via' : 'main-location'"
                    size="12"
                    >
                    {{ locationValidity }}
                  </ion-col>
                }
              </ion-row>
            }
          </div>
        }
        @if (thereIsLocationsChoiceToShow(article)) {
          <div class="travel-type-choices">
            <ion-row class="label">{{ article.locationsChoice.label }}</ion-row>
            @for (locationChoice of article.locationsChoice.values; track locationChoice) {
              <ion-row>
                @if (isDifferentThanZonesText(article, locationChoice)) {
                  <ion-col
                    [ngClass]="isViaLocation(locationChoice) ? 'via' : 'main-location'"
                    size="12"
                    >
                    {{ locationChoice }}
                  </ion-col>
                }
              </ion-row>
            }
          </div>
        }
        @for (include of article.includes; track include) {
          <ion-row>
            <ion-col size="12">{{ include }}</ion-col>
          </ion-row>
        }
        <ion-row>
          <ion-col size="12">{{ getZonesLabel(article.zones) }}</ion-col>
        </ion-row>
        @if (getZonesText(article.zones); as zones) {
          <ion-row class="zones" [class.tpc]="isTpc">
            <ion-col size="12">{{ zones }}</ion-col>
          </ion-row>
        }
        <ion-row class="travel-options">
          <ion-col size="12" class="travel-class"
            >{{ tripLabel }} <span class="reduction">{{ article.reduction?.description }}</span>
          </ion-col>
          <ion-col size="12" class="price">
            {{ getFormattedPrice(ticket.article.prices[0]) }}
            {{ ticket.article.vatLabel }}
          </ion-col>
        </ion-row>
        <div class="availability-date">
          <ion-row class="label">
            {{ article.validity.label || ('my-journeys.ticket-availability' | translate) }}
          </ion-row>
          <ion-row class="from"> {{ article.validity.from | date: dateTimeFormat }}-</ion-row>
          <ion-row class="to">
            {{ article.validity.to | date: dateTimeFormat }}
          </ion-row>
        </div>
        <div class="conditions">
          @for (condition of article.conditions; track condition) {
            <ion-row>
              <ion-col class="condition-text" [innerHTML]="condition"></ion-col>
            </ion-row>
          }
        </div>
        <ion-row class="purchase-date">
          <ion-col size="12"
            >{{ 'my-journeys.ticket-purchase.bought-on' | translate }}
            {{ ticket.purchaseDate | date: dateFormat }}
            {{ 'my-journeys.at' | translate }}
            {{ ticket.purchaseDate | date: 'HH:mm:ss' }}</ion-col
            >
          </ion-row>
          <div class="references">
            <ion-row class="ticket-id">
              <ion-col class="label">
                {{ ticket.ticketNumber?.label || ('my-journeys.ticket-detail.ticket-id' | translate) }}
              </ion-col>
              <ion-col class="value ion-text-end">{{ ticket.ticketNumber?.value }}</ion-col>
            </ion-row>
            <ion-row class="article-number">
              <ion-col class="label" size="auto">
                {{ ticket.articleNumber?.label || ('my-journeys.ticket-detail.article-number' | translate) }}
              </ion-col>
              <ion-col class="value ion-text-end">{{ ticket.articleNumber?.value }}</ion-col>
            </ion-row>
            <ion-row class="reference-number">
              <ion-col class="label" size="auto">
                {{ ticket.referenceNumber?.label || ('my-journeys.ticket-detail.reference-number' | translate) }}
              </ion-col>
              <ion-col class="value ion-text-end">{{ getReferenceNumber() }}</ion-col>
            </ion-row>
            @if (isTpc) {
              <ion-row class="order-number">
                <ion-col class="label" size="auto">
                  {{ 'my-journeys.ticket-detail.order-number' | translate }}
                </ion-col>
                <ion-col class="value ion-text-end">{{ getOrderNumber() }}</ion-col>
              </ion-row>
            }
            <ion-row class="fq-code">
              <ion-col size="12">{{ ticket.fqCode }}</ion-col>
            </ion-row>
          </div>
        </div>
      } @else {
        <p>{{ 'my-journeys.article-not-found' | translate }}</p>
      }
    } @else {
      <div>
        <p>{{ 'my-journeys.ticket-detail.is-processing' | translate }}</p>
      </div>
    }
    <ng-template #isProcessing>
      <div>
        <p>{{ 'my-journeys.ticket-detail.is-processing' | translate }}</p>
      </div>
    </ng-template>
  </ion-grid>
