import { ONE_SECOND_IN_MS } from '@traas/common/utils';
import * as moment from 'moment';
import { INPUT_DATETIME_SYNTHESE_FORMAT } from './datetime-format.constant';

/**
 * ISO 8601 Documentation : https://www.w3.org/TR/NOTE-datetime
 */

export class SyntheseDateHelper {
    static now(): string {
        // This is workaround to work with ion-datetime local utc
        return moment().local().format('YYYY-MM-DDTHH:mm:ss');
    }

    /**
     * Transform datetime from datepicker into date formatted to work with backend Synthese.
     * @param {ISO 8601 string date} iso8601DateFormat - 2018-09-21T11:49:50
     */
    static formatISO8601ToSyntheseDate(iso8601DateFormat: string): string {
        return moment(iso8601DateFormat).local().format(INPUT_DATETIME_SYNTHESE_FORMAT);
    }

    static formatDateToSyntheseDateTime(date: Date): string {
        return moment(date).format(INPUT_DATETIME_SYNTHESE_FORMAT);
    }

    /**
     * Transform string from INPUT_DATETIME_SYNTHESE_FORMAT format to date.
     * @param {string date} syntheseDate - 2018-09-21
     * @param {string time} syntheseTime - 11:49:50
     */
    static syntheseDatetimeStringToDate(syntheseDate: string, syntheseTime: string): Date {
        return moment(`${syntheseDate} ${syntheseTime}`, INPUT_DATETIME_SYNTHESE_FORMAT).toDate();
    }

    static parseSyntheseStringDate(date: string): Date {
        return moment(date).toDate();
    }

    static roundUpMinute(date: Date): any {
        const momentDate = moment(date);
        const roundUp =
            momentDate.second() || momentDate.millisecond() ? momentDate.add(1, 'minute').startOf('minute') : momentDate.startOf('minute');
        return roundUp.toDate();
    }

    static getSyntheseDatePlusOneSecond(datetime: Date): string {
        // We add 1 second so that Synthese doesn't send us the departures that leave at the exact time
        const date = new Date(datetime.getTime() + ONE_SECOND_IN_MS);
        return this.formatISO8601ToSyntheseDate(this.formatDateToSyntheseDateTime(date));
    }
}
