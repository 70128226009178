export interface HumanReadableStop {
    stopAreaFullName?: string;

    stopAreaName?: string;

    getCityName(): string;

    getName(): string;

    getId(): string;
}

export function createHumanReadableStop({
    name,
    cityName,
    id,
}: {
    name: string | undefined;
    cityName: string | undefined | null;
    id: string | undefined;
}): HumanReadableStop {
    return {
        getCityName: (): string => {
            return cityName ?? '';
        },
        getName: (): string => {
            return name ?? '';
        },
        getId: (): string => {
            return id ?? '';
        },
    };
}
