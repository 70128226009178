import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { environment } from '@traas/boldor/environments';
import { InfiniteScrollListMode } from '@traas/boldor/all-models';

@Component({
    selector: 'traas-scroll-behavior-badge',
    templateUrl: './scroll-behavior-badge.component.html',
    styleUrls: ['./scroll-behavior-badge.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScrollBehaviorBadgeComponent {
    @Input({ required: true }) mode!: InfiniteScrollListMode;

    visible = environment.isDebugMode;

    getEmoji(): string {
        switch (this.mode) {
            case InfiniteScrollListMode.Auto:
                return 'Auto 🔮';
            case InfiniteScrollListMode.Manual:
                return 'Manual 🤳';
            default:
                return 'Bug 💥';
        }
    }

    getColor(): string {
        switch (this.mode) {
            case InfiniteScrollListMode.Auto:
                return 'success';
            case InfiniteScrollListMode.Manual:
                return 'danger';
            default:
                return 'warning';
        }
    }
}
