import { createFeatureSelector, createSelector, select } from '@ngrx/store';
import {
    ArticlesBundle,
    ArticleSummary,
    ArticleViewModel,
    isQuickArticleViewModel,
    QuickArticleViewModel,
    SelectedZone,
} from '@traas/boldor/all-models';
import { ArticleType, PassengerType, PaymentMeans } from '@traas/boldor/graphql-generated/graphql';
import { pipe } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import * as _ from 'lodash';
import { CartState } from './cart.state';
import { hideTicketTypeFromCart } from '../ui-utils/configure-ticket-ui-utils';

const TICKET_NOT_AVAILABLE = 'billet non disponible';

export const selectCartState = createFeatureSelector<CartState>('cart');

export const getCart = createSelector(selectCartState, ({ cart }) => cart);

export const getCartPrice = createSelector(selectCartState, ({ cart }) => cart?.totalPrice);

export const getArticlesBundles = createSelector(selectCartState, (cart) => cart.articlesBundles);

// Used only by TPG for multizones picker
export const getAvailableArticlesZones = pipe(
    select(getArticlesBundles),
    map((articlesBundles) => {
        if (!articlesBundles) {
            return [];
        }
        const availableArticles = _.flatten(articlesBundles?.map((bundle) => bundle.availableArticles));
        let zones = availableArticles
            .filter((article) => isQuickArticleViewModel(article))
            .map((article) => {
                const quickArticle = article as QuickArticleViewModel;
                const currentZones = quickArticle.zones.map(({ id }) => +id);
                const places = quickArticle.zones
                    .map(({ description }) => description)
                    .filter((description) => !!description)
                    ?.join(', ');
                return {
                    zones: currentZones,
                    places,
                } as SelectedZone;
            });
        zones = _.uniqBy(zones, (curr) => `${curr.zones.join(',')}${curr.places}`);
        zones = _.orderBy(zones, (curr) => _.sum(curr.zones), ['asc']);
        /**
         * At this step (NOK):
         * 10, 200
         * 10, 210, 230
         * 10, 200, 230
         * 10, 210, 240
         * 10, 200, 240
         */

        const bizones = zones.filter((selectedZone) => selectedZone.zones.length === 2);
        let trizones = zones.filter((selectedZone) => selectedZone.zones.length === 3);
        trizones = _.orderBy(trizones, (selectedZone) => selectedZone.zones[1], ['asc']);
        /**
         * At this step (OK):
         * 10, 200
         * 10, 200, 230
         * 10, 200, 240
         * 10, 210, 230
         * 10, 210, 240
         */

        zones = [...bizones, ...trizones];
        return zones;
    }),
);

export const getIsMultizones = pipe(
    select(getArticlesBundles),
    filter((articleBundles) => !!articleBundles),
    map((articlesBundles) => isMultizones(articlesBundles)),
);

export const getSelectedTicketType = createSelector(selectCartState, ({ configureTicket }) => {
    const productId = configureTicket.selectedProductId;
    return configureTicket.ticketTypes.find(({ id }) => id === productId);
});

export const getChooseTicketManually = createSelector(selectCartState, (cart) => cart.configureTicket.chooseTicketManually);

export const getSelectedZones = createSelector(selectCartState, (cart) => cart.configureTicket.selectedZones);

export const getCartIsInitialized = createSelector(selectCartState, (cart) => cart.isInitialized);

export const hasSelectedPaymentMean = createSelector(selectCartState, (cart) => cart.isPaymentMeanSelected);

export const isBuyOperationDisabled = createSelector(selectCartState, (state) => {
    return state?.articlesBundles?.every((ab) => ab?.availableArticles?.length === 0) || (!state?.articlesBundles && state.isInitialized);
});

export const isBuyOperationChecked = createSelector(selectCartState, ({ cart }) => !!cart?.operations?.buy?.isChecked);

export const isSelectionEligibleForThirdPartyPayement = createSelector(selectCartState, ({ cart }) => {
    return cart?.articleSelections?.every((selection) => selection.article?.thirdPartyPayerInformation?.isEligible);
});

export const getIsDefaultArticlesBundleLoading = createSelector(
    selectCartState,
    ({ isDefaultArticlesBundleLoading }) => isDefaultArticlesBundleLoading,
);

export const getIsCartUpdating = createSelector(selectCartState, ({ isCartUpdating }) => isCartUpdating);

export const getConfiguredTicket = createSelector(selectCartState, ({ configureTicket }) => configureTicket);

export const getConnectedUserArticlesBundle = createSelector(
    selectCartState,
    ({ connectedUserArticlesBundle }) => connectedUserArticlesBundle,
);

export const getDurationsFilter = createSelector(selectCartState, ({ durationsFilter }) => durationsFilter);

export const hideTicketType = createSelector(selectCartState, ({ cart }): boolean => {
    return hideTicketTypeFromCart(cart);
});

export const getArticlesSummary = createSelector(selectCartState, ({ cart }): ArticleSummary[] => {
    return !cart || !cart.articleSelections
        ? []
        : cart.articleSelections.reduce((arr: ArticleSummary[], item) => {
              let found = false;
              const description = getArticleDescription(item.article);

              // increment item
              for (let i = 0; i < arr.length; i++) {
                  if (arr[i].title === TICKET_NOT_AVAILABLE && !item.article) {
                      found = true;
                      arr[i].count++;
                  } else if (arr[i].title === `${item.article?.title}${description}`) {
                      found = true;
                      arr[i].count++;
                  }
              }

              // initialize item
              if (!found && !item.article) {
                  arr.push({ title: TICKET_NOT_AVAILABLE, count: 1 });
              } else if (!found && !!item.article) {
                  arr.push({ title: `${item.article.title}${description}`, count: 1 });
              }

              return arr;
          }, []);
});

function isMultizones(articlesBundles: ArticlesBundle[] | null): boolean {
    const isArticleBundleMultizones =
        articlesBundles &&
        articlesBundles.length > 0 &&
        articlesBundles?.every((articlesBundle) => {
            return articlesBundle.availableArticles.every((article) => {
                const isQuickArticle = article.type === ArticleType.QuickArticle;
                const hasMultizones = (article as QuickArticleViewModel).zones?.length > 1;
                return isQuickArticle && hasMultizones;
            });
        });
    return isArticleBundleMultizones ?? false;
}

function getArticleDescription(article: ArticleViewModel): string {
    const SEPARATOR = ':';
    const description = article?.description ?? '';
    return description.includes(SEPARATOR) ? description.split(SEPARATOR)[1] : description;
}
