import { DivIcon, DivIconOptions } from 'leaflet';
import { ChangePlaceEventSourceEnum, MyGpsPositionPlace, Place, TraasGeolocationCoordinates } from '@traas/boldor/all-models';
import { DynamicPlaceAdapter } from './dynamic-place.adapter';

export class MyGpsPositionDynamicPlaceAdapter extends DynamicPlaceAdapter<Place<MyGpsPositionPlace>> {
    readonly #changePlaceSourceEvent = ChangePlaceEventSourceEnum.MyGpsPositionSelection;

    constructor(data: Place<MyGpsPositionPlace>) {
        super(data);
    }

    override getChangePlaceEvent(): ChangePlaceEventSourceEnum {
        return this.#changePlaceSourceEvent;
    }

    override getDivIcon(iconOptions?: DivIconOptions): DivIcon | undefined {
        return undefined;
    }

    override isDrawOnEndpointRequired(): boolean {
        return true;
    }

    override isDrawOnMapRequired(): boolean {
        return false;
    }

    override isNeededToFitMap(): boolean {
        return true;
    }

    override transformToCoordinates(): TraasGeolocationCoordinates {
        return this.getData().transformToCoordinates();
    }
}
