import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { LineService } from '../../../../services/common/line/line.service';
import { areThirdPartyPaidTickets, getFormattedTotalPrice } from '@traas/boldor/all-helpers';
import { DepartureAdapter } from '../../../../models/departure/departure';
import {
    DepartureOrderViewModel,
    HumanReadableStop,
    LineStyle,
    OrderViewModel,
    QuickTicketsOrderViewModel,
    TransitStop,
} from '@traas/boldor/all-models';
import { isTrain } from '@traas/boldor/business-rules';
import { Line } from '@traas/common/models';

@Component({
    selector: 'app-departure-ticket-summary',
    templateUrl: './departure-ticket-summary.component.html',
    styleUrls: ['./departure-ticket-summary.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DepartureTicketSummaryComponent implements OnInit {
    #lineService = inject(LineService);

    @Input() isRefundable = false;

    @Input({ required: true }) set departureOrder(order: OrderViewModel) {
        this.#departureOrderValue = order as DepartureOrderViewModel;
    }

    get departureOrder(): DepartureOrderViewModel {
        return this.#departureOrderValue;
    }

    @Input() detail = false;
    @Input() showOperationsIcon = true;
    @Input({ required: true }) order!: OrderViewModel;
    @Input() transitStops: TransitStop[] = [];

    @Output() refundOrder = new EventEmitter<DepartureOrderViewModel>();

    departureDate: string;
    departureStop: HumanReadableStop;
    lines: Line[];
    lineStyle: LineStyle;
    paddingCol: any = { top: '.3rem', bottom: '.3rem', left: null, right: null };
    totalPrice: string;
    #departureOrderValue: DepartureOrderViewModel;

    ngOnInit(): void {
        const departureAdapter = new DepartureAdapter(this.departureOrder.departure);
        this.departureStop = departureAdapter.getDepartureStop();
        this.departureDate = this.departureOrder.departure.plannedDepartureTime;
        this.lineStyle = this.#lineService.from(departureAdapter);
        this.lines = departureAdapter.getLines();
        if (this.departureOrder.tickets.length > 0) {
            this.totalPrice = this.#getTotalAmount();
        }
    }

    confirmRefundOrder(event: Event): void {
        event.stopPropagation();
        event.preventDefault();
        this.refundOrder.emit(this.departureOrder);
    }

    areThirdPartyPaidTickets(order: QuickTicketsOrderViewModel): boolean {
        return areThirdPartyPaidTickets(order);
    }

    isTrain(transport: string): boolean {
        return isTrain(transport);
    }

    #getTotalAmount(): string {
        return getFormattedTotalPrice(this.departureOrder.tickets);
    }
}
