import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    inject,
    Input,
    NgZone,
    OnInit,
    Output,
    viewChild,
    viewChildren,
} from '@angular/core';
import { CompanyService } from '@traas/boldor/company';
import { LineService } from '../../../../services/common/line/line.service';
import { LegAdapter } from '../../../../models/itinerary/leg';
import { StopAdapter } from '../../../../models/itinerary/stop';
import { head, last } from 'lodash';
import { LineStyle } from '@traas/boldor/all-models';
import { isTrain } from '@traas/boldor/business-rules';
import { ItineraryThermometerDrawerService } from '../../services/itinerary-thermometer-drawer.service';
import { ItineraryLegStopListItemComponent } from '../../components/itinerary-leg-stop-list-item/itinerary-leg-stop-list-item.component';
import { SvgAnchor } from '../svg-anchor.model';

@Component({
    selector: 'app-itinerary-leg-stop-list',
    templateUrl: './itinerary-leg-stop-list-container.component.html',
    styleUrl: './itinerary-leg-stop-list-container.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItineraryLegStopListContainerComponent implements OnInit, SvgAnchor, AfterViewInit {
    @Input({ required: true }) legAdapter!: LegAdapter;
    stopItems = viewChildren<ItineraryLegStopListItemComponent>('item');
    container = viewChild<ElementRef>('container');

    @Output() arrivalClicked = new EventEmitter<StopAdapter>();
    @Output() departureClicked = new EventEmitter<StopAdapter>();
    @Output() transportClicked = new EventEmitter<StopAdapter>();

    lineStyle: LineStyle;
    networkId = '';
    stopsAdapter: StopAdapter[] = [];
    isTpg = CompanyService.isTPG();
    isTpc = CompanyService.isTPG();

    isTrainLeg!: boolean;

    #ngZone = inject(NgZone);
    #itineraryThermometerDrawerService = inject(ItineraryThermometerDrawerService);
    #lineService = inject(LineService);

    ngOnInit(): void {
        this.isTrainLeg = isTrain(this.legAdapter.getTransportName());
        this.lineStyle = this.#lineService.from(this.legAdapter);
        this.networkId = this.legAdapter.getLine().networkId || '';
        this.stopsAdapter = this.legAdapter
            .getStops()
            .map((stop) => new StopAdapter(stop))
            .filter((stop) => {
                if (CompanyService.isTPC()) {
                    return stop.getArrivalTime() !== '';
                }
                return true;
            });
    }

    ngAfterViewInit(): void {
        this.#drawLegPathWhenReady();
    }

    getEndPoint(): HTMLElement {
        return this.stopItems().at(-1)?.thermometerStopIcon.nativeElement;
    }

    getStartPoint(): HTMLElement {
        return this.stopItems().at(0)?.thermometerStopIcon.nativeElement;
    }

    onItemClicked(clickedStop: StopAdapter): void {
        if (clickedStop === head(this.stopsAdapter)) {
            return this.departureClicked.emit(clickedStop);
        }
        if (clickedStop === last(this.stopsAdapter)) {
            return this.arrivalClicked.emit(clickedStop);
        }
        return this.transportClicked.emit(clickedStop);
    }

    #drawLegPathWhenReady(): void {
        let intervalId: any;
        const MAX_TIME_MS = 2000;
        let elapsedTime = 0;

        this.#ngZone.runOutsideAngular(() => {
            intervalId = setInterval(() => {
                elapsedTime += 10;

                // Si le temps maximal est atteint, arrêter la boucle
                if (elapsedTime >= MAX_TIME_MS) {
                    clearInterval(intervalId);
                    console.warn('Time limit reached, stopping the loop to draw itinerary path.');
                    return;
                }

                const isReadyToDraw = this.#itineraryThermometerDrawerService.isReadyToDraw(
                    this.stopItems().at(0)?.thermometerStopIcon.nativeElement,
                    this.stopItems().at(-1)?.thermometerStopIcon.nativeElement,
                    this.container(),
                );

                if (isReadyToDraw) {
                    clearInterval(intervalId);
                    this.#ngZone.run(() => {
                        this.#itineraryThermometerDrawerService.drawTransportLegPathBetween(
                            this.stopItems().at(0)?.thermometerStopIcon.nativeElement,
                            this.stopItems().at(-1)?.thermometerStopIcon.nativeElement,
                            this.container(),
                            {
                                lineBackgroundColor: this.lineStyle.backgroundColor ?? '',
                                fallbackLineStyle: this.lineStyle.style,
                                formattedDelayOfLeg: '',
                                withBorder: !this.isTpg, //is dark theme serait plus approprié,
                            },
                        );
                    });
                    return;
                }
            }, 10);
        });
    }
}
