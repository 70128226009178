import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { isAddress, isMyGpsPosition, isPoi } from '@traas/boldor/all-helpers';
import {
    ChangePlaceEventSourceEnum,
    createStopArea,
    DynamicPlace,
    Endpoint,
    isDeparture,
    NewEndpointPayload,
    Place,
    PlaceStop,
    SearchModeOptions,
    StopArea,
    toLatLngRect,
    TraasGeolocationCoordinates,
} from '@traas/boldor/all-models';
import { AnalyticsService } from '@traas/common/analytics';
import { LoggingService } from '@traas/common/logging';
import { DebugLayerName, dynamicLayerName, OnlineService } from '@traas/common/utils';
import { Layer } from 'leaflet';
import { firstValueFrom } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';
import { getIconNameByChangePlaceEvent } from '@traas/boldor/business-rules';
import { GridStoreActions } from '../../../components/grid/store';
import { DynamicPlaceService } from '../../../services/common/dynamic-place/dynamic-place.service';
import { createDynamicPlace } from '../../../services/common/dynamic-place/models/dynamic-place.factory';
import { MyGpsPositionDynamicPlaceAdapter } from '../../../services/common/dynamic-place/models/my-gps-position-dynamic-place.adapter';
import { StopDynamicPlaceAdapter } from '../../../services/common/dynamic-place/models/stop-dynamic-place.adapter';
import { ModalService } from '../../../services/common/modal.service';
import { ItineraryStoreActions } from '../../itinerary/store';
import { LayerBuilderService } from '../../map/services/layer-builder.service';
import { MapService } from '../../map/services/map.service';
import { AddressAdapter } from '../../place/adapters/address';
import { PoiAdapter } from '../../place/adapters/poi';
import { SearchPlacesComponent } from '../../place/containers/search-places/search-places.component';
import { StartupNotificationActions } from '../../startup-notification/store';
import { StartupNotificationState } from '../../startup-notification/store/startup-notification-state';
import { HomeState } from '../store';
import { EndpointActions, EndpointSelectors } from '../store/endpoint';
import { ChangePlaceEvent } from '../store/endpoint/change-place-event.interface';
import { SetBounds } from '../store/endpoint/endpoint.action';
import { getActiveEndpoint } from '../store/endpoint/endpoint.selector';
import { MapActions } from '../store/map';
import { SearchPlaceActions } from '../store/searchPlace';
import { ErrorCodes, isSuccess, TechnicalError } from '@traas/common/models';
import { BoldorLocalizationService } from '@traas/boldor/localization';
import { ToasterService } from '../../../services/common/toaster/toaster.service';

@Injectable({ providedIn: 'root' })
export class HomeService {
    #infoPopupWasShown = false;
    readonly #boldorLocalizationService = inject(BoldorLocalizationService);
    readonly #toasterService = inject(ToasterService);

    static createChangePlaceEventSourcePayload(dynamicPlace: DynamicPlace): ChangePlaceEvent {
        const locationName = dynamicPlace.getData().getName();
        const coordinates = dynamicPlace.transformToCoordinates();
        let latitude: string | undefined = undefined;
        let longitude: string | undefined = undefined;
        const source = dynamicPlace.getChangePlaceEvent();

        if (isAddress(source)) {
            const data = dynamicPlace.getData() as AddressAdapter;
            latitude = `${data.getLatitude()}`;
            longitude = `${data.getLongitude()}`;
        } else if (isPoi(source)) {
            const data = dynamicPlace.getData() as PoiAdapter;
            latitude = `${data.getLatitude()}`;
            longitude = `${data.getLongitude()}`;
        } else if (coordinates !== null && coordinates.latitude !== null && coordinates.longitude !== null) {
            latitude = coordinates.latitude.toString();
            longitude = coordinates.longitude.toString();
        }
        return {
            source,
            metadata: {
                locationName,
                latitude,
                longitude,
            },
        };
    }

    constructor(
        private analyticsService: AnalyticsService,
        private dynamicPlaceService: DynamicPlaceService,
        private layerBuilder: LayerBuilderService,
        private logger: LoggingService,
        private mapService: MapService,
        private messageLauncherStore: Store<StartupNotificationState>,
        private modalService: ModalService,
        private onlineService: OnlineService,
        private store: Store<HomeState>,
    ) {}

    async openSearchPlaces(): Promise<void> {
        const selected: Place<any> = await this.modalService.presentModal<Place>({
            component: SearchPlacesComponent,
            keyboardClose: false,
            backdropDismiss: false,
        });
        if (selected) {
            // Reset TransportModes because user started a new search
            this.store.dispatch(new ItineraryStoreActions.ResetEnabledTransportModes());

            // This is specific case "Mes favoris" or "Rechercher à l'aide de la carte"
            if (this.#isActionItem(selected)) {
                this.#dispatchItemActions();
                return; // return because is not a place
            }

            const activeEndpoint = await firstValueFrom(this.store.select(getActiveEndpoint));

            // Will disable the endpoint
            isDeparture(activeEndpoint)
                ? this.store.dispatch(new EndpointActions.StartChangingDeparture())
                : this.store.dispatch(new EndpointActions.StartChangingArrival());

            // Will run the journeys search, it is made for optimize search and run it as soon as possible
            await this.setDepartureOrArrival(selected, activeEndpoint);

            // Will fire all sides effect on map, endpoints, result's view, etc
            this.store.dispatch(new SearchPlaceActions.PickPlace({ place: selected }));
        } else {
            this.store.dispatch(new SearchPlaceActions.PickPlaceCanceled());
        }
    }

    async propagatePlace(place: Place, endpoint: Endpoint): Promise<void> {
        if (!place) {
            return await Promise.resolve();
        }
        const dynamicPlace = createDynamicPlace(place);
        const boundsFromPlaceResult = await this.dynamicPlaceService.createBoundsFrom(dynamicPlace);
        if (isSuccess(boundsFromPlaceResult)) {
            if (
                isAddress(dynamicPlace.getChangePlaceEvent()) ||
                isPoi(dynamicPlace.getChangePlaceEvent()) ||
                isMyGpsPosition(dynamicPlace.getChangePlaceEvent())
            ) {
                this.store.dispatch(
                    new SetBounds({
                        endpoint: endpoint,
                        bounds: toLatLngRect(boundsFromPlaceResult.value),
                    }),
                );
            }

            if (dynamicPlace.isDrawOnMapRequired() && boundsFromPlaceResult.value) {
                void this.#drawDynamicPlaceOnMap(dynamicPlace);
            }

            // the icon of place and the address label (for the case of address) are set by this
            if (dynamicPlace.isDrawOnEndpointRequired()) {
                const payload = HomeService.createChangePlaceEventSourcePayload(dynamicPlace);
                this.store.dispatch(new EndpointActions.SetChangePlaceEventSource(payload));
            }
            if (dynamicPlace.isNeededToFitMap()) {
                this.store.dispatch(new MapActions.Enable());

                if (isDeparture(endpoint)) {
                    this.store.dispatch(new EndpointActions.StartChangingDeparture());
                } else {
                    this.store.dispatch(new EndpointActions.StartChangingArrival());
                }
                /* This call will fire by side effect $departureChanged or $arrivalChanged if the bounds is different than the actual */
                const newBoundsResult = this.mapService.fitBounds(boundsFromPlaceResult.value?.pad(0.02));
                if (isSuccess(newBoundsResult)) {
                    this.mapService.redrawDebugLayer(newBoundsResult.value, DebugLayerName.PlaceArea);
                } else {
                    this.logger.logError(
                        new TechnicalError(`Can't redraw debug layer`, ErrorCodes.Map.DynamicLayer, newBoundsResult.error),
                    );
                }
            }
        } else {
            this.logger.logError(
                new TechnicalError(`Can't propagate place`, ErrorCodes.SearchPlace.PickPlace, boundsFromPlaceResult.error, {
                    place: JSON.stringify(place),
                    endpoint: JSON.stringify(endpoint),
                    dynamicPlace: JSON.stringify(dynamicPlace),
                }),
            );
            const searchPlaceFail = await this.#boldorLocalizationService.get('error-message.search-place-fail');
            await this.#toasterService.presentGenericFailure(searchPlaceFail);
        }
        if (isDeparture(endpoint)) {
            return firstValueFrom(
                this.store.select(EndpointSelectors.getIsDepartureChanging).pipe(
                    filter((isChanging) => !isChanging),
                    map(() => undefined),
                ),
            );
        }
        return firstValueFrom(
            this.store.select(EndpointSelectors.getIsArrivalChanging).pipe(
                filter((isChanging) => !isChanging),
                map(() => undefined),
            ),
        );
    }

    async setDepartureOrArrival(newPlace: Place<any>, activeEndpoint: Endpoint): Promise<void> {
        const dynamicPlace = createDynamicPlace(newPlace);
        let payload: NewEndpointPayload;
        const action = isDeparture(activeEndpoint) ? EndpointActions.NewDeparture : EndpointActions.NewArrival;
        switch (dynamicPlace.getChangePlaceEvent()) {
            case ChangePlaceEventSourceEnum.StopSelection:
                payload = this.#createNewEndpointPayloadFromStopPlace(dynamicPlace as StopDynamicPlaceAdapter);
                break;
            case ChangePlaceEventSourceEnum.AddressSelection:
            case ChangePlaceEventSourceEnum.PoiSelection:
                payload = this.#createNewEndpointPayloadFromAddressPlace(dynamicPlace);
                break;
            case ChangePlaceEventSourceEnum.MyGpsPositionSelection:
                payload = this.#createNewEndpointPayloadFromMyGpsPosPlace(dynamicPlace as MyGpsPositionDynamicPlaceAdapter);
                break;
            default:
                return;
        }
        this.store.dispatch(new action(payload));
    }

    showMessagesWhenOnlineOnce(): void {
        if (!this.#infoPopupWasShown) {
            this.onlineService
                .$getIsOnline()
                .pipe(
                    filter((isOnline) => isOnline),
                    first(),
                )
                .subscribe(() => {
                    this.messageLauncherStore.dispatch(new StartupNotificationActions.Load());
                    this.#infoPopupWasShown = true;
                });
        }
    }

    async #drawDynamicPlaceOnMap(dynamicPlace: DynamicPlace): Promise<void> {
        try {
            const { dynamicLayer, activeEndpoint } = await this.#createContextToDrawPlaceOnMap(dynamicPlace);
            const dynamicLayerNameByEndpoint: dynamicLayerName = this.layerBuilder.getDynamicLayerNameByEndpoint(activeEndpoint);
            this.mapService.setLayer(dynamicLayerNameByEndpoint, dynamicLayer);
            this.mapService.addLayerToMap(dynamicLayerNameByEndpoint);
        } catch (error) {
            this.logger.logLocalError(error);
        }
    }

    #createContextToDrawPlaceOnMap(
        dynamicPlace: DynamicPlace,
    ): Promise<{ readonly dynamicLayer: Layer; readonly activeEndpoint: Endpoint }> {
        return firstValueFrom(
            this.store.select(EndpointSelectors.getActiveEndpoint).pipe(
                map((activeEndpoint) => {
                    const coordinates: TraasGeolocationCoordinates = this.#buildCoordinatesFromDynamicPlace(dynamicPlace);
                    const dynamicLayer: Layer = this.dynamicPlaceService.createLayerFrom(dynamicPlace, coordinates);
                    return { activeEndpoint, dynamicLayer };
                }),
                filter(({ activeEndpoint, dynamicLayer }) => !!dynamicLayer),
            ),
        );
    }

    /**
     * This is used to build coordinates of the dynamic place
     */
    #buildCoordinatesFromDynamicPlace(dynamicPlace: DynamicPlace): TraasGeolocationCoordinates {
        return dynamicPlace.transformToCoordinates();
    }

    #isActionItem(place: Place): boolean {
        return place.getPlaceType() === 'actionItem';
    }

    #createNewEndpointPayloadFromStopPlace(selectedStop: StopDynamicPlaceAdapter): NewEndpointPayload {
        const coords = selectedStop.getData().transformToCoordinates();
        if (!coords || !coords.latitude || !coords.longitude) {
            throw new Error('[createNewEndpointPayloadFromStopPlace] coordinates should not be null.');
        }
        return {
            area: createStopArea(
                [
                    {
                        id: '',
                        geometry: '',
                        letter: '',
                        associatedCommercialStop: {
                            cityName: (selectedStop.getData().getData() as PlaceStop).cityName,
                            id: selectedStop.getData().getId(),
                            lines: [],
                            name: selectedStop.getData().getName(),
                            coordinates: {
                                latitude: coords.latitude,
                                longitude: coords.longitude,
                            },
                        },
                        transportMode: '',
                        lines: [],
                    },
                ],
                ChangePlaceEventSourceEnum.StopSelection,
                null,
            ),
            endpoint: {
                source: ChangePlaceEventSourceEnum.StopSelection,
                hasTooMuchStops: false,
                iconName: getIconNameByChangePlaceEvent(ChangePlaceEventSourceEnum.StopSelection),
                locations: selectedStop.getData().getName(),
            },
        };
    }

    #createNewEndpointPayloadFromMyGpsPosPlace(place: MyGpsPositionDynamicPlaceAdapter): NewEndpointPayload {
        const coords = place.transformToCoordinates();
        if (!coords || !coords.latitude || !coords.longitude) {
            throw new Error('[createNewEndpointPayloadFromMyGpsPosPlace] coordinates should not be null.');
        }
        return {
            area: {
                physicalStops: [],
                boundsRect: toLatLngRect(place.getData().getData().bounds),
                metadata: {
                    source: ChangePlaceEventSourceEnum.MyGpsPositionSelection,
                    locationName: place.getData().getName(),
                    latitude: coords.latitude.toString(),
                    longitude: coords.longitude.toString(),
                },
            } as StopArea,
            endpoint: {
                source: ChangePlaceEventSourceEnum.MyGpsPositionSelection,
                hasTooMuchStops: false,
                iconName: getIconNameByChangePlaceEvent(ChangePlaceEventSourceEnum.MyGpsPositionSelection),
                locations: place.getData().getName(),
            },
        };
    }

    #createNewEndpointPayloadFromAddressPlace(dynamicPlace: DynamicPlace): NewEndpointPayload {
        const coords = dynamicPlace.transformToCoordinates();
        if (!coords || !coords.latitude || !coords.longitude) {
            throw new Error('[createNewEndpointPayloadFromAddressPlace] coordinates should not be null.');
        }
        return {
            area: {
                physicalStops: [],
                boundsRect: null,
                metadata: {
                    source: dynamicPlace.getChangePlaceEvent(),
                    locationName: dynamicPlace.getData().getName(),
                    latitude: coords.latitude.toString(),
                    longitude: coords.longitude.toString(),
                },
            } as StopArea,
            endpoint: {
                source: dynamicPlace.getChangePlaceEvent(),
                hasTooMuchStops: false,
                iconName: getIconNameByChangePlaceEvent(dynamicPlace.getChangePlaceEvent()),
                locations: dynamicPlace.getData().getName(),
            },
        };
    }

    #dispatchItemActions(): void {
        // you can extend more actions here with a switch on place
        this.analyticsService.reportEvent('place_search__open_favorites');
        this.store.dispatch(new GridStoreActions.ChangeSearchMode({ mode: SearchModeOptions.GRID }));
    }
}
