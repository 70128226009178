import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, firstValueFrom, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { RouteUrl, RoutingService } from '@traas/common/routing';
import { environment } from '@traas/boldor/environments';
import { ItineraryStoreSelectors } from '@traas/boldor/common/features/itinerary/store';
import { OnlineService, PlatformUtilsService } from '@traas/common/utils';
import { ItineraryState } from '@traas/boldor/common/features/itinerary/store/itinerary.state';
import { Store } from '@ngrx/store';
import { AnalyticsService } from '@traas/common/analytics';
import { CustomerProviderService } from '../../../../services/common/customer/customer-provider.service';
import { SwisspassSsoService } from '../../../swisspass/services/swisspass-sso.service';
import { AccountManagementService } from '@traas/boldor/common/services/common/customer/account-management.service';

@Component({
    selector: 'common-login',
    templateUrl: 'common-login.component.html',
    styleUrls: ['./common-login.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommonLoginComponent {
    $showBuyTicketBySmsButton: Observable<boolean>;
    $showGuestCheckoutButton: Observable<boolean>;
    showSpTokenButton: boolean;
    $isLoading = new BehaviorSubject<boolean>(false);
    $isOnline: Observable<boolean>;
    $unsubscribe = new Subject<void>();

    constructor(
        private route: ActivatedRoute,
        private onlineService: OnlineService,
        private platformService: PlatformUtilsService,
        private routingService: RoutingService,
        private itineraryStore: Store<ItineraryState>,
        private customerService: CustomerProviderService,
        private accountManagementService: AccountManagementService,
        private analyticsService: AnalyticsService,
        private swisspassSsoService: SwisspassSsoService,
        private router: Router,
    ) {
        this.$showBuyTicketBySmsButton = this.route.queryParams.pipe(map((params) => params['showBuyTicketBySmsButton'] === 'true'));
        this.$showGuestCheckoutButton = this.route.queryParams.pipe(map((params) => params['showGuestCheckoutButton'] === 'true'));
        this.$isOnline = this.onlineService.$getIsOnline();
        this.showSpTokenButton = this.platformService.isWeb() && environment.isDebugMode;
    }

    async onContinueAsGuest(): Promise<void> {
        const returnUrl = this.routingService.getCurrentReturnUrl();
        if (!returnUrl) {
            throw new Error('To continue as Guest customer, we need a returnUrl parameter.');
        }
        await this.routingService.navigateToGuestForm(returnUrl);
    }

    async returnToPreviousPage(): Promise<void> {
        const currentReturnUrl = this.routingService.getCurrentReturnUrl();
        switch (currentReturnUrl) {
            case RouteUrl.ticketsTabUrl:
                await this.routingService.navigateToHome();
                break;
            case RouteUrl.cartOrdersUrl:
                await this.#tryRedirectToItineraryDetails();
                break;
            default:
                this.routingService.pop();
                break;
        }
    }

    async onLoginSwisspassClicked(): Promise<void> {
        const swisspassSSORedirectUrl = this.routingService.getSwisspassSSORedirectUrl();
        const returnUrl = this.routingService.getCurrentReturnUrl() ?? RouteUrl.menuTabUrl;

        try {
            this.$isLoading.next(true);
            const isLoggedIn = await this.accountManagementService.authenticateAndRedirectTo(null);

            if (!isLoggedIn) {
                return;
            }

            void this.#sendAnalytics();

            await this.router.navigateByUrl(returnUrl, { replaceUrl: true });
        } finally {
            this.$isLoading.next(false);
        }

        // If we have the isSwisspassSSORedirect parameter, we launch the SwisspassSSO process as well
        if (swisspassSSORedirectUrl) {
            await this.swisspassSsoService.swisspassSSORedirect(swisspassSSORedirectUrl);
        }
    }

    // As of 3 Avril, on met en standby la feature achat par sms mais elle reviendra wink wink
    // C'est pas vraiment unused on a juste commenté la feature dans le html
    // async buyTicketBySms(): Promise<void> {
    //     await this.routingService.navigateToBuyTicketBySms();
    // }

    async #tryRedirectToItineraryDetails(): Promise<void> {
        const selectedItinerary = await firstValueFrom(this.itineraryStore.select(ItineraryStoreSelectors.getSelectedItinerary));
        if (selectedItinerary?.id) {
            await this.routingService.navigateToItineraryDetails();
        } else {
            await this.routingService.navigateToHome();
        }
    }

    async #sendAnalytics(): Promise<void> {
        const customer = await this.customerService.getCustomer();
        this.analyticsService.sendAuthenticatedUserInformation(customer);
    }
}
