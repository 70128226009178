import { Inject, Injectable, InjectionToken } from '@angular/core';
import { convertToError, isApolloAuthenticationError, isApolloNetworkError } from './error-utils';
import { LogLevel, LogOptions } from '@traas/boldor/all-models';
import * as Sentry from '@sentry/capacitor';
import { BaseError, FatalError, TechnicalError } from '@traas/common/models';
import { ApolloError } from '@apollo/client/core';
import { ErrorContextBuilder } from './error-context-builder.service';
import { SentryErrorFingerprintService } from './sentry-error-fingerprint.service';

export function logOptionsFactory(level: LogLevel): LogOptions {
    return {
        level,
    };
}

export const LOG_REMOTE = new InjectionToken<string>('');

@Injectable({ providedIn: 'root' })
export class LoggingService {
    constructor(
        @Inject(LOG_REMOTE) protected logRemote: boolean,
        private errorContextBuilder: ErrorContextBuilder,
        private sentryErrorFingerprintService: SentryErrorFingerprintService,
    ) {}

    logError(error: TechnicalError | FatalError, level: LogLevel = 'error'): void {
        this.logLocalError(error, { level });
        if (this.#shouldSendToRemote(error)) {
            this.#logRemoteException(error);
        }
    }

    logLocalError(error: Error | unknown, options?: LogOptions): void {
        const toLog = convertToError(error);
        let context = {};
        if (toLog) {
            context = this.errorContextBuilder.buildContext(toLog);
        }

        switch (options?.level) {
            case 'debug':
                // eslint-disable-next-line no-restricted-syntax
                console.debug(toLog, context);
                break;
            case 'warning':
                console.warn(toLog, context);
                break;
            case 'info':
                // eslint-disable-next-line no-restricted-syntax
                console.info(toLog, context);
                break;
            case 'error':
            default:
                console.error(toLog, context);
        }
    }

    #logRemoteException(originalError: TechnicalError | FatalError): void {
        if (!this.logRemote) {
            return;
        }
        let captureContext = this.errorContextBuilder.buildContext(originalError);
        const fingerprint = this.sentryErrorFingerprintService.createFingerprint({ error: originalError, url: window.location.pathname });
        if (fingerprint) {
            captureContext = {
                ...captureContext,
                fingerprint,
            };
        }
        const clonedError = this.#cloneError(originalError);
        clonedError.stack = originalError.stack;
        clonedError.message = this.errorContextBuilder.composeErrorMessageWithAllCauses(originalError);
        Sentry.captureException(clonedError, { ...captureContext, level: clonedError instanceof FatalError ? 'fatal' : 'error' });
    }

    #cloneError(originalError: TechnicalError | FatalError): TechnicalError | FatalError {
        if (originalError instanceof FatalError) {
            return new FatalError(originalError.innerError);
        }
        return new TechnicalError(originalError.message, originalError.errorCode, originalError.innerError, originalError.context);
    }

    #shouldSendToRemote(error: Error | unknown): boolean {
        if (error instanceof FatalError) {
            return true;
        }
        if (error instanceof ApolloError) {
            return !isApolloNetworkError(error) && !isApolloAuthenticationError(error);
        }
        if (error instanceof BaseError) {
            return this.#shouldSendToRemote(error.innerError);
        }
        return true;
    }
}
