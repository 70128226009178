/* eslint-disable @typescript-eslint/no-use-before-define,@typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-assignment */
import {
    getUniqDurationsOf,
    getUniqTravelClassOf,
    getUniqTravelClassOfArticles,
    hasDurationSelection,
    hideTicketTypeFromCart,
} from '../ui-utils/configure-ticket-ui-utils';
import {
    ArticlesBundle,
    ArticleSelection,
    ArticleViewModel,
    ConfigureTicket,
    ConfigureTicketType,
    isQuickArticleViewModel,
    PriceType,
    QuickArticleViewModel,
    SelectedZone,
    TravelClassType,
    Traveler,
} from '@traas/boldor/all-models';
import * as _ from 'lodash';
import { cloneDeep, flatten, uniqBy } from 'lodash';
import { ArticleType, PassengerType, TravelType } from '@traas/boldor/graphql-generated/graphql';
import { createConfigureTicketTypeFromArticle } from '../../../models/cart/cart.utils';
import { CartState } from './cart.state';

export const computeTotalPriceOfArticleSelections = (articleSelections: ArticleSelection<ArticleViewModel>[]): number => {
    return articleSelections.reduce((total, current) => {
        try {
            if (current.article?.thirdPartyPayerInformation?.isEligible) {
                return total;
            }
            return total + current.article.prices[0].amountInCents;
        } catch (e) {
            return total;
        }
    }, 0);
};

const computeTotalPriceOfArticlesArray = (articles: ArticleViewModel[]): number => {
    return articles.reduce((total, current) => {
        try {
            return total + current.prices[0].amountInCents;
        } catch (e) {
            return total;
        }
    }, 0);
};

function computeSelectedTravelType(state: CartState, selectedPriceType: PriceType): TravelType | null {
    const isTicketHidden = hideTicketTypeFromCart(state.cart);
    const isDurationSelectionDisplayed = hasDurationSelection(
        isTicketHidden,
        state.cart?.articleSelections,
        state.configureTicket,
        selectedPriceType,
        state.durationsFilter,
    );

    if (isDurationSelectionDisplayed) {
        return state.configureTicket.selectedTravelType;
    }
    const [selectedPriceTypeTravelType] = getAllAvailableArticlesToUniqArray(state.articlesBundles ?? [])
        .filter(({ isSupersaver }) => (selectedPriceType === PriceType.Supersaver ? isSupersaver : !isSupersaver))
        .map(({ travelType }) => travelType);
    return selectedPriceTypeTravelType ?? null;
}

export function setPriceTypeSelectedReducer(newState: CartState, payload: { priceType: PriceType }): CartState {
    // 1. update selected price type
    newState.configureTicket.selectedPriceType = payload.priceType;

    // 2. update selected travel type
    newState.configureTicket.selectedTravelType = computeSelectedTravelType(newState, payload.priceType);

    const { selectedTravelType, selectedProductId, chooseTicketManually, selectedClass, selectedPriceType, selectedZones } =
        newState.configureTicket;

    // 3. update selected articles in each bundles
    newState.articlesBundles = updateSelectedStateOfAllArticlesBundle(
        newState.articlesBundles ?? [],
        chooseTicketManually ? selectedProductId ?? null : null, // null will not apply filter on this criteria
        selectedClass as TravelClassType,
        chooseTicketManually ? null : selectedTravelType, // null will not apply filter on this criteria
        selectedPriceType,
        selectedZones,
        chooseTicketManually,
    );

    // 4. update ticket type selected
    const allAvailableArticles = getAllAvailableArticlesToUniqArray(newState.articlesBundles ?? []);
    newState.configureTicket = updateConfigureTicketSelected(
        newState.configureTicket,
        allAvailableArticles,
        chooseTicketManually, // false will not apply filter on productId
    );

    // 5. check if ticket has been selected
    selectArticleByCriteriaIfNeeded(allAvailableArticles, selectedClass, selectedTravelType, selectedPriceType);

    // 6. update ticket type prices
    newState.configureTicket = updateConfigureTicketPrice(newState.configureTicket, newState.articlesBundles ?? []);
    return newState;
}

export const createArticlesSelections = (articlesBundles: ArticlesBundle[]): ArticleSelection<ArticleViewModel>[] => {
    return articlesBundles.map((bundle) => {
        const availableArticles = bundle.availableArticles ?? [];
        const selectedArticle = availableArticles.find(({ selected }) => selected);
        // TODO ISMA
        if (!selectedArticle) {
            return {
                article: availableArticles[0],
                passenger: bundle.passenger,
            };
        }
        return {
            article: selectedArticle,
            passenger: bundle.passenger,
        };
    });
};

export const getProductId = (article: any): string => {
    let productId = article.title;
    if (article.supplementQualifiers) {
        productId += article.supplementQualifiers;
    }
    if (article.routeQualifiers) {
        productId += article.routeQualifiers;
    }
    if (article.tripType) {
        productId += article.tripType;
    }
    return productId;
};

export function getSelectedTravelClassIfAvailable(articles: ArticleViewModel[], travelClass: TravelClassType): TravelClassType | null {
    if (articles?.length <= 0) {
        return travelClass;
    }
    const isTravelClassAvailableInArticles = articles.some((article) => article.travelClass?.id === travelClass);
    if (isTravelClassAvailableInArticles) {
        return travelClass;
    }
    return articles[0].travelClass?.id ?? null;
}

export function getSelectedTravelTypeIfAvailable(articles: ArticleViewModel[], travelType: TravelType | null): TravelType | null {
    if (articles?.length <= 0) {
        return travelType;
    }
    const isTravelTypeAvailableInArticles = articles.some((article) => article.travelType === travelType);
    if (isTravelTypeAvailableInArticles) {
        return travelType;
    }
    return articles[0].travelType ?? null;
}

/**
 * Must return ConfigureTicketType array with priceInCents updated.
 * @param ticketsType
 * @param articlesBundles
 * @param selectedClass
 * @param travelType
 * @param priceType
 */
function getUpdatedAllTicketPrice(
    ticketsType: ConfigureTicketType[],
    articlesBundles: ArticlesBundle[],
    selectedClass: TravelClassType,
    travelType: TravelType | null,
    priceType: PriceType | null,
): ConfigureTicketType[] {
    const hasMoreCriteriaAvailableValue = hasMoreCriteriaAvailableIn(articlesBundles);
    return ticketsType.map((ticketType) => {
        // We use this function, in case where the 1st class is selected but there is only tickets of second one
        const articlesCorrespondingCriteria = flatten(
            articlesBundles?.map((bundle) => {
                return bundle.availableArticles.filter((article) => {
                    if ((isDogBundle(bundle) || isBikeBundle(bundle)) && !hasMoreCriteriaAvailableValue) {
                        return true;
                    }
                    return hasSameValues(article, ticketType.id, selectedClass, travelType, priceType);
                });
            }),
        );

        return {
            ...ticketType,
            priceInCents: computeTotalPriceOfArticlesArray(articlesCorrespondingCriteria),
        };
    });
}

function getUpdatedAllTicketPriceDiff(tickets: ConfigureTicketType[]): ConfigureTicketType[] {
    return tickets.map((ticket) => {
        if (ticket.isSelected) {
            return {
                ...ticket,
            };
        }
        return {
            ...ticket,
        };
    });
}

/**
 * Will update priceInCents of configure ticket, by fouding all articles corresponding
 * to criteria.
 */
export const updateConfigureTicketPrice = (configureTicket: ConfigureTicket, articlesBundles: ArticlesBundle[]): ConfigureTicket => {
    const clonedConfigureTicket = cloneDeep(configureTicket) as ConfigureTicket;
    const { selectedClass, ticketTypes, selectedTravelType, selectedPriceType } = clonedConfigureTicket;

    const selectedClassToApply = determineTravelClassToApplyByBundles(
        { travelClass: selectedClass as TravelClassType },
        null,
        articlesBundles,
    );

    const ticketWithPriceUpdated = getUpdatedAllTicketPrice(
        ticketTypes,
        articlesBundles,
        /**
         * We don't want to filter automatically the correct ticket when
         * there is no one available with configureTicket class if we are in mode "choose manually"
         */
        configureTicket.chooseTicketManually ? (selectedClass as TravelClassType) : selectedClassToApply,
        selectedTravelType,
        selectedPriceType,
    );

    const ticketFullUpdated = getUpdatedAllTicketPriceDiff(ticketWithPriceUpdated);

    return {
        ...clonedConfigureTicket,
        ticketTypes: ticketFullUpdated,
    };
};

/**
 * We can have the configureTicket set to first class, but a list of bundle only available on second class.
 * So the class switch will not be shown, but every process must be aware of that and use the appropriate class.
 * So the second one, because no first class available.
 * @param criteria Default value to apply if there is more possibilities available
 * @param configureTicket
 * @param articlesBundles
 */
export function determineTravelClassToApplyByBundles(
    criteria: { travelClass?: TravelClassType; travelType?: TravelType; priceType?: PriceType },
    configureTicket: ConfigureTicket | null,
    articlesBundles: ArticlesBundle[],
): TravelClassType {
    let travelClassToApply = criteria.travelClass ?? (configureTicket?.selectedClass as TravelClassType);
    const hasMoreTravelClass = hasMoreTravelClassIn(articlesBundles);
    if (!hasMoreTravelClass) {
        travelClassToApply = getUniqTravelClassOf(articlesBundles)?.[0]?.id ?? travelClassToApply;
    }
    return travelClassToApply;
}

/**
 * We can have the configureTicket set to first class, but a list of bundle only available on second class.
 * So the class switch will not be shown, but every process must be aware of that and use the appropriate class.
 * So the second one, because no first class available.
 * @param criteria Default value to apply if there is more possibilities available
 * @param configureTicket
 * @param articles
 */
export function determineTravelClassToApplyByArticles(
    criteria: { travelClass?: TravelClassType; travelType?: TravelType; priceType?: PriceType },
    configureTicket: ConfigureTicket,
    articles: ArticleViewModel[],
): TravelClassType {
    let travelClassToApply = criteria.travelClass ?? (configureTicket?.selectedClass as TravelClassType);
    const hasMoreTravelClass = hasMoreTravelClassInArticles(articles);
    if (!hasMoreTravelClass) {
        travelClassToApply = getUniqTravelClassOfArticles(articles)[0]?.id ?? travelClassToApply;
    }
    return travelClassToApply;
}

/**
 * Will calc the price diff between : the total price of selected articles in each bundle and
 * the total price of articles corresponding to criteria
 */
export function getSelectedArticlesPriceDifferenceWithArticlesByCriteria(
    articlesBundles: ArticlesBundle[],
    configureTicket: ConfigureTicket,
    criteria: { travelClass?: TravelClassType; travelType?: TravelType; priceType?: PriceType },
): number {
    const travelClassToApply = determineTravelClassToApplyByBundles(criteria, configureTicket, articlesBundles);

    const allArticles = flatten(articlesBundles?.map(({ availableArticles }) => availableArticles)) as ArticleViewModel[];
    const selectedArticles = allArticles.filter(({ selected }) => selected);
    const totalPriceOfSelectedArticles = computeTotalPriceOfArticlesArray(selectedArticles);
    const availableArticlesByCriterias = getAllAvailableArticlesByCriterias(
        articlesBundles,
        travelClassToApply,
        criteria.travelType ?? configureTicket?.selectedTravelType,
        criteria.priceType ?? configureTicket?.selectedPriceType,
    );
    const totalPriceOfUnselectedArticles = computeTotalPriceOfArticlesArray(availableArticlesByCriterias);

    if (totalPriceOfUnselectedArticles === 0) {
        return 0;
    }
    return totalPriceOfUnselectedArticles - totalPriceOfSelectedArticles;
}

/**
 * Create ticket types from articles parameters and select ticket type
 * by default depending of the travelClass and travelType.
 */
export function initConfigureTicketFrom(
    articles: ArticleViewModel[],
    travelClass: TravelClassType | null,
    travelType: TravelType | null,
    chooseTicketManually: boolean | null,
    productId: string | null,
    priceType: PriceType | null,
): ConfigureTicket {
    // create a list of unique ticket types using default article bundle (connected user bundle)
    const configureTicketTypes = createCollectionOfTicketsType(articles);
    setSelectedTicket(configureTicketTypes, articles, travelClass, travelType, productId);
    const selectedTicket = configureTicketTypes.find(({ isSelected }) => isSelected);
    return {
        selectedTravelType: travelType,
        selectedClass: travelClass,
        selectedProductId: selectedTicket?.id ?? null,
        ticketTypes: configureTicketTypes,
        chooseTicketManually,
        selectedPriceType: priceType,
        selectedZones: null,
    };
}

function setSelectedTicket(
    configureTicketTypes: ConfigureTicketType[],
    articles: ArticleViewModel[],
    travelClass: TravelClassType | null,
    travelType: TravelType | null,
    pProductId: string | null,
): void {
    for (const article of articles ?? []) {
        const productId = getProductId(article);
        const wouldBeSelected = hasSameValues(article, pProductId, travelClass, travelType, null);
        const ticketTypeAssociated = configureTicketTypes.find(({ id }) => id === productId);
        if (ticketTypeAssociated && wouldBeSelected) {
            ticketTypeAssociated.isSelected = true;
            break;
        }
    }

    const noTicketTypesSelected = configureTicketTypes.every(({ isSelected }) => !isSelected);
    if (noTicketTypesSelected && configureTicketTypes[0]) {
        // eslint-disable-next-line no-param-reassign
        configureTicketTypes[0].isSelected = true;
    }
}

function createCollectionOfTicketsType(articles: ArticleViewModel[]): ConfigureTicketType[] {
    const allTicketsType = articles?.map((article) => {
        const productId = getProductId(article);
        return createConfigureTicketTypeFromArticle(productId, article, false);
    });
    if (allTicketsType) {
        return uniqBy(allTicketsType, 'id') as ConfigureTicketType[];
    }
    return [];
}

/**
 * Return articlesBundle with the correct selected boolean state on them.
 * Select will be set to true depending on criteria passed in parameters.
 * If one of them is set to null, it will be ignored.
 *
 * There is some particular case :
 * - If each bundle are for dog or bike AND there is not criteria available from view (show
 * switch in order view to configure the ticket selection), so it will be selected by default.
 * The reason is : I've a Person's bundle, I select "Supersaver" and 1st class. Then I remove
 * the person's bundle and add a dog bundle. Dog bundle has only one available article, so not switch
 * to configure ticket will be shown at screen. So if my dog's article is not 1st class or not "Supersaver",
 * like selected previously with person's bundle, it must be selected by default.
 */
export function updateSelectedStateOfArticlesBundle(
    articlesBundle: ArticlesBundle,
    productId: string | null,
    tripClass: TravelClassType | null,
    travelType: TravelType | null,
    priceType: PriceType | null,
    selectedZones: SelectedZone | null,
    moreCriteriaAvailable = true,
    hasMoreTripClassAvailable = true,
    hasMoreTravelTypeAvailable = true,
): ArticlesBundle {
    const isSupersaverPrice = priceType === PriceType.Supersaver;
    const isBikeBundleValue = isBikeBundle(articlesBundle);
    const isDogBundleValue = isDogBundle(articlesBundle);
    const containsSupersaverArticleValue = containsSupersaverArticle(articlesBundle);
    let isSelected = false;
    let hasAlreadyOneSelected = false;
    const checkCriteriaWithoutPriceType =
        isSupersaverPrice && (isBikeBundleValue || (isDogBundleValue && !containsSupersaverArticleValue)) && moreCriteriaAvailable;
    const updatedAvailableArticles = articlesBundle.availableArticles.map((article) => {
        if (checkCriteriaWithoutPriceType) {
            isSelected = hasSameValues(
                article,
                productId,
                hasMoreTripClassAvailable ? tripClass : null,
                hasMoreTravelTypeAvailable ? travelType : null,
                null,
                selectedZones,
            );
        } else if ((isBikeBundleValue || isDogBundleValue) && !moreCriteriaAvailable) {
            isSelected = true;
        } else {
            isSelected = hasSameValues(
                article,
                productId,
                hasMoreTripClassAvailable ? tripClass : null,
                hasMoreTravelTypeAvailable ? travelType : null,
                priceType,
                selectedZones,
            );
        }

        /**
         * The second condition can be removed if for TPC (generic boolean is used only for them)
         * if offer returner respect strictly the travelTypes array sent at each offers generation.
         */
        if (isSelected && !hasAlreadyOneSelected) {
            hasAlreadyOneSelected = true;
            return {
                ...article,
                selected: true,
            };
        }
        return {
            ...article,
            selected: false,
        };
    });

    return {
        ...articlesBundle,
        availableArticles: updatedAvailableArticles,
    };
}

export function containsSupersaverArticle({ availableArticles }: ArticlesBundle): boolean {
    return availableArticles.some(({ isSupersaver }) => isSupersaver);
}

export function isDogBundle({ passenger }: ArticlesBundle): boolean {
    return passenger.type === PassengerType.Dog;
}

export function isBikeBundle({ passenger }: ArticlesBundle): boolean {
    return passenger.type === PassengerType.Bike;
}

/**
 * The uniq overkill filter is there because the GW can return 2 offers 100% same in some case.
 * Example: Dog offers Luzern - Geneva with supersaver tickets, there is "Billet parcours"
 * and "Billet dégriffé", but twice are more expansive than the daily card for dog,
 * so there is 2 daily card (which is same offer/object).
 * @param bundle
 */
export function hasMoreCriteriaAvailable({ availableArticles }: ArticlesBundle): boolean {
    return _.uniq(availableArticles.map((article) => JSON.stringify(article))).length > 1;
}

export function hasMoreCriteriaAvailableIn(bundles: ArticlesBundle[]): boolean {
    return bundles.some((bundle) => hasMoreCriteriaAvailable(bundle));
}

function hasMoreTravelClassIn(bundles: ArticlesBundle[]): boolean {
    return getUniqTravelClassOf(bundles).length > 1;
}

function hasMoreTravelTypeIn(bundles: ArticlesBundle[]): boolean {
    return getUniqDurationsOf(bundles).length > 1;
}

function hasMoreTravelClassInArticles(articles: ArticleViewModel[]): boolean {
    return getUniqTravelClassOfArticles(articles).length > 1;
}

/**
 * It will use criteria in parameters to search which article to select.
 * If null is passed, we ignore the concerned criteria in search.
 */
export function updateSelectedStateOfAllArticlesBundle(
    articlesBundles: ArticlesBundle[],
    productId: string | null,
    tripClass: TravelClassType | null,
    travelType: TravelType | null,
    priceType: PriceType | null,
    selectedZones: SelectedZone | null,
    chooseManually: boolean | null,
): ArticlesBundle[] {
    const results: ArticlesBundle[] = [];
    const moreCriteriaAvailable = hasMoreCriteriaAvailableIn(articlesBundles);
    const moreTripClassAvailable = hasMoreTravelClassIn(articlesBundles);
    const moreTravelTypeAvailable = hasMoreTravelTypeIn(articlesBundles);

    for (const articlesBundle of articlesBundles) {
        const updatedArticlesBundle = updateSelectedStateOfArticlesBundle(
            cloneDeep(articlesBundle) as ArticlesBundle,
            productId,
            tripClass,
            travelType,
            priceType,
            selectedZones,
            chooseManually ? undefined : moreCriteriaAvailable,
            chooseManually ? undefined : moreTripClassAvailable,
            chooseManually ? undefined : moreTravelTypeAvailable,
        );
        results.push(updatedArticlesBundle);
    }
    return results;
}

function hasSameValues(
    article: ArticleViewModel,
    id: string | null,
    travelClass: TravelClassType | null | undefined,
    travelType: TravelType | null | undefined,
    priceType: PriceType | null,
    selectedZones?: SelectedZone | null,
): boolean {
    let hasSameId = true;
    let hasSameClass = true;
    let hasSameTravelType = true;
    let hasSamePriceType = true;
    let hasSameZone = true;
    if (id) {
        hasSameId = getProductId(article) === id;
    }
    if (travelClass) {
        hasSameClass =
            article.travelClass?.id === travelClass ||
            (travelClass === TravelClassType.CLASS_2 && article.travelClass?.id === TravelClassType.CLASS_CHANGE);
    }
    if (travelType) {
        hasSameTravelType = article.travelType === travelType;
    }
    if (priceType) {
        hasSamePriceType = article.isSupersaver ? priceType === PriceType.Supersaver : priceType === PriceType.Normal;
    }
    const isQuickArticle = article.type === ArticleType.QuickArticle;
    const hasMultizones = isQuickArticle && (article as QuickArticleViewModel).zones?.length > 1;
    if (isQuickArticle && hasMultizones && selectedZones?.zones && selectedZones.zones.length > 0) {
        hasSameZone = _.isEqual(
            (article as QuickArticleViewModel).zones?.map((zone) => +zone.id),
            selectedZones.zones,
        );
    }
    return hasSameId && hasSameClass && hasSameTravelType && hasSamePriceType && hasSameZone;
}

export function findArticleBy(
    articles: ArticleViewModel[],
    id: string | null,
    travelClass: TravelClassType | null,
    travelType: TravelType | null,
    priceType: PriceType | null,
): ArticleViewModel | undefined {
    return articles.find((article) => {
        return hasSameValues(article, id, travelClass, travelType, priceType);
    });
}

function selectArticleByCriteriaIfNeeded(
    articles: ArticleViewModel[],
    travelClass: TravelClassType | null,
    travelType: TravelType | null,
    priceType: PriceType,
): ArticleViewModel[] {
    const selectedArticle = articles.find(({ selected }) => selected);
    if (!selectedArticle) {
        const articleToSelect = findArticleBy(articles, null, travelClass, travelType, priceType);
        if (articleToSelect) {
            articleToSelect.selected = true;
        }
    }
    return articles;
}

export function updateConfigureTicketSelected(
    configureTicket: ConfigureTicket,
    availableArticles: ArticleViewModel[],
    chooseTicketManually: boolean | null,
): ConfigureTicket {
    const clonedConfigureTicket = cloneDeep(configureTicket) as ConfigureTicket;
    const { selectedClass, selectedTravelType, selectedProductId, selectedPriceType } = configureTicket;
    const selectedClassToApply = determineTravelClassToApplyByArticles(
        { travelClass: selectedClass as TravelClassType },
        configureTicket,
        availableArticles,
    );
    let hasSelectedOne = false;
    const newTicketTypes: ConfigureTicketType[] = clonedConfigureTicket.ticketTypes.map((item) => {
        if (hasSelectedOne || (chooseTicketManually && selectedProductId !== item.id)) {
            return { ...item, isSelected: false };
        }
        const sameArticle = findArticleBy(
            availableArticles,
            chooseTicketManually ? selectedProductId : item.id,
            /**
             * We don't want to reselect automatically the correct ticket when
             * there is no one available with configureTicket class if we are in mode "choose manually"
             */
            chooseTicketManually ? (selectedClass as TravelClassType) : selectedClassToApply,
            selectedTravelType,
            selectedPriceType,
        );
        if (sameArticle) {
            hasSelectedOne = true;
            return { ...item, isSelected: true };
        }
        return { ...item, isSelected: false };
    });
    if (!hasSelectedOne) {
        return clonedConfigureTicket;
    }
    const selectedTicketType = newTicketTypes.find(({ isSelected }) => isSelected);

    return {
        ...clonedConfigureTicket,
        ticketTypes: newTicketTypes,
        selectedProductId: selectedTicketType?.id ?? null,
    };
}

export function isSamePassenger(passengerA: Traveler, passengerB: Traveler): boolean {
    return passengerA.id === passengerB.id && passengerA.type === passengerB.type;
}

export function addOrUpdateExistingBundle(existingBundles: ArticlesBundle[], newBundle: ArticlesBundle): ArticlesBundle[] {
    const indexOfElementToUpdate = existingBundles
        .filter((bundle) => !!bundle)
        .findIndex(({ passenger }) => isSamePassenger(passenger, newBundle.passenger));

    if (indexOfElementToUpdate >= 0) {
        const updated = [...existingBundles];
        updated[indexOfElementToUpdate] = newBundle;
        return updated;
    }

    return [...existingBundles, newBundle];
}

export function removeBundleByPassenger(existingBundles: ArticlesBundle[], passengerToRemoveBundle: Traveler): ArticlesBundle[] {
    return existingBundles.filter((bundle) => !!bundle && !isSamePassenger(bundle.passenger, passengerToRemoveBundle));
}

/**
 * Check in existingConfigureTicket if there is already the same ticket by ID, if there is we continue the iteration
 * else we add it to ticketTypes
 */
export function updateMissingTicketsTypesFrom(existingConfigureTicket: ConfigureTicket, newBundle: ArticlesBundle): ConfigureTicket {
    const updatedTicketTypes = [...existingConfigureTicket.ticketTypes];
    let availableArticles = newBundle.availableArticles;
    if (existingConfigureTicket.selectedZones) {
        availableArticles = newBundle.availableArticles.filter((article) => {
            if (isQuickArticleViewModel(article)) {
                return _.isEqual(
                    article.zones.map(({ id }) => +id),
                    existingConfigureTicket.selectedZones?.zones,
                );
            }
            return false;
        });
    }

    for (const article of availableArticles) {
        const productId = getProductId(article);
        const foundTicketType = updatedTicketTypes.find(({ id }) => id === productId);

        if (!foundTicketType) {
            const [articlePrice] = article.prices;
            updatedTicketTypes.push({
                id: productId,
                label: article.title,
                description: article.categoryDescription,
                isSelected: updatedTicketTypes.length === 0,
                priceInCents: articlePrice.amountInCents,
                currency: articlePrice.currency,
                routeQualifiers: article.routeQualifiers ?? null,
                supplementQualifiers: article.supplementQualifiers ?? null,
                tripType: article.tripType ?? null,
            });
        }
    }
    const selectedTicketType = updatedTicketTypes.find(({ isSelected }) => isSelected);
    return {
        ...existingConfigureTicket,
        selectedProductId: existingConfigureTicket.selectedProductId ?? selectedTicketType?.id ?? null,
        ticketTypes: updatedTicketTypes,
    };
}

export function getAllAvailableArticlesToUniqArray(articlesBundles: ArticlesBundle[] = []): ArticleViewModel[] {
    const nestedArrays = articlesBundles.map(({ availableArticles }) => availableArticles);
    const flattenArray = flatten(nestedArrays);
    const results: ArticleViewModel[] = [];
    flattenArray.forEach((article) => {
        const isExists = results.find((resultArticle) => {
            return hasSameValues(resultArticle, getProductId(article), article.travelClass?.id, article.travelType, null);
        });
        if (!isExists) {
            results.push(article);
        }
    });
    return results;
}

function getAllAvailableArticlesByCriterias(
    articlesBundles: ArticlesBundle[],
    travelClass: TravelClassType,
    travelType: TravelType | null,
    priceType: PriceType | null,
): ArticleViewModel[] {
    return flatten(
        articlesBundles.map((bundle) => {
            const isBikeBundleValue = isBikeBundle(bundle);
            const isDogBundleValue = isDogBundle(bundle);
            const containsSupersaverArticleValue = containsSupersaverArticle(bundle);
            return bundle.availableArticles.filter((article) => {
                if (isBikeBundleValue || (isDogBundleValue && !containsSupersaverArticleValue)) {
                    return hasSameValues(article, getProductId(article), travelClass, travelType, null);
                }
                return hasSameValues(article, getProductId(article), travelClass, travelType, priceType);
            });
        }),
    );
}
