import { Injectable } from '@angular/core';
import { CreditCard } from '@traas/boldor/all-models';
import { CreditCardsService } from '../services/credit-cards.service';
import { LoadCreditCards, LoadCreditCardsFail, LoadCreditCardsSuccess, SetCreditCard } from './credit-cards.action';
import { CreditCardsState } from './credit-cards.state';
import { CreditCardsActions, CreditCardsSelectors } from './index';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { LoggingService } from '@traas/common/logging';
import { PreferencesService } from '@traas/boldor/common/services/common/preferences/preferences.service';
import { from, Observable, of } from 'rxjs';
import { catchError, first, map, switchMap } from 'rxjs/operators';

@Injectable()
export class CreditCardsEffect {
    $loadCreditCards: Observable<LoadCreditCardsFail | LoadCreditCardsSuccess> = createEffect(() =>
        this.$actions.pipe(
            ofType<LoadCreditCards>(CreditCardsActions.CreditCardsActionTypes.LoadCreditCards),
            switchMap(() =>
                from(this.preferencesService.getCurrency()).pipe(
                    switchMap((currency) => this.creditCardService.getCreditCards(currency)),
                    map((cards) => new CreditCardsActions.LoadCreditCardsSuccess(cards)),
                    catchError((error) => {
                        this.logger.logLocalError(error);
                        return of(new CreditCardsActions.LoadCreditCardsFail(error));
                    }),
                ),
            ),
        ),
    );

    $loadCreditCardsSuccess: Observable<SetCreditCard> = createEffect(() =>
        this.$actions.pipe(
            ofType<LoadCreditCardsSuccess>(CreditCardsActions.CreditCardsActionTypes.LoadCreditCardsSuccess),
            switchMap(() => {
                return this.creditCardStore.select(CreditCardsSelectors.getState).pipe(first());
            }),
            switchMap(async (state: CreditCardsState) => {
                // we check in payment effect if null so dont change null to undefined
                let selectedCreditCard: CreditCard | null = null;
                if (!state.selectedCreditCard && !!state.cards && state.cards.length > 0) {
                    // select the first of the new cards
                    selectedCreditCard = state.cards[0];
                } else {
                    const currentCardId = state.selectedCreditCard?.id;
                    // try to select the corresponding selection among the new cards
                    const creditCardFound = state.cards?.find((card) => card.id === currentCardId);
                    if (creditCardFound) {
                        selectedCreditCard = creditCardFound;
                    }
                }
                return new CreditCardsActions.SetCreditCard(selectedCreditCard);
            }),
        ),
    );

    constructor(
        private logger: LoggingService,
        private $actions: Actions,
        private creditCardService: CreditCardsService,
        private creditCardStore: Store<CreditCardsState>,
        private preferencesService: PreferencesService,
    ) {}
}
