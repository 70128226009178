/* tslint:disable:max-classes-per-file */
import { NearestStop, Ticket, Traveler, Zone } from '@traas/boldor/all-models';
import { Action } from '@ngrx/store';
import { CategoryTicketsAdapter } from '../../../models/ticket/category-tickets';
import { GqlQuickArticle } from '../services/quick-article.service';

export enum TicketActionTypes {
    Load = '[Ticket] Loading...',
    LoadSuccess = '[Ticket] Load Success',
    LoadError = '[Ticket] Load Error',
    LoadQuickTicketsByZone = '[Ticket by zone] Loading...',
    LoadQuickTicketsByZoneSuccess = '[Ticket by zone] Load Success',
    LoadQuickTicketsByZoneError = '[Ticket by zone] Load Error',
    LoadTicketList = '[Ticket List] Loading...',
    LoadTicketListSuccess = '[Ticket List] Load Success',
    LoadTicketListError = '[Ticket List] Load Error',
}

export class Load implements Action {
    readonly type = TicketActionTypes.Load;

    constructor(public payload?: { nearestStop: NearestStop }) {}
}
export class LoadSuccess implements Action {
    readonly type = TicketActionTypes.LoadSuccess;

    constructor(public payload: { quickArticles: CategoryTicketsAdapter[] }) {}
}
export class LoadError implements Action {
    readonly type = TicketActionTypes.LoadError;

    constructor(public errorMessage: string) {}
}

export class LoadQuickTicketsByZone implements Action {
    readonly type = TicketActionTypes.LoadQuickTicketsByZone;

    constructor(public payload?: { articleId: number; zones: Zone[]; traveler: Traveler }) {}
}
export class LoadQuickTicketsByZoneSuccess implements Action {
    readonly type = TicketActionTypes.LoadQuickTicketsByZoneSuccess;

    constructor(public payload: { quickTicketsByZone: Ticket[] }) {}
}
export class LoadQuickTicketsByZoneError implements Action {
    readonly type = TicketActionTypes.LoadQuickTicketsByZoneError;

    constructor(public errorMessage: string) {}
}

export class LoadTicketList implements Action {
    readonly type = TicketActionTypes.LoadTicketList;

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    constructor() {}
}
export class LoadTicketListSuccess implements Action {
    readonly type = TicketActionTypes.LoadTicketListSuccess;

    constructor(public payload: { ticketList: GqlQuickArticle[] }) {}
}
export class LoadTicketListError implements Action {
    readonly type = TicketActionTypes.LoadTicketListError;

    constructor(public errorMessage: string) {}
}

export type TicketAction =
    | Load
    | LoadSuccess
    | LoadError
    | LoadQuickTicketsByZone
    | LoadQuickTicketsByZoneSuccess
    | LoadQuickTicketsByZoneError
    | LoadTicketList
    | LoadTicketListSuccess
    | LoadTicketListError;
