/**
 * Traveler specify somebody for who a ticket is bought.
 *
 * This could be a frequent traveler or the Customer itself.
 */
import { PassengerType } from '@traas/boldor/graphql-generated/graphql';
import * as moment from 'moment';
import { Customer, GuestCustomer, Traveler } from '@traas/boldor/all-models';
import { INPUT_DATETIME_SYNTHESE_FORMAT } from '@traas/boldor/all-helpers';

const UNKNOWN_TRAVELER_ID = '-1';
// this const is used in the gateway to distinguish between auth-customer and other passengers.
const GENERIC_TRAVELER_NAME = 'Generic';
const HUMAN_PASSENGER_TYPE = PassengerType.Person;

export function createTravelerFromAuthCustomer(customer: Customer, hasHalfFare: boolean): Traveler {
    return {
        id: customer.id,
        firstName: customer.firstName,
        birthDate: customer.birthDate,
        lastName: customer.lastName,
        hasHalfFare,
        isCustomer: true,
        title: customer.title?.value,
        type: HUMAN_PASSENGER_TYPE,
        email: customer.email,
    };
}

export function createTravelerFromGuestCustomer(guestCustomer: GuestCustomer): Traveler {
    return {
        id: UNKNOWN_TRAVELER_ID,
        firstName: guestCustomer.firstName,
        birthDate: guestCustomer.birthDate,
        lastName: guestCustomer.lastName,
        hasHalfFare: guestCustomer.hasHalfFare,
        isCustomer: false,
        title: guestCustomer.title,
        type: HUMAN_PASSENGER_TYPE,
        email: guestCustomer.email,
    };
}

export function createGenericAdultTraveler(): Traveler {
    return {
        id: UNKNOWN_TRAVELER_ID,
        firstName: GENERIC_TRAVELER_NAME,
        birthDate: moment().subtract(20, 'years').local().format(INPUT_DATETIME_SYNTHESE_FORMAT),
        lastName: GENERIC_TRAVELER_NAME,
        hasHalfFare: false,
        isCustomer: false,
        title: 'HERR', // see gqlToNovaTitleType in the gateway.
        type: HUMAN_PASSENGER_TYPE,
    };
}
