<ion-accordion-group multiple="true" [value]="'quick-tickets'">
  <!-- Accordion for GPS ticket-->
  @if (locationTicketsCategory && nearestStop.value) {
    <ion-accordion class="e2e-ticket-category">
      <ion-item slot="header">
        @if (!nearestStop.isLoading && isGpsPosition()) {
          <ion-icon slot="start" name="locate"></ion-icon>
        } @else if (!nearestStop.isLoading && isDefaultPosition()) {
          <ion-icon slot="start" src="assets/icons/no-locate.svg"></ion-icon>
        } @else if (nearestStop.isLoading) {
          <ion-spinner slot="start"></ion-spinner>
        }
        <ion-label>Billets rapides depuis votre localisation</ion-label>
      </ion-item>
      @for (ticket of locationTicketsCategory.tickets; track ticket.id; let last = $last) {
        @if (ticket.isDisplayed) {
          <app-ticket-list-item
            class="location-ticket"
            slot="content"
            (ticketBuy)="buyTicket.emit({ ticket, nearestStop: nearestStop.value })"
            [isLastChild]="last"
            [ticket]="ticket"
            [nearestStop]="nearestStop.value"
          ></app-ticket-list-item>
        }
      }
    </ion-accordion>
  } @else if (nearestStop.isLoading || ($isTicketsCategoryLoading | async)) {
    <ion-accordion class="e2e-ticket-category" disabled="true">
      <ion-item slot="header">
        <ion-spinner slot="start"></ion-spinner>
        <ion-label>{{ 'tickets.is-loading-nearest-stop' | translate }}</ion-label>
      </ion-item>
    </ion-accordion>
  } @else {
    <div class="ion-padding">
      <app-message message="Aucune offre de billet ne correspond à votre géolocalisation"></app-message>
    </div>
  }

  <!-- Accordion for quick ticket-->
  @if (otherTicketsCategory) {
    <ion-accordion class="e2e-ticket-category" value="quick-tickets">
      <ion-item slot="header">
        <ion-icon slot="start" src="assets/icons/tabs/monochrome/tickets.svg"></ion-icon>
        <ion-label>{{ otherTicketsCategory.category.title }}</ion-label>
      </ion-item>

      @for (ticket of otherTicketsCategory.tickets; track ticket.id; let last = $last) {
        @if (ticket.isDisplayed) {
          <app-ticket-list-item
            slot="content"
            (ticketBuy)="buyTicketByZone.emit({ ticket, nearestStop: null })"
            [isLastChild]="last"
            [ticket]="ticket"
            [nearestStop]="nearestStop.value"
          ></app-ticket-list-item>
        }
      }
    </ion-accordion>
  }

  <!-- Accordion for SMS ticket-->
  @if (smsTicketsCategories.length > 0) {
    <ion-accordion class="e2e-ticket-category main-accordion">
      <ion-item slot="header">
        <ion-icon slot="start" class="other-ticket-icon" src="assets/icons/tabs/monochrome/tickets.svg"></ion-icon>
        <ion-label>Billets SMS</ion-label>
      </ion-item>

      @for (smsTicketCategory of smsTicketsCategories; track smsTicketCategory.category.title) {
        <ion-accordion class="e2e-ticket-category" slot="content" class="sub-accordion">
          <ion-item slot="header">
            <ion-icon slot="start" class="other-ticket-icon" src="assets/icons/tabs/monochrome/tickets.svg"></ion-icon>
            <ion-label>{{ smsTicketCategory.category.title }}</ion-label>
          </ion-item>
          @for (ticket of smsTicketCategory.tickets; track ticket.id; let last = $last) {
            @if (ticket.isDisplayed) {
              <app-ticket-list-item
                slot="content"
                (ticketBuy)="buySmsTicket(ticket)"
                [isLastChild]="last"
                [ticket]="ticket"
                [nearestStop]="nearestStop.value"
              ></app-ticket-list-item>
            }
          }
        </ion-accordion>
      }
    </ion-accordion>
  }

  <!-- Accordion for button search by RI-->
  <ion-accordion readonly="true" toggle-icon="" class="e2e-ticket-category" (click)="navigateToItinerary.emit()">
    <ion-item slot="header">
      <ion-icon slot="start" class="ticket-icon" id="go-to-map" src="assets/icons/itinerarysearch-idle.svg"></ion-icon>
      <ion-label>{{ 'tickets.from-itinerary' | translate }}</ion-label>
    </ion-item>
  </ion-accordion>
</ion-accordion-group>
