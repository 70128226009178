import { ChangeDetectionStrategy, Component, inject, Input, OnInit } from '@angular/core';
import { CompanyService } from '@traas/boldor/company';
import { getZonesLabelFromZones, getZonesTextFromZones } from '../../../../services/common/quick-article/quick-article.utils';
import { getFormattedPriceFromPrice } from '@traas/boldor/all-helpers';
import { Article, Price, Ticket, Zone } from '@traas/boldor/all-models';
import { ErrorCodes, TechnicalError } from '@traas/common/models';
import { LoggingService } from '@traas/common/logging';

@Component({
    selector: 'ticket-detail',
    templateUrl: './ticket-detail.component.html',
    styleUrls: ['./ticket-detail.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TicketDetailComponent implements OnInit {
    #logger = inject(LoggingService);

    @Input({ required: true }) ticket!: Ticket;

    readonly isTpc = CompanyService.isTPC();
    dateFormat = 'dd.MM.yyyy';
    dateTimeFormat = 'dd.MM.yyyy HH:mm:ss';
    tripLabel: string | undefined = undefined;

    getFormattedPrice(price: Price): string {
        return getFormattedPriceFromPrice(price, false, false);
    }

    getBilletTypeLabel(article: Article): string {
        let billetTypeLabel = '';
        if (article.tarifOwner) {
            billetTypeLabel += `${article.tarifOwner}`;
        }
        if (article.title) {
            billetTypeLabel += ` ${article.title}`;
        }
        return billetTypeLabel;
    }

    getZonesLabel(zones: Zone[]): string {
        return getZonesLabelFromZones(zones);
    }

    getZonesText(zones: Zone[]): string {
        return getZonesTextFromZones(zones);
    }

    getTripLabel(): string | undefined {
        let tripLabel = this.ticket.article.travelClass ? this.ticket.article.travelClass.description : undefined;
        const { roundTrip } = this.ticket.article;
        if (roundTrip) {
            tripLabel += ` ${roundTrip},`;
        }
        return tripLabel;
    }

    ngOnInit(): void {
        this.tripLabel = this.ticket.isProcessing ? undefined : this.getTripLabel();
    }

    isDifferentThanZonesText(article: Article, locationValidity: string): boolean {
        const zonesText = this.getZonesText(article.zones);
        return zonesText !== locationValidity;
    }

    thereIsLocationsValidityToShow(article: Article): boolean {
        const someValuesArePresent = article.locationsValidity?.values?.length > 0;
        const someValuesMustBeShown = article.locationsValidity?.values?.some((location) =>
            this.isDifferentThanZonesText(article, location),
        );
        return someValuesArePresent && someValuesMustBeShown;
    }

    thereIsLocationsChoiceToShow(article: Article): boolean {
        const someValuesArePresent = article.locationsChoice?.values?.length > 0;
        const someValuesMustBeShown = article.locationsChoice?.values?.some((location) => this.isDifferentThanZonesText(article, location));
        return someValuesArePresent && someValuesMustBeShown;
    }

    isViaLocation(locationValidity: string): boolean {
        return locationValidity?.startsWith('via ');
    }

    getReferenceNumber(): string {
        const { refundReferenceNumber, referenceNumber } = this.ticket;
        if (refundReferenceNumber) {
            return refundReferenceNumber;
        }
        if (referenceNumber) {
            return referenceNumber.value;
        }

        this.#logger.logError(
            new TechnicalError(
                '[getOrderNumber] refundReferenceNumber or referenceNumber should be defined.',
                ErrorCodes.Ticket.MissingData,
                undefined,
                { ticketId: this.ticket.id },
            ),
        );
        return '';
    }

    getOrderNumber(): string {
        const { refundOrderNumber, orderNumber } = this.ticket;
        if (refundOrderNumber) {
            return refundOrderNumber;
        }
        if (orderNumber) {
            return orderNumber;
        }

        this.#logger.logError(
            new TechnicalError(
                '[getOrderNumber] refundOrderNumber or orderNumber should be defined.',
                ErrorCodes.Ticket.MissingData,
                undefined,
                { ticketId: this.ticket.id },
            ),
        );
        return '';
    }
}
