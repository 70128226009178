import { Injectable } from '@angular/core';
import { convertToError, LoggingService } from '@traas/common/logging';
import { UnsecureStorage } from './unsecure-storage.service';
import { PlatformUtilsService } from '../platform-utils.service';
import { ErrorCodes, TechnicalError } from '@traas/common/models';

/**
 * @deprecated NE PLUS UTILISER, utiliser uniquement typed-storage
 */
@Injectable()
export class UnsecureTypedStorage<T> {
    /**
     * @deprecated NE PLUS UTILISER, utiliser uniquement typed-storage
     */
    #getKeyName(key: string | number | symbol): string {
        return String(key);
    }

    /**
     * @deprecated NE PLUS UTILISER, utiliser uniquement typed-storage
     */
    constructor(
        protected unsecureStorage: UnsecureStorage,
        protected logger: LoggingService,
        protected platformUtilsService: PlatformUtilsService,
    ) {}

    /**
     * @deprecated NE PLUS UTILISER, utiliser uniquement typed-storage
     */
    async removeItem(key: keyof T): Promise<void> {
        await this.unsecureStorage.remove(this.#getKeyName(key));
    }

    /**
     * @deprecated NE PLUS UTILISER, utiliser uniquement typed-storage
     */
    async getItem<K extends keyof T>(key: K, defaultValue: T[K] | null = null): Promise<T[K] | null> {
        if (!(await this.hasItem(key))) {
            return defaultValue;
        }
        const keyName = this.#getKeyName(key);
        const data: string | null = await this.unsecureStorage.get(keyName);
        if (data) {
            try {
                return JSON.parse(data);
            } catch (error) {
                this.logger.logError(
                    new TechnicalError(
                        `[TypedStorage.getItem] Error during parsing of "${keyName}"`,
                        ErrorCodes.Technical.JsonParse,
                        convertToError(error),
                    ),
                );
                return defaultValue;
            }
        }
        return defaultValue;
    }

    /**
     * @deprecated NE PLUS UTILISER, utiliser uniquement typed-storage
     */
    async setItem<K extends keyof T>(key: K, value: T[K]): Promise<void> {
        try {
            const data = JSON.stringify(value);
            await this.unsecureStorage.set(this.#getKeyName(key), data);
        } catch (error) {
            this.logger.logLocalError(convertToError(error));
        }
    }

    /**
     * @deprecated NE PLUS UTILISER, utiliser uniquement typed-storage
     */
    async hasItem(key: keyof T): Promise<boolean> {
        const keyToFind = this.#getKeyName(key);
        const allKeys = await this.unsecureStorage.keys();
        const foundIndex = allKeys.findIndex((currentKey) => currentKey === keyToFind);
        return foundIndex !== -1;
    }

    /**
     * @deprecated NE PLUS UTILISER, utiliser uniquement typed-storage
     */
    async clear(): Promise<void> {
        await this.unsecureStorage.clear();
    }
}
