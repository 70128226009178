import { Zone } from '@traas/boldor/all-models';
import * as _ from 'lodash';
import { CompanyService } from '@traas/boldor/company';

function getZonesUniq(zones: Zone[]): Zone[] {
    return _.uniqBy(zones, 'id');
}

function joinZones(zones: Zone[]): string {
    const separator = CompanyService.isTPC() ? ' ' : '-';
    return getZonesUniq(zones)
        .map(({ id }) => id)
        .join(separator);
}

/*
 * 'Valable pour:' is shown only if there is one unique zone that contains 'Zone'
 */
export function getZonesLabelFromZones(zones: Zone[]): string {
    if (zones && zones.length > 0) {
        const zoneText = 'Zone';
        const formattedZones = joinZones(zones);
        const isIdContainsZoneWord = formattedZones.includes(zoneText);
        if (getZonesUniq(zones).length === 1 && isIdContainsZoneWord) {
            const validityText = 'Valable pour:';
            return validityText;
        }
    }
    return '';
}

/*
 * Zone is merged and prefixed if necessary
 */
export function getZonesTextFromZones(zones: Zone[]): string {
    if (zones && zones.length > 0) {
        const formattedZones = joinZones(zones);
        if (getZonesLabelFromZones(zones).length === 0) {
            const zoneText = 'Zone';
            const zonesText = 'Zones';
            const zonePrefix = getZonesUniq(zones).length === 1 ? zoneText : zonesText;
            return `${zonePrefix} ${formattedZones}`;
        }
        return formattedZones;
    }
    return '';
}
