import { AbstractAdapter, ChangePlaceEventSourceEnum, DynamicPlace, Place, TraasGeolocationCoordinates } from '@traas/boldor/all-models';
import { DivIcon, DivIconOptions } from 'leaflet';
import { Observable, of } from 'rxjs';

export abstract class DynamicPlaceAdapter<T extends Place<any>> extends AbstractAdapter<T> implements DynamicPlace {
    getDivIcon(iconOptions: DivIconOptions = {}): DivIcon | undefined {
        return undefined;
    }

    getEndpointIconName(): string {
        return '';
    }

    isDrawOnMapRequired(): boolean {
        return false;
    }

    isNeededToFitMap(): boolean {
        return false;
    }

    isDrawOnEndpointRequired(): boolean {
        return false;
    }

    $getHtmlLeafletIcon(): Observable<string> {
        return of('');
    }

    getChangePlaceEvent(): ChangePlaceEventSourceEnum {
        throw new Error('Need to be called in specific class');
    }

    transformToCoordinates(): TraasGeolocationCoordinates {
        throw new Error('Need to be called in specific class');
    }
}
