<ion-header>
  <app-page-title title="{{ 'payment.title' | translate }}" [showHomeLogo]="false"></app-page-title>
</ion-header>

<ion-content>
  <div class="flex-container">
    <ion-grid>
      <div class="payment-container">
        <ion-row>
          <ion-col size="12">
            <payment-status
              [showError]="(($isPaymentError | async)!) || (($isStillGeneratingTimeout | async)!)"
              [isOffline]="($isOffline | async)!"
              [progressionPercent]="($progressionPercent | async)!"
              [statusTitle]="($paymentStatusTitle | async)!"
              [statusDescription]="($paymentStatusDescription | async)!"
              [isRunningTooLong]="(($isRunningTooLong | async)!) && (($progressionPercent | async)!) < 100"
            ></payment-status>
          </ion-col>
        </ion-row>
        <ion-row class="ion-justify-content-center">
          @if (($isPaymentError|async) && !($isStillGeneratingTimeout|async)) {
            <ion-col>
              <ion-button oitClickUsingBackButton (click)="onGoBack()" expand="full" shape="round" fill="outline" tappable>
                {{ 'payment.back-to-cart-button' | translate }}
              </ion-button>
            </ion-col>
          }
          @if ($isStillGeneratingTimeout|async) {
            <ion-col>
              <ion-button (click)="goToBookingTab()" expand="full" shape="round" fill="outline" tappable>
                {{ 'tabs.journeys' | translate }}
              </ion-button>
            </ion-col>
          }
        </ion-row>
      </div>
    </ion-grid>
  </div>
</ion-content>
