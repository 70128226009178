import { Injectable } from '@angular/core';
import { NearestStop, Ticket, TicketDuration, Traveler, Zone } from '@traas/boldor/all-models';
import {
    GenerateQuickArticlesByZonesGQL,
    GenerateQuickArticlesByZonesMutationVariables,
    QuickArticlesGQL,
    TicketListGQL,
} from '@traas/boldor/graphql/generated/tpc/graphql';
import { PreferencesService } from '@traas/boldor/common/services/common/preferences/preferences.service';
import { BoldorLocalizationService } from '@traas/boldor/localization';
import { firstValueFrom } from 'rxjs';
import { ArticleService } from '../../../services/common/article/article.service';
import { GqlQuickArticle, QuickArticleService } from './quick-article.service';
import { QuickArticleInputServiceTpc } from './quick-article-input.service.tpc';

@Injectable({
    providedIn: 'root',
})
export class QuickArticleServiceTpc extends QuickArticleService {
    constructor(
        protected override articleService: ArticleService,
        private boldorLocalizationService: BoldorLocalizationService,
        private generateQuickArticlesByZones: GenerateQuickArticlesByZonesGQL,
        protected quickArticleInputService: QuickArticleInputServiceTpc,
        protected quickArticlesGQL: QuickArticlesGQL,
        private preferencesService: PreferencesService,
        private ticketListGQL: TicketListGQL,
    ) {
        super(articleService);
    }

    async getQuickArticles(
        nearestStop: NearestStop | null,
        passenger?: Traveler,
        durationsFilter?: TicketDuration[],
    ): Promise<GqlQuickArticle[]> {
        const defaultValue: GqlQuickArticle[] = [];
        if (!nearestStop) {
            return defaultValue;
        }
        const variables = await this.quickArticleInputService.createQuickArticleInputVariables(nearestStop, passenger, durationsFilter);
        const { data } = await firstValueFrom(this.quickArticlesGQL.mutate(variables));
        return data ? data.article.generateQuickArticles ?? defaultValue : defaultValue;
    }

    async getTicketList(): Promise<GqlQuickArticle[]> {
        const result = await firstValueFrom(this.ticketListGQL.fetch());
        return result.data.getQuickArticles as GqlQuickArticle[];
    }

    async generateQuickArticleAdapterByZones(articleId: number, zones: Zone[], traveler: Traveler): Promise<Ticket[]> {
        return (await this.generateQuickArticleByZone(articleId, zones, traveler)).map((quickQuickArticles) =>
            this.adaptQuickArticle(quickQuickArticles),
        );
    }

    async generateQuickArticleByZone(articleId: number, zones: Zone[], traveler: Traveler): Promise<GqlQuickArticle[]> {
        const languageId = this.boldorLocalizationService.languageCode;
        const variables: GenerateQuickArticlesByZonesMutationVariables = {
            generateQuickArticlesByZonesInput: {
                articleId,
                zones: zones.map(({ id }) => {
                    return { id };
                }),
                language: {
                    id: languageId,
                },
                isAuthenticatedCustomer: traveler.isCustomer,
                passengerType: traveler.type,
                passenger: {
                    firstName: traveler.firstName,
                    lastName: traveler.lastName,
                    birthDate: traveler.birthDate,
                    id: traveler.id,
                    hasHalfFare: traveler.hasHalfFare,
                    title: traveler.title,
                },
            },
        };

        const result = await firstValueFrom(this.generateQuickArticlesByZones.mutate(variables));
        return result.data?.article.generateQuickArticlesByZone ?? [];
    }
}
