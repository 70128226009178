import { Injectable } from '@angular/core';
import { ThresholdConfiguration, TimeDisplayMode } from '@traas/boldor/all-models';
import { getDelayInMinutes } from '@traas/boldor/business-rules';
import { addSeconds, differenceInMilliseconds, format } from 'date-fns';
import { ONE_MINUTE_IN_SECONDS, ONE_SECOND_IN_MS } from '@traas/common/utils';

@Injectable({
    providedIn: 'root',
})
export class DepartureTimeService {
    createDepartureTimeViewModel(departureTimeInfo: DepartureTimeInfo): DepartureTimeViewModel {
        const msUntilDeparture = this.#calculateMsUntilDeparture(departureTimeInfo);
        const msUntilOutdated = this.#calculateMsUntilOutdated(departureTimeInfo);
        const isTransportApproaching = this.#isTransportApproaching(departureTimeInfo, msUntilDeparture, msUntilOutdated);
        return {
            formattedTime: this.#formatDepartureTime(departureTimeInfo, msUntilDeparture),
            isTransportApproaching,
            formattedDelay: this.#getFormattedDelay(isTransportApproaching, departureTimeInfo, msUntilDeparture),
        };
    }

    #formatDepartureTime(departureTimeInfo: DepartureTimeInfo, timeDifferenceInMS: number): string {
        if (this.#isAbsoluteOrPast(departureTimeInfo, timeDifferenceInMS)) {
            return this.#getFormattedDepartureTime(departureTimeInfo.timestampInMS);
        }
        if (this.#isUnderShowMinutesThreshold(departureTimeInfo.thresholds, timeDifferenceInMS)) {
            return `${Math.round(timeDifferenceInMS / ONE_SECOND_IN_MS / ONE_MINUTE_IN_SECONDS)}'`;
        }
        return this.#getFormattedDepartureTime(departureTimeInfo.timestampInMS);
    }

    #isAbsoluteOrPast(params: DepartureTimeInfo, timeDifferenceInMS: number): boolean {
        return params.timeDisplayMode === TimeDisplayMode.Absolute || timeDifferenceInMS <= 0;
    }

    #isUnderShowMinutesThreshold(thresholdConfiguration: ThresholdConfiguration, timeDifferenceInMS: number): boolean {
        const minutesUntilDeparture = Math.round(timeDifferenceInMS / ONE_SECOND_IN_MS / ONE_MINUTE_IN_SECONDS);
        return minutesUntilDeparture < thresholdConfiguration.minutesThresholdToDisplayWaitingMinutes;
    }

    #isTransportApproaching(params: DepartureTimeInfo, msUntilDeparture: number, msUntilOutdated: number | null): boolean {
        const minimumThresholdInSeconds = params.thresholds.minimumThresholdToShowIconInSeconds;
        const secondsUntilDeparture = Math.round(msUntilDeparture / ONE_SECOND_IN_MS);
        const secondsUntilOutdated = msUntilOutdated !== null ? Math.round(msUntilOutdated / ONE_SECOND_IN_MS) : 0;

        return (
            this.#isWithinThreshold(secondsUntilDeparture, minimumThresholdInSeconds) ||
            this.#isWithinThreshold(secondsUntilOutdated, minimumThresholdInSeconds)
        );
    }

    #isWithinThreshold(timeInSeconds: number, threshold: number): boolean {
        return timeInSeconds > 0 && timeInSeconds <= threshold;
    }

    #calculateMsUntilDeparture(params: DepartureTimeInfo): number {
        const now = new Date();
        const departureDateWithDelay = addSeconds(new Date(params.timestampInMS), params.delayInSec);
        return differenceInMilliseconds(departureDateWithDelay, now);
    }

    #calculateMsUntilOutdated(params: DepartureTimeInfo): number | null {
        if (!params.outdatedDate) {
            return null;
        }
        const now = new Date();
        const outdatedDateWithDelay = addSeconds(params.outdatedDate, params.delayInSec);
        return differenceInMilliseconds(outdatedDateWithDelay, now);
    }

    #getFormattedDepartureTime(timestampInMS: number): string {
        return format(new Date(timestampInMS), 'HH:mm');
    }

    #isShowDelay(isTransportApproaching: boolean, params: DepartureTimeInfo, timeDifferenceInMS: number): boolean {
        const isAbsoluteOrPast = this.#isAbsoluteOrPast(params, timeDifferenceInMS);
        const isUnderShowMinutesThreshold = this.#isUnderShowMinutesThreshold(params.thresholds, timeDifferenceInMS);
        if (isAbsoluteOrPast) {
            return true;
        }

        return !(isTransportApproaching || isUnderShowMinutesThreshold);
    }

    #getFormattedDelay(isTransportApproaching: boolean, params: DepartureTimeInfo, timeDifferenceInMS: number): string {
        const showDelay = this.#isShowDelay(isTransportApproaching, params, timeDifferenceInMS);
        return showDelay
            ? getDelayInMinutes(addSeconds(new Date(params.timestampInMS), params.delayInSec), new Date(params.timestampInMS))
            : '';
    }
}

export interface DepartureTimeInfo {
    timestampInMS: number;
    timeDisplayMode: TimeDisplayMode;
    thresholds: ThresholdConfiguration;
    delayInSec: number;
    outdatedDate?: Date;
}

export interface DepartureTimeViewModel {
    formattedTime: string;
    formattedDelay: string;
    isTransportApproaching: boolean;
}
