import { ItineraryLegInput } from '@traas/boldor/graphql-generated/graphql';
import { TransportNames } from '@traas/boldor/all-models';
import { LegAdapter } from '../../../models/itinerary/leg';
import * as _ from 'lodash';
import { ErrorCodes, TechnicalError } from '@traas/common/models';

export class FrontToGqlItineraryConverter {
    static toItineraryLegInputCollection(legs: LegAdapter[]): ItineraryLegInput[] {
        const legsWithoutWalkOnStart: LegAdapter[] = _.dropWhile(legs, (leg: LegAdapter) => {
            return leg.isByFoot();
        });
        const legsWithoutWalkOnStartAndEnd: LegAdapter[] = _.dropRightWhile(legsWithoutWalkOnStart, (leg: LegAdapter) => {
            return leg.isByFoot();
        });
        return legsWithoutWalkOnStartAndEnd.map((leg, index, all) => this.#toItineraryLegInput(leg, index, all));
    }

    static #toItineraryLegInput(current: LegAdapter, index: number, all: LegAdapter[]): ItineraryLegInput {
        if (current.isByFoot()) {
            return this.#fromWalkLegToItineraryLegInput(index, all);
        }
        return this.#fromTransportLegToItineraryLegInput(current);
    }

    static #fromTransportLegToItineraryLegInput(leg: LegAdapter): ItineraryLegInput {
        const departureStopDidok = leg.getFirstStop().didok?.toString();
        if (!departureStopDidok) {
            throw new TechnicalError(
                '[fromTransportLegToItineraryLegInput] departureStopDidok should be defined.',
                ErrorCodes.Graphql.Mapping,
                undefined,
                { leg: leg.getData() },
            );
        }

        const arrivalStopDidok = leg.getLastStop().didok?.toString();
        if (!arrivalStopDidok) {
            throw new TechnicalError(
                '[fromTransportLegToItineraryLegInput] arrivalStopDidok should be defined.',
                ErrorCodes.Graphql.Mapping,
                undefined,
                { leg: leg.getData() },
            );
        }

        return {
            arrivalDateTime: leg.getArrivalDate(),
            arrivalStopDidok,
            departureDateTime: leg.getScheduledDepartureDate(),
            departureStopDidok,
            isFirstClassAuthorized: leg.getIsFirstClassAuthorized(),
            novaCompanyId: leg.getTransportCompanyId(),
            transitStopsDidok: leg.getTransitStopsDidok().map((didok) => didok?.toString()),
            transportCode: leg.getTransportCode(),
            serviceNumber: leg.getServiceNumber(),
        };
    }

    static #fromWalkLegToItineraryLegInput(indexOfWalkLeg: number, legs: LegAdapter[]): ItineraryLegInput {
        const currentLeg = legs[indexOfWalkLeg];
        const previousLeg = legs[indexOfWalkLeg - 1];
        const nextLeg = legs[indexOfWalkLeg + 1];
        const WALK_NOVA_COMPANY_ID = '00';
        const arrivalStopDidok = nextLeg.getFirstStop().didok?.toString();
        const departureStopDidok = previousLeg.getLastStop().didok?.toString();

        if (!arrivalStopDidok) {
            throw new TechnicalError(
                '[fromWalkLegToItineraryLegInput] arrivalStopDidok must not be null.',
                ErrorCodes.Graphql.Mapping,
                undefined,
                { leg: nextLeg.getData() },
            );
        }
        if (!departureStopDidok) {
            throw new TechnicalError(
                '[fromWalkLegToItineraryLegInput] departureStopDidok must not be null.',
                ErrorCodes.Graphql.Mapping,
                undefined,
                { leg: previousLeg.getData() },
            );
        }

        return {
            arrivalDateTime: nextLeg.getScheduledDepartureDate(),
            arrivalStopDidok,
            departureDateTime: previousLeg.getArrivalDate(),
            departureStopDidok,
            isFirstClassAuthorized: currentLeg.getIsFirstClassAuthorized(),
            novaCompanyId: WALK_NOVA_COMPANY_ID,
            transitStopsDidok: [],
            transportCode: TransportNames.WALK,
        };
    }
}
