import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { BuyTicketEvent, NearestStop, PositionType, TicketViewModel } from '@traas/boldor/all-models';
import { SMS, SmsOptions } from '@awesome-cordova-plugins/sms/ngx';
import { CategoryTicketsViewModel } from '@traas/boldor/common/features/ticket/models/ticket-list-mapper';
import { Store } from '@ngrx/store';
import { TicketState } from '@traas/boldor/common/features/ticket/store/ticket.state';
import { TicketStoreSelectors } from '@traas/boldor/common/features/ticket/store';

const PHONE_NUMBER_BUY_TICKET_BY_SMS = '456';

@Component({
    selector: 'travys-ticket-list',
    templateUrl: './ticket-list.component.html',
    styleUrls: ['./ticket-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TicketListComponent {
    @Input({ required: true }) $isOnline!: Observable<boolean>;
    @Input({ required: true }) locationTicketsCategory!: CategoryTicketsViewModel | null;
    @Input({ required: true }) nearestStop: { value: NearestStop | null; isLoading: boolean } = {
        value: null,
        isLoading: false,
    };
    @Input({ required: true }) otherTicketsCategory!: CategoryTicketsViewModel | null;
    @Input({ required: true }) smsTicketsCategories!: CategoryTicketsViewModel[];

    @Output() buyTicket = new EventEmitter<BuyTicketEvent>();
    @Output() buyTicketByZone = new EventEmitter<BuyTicketEvent>();
    @Output() navigateToItinerary = new EventEmitter<void>();

    $isTicketsCategoryLoading: Observable<boolean>;

    constructor(
        private sms: SMS,
        private store: Store<TicketState>,
    ) {
        this.$isTicketsCategoryLoading = this.store.select(TicketStoreSelectors.getIsQuickArticlesLoading);
    }

    isGpsPosition(): boolean {
        return this.nearestStop.value?.positionType === PositionType.Gps;
    }

    isDefaultPosition(): boolean {
        return this.nearestStop.value?.positionType === PositionType.Default;
    }

    async buySmsTicket(ticket: TicketViewModel): Promise<void> {
        const options: SmsOptions = { android: { intent: 'INTENT' } };
        try {
            console.log('Send SMS code', ticket.article.smsCode);
            await this.sms.send(PHONE_NUMBER_BUY_TICKET_BY_SMS, ticket.article.smsCode, options);
        } catch (error) {
            console.log('SMS not sent');
        }
    }
}
