import * as L from 'leaflet';
import { Circle, DefaultMapPanes, DivIcon, FitBoundsOptions, LatLng, LatLngBounds, Layer, LayerGroup, Marker, Point } from 'leaflet';
import { getAllDebugLayersConfiguration, layerName, LayerSet, RADIUS_AROUND_POINT_IN_METERS_WHEN_NO_STOPS } from './leaflet.constants';
import { ErrorCodes, Result, TechnicalError } from '@traas/common/models';
import { convertToError } from '@traas/common/logging';

export abstract class LeafletService {
    protected debugLayers: { [name: string]: LayerGroup } = {};
    #map?: L.Map;
    readonly #layers = new Map<layerName, Layer>();

    getMap(): L.Map {
        if (!this.hasMap()) {
            throw new TechnicalError('No map.', ErrorCodes.Map.NoMap);
        }
        return this.#map as L.Map;
    }

    getSize(): Point {
        return this.getMap().getSize();
    }

    setMap(map: L.Map): void {
        this.#map = map;
    }

    hasMap(): boolean {
        return this.#map !== undefined;
    }

    setZoom(zoomLevel: number): void {
        this.getMap().setZoom(zoomLevel);
    }

    getZoom(): number {
        return this.getMap().getZoom();
    }

    getBounds(): LatLngBounds {
        return this.getMap().getBounds();
    }

    getPanes(): ({ [p: string]: HTMLElement } & DefaultMapPanes) | [] {
        try {
            return this.getMap().getPanes();
        } catch (error: any) {
            console.error(`MapService:getPanes: ${error.message}`);
            return [];
        }
    }

    createMarker(latLng: LatLng, icon: DivIcon | undefined): Marker {
        return new L.Marker(latLng, { icon });
    }

    getLatLngBoundsFromMarker(marker: Marker): Result<LatLngBounds, TechnicalError> {
        try {
            const gpsPosition = marker.getLatLng();
            const bounds = this.createBoundsFromLatLng(gpsPosition, 0);
            return {
                success: true,
                value: bounds,
            };
        } catch (error) {
            return {
                success: false,
                error: new TechnicalError(`Can't get latlng bounds from marker`, ErrorCodes.Map.Marker, convertToError(error), {
                    marker: marker?.toString(),
                }),
            };
        }
    }

    createBoundsFromLatLng(latLng: LatLng, radiusFromCenterInMeters: number = RADIUS_AROUND_POINT_IN_METERS_WHEN_NO_STOPS): LatLngBounds {
        return latLng.toBounds(radiusFromCenterInMeters);
    }

    setLayer(name: layerName, layer: Layer | null): void {
        // fixme: This method smells! we have an obvious side effect. setLayer !== removeLayersFromMap
        // even worse, this side effects seems to be used by methods such as setTracksLayer, setCommercialStopsLayer etc…
        // This method should in my understanding only call this.layers.set, however it might be private and one should call
        // a new method: addLayerToMap(name: string, layer: Layer); which one would remove the layer from the map and add the new back
        // and then call the this.setLayer method.
        if (!layer) {
            return;
        }

        if (this.isLayerOnMap(name)) {
            this.removeLayerFromMap(name);
        }
        this.#layers.set(name, layer);
    }

    setLayers(layerSet: LayerSet): void {
        for (const [layerName, layer] of Object.entries(layerSet)) {
            this.setLayer(layerName, layer);
        }
    }

    /**
     * Show layer named on the active map. It must be added.
     */
    addLayerToMap(name: layerName): void {
        try {
            const layer = this.getLayerUsingName(name);
            if (layer && !this.getMap().hasLayer(layer)) {
                this.getMap().addLayer(layer);
            }
            if (!layer) {
                console.debug(`Layer not found: ${name}`);
            }
        } catch (error: any) {
            console.error(`MapService:addLayerToMapUsingName: ${error.message}`);
        }
    }

    getAverageDistanceFrom(center: LatLng, points: LatLng[]): number {
        const distances = points.map((point) => +point.distanceTo(center)).filter((distance) => !isNaN(distance) && distance > 0);
        const sum = distances.reduce((acc, cur) => acc + cur, 0);
        return sum / distances.length;
    }

    /**
     * Hide layer named.
     */
    removeLayerFromMap(layerName: layerName): void {
        try {
            const layer = this.getLayerUsingName(layerName);
            if (layer) {
                this.#removeMapLayer(layer);
            } else {
                console.debug('No layer to remove');
            }
        } catch (error) {
            // NO NO NO
            // console.log('Warning, attempt to remove a missing layer from the map: ', layerName);
        }
    }

    removeLayersFromMap(layersNames: layerName[]): void {
        layersNames.forEach((name) => {
            this.removeLayerFromMap(name);
        });
    }

    addLayersToMap(layersNames: layerName[]): void {
        layersNames.forEach((name) => {
            this.addLayerToMap(name);
        });
    }

    deleteLayersUsingName(layersNames: layerName[]): void {
        layersNames.forEach((name) => {
            this.deleteLayerUsingName(name);
        });
    }

    deleteLayerUsingName(layerName: layerName): void {
        this.removeLayerFromMap(layerName);
        this.#layers.delete(layerName);
    }

    isLayerOnMap(layerName: layerName): boolean {
        const layer = this.getLayerUsingName(layerName);
        return !!layer && this.hasMap() && this.getMap().hasLayer(layer);
    }

    getLayerUsingName(layerName: layerName): Layer | undefined {
        const layer = this.#layers.get(layerName);
        if (!layer) {
            console.debug(`No layer with this name ${layerName}`);
            return undefined;
        }
        return layer;
    }

    /**
     * Returns all layers, even if it's not drawn on map
     */
    getAllLayersNames(): layerName[] {
        return Array.from(this.#layers.keys());
    }

    createCircleIncluding(points: LatLng[], center: LatLng): Circle {
        let radius = RADIUS_AROUND_POINT_IN_METERS_WHEN_NO_STOPS;
        if (points && points.length > 0) {
            radius = this.getAverageDistanceFrom(center, points);
        }
        return new Circle(center, { radius, color: 'transparent' });
    }

    disableDragging(): void {
        if (this.hasMap()) {
            this.getMap().dragging.disable();
        } else {
            console.warn('disableDragging failed because map is undefined');
        }
    }

    enableDragging(): void {
        if (this.hasMap()) {
            this.getMap().dragging.enable();
        } else {
            console.warn('enableDragging failed because map is undefined');
        }
    }

    protected fitBounds(bounds: LatLngBounds, options: FitBoundsOptions = {}): Result<LatLngBounds, TechnicalError> {
        try {
            if (bounds && this.hasMap()) {
                return {
                    success: true,
                    value: this.getMap().fitBounds(bounds, options).getBounds(),
                };
            }
            return {
                success: false,
                error: new TechnicalError('Cannot fit bounds', ErrorCodes.Map.FitBounds, undefined, {
                    hasMap: this.hasMap(),
                    bounds: bounds,
                    options: options,
                }),
            };
        } catch (error) {
            return {
                success: false,
                error: new TechnicalError(`Cannot fit bounds`, ErrorCodes.Map.FitBounds, convertToError(error), {
                    hasMap: this.hasMap(),
                    bounds: bounds,
                    options: options,
                }),
            };
        }
    }

    protected initDebugLayersControl(): void {
        L.control
            .layers(undefined, this.debugLayers, {
                position: 'bottomleft',
            })
            .addTo(this.getMap());
    }

    protected initDebugLayers(): void {
        getAllDebugLayersConfiguration().forEach((layerConf) => {
            this.debugLayers[layerConf.name.toString()] = new LayerGroup();
        });
    }

    #removeMapLayer(layer: Layer): void {
        this.getMap().removeLayer(layer);
    }
}
