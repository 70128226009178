import { ChangeDetectionStrategy, Component, computed, ElementRef, inject, Input, NgZone, viewChild, viewChildren } from '@angular/core';
import { LegAdapter } from '../../../../models/itinerary/leg';
import { Leg } from '@traas/boldor/all-models';
import { ItineraryLegComponent } from '../itinerary-leg/itinerary-leg.component';
import { ItineraryThermometerDrawerService } from '../../services/itinerary-thermometer-drawer.service';

@Component({
    selector: 'app-itinerary-details-content',
    templateUrl: './itinerary-details-content.component.html',
    styleUrls: ['./itinerary-details-content.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItineraryDetailsContentComponent {
    container = viewChild('container', { read: ElementRef });
    legsComponent = viewChildren<ItineraryLegComponent>('leg');
    readonly #itineraryThermometerDrawerService = inject(ItineraryThermometerDrawerService);
    readonly #ngZone = inject(NgZone);
    walkingPathPairPoints = computed(() => {
        const uniquePointPairs: { startPoint: any; endPoint: any }[] = [];
        const existingPairs = new Set();
        const legsComponent = this.legsComponent();
        legsComponent.forEach((legComponent, index) => {
            const isByFoot = legComponent.leg.isByFoot();
            if (!isByFoot) return;

            const nextLeg = legsComponent.find((leg, i) => i > index && !leg.leg.isByFoot());
            const previousLeg = legsComponent
                .slice(0, index)
                .reverse()
                .find((leg) => !leg.leg.isByFoot());

            const startPoint = this.getStartPoint(previousLeg);
            const endPoint = this.getEndPoint(nextLeg);
            if (!startPoint && !endPoint) return;

            const pairString = `${previousLeg?.leg.getData().serviceId}-${nextLeg?.leg.getData().serviceId}`;
            if (!existingPairs.has(pairString)) {
                existingPairs.add(pairString);
                uniquePointPairs.push({ startPoint, endPoint });
            }
        });

        return uniquePointPairs;
    });

    get legs(): LegAdapter[] {
        return this.#_legs;
    }

    @Input({ required: true })
    set legs(value: Leg[]) {
        this.#_legs = value.map((leg) => new LegAdapter(leg));
    }

    #_legs: LegAdapter[] = [];

    processLegs(): void {
        (this.container()?.nativeElement as HTMLElement).querySelectorAll('.walking-path').forEach((path) => path.remove());

        let intervalId: any;
        const MAX_TIME_MS = 2000;
        let elapsedTime = 0;

        this.#ngZone.runOutsideAngular(() => {
            intervalId = setInterval(() => {
                elapsedTime += 10;

                // Si le temps maximal est atteint, arrêter la boucle
                if (elapsedTime >= MAX_TIME_MS) {
                    clearInterval(intervalId);
                    console.warn('Time limit reached, stopping the loop to draw itinerary path.');
                    return;
                }

                const pairPoints = this.walkingPathPairPoints();

                const isReadyToDraw = pairPoints.map(({ startPoint, endPoint }) =>
                    this.#itineraryThermometerDrawerService.isReadyToDraw(startPoint, endPoint, this.container(), 'bottom-to-top'),
                );

                if (isReadyToDraw) {
                    clearInterval(intervalId);
                    this.#ngZone.run(() => {
                        pairPoints.forEach(({ startPoint, endPoint }) => {
                            console.log(`Start Point: ${startPoint}, End Point: ${endPoint}`);
                            this.#itineraryThermometerDrawerService.drawWalkingLegPathBetween(startPoint, endPoint, this.container(), '');
                        });
                    });
                    return;
                }
            }, 10);
        });
    }

    getStartPoint(previousLegComponent: ItineraryLegComponent | undefined): HTMLElement | undefined {
        if (!previousLegComponent || previousLegComponent.leg.isByFoot()) {
            return undefined;
        }

        return previousLegComponent.svgAnchor()?.getEndPoint();
    }

    getEndPoint(nextLegComponent: ItineraryLegComponent | undefined): HTMLElement | undefined {
        if (!nextLegComponent || nextLegComponent.leg.isByFoot()) {
            return undefined;
        }
        return nextLegComponent.svgAnchor()?.getStartPoint();
    }
}
