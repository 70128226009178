import { Injectable } from '@angular/core';
import { MAX_BOOKMARKS } from '@traas/boldor/business-rules';
import { HomeState } from '../../../features/home/store';
import { SearchPlaceActions } from '../../../features/home/store/searchPlace';
import { Store } from '@ngrx/store';
import * as _ from 'lodash';
import { environment } from '@traas/boldor/environments';
import { Area, Bookmark, BookmarkStorage } from '@traas/boldor/all-models';
import { BookmarkAdapter, createBookmark } from '../../../models/bookmark/bookmark-adapter.model';
import { ToasterService } from '../toaster/toaster.service';
import { ObservableTypedStorage } from '@traas/common/utils';

@Injectable({
    providedIn: 'root',
})
export class BookmarkService {
    constructor(
        private toasterService: ToasterService,
        private bookmarkStorage: ObservableTypedStorage<BookmarkStorage>,
        private homeStateStore: Store<HomeState>,
    ) {}

    async initializeBookmarks(): Promise<void> {
        if (!(await this.bookmarkStorage.hasItem('bookmarks'))) {
            const defaultBookmarks = this.#getDefaultBookmark();
            await this.#addDefaultsBookmarksToStorage(defaultBookmarks);
        }

        const bookmarks = await this.getBookmarks();
        this.homeStateStore.dispatch(new SearchPlaceActions.InitBookmarks({ bookmarks }));
    }

    async getBookmarkAdapters(): Promise<BookmarkAdapter[]> {
        const bookmarks = await this.getBookmarks();
        return bookmarks.map((current) => new BookmarkAdapter(current));
    }

    async getBookmarks(): Promise<Bookmark[]> {
        return (await this.bookmarkStorage.getItem('bookmarks')) ?? [];
    }

    async setBookmarks(bookmarks: Bookmark[]): Promise<void> {
        await this.bookmarkStorage.setItem('bookmarks', bookmarks);
    }

    async getBookmarksOrderIds(): Promise<(string | undefined)[]> {
        return (await this.bookmarkStorage.getItem('bookmarksOrderIds')) ?? [];
    }

    async setBookmarksOrderIds(bookmarks: (string | undefined)[]): Promise<void> {
        await this.bookmarkStorage.setItem('bookmarksOrderIds', bookmarks);
    }

    async hasBookmarks(): Promise<boolean> {
        const bookmarks = await this.getBookmarks();
        return bookmarks.length > 0;
    }

    async hasBookmarkInStorage(area: Area): Promise<boolean> {
        // Check if current areaAdapter exist already in bookmark by comparing physicalStops
        const bookmarks = await this.getBookmarks();
        const bookmark = createBookmark(area);
        return BookmarkService.isAlreadyPresent(bookmark, bookmarks);
    }

    async hasFreeCell(): Promise<boolean> {
        return (await this.#countActualBookmarks()) < MAX_BOOKMARKS;
    }

    static isAlreadyPresent(bookmark: Pick<Bookmark, 'stops'>, bookmarks: Bookmark[]): boolean {
        return bookmarks.some(
            (current) =>
                _.isEqual(current.stops?.id?.sort(), bookmark.stops?.id?.sort()) ||
                _.isEqual(current.stops?.didok?.sort(), bookmark.stops?.didok?.sort()),
        );
    }

    async #countActualBookmarks(): Promise<number> {
        const actualBookmarks = await this.getBookmarks();
        return actualBookmarks.length;
    }

    async clearBookmarks(): Promise<void> {
        await this.bookmarkStorage.removeItem('bookmarks');
        await this.bookmarkStorage.removeItem('bookmarksOrderIds');
    }

    async #removeBookmarkOrderId(id: string): Promise<void> {
        const bookmarksIds = await this.getBookmarksOrderIds();
        const nextBookmarksIds = _.without(bookmarksIds, id);
        await this.setBookmarksOrderIds(nextBookmarksIds);
    }

    async removeBookmark(bookmark: Bookmark): Promise<void> {
        const bookmarkAdapters = await this.getBookmarkAdapters();
        const bookmarkToDelete = new BookmarkAdapter(bookmark);

        await this.#removeBookmarkOrderId(bookmarkToDelete.getId());

        const filteredBookmarks = bookmarkAdapters
            .filter((bookmarkAdapter) => !bookmarkAdapter.isEqual(bookmarkToDelete))
            .map((bookmarkAdapter) => bookmarkAdapter.getData());
        await this.setBookmarks(filteredBookmarks);
        await this.toasterService.presentSuccessOnRemovingBookmark();
    }

    async addBookmarkToStorage(bookmark: Bookmark): Promise<void> {
        const bookmarks = await this.getBookmarks();
        const isAlreadyExisting = BookmarkService.isAlreadyPresent(bookmark, bookmarks);

        if (isAlreadyExisting) {
            await this.toasterService.presentFailureBookmarkAlreadyAdded();
            return;
        }

        if (!(await this.hasFreeCell())) {
            await this.toasterService.presentBookmarkLimitReached();
            return;
        }
        await this.#appendBookmarkToStorage(bookmark);
        await this.toasterService.presentSuccessOnAddingBookmark();
    }

    async #appendBookmarkToStorage(bookmark: Bookmark): Promise<void> {
        const bookmarks = await this.getBookmarks();
        const bookmarkAndNewBookmark = [...bookmarks, bookmark];
        await this.setBookmarks(bookmarkAndNewBookmark);
    }

    #getDefaultBookmark(): Bookmark[] {
        return environment.defaultBookmarks ?? [];
    }

    async #addDefaultsBookmarksToStorage(defaultsBookmarks: Bookmark[]): Promise<void> {
        const oldBookmarks = await this.getBookmarks();
        const bookmarkAndNewBookmark = [...oldBookmarks, ...defaultsBookmarks];
        await this.setBookmarks(bookmarkAndNewBookmark);
    }
}
