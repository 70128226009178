import { Injectable } from '@angular/core';
import { convertToError, LoggingService } from '@traas/common/logging';
import { PlatformUtilsService } from '../platform-utils.service';
import { ErrorCodes, TechnicalError } from '@traas/common/models';
import { DataType, SecureStorage } from '@aparajita/capacitor-secure-storage';
import { isNumber } from 'lodash';

@Injectable()
export class TypedStorage<T> {
    #getKeyName(key: string | number | symbol): string {
        return String(key);
    }

    constructor(
        protected logger: LoggingService,
        protected platformUtilsService: PlatformUtilsService,
    ) {}

    async removeItem(key: keyof T): Promise<void> {
        await SecureStorage.remove(this.#getKeyName(key));
    }

    async getItem<K extends keyof T>(key: K, defaultValue: T[K] | null = null): Promise<T[K] | null> {
        if (!(await this.hasItem(key))) {
            return defaultValue;
        }
        const keyName = this.#getKeyName(key);
        const data = await SecureStorage.getItem(keyName);
        if (!data) {
            return defaultValue;
        }

        try {
            return JSON.parse(data);
        } catch (error) {
            this.logger.logError(
                new TechnicalError(
                    `[TypedStorage.getItem] Error during parsing of "${keyName}"`,
                    ErrorCodes.Technical.JsonParse,
                    convertToError(error),
                ),
            );
            return defaultValue;
        }
    }

    async setItem<K extends keyof T>(key: K, value: T[K]): Promise<void> {
        try {
            await SecureStorage.setItem(this.#getKeyName(key), JSON.stringify(value));
        } catch (error) {
            this.logger.logLocalError(convertToError(error));
        }
    }

    async hasItem(key: keyof T): Promise<boolean> {
        const keyToFind = this.#getKeyName(key);
        const allKeys = await SecureStorage.keys();
        const foundIndex = allKeys.findIndex((currentKey) => currentKey === keyToFind);
        return foundIndex !== -1;
    }

    async clear(): Promise<void> {
        await SecureStorage.clear();
    }
}
