@if (leg.isByFoot()) {
    <app-itinerary-leg-walk [leg]="leg" [isFirstLeg]="isFirstLeg" [isLastLeg]="isLastLeg"></app-itinerary-leg-walk>
} @else {
    <div class="padding-top">
        @if (leg.getAdditionalInformation().length > 0) {
            <traas-additional-information [class.offset-left]="!isFirstLeg" [additionalInformation]="leg.getAdditionalInformation()" />
        }

        @if (showLegDetails) {
            <app-itinerary-leg-stop-list
                #svgAnchor
                [legAdapter]="leg"
                tappable
                (transportClicked)="toggleShowLegDetails()"
                (departureClicked)="onDepartureClicked($event)"
                (arrivalClicked)="onArrivalClicked($event)"
            />
        } @else {
            <app-itinerary-leg-transport
                #svgAnchor
                [class.is-last-leg]="isLastLeg"
                [leg]="leg"
                tappable
                (transportClicked)="toggleShowLegDetails()"
                (warningClicked)="onWarningClicked($event)"
                (departureClicked)="onDepartureClicked($event)"
                (arrivalClicked)="onArrivalClicked($event)"
            />
        }
    </div>
}
