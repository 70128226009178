import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, timer } from 'rxjs';
import { PaymentStatus } from '../../features/payment/store/payment.state';
import { distinctUntilChanged, finalize, map, switchMap, takeWhile } from 'rxjs/operators';

export interface PaymentProgressStage {
    percent: [number, number];
    duration: number;
    status: PaymentStatus[];
}

@Injectable({
    providedIn: 'root',
})
export class ProgressService {
    #timeoutId: any;
    #isPaused = false;
    #startTime: number;
    #paymentProgressStages: PaymentProgressStage[] = [
        { percent: [0, 30], duration: 3300, status: [PaymentStatus.INITIAL] },
        { percent: [30, 32], duration: 20, status: [PaymentStatus.PREPARATION] },
        { percent: [32, 70], duration: 13000, status: [PaymentStatus.VERIFICATION] },
        { percent: [70, 71], duration: 10, status: [PaymentStatus.VERIFIED] },
        { percent: [71, 95], duration: 3200, status: [PaymentStatus.VALIDATION] },
        { percent: [95, 100], duration: 2000, status: [PaymentStatus.CAPTURED] }, // La dernière étape est un peu plus longue au cas ou le réseau est lent
    ];
    readonly #ACCEPTABLE_DURATION_TO_PROCESS_ALL_STAGES = this.#paymentProgressStages.reduce((acc, curr) => acc + curr.duration, 0);
    #remainingTime = this.#ACCEPTABLE_DURATION_TO_PROCESS_ALL_STAGES;
    $isRunningTooLong = new BehaviorSubject(false);

    startProgression($paymentStatus: Observable<PaymentStatus>): Observable<number> {
        this.$isRunningTooLong.next(false);

        if (!this.#isPaused) {
            this.startOrResumeTimer();
        }

        return $paymentStatus.pipe(
            distinctUntilChanged(),
            switchMap((status) => {
                const stage = this.#findCurrentStage(status, this.#paymentProgressStages);
                if (stage) {
                    return this.#progressObservable({ start: stage.percent[0], end: stage.percent[1], duration: stage.duration });
                } else {
                    return of(100);
                }
            }),
            finalize(() => this.#stopTimer()),
        );
    }

    startOrResumeTimer(): void {
        if (!this.#isPaused && !!this.#timeoutId) {
            console.warn('Timer already running');
            return;
        }
        clearTimeout(this.#timeoutId);
        this.#isPaused = false;
        this.#startTime = Date.now(); // Mémorise le moment du démarrage ou de la reprise
        this.#timeoutId = setTimeout(() => {
            this.$isRunningTooLong.next(true);
            this.#stopTimer();
        }, this.#remainingTime);
    }

    /**
     * La méthode pauseTimer met le timer en pause et mémorise le temps restant.
     * Elle est utilisée pour mettre le timer en pause lorsqu'on quitte la page de paiement,
     * par exemple pour aller sur la page Saferpay.
     */
    pauseTimer(): void {
        if (!this.#isPaused && !!this.#timeoutId) {
            clearTimeout(this.#timeoutId);
            this.#isPaused = true;
            this.#remainingTime -= Date.now() - this.#startTime; // Calcule le temps restant
        }
    }

    #stopTimer(): void {
        clearTimeout(this.#timeoutId);
        this.#timeoutId = null;
        this.#isPaused = false;
        this.#remainingTime = this.#ACCEPTABLE_DURATION_TO_PROCESS_ALL_STAGES;
    }

    #progressObservable(param: { start: number; end: number; duration: number }): Observable<number> {
        const startTime = Date.now();
        const intervalDuration = 50;

        return timer(0, intervalDuration).pipe(
            map(() => this.#calculateProgress(startTime, param.start, param.end, param.duration, Date.now())),
            takeWhile((progress) => progress < param.end, true),
        );
    }

    /**
     * La méthode calculateProgress calcule le pourcentage de progression entre startPercent et endPercent en fonction du temps écoulé
     * (timeElapsed). Voici les étapes :
     *
     * 1. Calcul du temps écoulé : timeElapsed est la différence entre le temps actuel (currentTime) et le temps de départ (startTime).
     * 2. Calcul de la progression : La progression est calculée en trouvant la fraction du temps écoulé par rapport à la
     *    durée totale (timeElapsed / duration), puis en l'appliquant à la différence entre endPercent et startPercent.
     * 3. Limitation de la progression : Math.min(Math.max(progress, startPercent), endPercent) garantit que la progression reste dans
     *    les limites de startPercent et endPercent.
     */
    #calculateProgress(startTime: number, startPercent: number, endPercent: number, duration: number, currentTime: number): number {
        const timeElapsed = currentTime - startTime;
        const progress = startPercent + (endPercent - startPercent) * (timeElapsed / duration);
        return Math.min(Math.max(progress, startPercent), endPercent);
    }

    #findCurrentStage(status: PaymentStatus, paymentProgressStages: PaymentProgressStage[]): PaymentProgressStage | null {
        const index = paymentProgressStages.findIndex((curr) => curr.status.includes(status));
        if (index === -1) return null;
        return paymentProgressStages[index];
    }
}
