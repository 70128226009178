import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Ticket } from '@traas/boldor/all-models';

@Component({
    selector: 'app-ticket-order',
    templateUrl: './ticket-order.component.html',
    styleUrls: ['./ticket-order.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TicketOrderComponent {
    @Input({ required: true }) ticket!: Ticket;
}
