import { ElementRef, inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { LoggingService } from '@traas/common/logging';

const LINE_PATH_WIDTH_IN_REM = 0.5;

@Injectable({
    providedIn: 'root',
})
export class ItineraryThermometerDrawerService {
    #rendererFactory = inject(RendererFactory2);
    #rootFontSize = parseFloat(getComputedStyle(document.documentElement).fontSize);
    #logger = inject(LoggingService);

    drawTransportLegPathBetween(
        element1: HTMLElement,
        element2: HTMLElement,
        container: ElementRef | undefined,
        options: {
            lineBackgroundColor: string;
            fallbackLineStyle: string;
            formattedDelayOfLeg: string;
            withBorder: boolean;
        },
    ): void {
        if (!container) {
            return;
        }

        try {
            const renderer = this.#rendererFactory.createRenderer(null, null);
            const positions = this.#calculatePositions(element1, element2, container, 'center-to-center');

            const svg = this.#createSVGElement(renderer);
            const legPath = this.#createLineElement(
                renderer,
                positions.lineStart,
                positions.lineEnd,
                options.lineBackgroundColor,
                options.fallbackLineStyle,
                `${LINE_PATH_WIDTH_IN_REM}rem`,
                false,
            );

            if (options.formattedDelayOfLeg) {
                const legDuration = this.#createTextElement(renderer, positions.textPosition, options.formattedDelayOfLeg);
                renderer.appendChild(svg, legDuration);
            }

            if (options.withBorder) {
                const borderLines = this.#createBorderLines(renderer, positions.lineStart, positions.lineEnd);
                renderer.appendChild(svg, borderLines.leftBorderLine);
                renderer.appendChild(svg, borderLines.rightBorderLine);
            }

            renderer.appendChild(svg, legPath);
            renderer.appendChild(container.nativeElement, svg);
        } catch (e) {
            this.#logger.logLocalError(e);
        }
    }

    drawWalkingLegPathBetween(
        element1: HTMLElement | null,
        element2: HTMLElement | null,
        container: ElementRef | undefined,
        formattedDuration: string,
    ): void {
        if (!container) {
            return;
        }
        try {
            const renderer = this.#rendererFactory.createRenderer(null, null);
            const positions = this.#calculatePositions(element1, element2, container, 'bottom-to-top');

            const svg = this.#createSVGElement(renderer);
            svg.classList.add('walking-path');
            const legPath = this.#createLineElement(
                renderer,
                positions.lineStart,
                positions.lineEnd,
                '#9b9b9b',
                '',
                `${LINE_PATH_WIDTH_IN_REM}rem`,
                true,
            );

            const legDuration = this.#createTextElement(renderer, positions.textPosition, formattedDuration);
            renderer.appendChild(svg, legDuration);

            renderer.appendChild(svg, legPath);
            renderer.appendChild(container.nativeElement, svg);
        } catch (e) {
            this.#logger.logLocalError(e);
        }
    }

    isReadyToDraw(
        element1: HTMLElement,
        element2: HTMLElement,
        container: ElementRef | undefined,
        drawingMode: 'center-to-center' | 'bottom-to-top' = 'center-to-center',
    ): boolean {
        if (!container) {
            return false;
        }
        try {
            const positions = this.#calculatePositions(element1, element2, container, drawingMode);
            return positions.lineEnd.y > 0 && positions.lineEnd.x > 0 && positions.lineStart.x > 0;
        } catch (e) {
            this.#logger.logLocalError(e);
            return false;
        }
    }

    #calculatePositions(
        element1: HTMLElement | null,
        element2: HTMLElement | null,
        container: ElementRef,
        drawingMode: 'center-to-center' | 'bottom-to-top',
    ): { lineStart: { x: number; y: number }; lineEnd: { x: number; y: number }; textPosition: { x: number; y: number } } {
        if (!element1 && !element2) {
            throw new Error('At least one element must be provided');
        }

        const offset = 5;
        const containerRect = container.nativeElement.getBoundingClientRect();

        const calculateElementCenter = (elementRect: ClientRect, containerLeft: number, containerTop: number): { x: number; y: number } => {
            const x = elementRect.left + elementRect.width / 2 - containerLeft;
            const y = elementRect.top + elementRect.height / 2 - containerTop;
            return { x, y };
        };

        const calculateElementTop = (elementRect: ClientRect, containerLeft: number, containerTop: number): { x: number; y: number } => {
            const x = elementRect.left + elementRect.width / 2 - containerLeft;
            const y = elementRect.top - containerTop - offset;
            return { x, y };
        };

        const calculateElementBottom = (elementRect: ClientRect, containerLeft: number, containerTop: number): { x: number; y: number } => {
            const x = elementRect.left + elementRect.width / 2 - containerLeft;
            const y = elementRect.top + elementRect.height - containerTop + offset;
            return { x, y };
        };

        let startPoint = { x: 0, y: 0 };
        let endPoint = { x: 0, y: 0 };

        // Minimum offset to avoid drawing the line outside the container
        const EMPTY_POINT_OFFSET = 4;

        if (element1 && element2) {
            if (drawingMode === 'center-to-center') {
                startPoint = calculateElementCenter(element1.getBoundingClientRect(), containerRect.left, containerRect.top);
                endPoint = calculateElementCenter(element2.getBoundingClientRect(), containerRect.left, containerRect.top);
            } else {
                startPoint = calculateElementBottom(element1.getBoundingClientRect(), containerRect.left, containerRect.top);
                endPoint = calculateElementTop(element2.getBoundingClientRect(), containerRect.left, containerRect.top);
            }
        } else if (element1) {
            if (drawingMode === 'center-to-center') {
                startPoint = calculateElementCenter(element1.getBoundingClientRect(), containerRect.left, containerRect.top);
            } else {
                startPoint = calculateElementBottom(element1.getBoundingClientRect(), containerRect.left, containerRect.top);
            }
            endPoint = { x: startPoint.x, y: containerRect.height - EMPTY_POINT_OFFSET };
        } else if (element2) {
            if (drawingMode === 'center-to-center') {
                endPoint = calculateElementCenter(element2.getBoundingClientRect(), containerRect.left, containerRect.top);
            } else {
                endPoint = calculateElementTop(element2.getBoundingClientRect(), containerRect.left, containerRect.top);
            }
            startPoint = { x: endPoint.x, y: EMPTY_POINT_OFFSET };
        }

        // Calculate the text's position considering the offset
        const horizontalOffsetForText = 18;
        const textMidX = (startPoint.x + endPoint.x) / 2 - horizontalOffsetForText;
        const textMidY = (startPoint.y + endPoint.y) / 2;

        return {
            lineStart: startPoint,
            lineEnd: endPoint,
            textPosition: { x: textMidX, y: textMidY },
        };
    }

    #createSVGElement(renderer: Renderer2): HTMLElement {
        const svg = renderer.createElement('svg', 'svg');
        renderer.addClass(svg, 'itinerary-transport-leg-thermometer-path');

        // Calculate the width and height of the SVG element
        const width = 200;

        // Set the width, height, and position attributes of the SVG
        svg.setAttribute('width', width.toString());
        svg.setAttribute('height', '100%');
        svg.setAttribute('style', 'position: absolute; top: 0; left: 0; pointer-events: none;');

        return svg;
    }

    #createLineElement(
        renderer: Renderer2,
        lineStart: { x: number; y: number },
        lineEnd: { x: number; y: number },
        color: string,
        fallbackLineStyle: string,
        strokeWidth = `${LINE_PATH_WIDTH_IN_REM}rem`,
        dashed: boolean,
    ): any {
        const line = renderer.createElement('line', 'svg');
        line.setAttribute('x1', lineStart.x.toString());
        line.setAttribute('y1', lineStart.y.toString());
        line.setAttribute('x2', lineEnd.x.toString());
        line.setAttribute('y2', lineEnd.y.toString());
        if (color.length) {
            line.setAttribute('stroke', color);
        } else {
            line.classList.add(fallbackLineStyle);
        }
        line.setAttribute('stroke-width', strokeWidth);
        if (dashed) {
            // Exemple de configuration de pointillé: longueur du trait, longueur de l'espace
            // Cette configuration peut être ajustée en fonction de l'apparence souhaitée
            line.setAttribute('stroke-dasharray', '15,15');
            line.setAttribute('stroke-linecap', 'round');
            // <path class="leaflet-interactive" stroke="#9B9B9B" stroke-opacity="1" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="15,15" fill="none" d="M263 171L200 249L179 231L113 330"></path>
        }
        return line;
    }

    #createTextElement(renderer: Renderer2, textPosition: { x: number; y: number }, content: string): any {
        const text = renderer.createElement('text', 'svg');
        renderer.addClass(text, 'duration');
        text.textContent = content;
        text.setAttribute('x', textPosition.x.toString());
        text.setAttribute('y', textPosition.y.toString());
        text.setAttribute('text-anchor', 'middle');
        text.setAttribute('dominant-baseline', 'middle');
        text.setAttribute('transform', `rotate(270, ${textPosition.x}, ${textPosition.y})`);
        return text;
    }

    #createBorderLines(
        renderer: Renderer2,
        lineStart: { x: number; y: number },
        lineEnd: { x: number; y: number },
    ): {
        leftBorderLine: any;
        rightBorderLine: any;
    } {
        const BORDER_OFFSET = 1;
        const totalOffset = (LINE_PATH_WIDTH_IN_REM / 2) * this.#rootFontSize + BORDER_OFFSET;
        const leftBorderLine = this.#createLineElement(
            renderer,
            { x: lineStart.x - totalOffset, y: lineStart.y },
            { x: lineEnd.x - totalOffset, y: lineEnd.y },
            'white',
            '',
            '1px',
            false,
        );
        const rightBorderLine = this.#createLineElement(
            renderer,
            { x: lineStart.x + totalOffset, y: lineStart.y },
            { x: lineEnd.x + totalOffset, y: lineEnd.y },
            'white',
            '',
            '1px',
            false,
        );

        return {
            leftBorderLine,
            rightBorderLine,
        };
    }
}
