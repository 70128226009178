import { Area, isBookmarkArea, isStopArea, StopArea, toBounds } from '@traas/boldor/all-models';
import { isAddress, isFollowGps, isMyGpsPosition, isPoi } from './change-place-event-source.helper';
import { LatLng } from 'leaflet';
import * as _ from 'lodash';
import { isNotNullOrUndefined, PhysicalStopAdapter } from '@traas/common/utils';
import { getMaxCommercialSopsToFireSearch } from './journey-search-conditions';

export class AreaHelper {
    /**
     * use company from environment.company
     * @param area
     * @param company
     */
    static containsTooManyCommercialStops(area: Area, company: string): boolean {
        return AreaHelper.getUniqCommercialStopIds(area).length > getMaxCommercialSopsToFireSearch(company);
    }

    static getPhysicalStopsDistinctByName(area: Area): PhysicalStopAdapter[] {
        if (isStopArea(area)) {
            const physicalStopAdapters = area.physicalStops.map((stop) => new PhysicalStopAdapter(stop));
            return _.uniqBy(physicalStopAdapters, (adapter) => adapter.getCommercialStopName());
        }
        console.log(`Can't getPhysicalStopsDistinctByName on area type different than StopArea`);
        return [];
    }

    /**
     * use company from environment.company
     * @param area
     * @param company
     */
    static isSuitableForSearch(area: Area | null, company: string): boolean {
        if (!area || AreaHelper.containsTooManyCommercialStops(area, company)) {
            return false;
        }

        const canSearchByStops = this.hasPhysicalStops(area);
        const canSearchByLatLng = this.isLatLngPlace(area) && !!area.metadata.latitude && !!area.metadata.longitude;
        return canSearchByLatLng || canSearchByStops;
    }

    static hasLatLng(area: Area): boolean {
        const { latitude, longitude } = area.metadata;
        return !!(latitude && longitude);
    }

    static getLatLng(area: Area): LatLng {
        const { latitude, longitude } = area.metadata;
        return new LatLng(Number.parseFloat(latitude), Number.parseFloat(longitude));
    }

    static hasPhysicalStops(area: Area): boolean {
        if (isBookmarkArea(area)) {
            return _.uniq(area.originalBookmark.stops.id).length > 0;
        }
        if (isStopArea(area)) {
            return (area as StopArea).physicalStops.length > 0;
        }
        return false;
    }

    static equals(left: Area, right: Area): boolean {
        const leftDidoks = this.getUniqStopsDidok(left);
        const rightDidoks = this.getUniqStopsDidok(right);
        const hasUniqueDidoks = leftDidoks.length > 0 || rightDidoks.length > 0;
        if (hasUniqueDidoks) {
            const mismatchedDidoks = _.xor(leftDidoks, rightDidoks);
            return mismatchedDidoks.length === 0;
        }

        const leftStopIds = this.getUniqCommercialStopIds(left);
        const rightStopIds = this.getUniqCommercialStopIds(right);
        const hasUniqueCommercialStops = leftStopIds.length > 0 || rightStopIds.length > 0;
        if (hasUniqueCommercialStops) {
            const mismatchedStops = _.xor(leftStopIds, rightStopIds);
            return mismatchedStops.length === 0;
        }

        const bothLatLngPlaceType = this.isLatLngPlace(left) && this.isLatLngPlace(right);
        if (bothLatLngPlaceType) {
            return this.isSameLatLngPlace(left, right);
        }

        return left.metadata.source === right.metadata.source;
    }

    static areBothFollowingGpsPosition(a: Area, b: Area): boolean {
        return isFollowGps(a.metadata.source) && isFollowGps(b.metadata.source);
    }

    static areClose(left: Area, right: Area): boolean {
        const MAX_CLOSENESS_DISTANCE_METERS = 50;

        const centerOfArea1 = toBounds(left.boundsRect)?.getCenter();
        const centerOfArea2 = toBounds(right.boundsRect)?.getCenter();

        if (!centerOfArea1 || !centerOfArea2) return false;

        const distanceMeters = centerOfArea1.distanceTo(centerOfArea2);
        return distanceMeters <= MAX_CLOSENESS_DISTANCE_METERS;
    }

    /**
     * Return uniq commercial stops id for StopArea, and uniq StopId for BookmarkArea.
     * Else empty array.
     */
    static getUniqCommercialStopIds(area: Area): string[] {
        if (isBookmarkArea(area)) {
            return _.uniq(area.originalBookmark.stops.id);
        } else if (isStopArea(area)) {
            return _.uniq(area.physicalStops.map((stop) => stop.associatedCommercialStop?.id).filter(isNotNullOrUndefined));
        } else return [];
    }

    static getUniqStopsDidok(area: Area): number[] {
        if (isBookmarkArea(area)) {
            return _.uniq(area.originalBookmark.stops.didok);
        } else if (isStopArea(area)) {
            return _.uniq(area.physicalStops.map((stop) => stop.associatedCommercialStop?.didok).filter(isNotNullOrUndefined));
        } else return [];
    }

    static isSameLatLngPlace(a: Area, b: Area): boolean {
        return AreaHelper.hasLatLng(a) && AreaHelper.hasLatLng(b) && AreaHelper.getLatLng(a).equals(AreaHelper.getLatLng(b));
    }

    static isLatLngPlace({ metadata }: Area): boolean {
        const source = metadata.source;
        return isPoi(source) || isAddress(source) || isMyGpsPosition(source) || isFollowGps(source);
    }
}
