<ion-row>
  <div class="departure">
    <div class="departure-first-col">
      @if (stopIconDetailName === null) {
        <div class="departure-cross"></div>
      }
      <ion-col class="booking-icon">
        <div class="previous-line-path" [ngClass]="linePathStyle" [style.background-color]="linePathBackgroundColor"></div>

        <ion-icon [class.tpg]="isTPG" [src]="thermoStopIconName"></ion-icon>

        @if (!isLast) {
          <div
            class="next-line-path"
            [ngClass]="linePathStyle"
            [style.background-color]="linePathBackgroundColor"
          ></div>
        }
      </ion-col>
    </div>

    @if (transitStopViewModel.hasDisruption) {
      <app-warning-icon
        class="departure-warning"
        (click)="openWarningModal($event)"
      ></app-warning-icon>
    }

    <div class="departure-col" [class.no-margin]="isTPG">
      <div class="stop-name-container">
        <div class="stop-name">
          {{ transitStopViewModel.stop | stopName }}
        </div>
        @if (transitStopViewModel.isCancelled) {
          @for (message of transitStopViewModel.cancellationMessages; track message) {
            <div class="cancelled-message-container">
              <traas-cancelled-trip-icon [isBus]="false"></traas-cancelled-trip-icon>
              <span class="cancelled-message" [innerHTML]="message.htmlContent"></span>
            </div>
          }
        }
      </div>
      <div class="departure-right">
        <div class="departure-time" [class.cancelled]="transitStopViewModel.isCancelled">
          <span>{{ transitStopViewModel.arrivalTime }}</span>
          @if (!transitStopViewModel.isCancelled) {
            <span class="delay">{{ transitStopViewModel.arrivalDelay }}</span>
          }
        </div>

        <div class="departure-options">
          @if (!transitStopViewModel.isCancelled && isTPC && stopIconDetailName) {
            <ion-icon
              (click)="stopInformationClicked()"
              class="on-demand e2e-stop-information-clickable"
              [src]="stopIconDetailName"
            ></ion-icon>
          }
        </div>

        <div
          class="departure-track"
          (click)="arrowClicked()"
          [class.hide]="!activateForwardArrow"
          [class.canceled]="transitStopViewModel.isCancelled"
          >
          <ion-icon name="arrow-forward"></ion-icon>
        </div>
      </div>
    </div>
  </div>
</ion-row>
