<div
  class="from ion-align-self-start"
  [class.no-padding]="defaultPaddingOnCol ? null : true"
  [style.padding-top]="paddingTop"
  [style.padding-bottom]="paddingBottom"
  >
  <span>{{ 'endpoint.from' | translate }}</span
    ><span class="value e2e-itinerary-departure">{{ departureStop | stopName }}</span>
  </div>
  @if (arrivalStop) {
    <div
      class="to ion-align-self-start"
      [class.no-padding]="defaultPaddingOnCol ? null : true"
      [style.padding-top]="paddingTop"
      [style.padding-bottom]="paddingBottom"
      >
      <span>{{ 'endpoint.to' | translate }}</span
        ><span class="value e2e-itinerary-arrival">{{ arrivalStop | stopName }}</span>
      </div>
    }
    @if (departureDatetime) {
      <div
        class="the ion-align-self-start"
        [class.no-padding]="defaultPaddingOnCol ? null : true"
        [style.padding-top]="paddingTop"
        [style.padding-bottom]="paddingBottom"
        >
        <span>{{ 'endpoint.on' | translate }}</span
          ><span class="value e2e-itinerary-datetime">{{ departureDatetime | dayOfWeek }}</span>
        </div>
      }
