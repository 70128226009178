import { Router } from '@angular/router';
import { GuestCustomer } from '@traas/boldor/all-models';
import { inject, Injectable } from '@angular/core';
import { GUEST_CUSTOMER_PARAM_NAME } from './url-constants';

const ORIGIN_PARAM_NAME = 'origin';

@Injectable({
    providedIn: 'root',
})
export class RouterStateService {
    readonly #router = inject(Router);

    getGuestCustomer(): GuestCustomer | undefined {
        const navigationState = this.#getNavigationState();
        if (navigationState?.[GUEST_CUSTOMER_PARAM_NAME]) {
            return navigationState[GUEST_CUSTOMER_PARAM_NAME] as GuestCustomer;
        }
        return undefined;
    }

    getOrigin(): string | undefined {
        const navigationState = this.#getNavigationState();
        if (navigationState?.[ORIGIN_PARAM_NAME]) {
            return navigationState[ORIGIN_PARAM_NAME];
        }
        return undefined;
    }

    #getNavigationState(): { [k: string]: any } | undefined {
        return this.#router.getCurrentNavigation()?.extras?.state;
    }
}
