/* eslint-disable @typescript-eslint/member-ordering */
import { GqlDepartureArticle } from '../../features/departure/services/departure.service';
import { GqlItineraryArticle } from '../../features/itinerary/services/itinerary.service';
// import * as ServiceToGql from '../../../../../graphql/source/common/converter/service-to-gql.converter';
// import * as GqlToService from '../../../../../graphql/source/common/converter/gql-to-service.converter';
import { getFormattedPriceFromPrice, GqlToService, ServiceToGql } from '@traas/boldor/all-helpers';
import * as Gql from '@traas/boldor/graphql-generated/graphql';
import { DepartureArticleViewModel, ItineraryArticleViewModel, QuickArticleViewModel, Zone } from '@traas/boldor/all-models';
import { CompanyService } from '@traas/boldor/company';

export class GqlArticleConverter {
    static readonly isTPG = CompanyService.isTPG();

    static isItineraryArticleViewModel(article: { type: Gql.ArticleType }): article is ItineraryArticleViewModel {
        return article && article.type === Gql.ArticleType.ItineraryArticle;
    }

    static toItineraryArticleInput(article: ItineraryArticleViewModel): Gql.ItineraryArticleInput {
        const [firstPrice] = article.prices;
        return {
            zones: ServiceToGql.zoneInput(article.zones),
            travelType: article.travelType,
            title: article.title,
            description: article.description,
            offerNumber: article.offerNumber,
            novaId: article.novaId,
            amount: {
                amountInCents: firstPrice.amountInCents,
                currency: ServiceToGql.currency(firstPrice.currency),
            },
            reduction: article.reduction ? ServiceToGql.reduction(article.reduction) : null,
            prices: article.prices.map((price) => {
                return {
                    amountInCents: price.amountInCents,
                    currency: ServiceToGql.currency(price.currency),
                };
            }),
            travelClass: ServiceToGql.travelClass(article.travelClass),
            unavailabilityReason: article.unavailabilityReason
                ? {
                      label: article.unavailabilityReason.label,
                      value: article.unavailabilityReason.value,
                  }
                : null,
            isSupersaver: article.isSupersaver,
            thirdPartyPayerInformation: article.thirdPartyPayerInformation,
        };
    }

    static toDepartureArticleInput(article: DepartureArticleViewModel): Gql.DepartureArticleInput {
        const [firstPrice] = article.prices;
        return {
            travelType: article.travelType,
            title: article.title,
            description: article.description,
            amount: {
                amountInCents: firstPrice.amountInCents,
                currency: ServiceToGql.currency(firstPrice.currency),
            },
            reduction: ServiceToGql.reduction(article.reduction),
            prices: article.prices.map((price) => {
                return {
                    amountInCents: price.amountInCents,
                    currency: ServiceToGql.currency(price.currency),
                };
            }),
            travelClass: ServiceToGql.travelClass(article.travelClass),
            articleId: article.articleId,
            zones: ServiceToGql.zoneInput(article.zones),
        };
    }

    static itineraryArticleToViewModel(article: GqlItineraryArticle): ItineraryArticleViewModel {
        const [firstPrice] = article.prices;
        return {
            categoryDescription: article.categoryDescription,
            description: article.description,
            novaId: article.novaId,
            offerNumber: article.offerNumber,
            paymentMeans: article.paymentMeans,
            reduction: GqlToService.reduction(article.reduction),
            supplementQualifiers: article.supplementQualifiers,
            routeQualifiers: article.routeQualifiers,
            tripType: article.roundTrip,
            travelType: article.travelType,
            unavailabilityReason: article.unavailabilityReason,
            zones: article.zones,

            prices: article.prices.map((price) => GqlToService.priceInCents(price)),
            travelClass: GqlToService.travelClass(article.travelClass),
            title: this.#getTitleFromGqlArticle(article),
            humanReadablePrice: getFormattedPriceFromPrice(GqlToService.price(firstPrice), false, !this.isTPG),
            smsCode: undefined,
            type: Gql.ArticleType.ItineraryArticle,
            selected: false,
            validityDurationLabel: article.validityDurationLabel,
            isSupersaver: article.isSupersaver,
            thirdPartyPayerInformation: article.thirdPartyPayerInformation
                ? {
                      isEligible: article.thirdPartyPayerInformation.isEligible,
                      fare: article.thirdPartyPayerInformation.fare,
                      thirdPartyName: article.thirdPartyPayerInformation.thirdPartyName,
                  }
                : null,
        };
    }

    static departureArticleToViewModel(article: GqlDepartureArticle): DepartureArticleViewModel {
        const [firstPrice] = article.prices;
        return {
            categoryDescription: '',
            reduction: GqlToService.reduction(article.reduction),
            articleId: article.articleId,
            description: article.description,
            paymentMeans: article.paymentMeans,
            unavailabilityReason: null,
            travelType: article.travelType,
            smsCode: article.smsCode,
            type: Gql.ArticleType.DepartureArticle,
            prices: article.prices.map((price) => GqlToService.priceInCents(price)),
            title: this.#getTitleFromGqlArticle(article),
            humanReadablePrice: getFormattedPriceFromPrice(GqlToService.price(firstPrice), false, !this.isTPG),
            travelClass: GqlToService.travelClass(article.travelClass),
            selected: false,
            validityDurationLabel: article.validityDurationLabel,
            zones: article.zones,
            thirdPartyPayerInformation: article.thirdPartyPayerInformation
                ? {
                      isEligible: article.thirdPartyPayerInformation.isEligible,
                      fare: article.thirdPartyPayerInformation.fare,
                      thirdPartyName: article.thirdPartyPayerInformation.thirdPartyName,
                  }
                : null,
        };
    }

    static quickArticleToViewModel(article: Gql.QuickArticle, zones?: Zone[]): QuickArticleViewModel {
        const [firstPrice] = article.prices ?? [];
        return {
            validityDurationLabel: article.validityDurationLabel ?? undefined,
            reduction: GqlToService.reduction(article.reduction),
            articleId: article.articleId,
            type: Gql.ArticleType.QuickArticle,
            description: article.description ?? '',
            category: article.category
                ? {
                      id: article.category.id,
                      description: article.category.description ?? '',
                  }
                : null,
            categoryDescription: article.categoryDescription ?? '',
            paymentMeans: article.paymentMeans ?? [],
            unavailabilityReason: null,
            travelType: article.travelType,
            smsCode: article.smsCode ?? undefined,
            prices: (article.prices ?? []).map((price) => GqlToService.priceInCents(price)),
            title: article.title ?? '',
            humanReadablePrice: getFormattedPriceFromPrice(GqlToService.price(firstPrice), false, !this.isTPG),
            travelClass: GqlToService.travelClass(article.travelClass),
            thirdPartyPayerInformation: article.thirdPartyPayerInformation
                ? {
                      isEligible: article.thirdPartyPayerInformation.isEligible,
                      fare: article.thirdPartyPayerInformation.fare,
                      thirdPartyName: article.thirdPartyPayerInformation.thirdPartyName,
                  }
                : null,
            selected: false,
            zones:
                zones?.map(({ id, description }) => ({
                    id,
                    description,
                })) ?? [],
        };
    }

    static #getTitleFromGqlArticle(article: GqlDepartureArticle | GqlItineraryArticle): string {
        if (article.title) {
            return article.title;
        }
        return this.#getLabelFromTravelClassType(article.travelClass.id);
    }

    static #getLabelFromTravelClassType(travelClassType: Gql.TravelClassType): string {
        switch (travelClassType) {
            case Gql.TravelClassType.Class_2:
                return '2cl.';
            case Gql.TravelClassType.Class_1:
                return '1cl.';
            case Gql.TravelClassType.ClassChange:
                return 'Surclassement';
        }
    }
}
