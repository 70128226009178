import { Injectable } from '@angular/core';
import { ItineraryCart } from '@traas/boldor/all-models';
import { BuyItineraryTicketArticlesGQL, BuyItineraryTicketArticlesMutationVariables } from '@traas/boldor/graphql-generated/graphql';
import { ServiceToGql } from '@traas/boldor/all-helpers';
import { createPassengerInputFrom } from '../../../models/customer';
import { GqlArticleConverter } from '../../../models/ticket/gql-article-converter';
import { firstValueFrom } from 'rxjs';
import { PurchaseEmailHelperService } from './purchase-email-helper.service';
import { ErrorCodes, TechnicalError } from '@traas/common/models';

@Injectable({
    providedIn: 'root',
})
export class ItineraryArticlePurchaseService {
    constructor(
        private buyItineraryTicketArticlesGQL: BuyItineraryTicketArticlesGQL,
        private purchaseEmailHelperService: PurchaseEmailHelperService,
    ) {}

    async buyItineraryArticles(parameters: ItineraryCart): Promise<string> {
        const variables = await this.#createItineraryBuyVariables(parameters);
        const result = await firstValueFrom(this.buyItineraryTicketArticlesGQL.mutate(variables));
        const orderId = result.data?.purchasePublic.buyItineraryTicketArticles?.orderId;
        if (!orderId) {
            if (!orderId) {
                throw new TechnicalError(
                    'buyItineraryTicketArticlesGQL response must contains an orderId',
                    ErrorCodes.Purchase.BuyArticles,
                    undefined,
                    { userId: variables.basket.articleSelections?.[0]?.passenger.id },
                );
            }
        }
        return orderId;
    }

    async #createItineraryBuyVariables(itineraryCart: ItineraryCart): Promise<BuyItineraryTicketArticlesMutationVariables> {
        const { articleSelections, itinerary } = itineraryCart;
        let defaultEmail: string | undefined = undefined;
        if (this.purchaseEmailHelperService.shouldFetchDefaultEmail(itineraryCart)) {
            defaultEmail = await this.purchaseEmailHelperService.fetchDefaultCustomerEmail();
        }
        // return the buy variables input
        return {
            basket: {
                itinerary: ServiceToGql.itineraryBasket(itinerary),
                articleSelections: articleSelections
                    .filter(({ article }) => !!article)
                    .map(({ article, passenger }) => {
                        return {
                            passengerType: passenger.type,
                            article: GqlArticleConverter.toItineraryArticleInput(article),
                            isAuthenticatedCustomer: passenger.isCustomer,
                            passenger: createPassengerInputFrom({ ...passenger, email: passenger.email ?? defaultEmail }),
                        };
                    }),
                requestStop: !!itineraryCart.operations?.stopRequest?.isChecked,
            },
        };
    }
}
