import { Injectable } from '@angular/core';
import { MAX_BOOKMARKS } from '@traas/boldor/business-rules';
import { ToastController } from '@ionic/angular';
import { ToastOptions } from '@ionic/core';
import { BoldorLocalizationService } from '@traas/boldor/localization';

@Injectable()
export class ToasterService {
    constructor(
        private toastCtrl: ToastController,
        private boldorLocalizationService: BoldorLocalizationService,
    ) {}

    async presentSuccessOnAddingBookmark(): Promise<void> {
        const options = await this.#createDefaultToastOptions();
        const favoriteAdded = await this.boldorLocalizationService.get('home-grid.add-favorite');
        const toast = await this.toastCtrl.create({
            ...options,
            message: favoriteAdded,
        });
        toast.present();
    }

    async presentSuccessOnRemovingBookmark(): Promise<void> {
        const favoriteRemoved = await this.boldorLocalizationService.get('home-grid.remove-favorite');
        const options = await this.#createDefaultToastOptions();
        const toast = await this.toastCtrl.create({
            message: favoriteRemoved,
            ...options,
        });
        toast.present();
    }

    async presentFailureBookmarkAlreadyAdded(): Promise<void> {
        const alreadyAdded = await this.boldorLocalizationService.get('home-grid.favorite-already-added');
        const options = await this.#createDefaultToastOptions();
        const toast = await this.toastCtrl.create({
            message: alreadyAdded,
            ...options,
        });
        toast.present();
    }

    async presentFailureBookingOutdated(): Promise<void> {
        const options = await this.#createDefaultToastOptions();
        const outdated = await this.boldorLocalizationService.get('tpc.request-stop.outdated');
        const toast = await this.toastCtrl.create({
            message: outdated,
            cssClass: 'danger',
            ...options,
        });
        toast.present();
    }

    async presentGenericFailure(message: string | undefined | null): Promise<void> {
        if (message) {
            const options = await this.#createDefaultToastOptions();
            const toast = await this.toastCtrl.create({
                message,
                color: 'danger',
                ...options,
            });
            await toast.present();
        }
    }

    async presentGenericWarning(message: string): Promise<void> {
        const options = await this.#createDefaultToastOptions();
        const toast = await this.toastCtrl.create({
            message,
            color: 'warning',
            ...options,
        });
        await toast.present();
    }

    async presentBookmarkLimitReached(): Promise<void> {
        const limitReached = await this.boldorLocalizationService.get('home-grid.favorite-limit-reached', { MAX_BOOKMARKS });
        const options = await this.#createDefaultToastOptions();
        const toast = await this.toastCtrl.create({
            message: limitReached,
            ...options,
        });
        return toast.present();
    }

    async presentSuccessItinerarySaving(): Promise<HTMLIonToastElement> {
        const saveItinerarySuccess = await this.boldorLocalizationService.get('cart.save-itinerary-success');
        const toast = await this.toastCtrl.create({
            message: saveItinerarySuccess,
            duration: 2000,
            color: 'success',
        });
        await toast.present();
        return toast;
    }

    async presentOfflineMessage(): Promise<void> {
        const offlineMessage = await this.boldorLocalizationService.get('common.no-internet-warning');
        const options = await this.#createDefaultToastOptions();
        const toast = await this.toastCtrl.create({
            message: offlineMessage,
            color: 'danger',
            ...options,
        });
        await toast.present();
    }

    async #createDefaultToastOptions(): Promise<ToastOptions> {
        const closeBtn = await this.boldorLocalizationService.get('common.close');
        return {
            duration: 3000,
            position: 'bottom',
            cssClass: `toaster-added`,
            buttons: [
                {
                    text: closeBtn,
                    role: 'cancel',
                },
            ],
        };
    }
}
